import React, { useContext } from "react";
import { Container, Row } from "react-bootstrap";
import GetInTouchForm from "./getInTouchForm";
import GetInTouchPersonal from "./getInTouchPersonal";
import "../../css/getInTouchMultiple.css";
import { ActualLangContext } from "../../contexts/ActualLangContext";

const GetInTouchMultiple = ({ title, personal }) => {
  const { actualLang } = useContext(ActualLangContext);
  return (
    <Container id="getInTouch" className="multiple" fluid>
      <Container>
        <div className="line"></div>
        <p>
          {actualLang === "en"
            ? "If you are seeking digital transformation for your company or organization, we can assist you. Do not hesitate to contact us to discuss your ideas and projects. We will provide you with a budget tailored to your needs."
            : "Si buscas la transformación digital para tu empresa u organización, podemos ayudarte. No dudes en ponerte en contacto con nosotros para comentar tus ideas y proyectos, y facilitarte un presupuesto acorde a sus necesidades."}
        </p>
      </Container>
      <Row>
        <GetInTouchForm title={title} withAlert/>
        <GetInTouchPersonal personal={personal} />
      </Row>
    </Container>
  );
};

export default GetInTouchMultiple;
