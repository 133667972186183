import React, { useEffect, useState } from "react";
import axios from "axios";
import BannerCulture from "../components/landings/bannerCulture";
import { Footer } from "../components/commons/footer";
import { LocationAddress } from "../components/usa/locationAddress";
import CareerJoinCulture from "../components/home/careerJoinCulture";
import MenuUsa from "../components/commons/menuUsa";
import { ExclusiveBenefits } from "../components/landings/exclusiveBenefits";
import { BodyCulture } from "../components/landings/bodyCulture";
import "../css/landingCulture.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
export const Culture = () => {
  const [header, setHeader] = useState({});
  const [bannerImg, setBannerImg] = useState({});
  const [bannerImgMobile, setBannerImgMobile] = useState({});
  const [chiefData, setChiefData] = useState({});
  const [videos, setVideos] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL_API}/landing-cultura`)
      .then((res) => {
        setVideos([
          {
            url: res.data.videoPossumus.urlYouTube1,
            description: res.data.videoPossumus.urlYouTube1Description,
            people: res.data.videoPossumus.people1,
          },
          {
            url: res.data.videoPossumus.urlYouTube2,
            description: res.data.videoPossumus.urlYouTube2Description,
            people: res.data.videoPossumus.people2,
          },
          {
            url: res.data.videoPossumus.urlYouTube3,
            description: res.data.videoPossumus.urlYouTube3Description,
            people: res.data.videoPossumus.people3,
          },
          {
            url: res.data.videoPossumus.urlYouTube4,
            description: res.data.videoPossumus.urlYouTube4Description,
            people: res.data.videoPossumus.people4,
          },
        ]);
        setHeader(res.data.header);
        setBannerImg(res.data.header.banner?.url);
        setBannerImgMobile(res.data.header.bannerMobile?.url);
        setChiefData(res.data.chief);
      });
  }, []);
  const {
    titleLanding,
    titleLandingPart1,
    titleLandingPart2,
    description,
    logoPossumus,
    imagePeople,
  } = header;
  const { name, positionWork, image, imageIcon } = chiefData;
  const url = process.env.REACT_APP_URL + "/en-en/culture";
  return (
    <HelmetProvider>
      <Helmet>
        <title>{`Possumus - Culture`}</title>
        <meta property="og:title" content="Possumus - Culture" />
        <link rel="canonical" href={`${url}`} />
        <meta property="og:url" content={`${url}`} />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="Possumus" />
        <meta
          property="og:image"
          content="https://possumustech.blob.core.windows.net/staticfiles/Miniatura_OG_b0b1d5c32e.png"
        />
      </Helmet>
      <MenuUsa />
        <>
          <BannerCulture
            idContainer="bannerLandingCulture"
            idButton="#formLandingEnglish"
            idHeader="titleMainLanding"
            imgBanner={bannerImg}
            imgBannerMobile={bannerImgMobile}
            title={titleLanding}
            template="none"
            description={description}
            logoPossumus={logoPossumus}
            imagePeople={imagePeople}
            titleLandingPart1={titleLandingPart1}
            titleLandingPart2={titleLandingPart2}
          />
          <BodyCulture
            videos={videos}
            name={name}
            positionWork={positionWork}
            image={image}
            imageIcon={imageIcon}
          />
          <ExclusiveBenefits />
          <CareerJoinCulture />
          <LocationAddress />
          <Footer />
        </>
    </HelmetProvider>
  );
};
