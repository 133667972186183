import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "../../css/what.css";
import { valueProposal } from "../../config/home/textPage";
import { useTranslation } from "react-i18next";
import WhatMoveUs from "../commons/WhatMoveUs";
const WhatMoves = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Container fluid id="whatmoveus">
        <Container>
          <WhatMoveUs />
          <Row className="mt-4 d-none d-sm-none d-md-block">
            <Col className="text-center mt-4">
              <Button
                variant="secondary"
                href={`/${i18n.language === "en" ? "en-en" : "es-es"}/about`}
              >
                {t("valueProposal.button", {
                  button: `${valueProposal.button}`,
                })}
              </Button>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default WhatMoves;
