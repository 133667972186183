export const cultureEs = {
  linkCulture: {
    name: "Cultura",
  },
  headerBannerCulture: {
    title: "Cultura Possumus ",
    titleLandingPart1: "¡Es hora de",
    titleLandingPart2: "evolucionar!",
    description: `Crecé en tu carrera profesional siendo parte
     de los mejores equipos de desarrollo,
     con proyectos escala mundial. `,
    descriptionPossumus1: `En Possumus generamos experiencias positivas
     a través de la tecnología, centradas en las personas,
      con proyectos escala mundial para clientes en todo el mundo.
       Para lograrlo trabajamos bajo una cultura de valor, innovadora, iterativa, cercana,
     que nos ayuda a generar una comunidad fuerte, que escala día a día.`,
    descriptionPossumus21: `Recorré el programa de `,
    descriptionPossumus2u1: "Beneficios",
    descriptionPossumus22: " o aplicá para conocer nuestras ",
    descriptionPossumus2u2: "Búsquedas activas.",
    buttonBenefits: "Beneficios",
    buttonGrowth: "{{buttonGrowth}}",
    buttonSearches: "Búsquedas activas",
  },
  bodyCulture: {
    growIntoPossumus: "Crecer en Possumus",
    growIntoPossumusDescription: `Conocé la experiencia Possumus en primera persona. Profesionales
    que ya eligieron trabajar en Possumus te cuentan lo que significa
    formar parte.`,
  },
  chiefCulture: {
    paragraph: `El trabajo es una dimensión más de nuestra vida,
    y ciertamente no es la más importante.`,
  },
  exclusiveBenefits: {
    title: "Beneficios exclusivos para vos",
  },
  careerJoinCulture: {
    description: "¡Uníte a nuestro equipo y hagamos algo genial, juntos!",
    button1: "Ver nuestras vacantes",
    button2: "Enviar curriculum",
  },
};
export const cultureEn = {
  linkCulture: {
    name: "{{name}}",
  },
  headerBannerCulture: {
    title: "{{title}}",
    titleLandingPart1: "{{titleLandingPart1}}",
    titleLandingPart2: "{{titleLandingPart2}}",
    description: "{{description}}",
    descriptionPossumus1: "{{descriptionPossumus1}}",
    descriptionPossumus21: "{{descriptionPossumus21}}",
    descriptionPossumus2u1: "{{descriptionPossumus2u1}}",
    descriptionPossumus22: "{{descriptionPossumus22}}",
    descriptionPossumus2u2: "{{descriptionPossumus2u2}}",
    buttonBenefits: "{{buttonBenefits}}",
    buttonGrowth: "Growth",
    buttonSearches: "{{buttonSearches}}",
  },

  bodyCulture: {
    growIntoPossumus: "{{growIntoPossumus}}",
    growIntoPossumusDescription: `{{growIntoPossumusDescription}} `,
  },
  chiefCulture: {
    paragraph: `{{paragraph}}`,
  },
  exclusiveBenefits: {
    title: "{{title}}",
  },
  careerJoinCulture: {
    description: "{{description}}",
    button1: "{{button1}}",
    button2: "{{button2}}",
  },
};
