import React from "react";
import "../css/SimplePay.css";
import { Fragment } from "react";
import MenuUsa from "../components/commons/menuUsa";
import { useContext } from "react";
import { ContentSimplePayLandingContext } from "../contexts/ContentSimplePayLandingContext";
import SideNavbar from "../components/commons/SideNavbar";
import Header from "../components/simplePay/Header";
import Cards from "../components/simplePay/Cards";
import withCircle from "../utils/WithCirlce";
import Features from "../components/simplePay/Features";
import Services from "../components/simplePay/Services";
import Modalities from "../components/simplePay/Modalities";
import GetInTouch from "../components/commons/getInTouch";
import { LocationAddress } from "../components/usa/locationAddress";
import { Footer } from "../components/commons/footer";
import { BannerDownload } from "../components/simplePay/BannerDownload";
import { Container, Row } from "react-bootstrap";
import { SideNavbarProvider } from "../contexts/SideNavbarContext";
import SEOContent from "../components/commons/SEOContent";

const SimplePay = () => {
  const {
    navegation,
    cards,
    SEOContent: SEOData,
  } = useContext(ContentSimplePayLandingContext);
  const CardsWithCircle = withCircle(
    Cards,
    { top: "-50%", right: "85%" },
    cards
  );

  return (
      <Fragment>
        <SEOContent SEOData={SEOData} />
        <MenuUsa />
        <Container fluid>
          <Row>
            <SideNavbarProvider>
              <Header />
              <CardsWithCircle />
              <Features />
              <Services />
              <Modalities />
              <SideNavbar items={navegation} />
            </SideNavbarProvider>
          </Row>
        </Container>
        <div
          style={{
            position: "relative",
            zIndex: "10",
            backgroundColor: "#fff",
          }}
          id="simple-pay-usuals"
        >
          <BannerDownload />
          <GetInTouch />
          <LocationAddress />
          <Footer />
        </div>
      </Fragment>
  );
};

export default SimplePay;
