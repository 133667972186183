import "./Banner.css";
import { Button, Container, Image } from "react-bootstrap";
import MSPartner from "../../../../assets/landings/houston/MSPartner.svg";
import MSPartnerBlack from "../../../../assets/landings/houston/MSPartnerBlack.svg";
import IsoCertificate from "../../../commons/IsoCertificate";
import Form from "../Form";

export default function Banner() {
  return (
    <>
      <Container className="bannerBackground" fluid></Container>
      <Container className="banner">
        <h1>AI-Powered <br />Software Solutions</h1>
        <p>Scale up your projects with customized <br />AI-generated software solutions.</p>
        <Button variant="quinary" href="#ourExpertise">Learn more</Button>
        <Form className="d-md-none" />
        <Image className="d-none d-md-block" src={MSPartner} alt="Microsoft Partner" />
        <Image className="d-md-none" src={MSPartnerBlack} alt="Microsoft Partner" />
        <IsoCertificate textColor="#fff" />
      </Container>
    </>
  );
}
