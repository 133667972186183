import React from "react";
import { Container, Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { sectionCloudSre } from "../../config/sre/textPage";
import { bannerDownload } from "../../config/Commons/textPage";

export const DevopsCloud = () => {
  const { t } = useTranslation();
  return (
    <>
      <Container fluid id="CloudInfrastructure">
        <Container>
          <Row className="justify-content-md-center">
            <Col lg={10}>
              <h2 className="text-center mt-4 p-1">
                {t("sectionCloudSre.title", {
                  title: `${sectionCloudSre.sectionPlatformTitle}`,
                  interpolation: { escapeValue: false },
                })}
              </h2>
            </Col>
          </Row>
          <Row className="justify-content-md-center text-center p-2">
            <Col lg={9}>
              <h3>
                {t("sectionCloudSre.paragraph", {
                  paragraph: `${sectionCloudSre.paragraphPlatform}`,
                })}
              </h3>
            </Col>
          </Row>
          <Row className="justify-content-md-center text-center row-list-devops">
            <Col lg={6}>
              <div className="list-onshore">
                <ul>
                  <li>
                    {t("sectionCloudSre.item1", {
                      item1: `${sectionCloudSre.item1}`,
                    })}
                  </li>
                  <li>
                    {t("sectionCloudSre.item2", {
                      item2: `${sectionCloudSre.item2}`,
                    })}
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={6}>
              <div className="list-onshore margin-items">
                <ul>
                  <li>
                    {t("sectionCloudSre.item3", {
                      item3: `${sectionCloudSre.item3}`,
                    })}
                  </li>
                  <li>
                    {t("sectionCloudSre.item4", {
                      item4: `${sectionCloudSre.item4}`,
                    })}
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="justify-content-center align-items-center d-flex  mt-4">
              <Button
                variant="secondary"
                href="#getInTouch"
                className="paddingButtonMainBanner"
              >
                {t("buttonsAndAccesories.buttonGetFree", {
                  buttonGetFree: `${bannerDownload.buttonGetFree}`,
                })}
              </Button>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};
