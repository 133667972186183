import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { createContext } from "react";
import { ActualLangContext } from "./ActualLangContext";
import axios from "axios";
import { AuthStrapiContext } from "./AuthStrapiV3Context";

export const ContentItaasContext = createContext({});

export const ContentItaasProvider = ({ children }) => {
  const { actualLang } = useContext(ActualLangContext);
  const { token } = useContext(AuthStrapiContext);

  const [loadedData, setLoadedData] = useState(false);
  const [SEOContent, setSEOContent] = useState({});
  const [headerContent, setHeaderContent] = useState({});
  const [infoButtons, setInfoButtons] = useState([]);
  const [introductionContent, setIntroductionContent] = useState({});
  const [servicesIT, setServicesIT] = useState({});
  const [description, setDescription] = useState({});

  useEffect(() => {
    setLoadedData(false);
    const url = `${process.env.REACT_APP_URL_API}/pages-itaa-s?_locale=${actualLang}`;
    if(token){
      axios
      .get(url, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setSEOContent(res.data?.SEOMetaData);
        setHeaderContent(res.data?.header[0]);
        setInfoButtons(res.data?.buttons);
        setIntroductionContent(res.data?.introduction[0]);
        setServicesIT(res.data?.servicesIt[0]);
        setDescription(res.data?.description[0]);
        setLoadedData(true);
      }).catch(err => console.log(`Ocurrio un error al obtener los datos de la pagina de ITaaS: ${err.message}`))
    }
  }, [actualLang, token]);
  return (
    <ContentItaasContext.Provider
      value={{
        loadedData,
        SEOContent,
        headerContent,
        infoButtons,
        introductionContent,
        servicesIT,
        description,
      }}
    >
      {children}
    </ContentItaasContext.Provider>
  );
};
