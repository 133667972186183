import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";

const ClutchReviews = () => {
  const [actualScale, setActualScale] = useState(100)
  useEffect(() => {
    if(window.innerWidth > 1500){
      setActualScale(135)
    }
    // add widget to end of body and run it
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://widget.clutch.co/static/js/widget.js";
    script.async = true;
    document.body.appendChild(script);
    // run script
    script.onload = () => {
      window.CLUTCHCO.Init();
    };
    return () => {
      window.CLUTCHCO.Destroy();
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Container fluid id="clutchContainer">
      <div
        class="clutch-widget"
        data-url="https://widget.clutch.co"
        data-widget-type="12"
        data-height="375"
        data-nofollow="true"
        data-expandifr="true"
        data-scale={actualScale}
        data-reviews="2289137,2276191,2164117,2061293,2026336,2018146,2012647,1984891"
        data-clutchcompany-id="1572791"
      ></div>
    </Container>
  );
};

export default ClutchReviews;
