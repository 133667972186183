import React from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import arrow from "../../../assets/Arrow.svg";
import { IterativeDevelopmentDesk } from "./iterativeDevelopmentDesk";
import { IterativeDevelopmentMobile } from "./iterativeDevelopmentMobile";
import { useTranslation } from "react-i18next";
import { softwareEngineering } from "../../../config/softwareEngineering/textPage";
import { bannerDownload } from "../../../config/Commons/textPage";

export const IterativeDevelopmentMain = () => {
  const { t } = useTranslation();
  return (
    <>
      <Row
        className="justify-content-md-center spacing"
        id="IterativeDevelopment"
      >
        <Col lg={10} className="justify-content-center d-flex">
          <Image src={arrow} alt="" />
        </Col>
        <Col lg={10}>
          <h2 className="text-center mt-4">
            {t("sectionIterative.title", {
              title: `${softwareEngineering.sectionIterativeTitle}`,
            })}
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-md-center text-center spacingSectionSoftwareEngineering">
        <Col lg={9}>
          <h3>
          {t("sectionIterative.paragraph", {
              paragraph: `${softwareEngineering.paragraphIterative}`,
            })}
          </h3>
        </Col>
      </Row>
      <IterativeDevelopmentDesk />
      <IterativeDevelopmentMobile />
      <Row>
        <Col className="justify-content-center align-items-center d-flex mt-4">
          <Button
            variant="secondary"
            href="#getInTouch"
            className="paddingButtonMainBanner"
          >
            {t("buttonsAndAccesories.buttonGetFree", {
              buttonGetFree: `${bannerDownload.buttonGetFree}`,
            })}
          </Button>
        </Col>
      </Row>
    </>
  );
};
