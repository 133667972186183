import { Button, Col, Row } from "react-bootstrap";
import { bannerDownload } from "../../../config/Commons/textPage";
import { useTranslation } from "react-i18next";
import ServicesDesk from "./ServicesDesk";
import ServicesMobile from "./ServicesMobile";

const ServicesMain = () => {
  const { t } = useTranslation();

  return (
    <>
      <ServicesDesk />
      <ServicesMobile />
      <Row>
        <Col className="justify-content-center align-items-center d-flex mb-4">
          <Button
            variant="secondary"
            href="#getInTouch"
            className="paddingButtonMainBanner"
          >
            {t("buttonsAndAccesories.buttonGetFree", {
              buttonGetFree: `${bannerDownload.buttonGetFree}`,
            })}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ServicesMain;
