import { useContext } from "react";
import arrowDown from "../../assets/ArrowDown.svg";
import { Col, Container, Row, Image } from "react-bootstrap";
import { ContentItaasContext } from "../../contexts/ContentItaasContext";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import ListOnShore from "../commons/listOnShore";

const Introduction = () => {

  const { introductionContent } = useContext(ContentItaasContext);
  
  return (
    <Container fluid id="introduction">
      <Row className="justify-content-md-center">
        <Col lg={10} className="justify-content-center d-flex">
          <Image src={arrowDown} alt="" />
        </Col>
        <Col lg={10}>
          <h2 className="text-center mt-4">{introductionContent.Title}</h2>
        </Col>
      </Row>
      <Row className="justify-content-md-center text-center p-2">
        <Col lg={7}>
          <p className="subtitle">
            <ReactMarkdown>{introductionContent.Subtitle}</ReactMarkdown>
          </p>
        </Col>
      </Row>
      <ListOnShore items={introductionContent.ItemList} />
      <Row className="justify-content-md-center text-center p-2">
        <Col lg={7}>
          <p className="subtitle">
            <ReactMarkdown>{introductionContent.Description1}</ReactMarkdown>
          </p>
          <p className="subtitle">
            <ReactMarkdown>{introductionContent.Description2}</ReactMarkdown>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Introduction;
