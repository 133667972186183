export const valueProposalEs = {
  valueProposal: {
    title: "Propuesta de valor",
    description1: `Nuestro enfoque está basado en cuidados procesos de digitalización que se convierten en soluciones tecnológicas para todo tipo de empresas.`,
    description2: `
    Co creamos con nuestros clientes productos de software de alta calidad, para abordar la transformación digital, siempre haciendo foco en la usabilidad y el diseño.`,
    button: "Más sobre de nosotros",
  },
};

export const valueProposalEn = {
  valueProposal: {
    title: "{{title}}",
    description1: "{{description1}}",
    description2: "{{description2}}",
    button: "{{button}}",
  },
};
