import React, { Fragment } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Java from "../../assets/icon-tech/logo-java.svg";
import Net from "../../assets/icon-tech/logo-net.svg";
import ReactLogo from "../../assets/icon-tech/logo-react.svg";
import Ios from "../../assets/icon-tech/ios_dev.svg";
import Android from "../../assets/icon-tech/android_dev.svg";
import Node from "../../assets/icon-tech/logo-node.svg";
import Angular from "../../assets/icon-tech/logo-angular.svg";
import { useTranslation } from "react-i18next";
import { technologiesStaffSection } from "../../config/StaffAugmentation/textPage";

const Technologies = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Container fluid id="Technologies">
        <Container>
          <Row className="d-flex justify-content-center">
            <Col className="row-width" xl={8}>
              <h2 className="text-center">
                {t("technologiesStaff.title", {
                  title: `${technologiesStaffSection.title}`,
                })}
              </h2>
              <p className="text-center">
                {t("technologiesStaff.paragraph1", {
                  paragraph1: `${technologiesStaffSection.paragraph1}`,
                })}
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="mt-5">
              <ul>
                <li>
                  <img src={Java} alt="Java"></img>
                </li>
                <li>
                  <img src={Net} alt=".Net"></img>
                </li>
                <li>
                  <img src={ReactLogo} alt="React"></img>
                </li>
                <li>
                  <img src={Ios} alt="Ios"></img>
                </li>
                <li>
                  <img src={Android} alt="Android"></img>
                </li>
                <li>
                  <img src={Node} alt="Node"></img>
                </li>
                <li>
                  <img src={Angular} alt="Angular"></img>
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <Button variant="secondary" href="#getInTouch">
                {t("technologiesStaff.buttonConnectWith", {
                  buttonConnectWith: `${technologiesStaffSection.buttonConnectWith}`,
                })}
              </Button>
            </Col>
          </Row>
        </Container>
      </Container>
    </Fragment>
  );
};

export default Technologies;
