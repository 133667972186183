import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { chiefCulture } from "../../config/culture/textPage";
export const Chief = ({ img, name, position, imageIcon }) => {
  const { t } = useTranslation();
  return (
    <>
      <Container className="containerChief">
        <Row className="justify-content-md-center text-center spacingSectionSoftwareEngineering mt-4">
          <Col lg={2} className="marginMobile">
            <img src={imageIcon?.url} className="img-fluid" alt="logoIcon" />
          </Col>
          <Col
            lg={7}
            className="d-flex justify-content-md-center  align-items-center containerTitlePossumus"
          >
            <h3 className="textChief">
            {t("chiefCulture.paragraph", {
                paragraph: `${chiefCulture.paragraph}`,
              })}
            </h3>
          </Col>
        </Row>
      </Container>
      <Container id="titleParticipantsDiscovery" className="containerChiefPerson">
        <Row className="textLandingGeneric d-flex justify-content-center align-items-center">
          <Col md={10} lg={9} sm={12} xxl={5} id="desc">
            <Row className="rowSpeaker">
              <Col md={3} lg={3} className="parti-center">
                <img src={img?.url} className="img-fluid" alt="chief" />
              </Col>
              <Col md={9} lg={9} className="d-flex align-items-center parti-center">
                <div>
                  <h2 className="nameChief">{name}</h2>
                  <p className="mb-0">{position}</p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};
