export const srePage = {
  title: "Cloud Engineering & DevOps Practice",
  paragraph1: `We work to achieve agile digital transformation processes in companies, impacting on user experience and driving the evolution of software assets through dynamic and secure processes. They deliver high-value products and impact people with positive experiences.`,
  paragraph2: `Our work focuses on ensuring the apps’ availability, performance, scalability and resilience, as well as task automation and continuous platform improvement to optimize development time and to reduce the risk of errors during production.`,
  paragraph3: `At`,
  company: " Possumus, ",
  paragraph4: `we implement Agile methodologies to create positive experiences through technology with human value.`,
};
export const sreScreen = {
  titleBannerSre:
    "Ensure the perfomance, scalability, and security of your systems with our SRE expertise",
  subTitleBannerSre:
    "Our SRE team provides customized, scalable, and secure services that optimize cloud system management and maximize business efficiency.",
};
export const menuSre = {
  cardDeskOnePart1: "Platform",
  cardDeskOnePart2: "Engineering",
  cardDeskTwoPart1: "Cloud",
  cardDeskTwoPart2: "infrastructure management & monitoring",
  cardDeskThreePart1: "Cloud native application",
  cardDeskThreePart2: "development",
  titleSectionOne: "Platform Engineering",
  titleSectionTwo: "Cloud infrastruture management & monitoring",
  titleSectionThree: "Cloud native application development",
};
export const sectionPlatform = {
  sectionPlatformTitle: "Platform Engineering",
  paragraphPlatform: `We design, implement and maintain internal development platforms (IDP). These IDP reduce the weight of knowledge generation and allow the development team’s self-service. They optimize costs, reduce management complexities and increase reliability.`,
  item1: "Software Architecture.",
  item2: "Microservices & Serverless.",
  item3: "Containers & Orchestration.",
  item4: "CI/CD proceses automation.",
  item5: "DevSecOps.",
  item6: "API Thinking.",
};
export const sectionCloudSre = {
  sectionPlatformTitle: "Cloud infrastructure management & monitoring",
  paragraphPlatform: `We provide cloud infrastructure optimization, ensuring optimal performance of the applications deployed on it. We offer a wide range of solutions to meet the unique needs of each client, from cloud infrastructure deployment and configuration to real-time monitoring and proactive troubleshooting.`,
  item1: "Cloud Consulting.",
  item2: "Infraestructure provisioning (IaC).",
  item3: "Monitoring and alarming.",
  item4: "Cloud Native Technologies.",
};
export const approachesSre = {
  title: "Approaches and Methodologies",
  paragraph: `These practices help us get a deep understanding of our clients’ needs and develop solutions that effectively meet those needs, such as: `,
};
export const sectionApproachesSre = {
  titleCard1: "Product Discovery",
  card1Paragraph: `We use different tools, activities and practices to determine early on what the real need is, avoiding solutions based on assumptions, reducing risks, and maximizing results.`,
  titleCard2: "Proof of Concept",
  card2Paragraph: `Through the PoC, we confirm the technical feasibility of the client initiative.`,
  titleCard3: "Minimum Viable Product",
  card3Paragraph: `We develop a project with enough
   features to be tested that clarify the potential of
    features based on the experience of the first users.`,
  titleCard4: "Evolutionary Maintenance",
  card4Paragraph:
    "Focusing on evaluating, maintaining, updating, and evolving each product, we carry out improvement processes based on constant innovation and the pragmatism of working with agile methodologies.",
};
export const cloudProviderSre = {
  title: "Cloud Provider",
};
export const cloudnativeSre = {
  title: "Cloud native application development",
  paragraph:
    "We develop cloud-native apps using advanced technologies and practices to maximize their benefits and features. This results in a fast, secure, and efficient implementation and scaling, providing business agility and efficiency.",
};
