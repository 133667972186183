import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";

const AwardsList = ({ data }) => {
  return (
    <Container id="awardsListContainer">
      {data?.map((award, idx) => {
        return (
          !award.isHighlighted && (
            <Row key={idx}>
              <Col lg={{span:2, offset: 1}} className="d-flex justify-content-center align-items-center">
                <Image src={award.image.url} alt={award.award} />
              </Col>
              <Col lg={{span: 8}}>
                <span>{award.scope}</span>
                <h3>{award.award}</h3>
              </Col>
            </Row>
          )
        );
      })}
    </Container>
  );
};

export default AwardsList;
