import React, { useContext } from "react";
import MenuUsa from "../components/commons/menuUsa";
import MainBanner from "../components/home/banner";
import TrustedUsa from "../components/commons/trustedUsa";
import ServicesUsa from "../components/home/ourServicesUsa";
import { LocationAddress } from "../components/usa/locationAddress";
import WhatMoves from "../components/home/whatMoves";
import WhatWeDo from "../components/home/whatWeDo";
import Partners from "../components/home/ourPartners";
import CareerJoinUs from "../components/home/careerJoinUs";
import { Footer } from "../components/commons/footer";
import { DownloadBanner } from "../components/commons/downloadBanner";
import Awards from "../components/commons/Awards";
import { ContentHomeContext } from "../contexts/ContentHomeContext";
import SEOContent from "../components/commons/SEOContent";
import { HomeMSSeparator } from "../components/home/MSSeparator";

export const HomeUsa = () => {
  const { SEOContent: SEOData } = useContext(ContentHomeContext);
  return (
    <>
      <SEOContent SEOData={SEOData} />
      <MenuUsa />
      <MainBanner />
      <TrustedUsa />
      <HomeMSSeparator />
      <ServicesUsa />
      <WhatMoves />
      <WhatWeDo />
      <Partners />
      <Awards />
      <DownloadBanner />
      <CareerJoinUs />
      <LocationAddress />
      <Footer />
    </>
  );
};
