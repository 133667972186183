import React, { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import isopk1 from "../../assets/iso1pk.svg";
import isopk2 from "../../assets/iso2pk.svg";
import { useTranslation } from "react-i18next";
import { menuSre } from "../../config/sre/textPage";

export const CardsSRE = () => {
  const { t } = useTranslation();
  const [imgIso1, setImgIso1] = useState(isopk1);
  const [imgIso2, setImgIso2] = useState(isopk1);
  const [imgIso3, setImgIso3] = useState(isopk1);
  return (
    <>
      <Container
        id="servicesSoftware"
        className="d-none d-sm-none d-md-none d-lg-block"
      >
        <Row className="justify-content-md-center text-left listServices">
          <Col lg={4}>
            <a
              href="#PlatformEngineering"
              target="_self"
              className="link-style"
            >
              <Card
                onMouseLeave={() => setImgIso1(isopk1)}
                onMouseEnter={() => setImgIso1(isopk2)}
                className="action-card"
              >
                <div className="clearfix d-lg-flex">
                  <img
                    src={imgIso1}
                    className="img-fluid float-left mr-1"
                    alt="Platform Engineering"
                  ></img>
                  <div>
                    <h4>
                      {t("menuSre.cardDeskOnePart1", {
                        cardDeskOnePart1: `${menuSre.cardDeskOnePart1}`,
                      })}
                      <br />
                      {t("menuSre.cardDeskOnePart2", {
                        cardDeskOnePart2: `${menuSre.cardDeskOnePart2}`,
                      })}
                    </h4>
                  </div>
                </div>
              </Card>
            </a>
          </Col>
          <Col lg={4}>
            <a
              href="#CloudInfrastructure"
              target="_self"
              className="link-style"
            >
              <Card
                onMouseLeave={() => setImgIso2(isopk1)}
                onMouseEnter={() => setImgIso2(isopk2)}
                className="action-card"
              >
                <div className="clearfix d-lg-flex">
                  <img
                    src={imgIso2}
                    className="img-fluid float-left mr-1"
                    alt="Cloud Infrastructure"
                  ></img>
                  <div>
                    <h4>
                      {t("menuSre.cardDeskTwoPart1", {
                        cardDeskTwoPart1: `${menuSre.cardDeskTwoPart1}`,
                      })}
                      <br />
                      {t("menuSre.cardDeskTwoPart2", {
                        cardDeskTwoPart2: `${menuSre.cardDeskTwoPart2}`,
                      })}
                    </h4>
                  </div>
                </div>
              </Card>
            </a>
          </Col>
          <Col lg={4}>
            <a href="#CloudNative" target="_self" className="link-style">
              <Card
                onMouseLeave={() => setImgIso3(isopk1)}
                onMouseEnter={() => setImgIso3(isopk2)}
                className="action-card"
              >
                <div className="clearfix d-lg-flex">
                  <img
                    src={imgIso3}
                    className="img-fluid float-left mr-1"
                    alt="Cloud Native"
                  ></img>
                  <div>
                    <h4>
                      {t("menuSre.cardDeskThreePart1", {
                        cardDeskThreePart1: `${menuSre.cardDeskThreePart1}`,
                      })}
                      <br />
                      {t("menuSre.cardDeskThreePart2", {
                        cardDeskThreePart2: `${menuSre.cardDeskThreePart2}`,
                      })}
                    </h4>
                  </div>
                </div>
              </Card>
            </a>
          </Col>
        </Row>
      </Container>
      <Container
        id="servicesSoftware"
        className="d-block d-sm-block d-md-block d-lg-none "
      >
        <Row className="justify-content-md-center text-left listSoftEngineeringMobile">
          <Col md={9} sm={12}>
            <a
              href="#PlatformEngineering"
              target="_self"
              className="link-style"
            >
              <Card
                onMouseLeave={() => setImgIso1(isopk1)}
                onMouseEnter={() => setImgIso1(isopk2)}
                className="action-card"
              >
                <Row className="g-0">
                  <Col className="col-3">
                    <img
                      src={imgIso1}
                      className="img-fluid"
                      alt="Platform Engineering"
                    ></img>
                  </Col>
                  <Col className="col-8">
                    <h4 className="m-0">
                      {t("menuSre.titleSectionOne", {
                        titleSectionOne: `${menuSre.titleSectionOne}`,
                      })}
                    </h4>
                  </Col>
                </Row>
              </Card>
            </a>
          </Col>
          <Col md={9} sm={12}>
            <a
              href="#CloudInfrastructure"
              target="_self"
              className="link-style"
            >
              <Card
                onMouseLeave={() => setImgIso2(isopk1)}
                onMouseEnter={() => setImgIso2(isopk2)}
                className="action-card"
              >
                <Row className="g-0">
                  <Col className="col-3">
                    <img
                      src={imgIso1}
                      className="img-fluid"
                      alt="Cloud Infrastructure"
                    ></img>
                  </Col>
                  <Col className="col-8">
                    <h4 className="m-0">
                    {t("menuSre.titleSectionTwo", {
                        titleSectionTwo: `${menuSre.titleSectionTwo}`,
                      })}
                    </h4>
                  </Col>
                </Row>
              </Card>
            </a>
          </Col>
          <Col md={9} sm={12}>
            <a href="#CloudNative" target="_self" className="link-style">
              <Card
                onMouseLeave={() => setImgIso3(isopk1)}
                onMouseEnter={() => setImgIso3(isopk2)}
                className="action-card"
              >
                <Row className="g-0">
                  <Col className="col-3">
                    <img
                      src={imgIso1}
                      className="img-fluid"
                      alt="Cloud Native"
                    ></img>
                  </Col>
                  <Col className="col-8">
                    <h4 className="m-0">
                    {t("menuSre.titleSectionThree", {
                        titleSectionThree: `${menuSre.titleSectionThree}`,
                      })}
                    </h4>
                  </Col>
                </Row>
              </Card>
            </a>
          </Col>
        </Row>
      </Container>
    </>
  );
};
