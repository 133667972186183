export const homeEs = {
  mainbannerHome: {
    title: "Trabaje con los mejores equipos de desarrollo",
    subtitle:
      "Nos enfocamos en brindar soluciones poderosas de ingeniería de software a medida, para que su negocio escale hacia el siguiente nivel. Con metodologías ágiles, desarrollamos bajo las mejores prácticas dinámicas y confiables.",
  },
  trusted: {
    title: "Confían en nosotros",
  },
  menuServices: {
    title: "Nuestros servicios",
    subtitle1: "Potencie su negocio con nuestros servicios",
    subtitle2: "nuestros servicios",
    softPart1: "Ingeniería ",
    softPart2: "de software",
    button: "Saber más",
    sre: "Cloud Engineering & DevOps Practice",
    staffPart1: "Staff",
    staffPart2: "Augmentation",
    staffPart1MenuHeader: "Staff augmentation",
  },
  bannerDownloadFree: {
    title: "Descargue gratis nuestro brochure",
  },
  page404: {
    title: "Algo ha desaparecido...",
    paragrahp:
      "Lo sentimos, el contenido que busca no existe. O bien ha sido eliminado, o ha escrito mal el enlace.",
    button: "Ir al Home",
  },
};

export const homeEn = {
  mainbannerHome: {
    title: "{{title}}",
    subtitle: "{{subtitle}}",
    services: "{{servicesHome}}",
  },
  trusted: {
    title: "{{title}}",
  },
  menuServices: {
    title: "{{title}}",
    subtitle1: "{{subtitle1}}",
    subtitle2: "{{subtitle2}}",
    softPart1: "{{softPart1}}",
    softPart2: "{{softPart2}}",
    button: "{{button}}",
    sre: "{{sre}}",
    staffPart1: "{{staffPart1}}",
    staffPart2: "{{staffPart2}}",
    staffPart1MenuHeader: "{{staffPart1MenuHeader}}",
  },
  bannerDownloadFree: {
    title: "{{bannerDownloadFree}}",
  },
  page404: {
    title: "{{title}}",
    paragrahp: "{{paragrahp}}",
    button: "{{button}}",
  },
};
