import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";

export const BannerInfoSuport = () => {
  return (
    <Container className="container-style">
      <div className="justify-content-center align-items-center d-flex">
        <div class="d-none d-sm-none d-xl-block">
          <Card className="card-shadow">
            <Row className="row-banner-info">
              <Col
                lg={4}
                md={12}
                className="justify-content-center align-items-center d-flex card-border"
              >
                <div>
                  <Card.Text className="m-0 text-email">E-Mail</Card.Text>
                  <Card.Text className="m-0 text-data-email">
                    support@possumus.tech
                  </Card.Text>
                </div>
              </Col>

              <Col lg={8} md={12} className="data-suport">
                <Card.Text className="m-0">
                  Recordá indicar en el correo:
                </Card.Text>
                <ul>
                  <li>
                    <Card.Text>
                      Prioridad del pedido en el asunto del correo.
                    </Card.Text>
                  </li>
                  <li>
                    <Card.Text>Descripción ampliada del problema</Card.Text>
                  </li>
                  <li>
                    <Card.Text>Datos del equipo</Card.Text>
                  </li>
                  <li>
                    <Card.Text>
                      Captura del error en caso que corresponda
                    </Card.Text>
                  </li>
                </ul>
              </Col>
            </Row>
          </Card>
        </div>

        <div class="d-block d-sm-block d-xl-none ">
          <Card className="card-shadow">
            <Row className="row-banner-info">
              <Col
                lg={5}
                md={12}
                className="justify-content-center align-items-center d-flex mb-2"
              >
                <div>
                  <Card.Text className="m-0 text-email">E-Mail</Card.Text>
                  <Card.Text className="m-0 text-data-email">
                    support@possumus.tech
                  </Card.Text>
                  <hr />
                </div>
              </Col>

              <Col lg={7} md={12}>
                <Card.Text>Recordá indicar en el correo:</Card.Text>
                <ul>
                  <li>
                    <Card.Text>
                      Prioridad del pedido en el asunto del correo.
                    </Card.Text>
                  </li>
                  <li>
                    <Card.Text>Descripción ampliada del problema</Card.Text>
                  </li>
                  <li>
                    <Card.Text>Datos del equipo</Card.Text>
                  </li>
                  <li>
                    <Card.Text>
                      Captura del error en caso que corresponda
                    </Card.Text>
                  </li>
                </ul>
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </Container>
  );
};
