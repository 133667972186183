import { useEffect, useState } from "react";
import BannerAlt from "../components/landings/bannerAlt";
import DetailsTwoParticipantsGeneric from "../components/landings/detailsTwoParticipantsGeneric";
import FooterLanding from "../components/landings/footerLanding";
import FormLanding from "../components/landings/form";
import HeaderLanding from "../components/landings/header";
import { DetailsDescription } from "../components/landings/detailsDescription";
import { WebinarSummary } from "../components/landings/webinarSummary";
import { IframeYouTube } from "../components/landings/iframeYouTube";
import { BannerMoreInfo } from "../components/landings/bannerMoreInfo";
import { DownloadFree } from "../components/landings/downloadFree";
import { ModalDownloadFree } from "../components/landings/modalDownloadFree";
import { useRequestApi } from "../hooks/useRequestApi";
import Partners from "../components/home/ourPartners";
import { Spinner } from "react-bootstrap";
import { Helmet, HelmetProvider } from "react-helmet-async";
export const Sre = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {
    bannerImg,
    dataSpeaker,
    imgSpeakerOne,
    imgSpeakerTwo,
    bannerBox,
    bannerImgMobile,
    bannerBoxImg,
    contact,
    contactEvent,
    postWebinarDesc,
    sticker,
    requestApiSre,
    dataContentLanding,
    loader,
  } = useRequestApi();

  useEffect(() => {
    requestApiSre();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { titleHeader, date, hour, title1, active, title2, subtitles } =
    dataContentLanding;
  const {
    paragraph,
    title,
    subtitle,
    description,
    speaker1,
    speaker2,
    speakerJob1,
    speakerJob2,
    company,
    exposes,
    description2,
  } = dataSpeaker;
  const url = process.env.REACT_APP_URL + "/webinars/sre";
  return loader ? (
    <Spinner />
  ) : (
    <HelmetProvider>
       <Helmet>
        <title>{`Possumus - Microsoft ARO (Azure Red Hat OpenShift)`}</title>
        <meta
          property="og:title"
          content="Possumus - Microsoft ARO (Azure Red Hat OpenShift)"
        />
        <link rel="canonical" href={`${url}`} />
        <meta property="og:url" content={`${url}`} />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="Possumus" />
        <meta
          property="og:image"
          content="https://possumustech.blob.core.windows.net/staticfiles/Miniatura_OG_b0b1d5c32e.png"
        />
      </Helmet>
      <div id="talkTechSolid">
        <HeaderLanding
          title={titleHeader}
          phone="0810 345 0562"
          template="dark"
        />
        <BannerAlt
          classButton="btn-blue"
          idContainer="bannerLandingSolidWebinar"
          imgBanner={bannerImg}
          imgBannerMobile={bannerImgMobile}
          idButton="formLandingButtonSre"
          href="#formLanding"
          idHeader="titleMain"
          date={date}
          time={hour}
          title={title1}
          tittle2={title2}
          subtitle={subtitles}
          active={active}
          template="none"
          buttonWatchWebinar={"Ver webinar"}
          buttonParticipateWebinar={"Quiero participar"}
        />
        {active ? (
          <DetailsTwoParticipantsGeneric
            paragraph={paragraph}
            title={title}
            sticker={sticker?.url}
            description={subtitle}
            descriptionParagraph2={description2}
            speakerOne={speaker1}
            speakerTwo={speaker2}
            speakerOnePuesto={speakerJob1}
            speakerTwoPuesto={speakerJob2}
            imgSpeakerOne={imgSpeakerOne.url}
            imgSpeakerTwo={imgSpeakerTwo.url}
            bannerText={bannerBox?.bannerText}
            bannerImg={bannerBoxImg?.url}
            exposes={exposes}
            colImage={2}
            colPost={4}
            colorText={"textWhite"}
            company={company}
          />
        ) : (
          <>
            <WebinarSummary
              columnOne={postWebinarDesc.columnOne}
              columnTwo={postWebinarDesc.columnTwo}
              columnThree={postWebinarDesc.columnThree}
              columnFour={postWebinarDesc.columnFour}
              postWebinarData={postWebinarDesc?.Title}
            />
            <DetailsDescription
              speakerOnePosition={speakerJob1}
              speakerTwoPosition={speakerJob2}
              speakerOne={speaker1}
              speakerTwo={speaker2}
              dataDetails={dataSpeaker}
              descriptionParagraph2={subtitle}
              description={description}
              imgSpeakerOne={imgSpeakerOne.url}
              imgSpeakerTwo={imgSpeakerTwo?.url}
              sticker={sticker?.url}
              bannerImg={bannerBoxImg?.url}
              bannerText={bannerBox?.bannerText}
              colorText={"textWhite"}
              company={company}
              exposes={exposes}
            />
          </>
        )}
        <div className="spacing"></div>
        <IframeYouTube
          id={contact.Id_Youtube}
          title={contact.Video_title}
          active={active}
        />
        <DownloadFree
          imageBackgroudMobile={dataContentLanding.downloadFreeMobile?.url}
          handleShow={handleShow}
          download={contact.download}
          active={active}
          textBanner={dataContentLanding.textBannerFree}
          id="formLandingButtonSre"
        />
        {dataContentLanding.salesConsultant && (
          <BannerMoreInfo
            completeName={dataContentLanding.salesConsultant.name_last_name}
            email={dataContentLanding.salesConsultant.email}
            phone={dataContentLanding.salesConsultant.phone}
            phoneOp={dataContentLanding.salesConsultant.phone_Op}
            imageSalesConsultant={
              dataContentLanding.salesConsultant.Image_sales_consultant?.url
            }
            position={dataContentLanding.salesConsultant.position}
            active={dataContentLanding.Active}
            needInformation={dataContentLanding.salesConsultant.needInformation}
          />
        )}
        <FormLanding
          idButton={"formLandingButtonSre"}
          active={active}
          titleActive={contact.formTitle}
          titleEvent={contactEvent?.formTitle}
          contactOrigin={
            active ? contact.originContact : contactEvent?.originContact
          }
        />
        <Partners />
        <FooterLanding title={dataContentLanding.Subtitle_Footer} />
      </div>
      <ModalDownloadFree
        imgModal={dataContentLanding.imgModal?.url}
        show={show}
        onHide={handleClose}
        url={dataContentLanding.pathname}
        data={dataContentLanding?.bannerBox?.file[0].url}
        id="formLandingButtonSre"
      />
    </HelmetProvider>
  );
};
