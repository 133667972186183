import React from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import WeloveImg from "../../assets/logos/WLT.svg";
import IsoCertificate from "./IsoCertificate";

const IsoCertificateAndWeLoveDev = ({button, logo}) => {
  return (
    <Row className="logoAndIso">
      {button && (
        <Col lg={3} className="d-flex justify-content-center d-lg-block">
          <Button style={{width: '100%'}} variant={button.variant} href={button.href}>{button.textButton}</Button>
        </Col>
      )}
      <IsoCertificate button={button} />
      <Col lg={button ? 3 : 6} sm={8}>
        <Image
          src={logo ? logo : WeloveImg}
          className={logo ? "float-logo" : "img-right"}
          alt="Possumus We love dev"
        />
      </Col>
    </Row>
  );
};

export default IsoCertificateAndWeLoveDev;
