import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col, Card } from "react-bootstrap";

const OpenJobs = () => {
  const [posiciones, setposiciones] = useState([]);
  const [loadedData, setLoadedData] = useState(false);

  var config = {
    method: "get",
    url: "https://ats.bizneo.com/api/v3/companies/170837/jobs.json",
    withCredentials: false,
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Token token=Xlr92ZOUho6iQ1pAyrMwgg, user_email=170837-1666354768@api.bizneo.com",
    },
  };

  useEffect(() => {
    axios(config)
      .then(function (response) {
        setposiciones(response.data.jobs);
        setLoadedData(true);
      })
      .catch(function (error) {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container fluid id="openingJobs">
      <Container>
        <h4>Open Jobs</h4>
        <Row>
          {loadedData ? (
            posiciones.map((posicion) => (
              <>
                {posicion.brand.id === 1044 &&
                posicion.status === "activated" ? (
                  <Col lg={4} key={posicion.id}>
                    <a
                      key={posicion.id}
                      href={posicion.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Card className="cardOpenJobs">
                        <Card.Body>
                          <Card.Title>
                            <h5>{posicion.title}</h5>
                          </Card.Title>
                          <Card.Text>
                            <p>
                              Location:{" "}
                              <strong>{posicion.locations[0].city.name}</strong>
                            </p>
                            <p>
                              Commitment:{" "}
                              <strong>{posicion.workday_translation}</strong>
                            </p>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </a>
                  </Col>
                ) : (
                  ""
                )}
              </>
            ))
          ) : (
            <p>Cargando</p>
          )}
        </Row>
      </Container>
    </Container>
  );
};

export default OpenJobs;
