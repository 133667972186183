import React, { useContext } from "react";
import "../../css/staff.css";
import "../../css/devOpsSre.css";
import { CardsSRE } from "../../components/commons/cardsSRE";
import { DevopsPlatform } from "../../components/devops/devopsPlatform";
import { DevopsCloud } from "../../components/devops/devopsCloud";
import { CloudNative } from "../../components/devops/cloudNative";
import { ApproachesAndMethodologies } from "../../components/devops/approachesAndMethodologies";
import { BannerCloud } from "../../components/devops/bannerCloud";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Layout from "./Layout";
import useGetHeaderContent from "../../hooks/useGetHeaderContent";
import { ActualLangContext } from "../../contexts/ActualLangContext";
import DevOpsHeader from "../../components/landings/comercial/devopsHeader";

const DevOpsSreLanding = () => {
  const { actualLang } = useContext(ActualLangContext);

  const isInEnglish = actualLang === "en";

  const url =
    process.env.REACT_APP_URL + isInEnglish
      ? "/service/devops-sre"
      : "/servicios/devops-sre";

  const { titulo, bajada } = useGetHeaderContent("devops-sre");
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{`Possumus - Devops sre`}</title>
          <meta property="og:title" content="Possumus - Devops sre" />
          <link rel="canonical" href={`${url}`} />
          <meta property="og:url" content={`${url}`} />
          <meta property="og:type" content="article" />
          <meta property="og:site_name" content="Possumus" />
          <meta
            property="og:image"
            content="https://possumustech.blob.core.windows.net/staticfiles/Miniatura_OG_b0b1d5c32e.png"
          />
          {isInEnglish && (
            <script>
              {(function (w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({
                  "gtm.start": new Date().getTime(),
                  event: "gtm.js",
                });
                var f = d.getElementsByTagName(s)[0],
                  j = d.createElement(s),
                  dl = l !== "dataLayer" ? "&l=" + l : "";
                j.async = true;
                j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
                f.parentNode.insertBefore(j, f);
              })(window, document, "script", "dataLayer", "GTM-5PSNR7KR")}
            </script>
          )}
        </Helmet>
        <Layout>
          <div className="bg-circle-sre">
            <DevOpsHeader titulo={titulo} bajada={bajada} />
            <CardsSRE />
            <DevopsPlatform />
            <DevopsCloud />
            <BannerCloud />
            <div className="bg-circle-gray">
              <CloudNative />
              <ApproachesAndMethodologies />
            </div>
          </div>
        </Layout>
      </HelmetProvider>
      {isInEnglish && (
        <noscript>
          {`<iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-5PSNR7KR"
            height="0"
            width="0"
            style="display:none;visibility:hidden"
          ></iframe>`}
        </noscript>
      )}
    </>
  );
};

export default DevOpsSreLanding;
