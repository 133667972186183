import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ourWorkAboutUsSection } from "../../config/about/textPage";

const OurWork = () => {
  const { t } = useTranslation();

  return (
    <Container fluid id="ourWork">
      <Row className="justify-content-md-center text-center">
        <Col lg="8">
          <h2 class="border-bottom text-center">
            {t("ourWorkAboutUs.title", {
              title: `${ourWorkAboutUsSection.title}`,
            })}
          </h2>
          <h3>
            {t("ourWorkAboutUs.description1", {
              description1: `${ourWorkAboutUsSection.description1}`,
            })}
          </h3>
        </Col>
      </Row>
    </Container>
  );
};

export default OurWork;
