import React, { useContext } from "react";
import { Container, Button, Col, Image, Row } from "react-bootstrap";
import { ContentProductsContext } from "../../contexts/ContentProductsContext";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import Loader from "../commons/loader";

const SimplePay = () => {
  const { simplePayContent } = useContext(ContentProductsContext);
  return (
    <>
      <Container fluid className="product-section" id="simple-pay">
        <Row className="justify-content-md-center">
          <Col lg={10}>
            <h2 className="text-center mt-4">{simplePayContent.title}</h2>
          </Col>
        </Row>
        <Row className="justify-content-md-center text-center p-2">
          <Col lg={9}>
            <p className="subtitle">{simplePayContent.subtitle}</p>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col lg={4} sm={12} className="d-flex justify-content-center">
            {simplePayContent?.images  ? (
              <Image
                src={
                  simplePayContent.images ? simplePayContent.images[0].url : ""
                }
                alt="Simple pay ilustration"
              />
            ) : (
              <Loader />
            )}
          </Col>
          <Col lg={4} xs={12} md={10} className="product-section-description">
            <p>
              <ReactMarkdown>{simplePayContent.description}</ReactMarkdown>
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="justify-content-center align-items-center d-flex">
            <Button
              variant="secondary"
              href="/en-en/simple-pay"
              className="paddingButtonMainBanner"
            >
              {" "}
              {simplePayContent?.buttonText}
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SimplePay;
