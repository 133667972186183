import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import imgAzure from "../../assets/Microsoft.svg";
import "../../css/partner.css";
import { useTranslation } from "react-i18next";
import { ourPartners } from "../../config/ourPartners/textPage";

const Partners = () => {
  const { t } = useTranslation();
  return (
    <Container fluid className="containerPartners">
      <Row className="justify-content-md-center" id="">
        <Col lg={12}>
          <h2 className="mt-4 textProviderHome">
            {t("ourPartners.title", {
              title: `${ourPartners.title}`,
            })}
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center align-items-center d-flex">
        <Col
          lg={4}
          xl={4}
          xxl={4}
          sm={12}
          className="justify-content-center align-items-center d-flex separatorImageLogoMobile containerImagePartners"
        >
          <img src={imgAzure} className="img-fluid mr-1" alt="Azure" />
        </Col>
      </Row>
    </Container>
  );
};

export default Partners;
