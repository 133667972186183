import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { ActualLangContext } from "../contexts/ActualLangContext";

// ESTE HOOK ES UNICAMENTE PARA LAS LANDINGS DE COMERCIAL
export default function useGetHeaderContent(slug) {
  const { actualLang } = useContext(ActualLangContext);

  const [headerContent, setHeaderContent] = useState(null);

  useEffect(() => {
    async function getContent() {
      return axios
        .get(
          `${process.env.REACT_APP_URL_API_V4}/api/landings-comercial?[locale]=${actualLang}&filters[slug][$contains]=${slug}`,
          {headers: {Authorization: `bearer ${process.env.REACT_APP_URL_API_V4_TOKEN}`}}
        )
        .then((res) => res.data)
        .catch(err => console.log(err))
    }
    getContent().then((res) => setHeaderContent(res));
  }, [actualLang, slug]);
  
  return { titulo: headerContent?.data[0].attributes.titulo, bajada: headerContent?.data[0].attributes.bajada };
}
