export const contactEs = {
  contact: {
    title: "Contacto",
    contactLink: "Contáctanos",
  },
};
export const contactEn = {
  contact: {
    title: "{{title}}",
    contactLink: "{{contactLink}}",
  },
};
