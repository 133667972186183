import { createContext, useState } from "react";

export const ActualLangContext = createContext({});

export const ActualLangProvider = ({ children }) => {
  const [actualLang, setActualLang] = useState(localStorage.getItem("lang") ? localStorage.getItem("lang") : "en");

  const setLang = (newLang) => {
    localStorage.setItem("lang", newLang);
    localStorage.setItem("i18nextLng", newLang);
    setActualLang(newLang);
  };

  return (
    <ActualLangContext.Provider value={{ actualLang, setLang }}>
      {children}
    </ActualLangContext.Provider>
  );
};
