import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Loader from "../commons/loader";

const ContactReadyBuild = () => {
  const [offices, setOffices] = useState([]);
  const [personal, setPersonal] = useState({});
  const [loadedData, setLoadedData] = useState(false);
  const {
    nombre_apellido,
    telefono_Op,
    telefono,
    email,
    puesto,
    Image_personal,
  } = personal;
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL_API}/institucional`).then((res) => {
      setOffices(res.data.Oficinas);
      setPersonal(res.data.Personal);
      setLoadedData(true);
    });
  }, []);
  return (
    <Container fluid id="contactReadyBuild">
      <Container>
        <Row className="sales">
          <Col lg={5} id="info">
            <h2>
              Ready to build
              <br />
              your software?
            </h2>
          </Col>
          <Col lg={7} className="p-0 m-0">
            <Row className="p-0 m-0">
              <Col lg={3} className="text-center">
                <img
                  className="img-fluid"
                  src={Image_personal?.url}
                  alt="Sebastian Delfino"
                ></img>
              </Col>
              <Col lg={9} className="p-0 m-0" id={"positionPersonal"}>
                <h3>{nombre_apellido}</h3>
                <h4>{puesto}</h4>
                <p>{`W: ${telefono_Op}`}</p>
                <p>{`T: ${telefono}`}</p>
                <p>
                  E: <a href={`mailto:${email}`}>{email}</a>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="address text-left">
          <ul class="list-group list-group-horizontal">
            {loadedData ? (
              offices.map((office) => (
                <li class="list-group-item flex-fill" key={office.id}>
                  <h3>{office.Locacion}</h3>
                  <p>{office.Direccion}</p>
                  <p>{office.Localidad}</p>
                  <p>{office.CP}</p>
                  <p>{office.Provincia}</p>
                  <p>{office.Pais}</p>
                </li>
              ))
            ) : (
              <Loader />
            )}
          </ul>
        </Row>
      </Container>
    </Container>
  );
};

export default ContactReadyBuild;
