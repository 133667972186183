import { Container, Image, Row, Col, Button } from "react-bootstrap";
import calendar from "../../assets/landings/FCalendar.svg";
import clock from "../../assets/landings/FClock.svg";
export const BannerAlt = ({
  date,
  time,
  title,
  tittle2,
  active,
  imgBanner,
  template,
  imgBannerMobile,
  idContainer,
  mainTitle,
  idButton,
  idHeader,
  subtitle,
  buttonWatchWebinar,
  buttonParticipateWebinar,
  href,
}) => {
  return (
    <>
      <Container
        fluid
        id={idContainer}
        style={{ backgroundImage: `url(${imgBanner})` }}
        className={`${template} d-none d-sm-none d-md-block`}
      >
        <Container>
          <h4>{tittle2}</h4>
          <Row>
            <Col id={idHeader} md={9} xl={8} xxl={7} sm={6} lg={10} xs={12}>
              <h1>{mainTitle}</h1>
              <h2>{title}</h2>
              {subtitle && <h2>{subtitle}</h2>}
            </Col>
          </Row>
          {active && (
            <Row className="mt-4">
              <Col md={8} xl={4} xxl={4} lg={4}>
                <h3>
                  <span>
                    <Image src={calendar} className="iconsBanner" /> {date}
                  </span>
                </h3>
              </Col>
              <Col md={8} xl={4} xxl={4} lg={4}>
                <h3>
                  <span>
                    <Image src={clock} className="iconsBanner" /> {time}
                  </span>
                </h3>
              </Col>
            </Row>
          )}
          {buttonWatchWebinar && (
            <div id={idButton}>
              <Button href={href} variant="primary" className="btn-blue">
                {active ? buttonParticipateWebinar : buttonWatchWebinar}
              </Button>
            </div>
          )}
        </Container>
      </Container>

      <Container
        fluid
        id={idContainer}
        style={{ backgroundImage: `url(${imgBannerMobile})` }}
        className={`${template} d-block d-sm-block d-md-none`}
      >
        <Container className="styleColParticipants">
          <h4>{tittle2}</h4>
          <Row>
            <Col id={idHeader} md={9} xl={9} xxl={8} sm={6} lg={5} xs={12}>
              <h1>{mainTitle}</h1>
              <h2>{title}</h2>
              {subtitle && <h2>{subtitle}</h2>}
            </Col>
          </Row>
          {active && (
            <Row className="mt-4">
              <Col md={8} xl={4} xxl={4} lg={4}>
                <h3>
                  <span>
                    <Image src={calendar} className="iconsBanner" /> {date}
                  </span>
                </h3>
              </Col>
              <Col md={8} xl={4} xxl={4} lg={4}>
                <h3>
                  <span>
                    <Image src={clock} className="iconsBanner" /> {time}
                  </span>
                </h3>
              </Col>
            </Row>
          )}
          {buttonWatchWebinar && (
            <div id={idButton}>
              <Button href={href} variant="primary" className="btn-blue">
                {active ? buttonParticipateWebinar : buttonWatchWebinar}
              </Button>
            </div>
          )}
        </Container>
      </Container>
    </>
  );
};

export default BannerAlt;
