import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AppoachesMain } from "./appoaches/appoachesMain";
import { useTranslation } from "react-i18next";
import { approachesSre } from "../../config/sre/textPage";

export const ApproachesAndMethodologies = () => {
  const { t } = useTranslation();
  return (
    <Container fluid className="containerStyleApproach">
      <Container>
        <Row className="justify-content-md-center">
          <Col lg={12}>
            <h2 className="text-center">
              {t("approachesSre.title", {
                title: `${approachesSre.title}`,
              })}
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-md-center text-center spacingSectionSoftwareEngineering mt-2">
          <Col lg={10}>
            <h3>
              {t("approachesSre.paragraph", {
                paragraph: `${approachesSre.paragraph}`,
              })}
            </h3>
          </Col>
        </Row>
        <AppoachesMain />
      </Container>
    </Container>
  );
};
