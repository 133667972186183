import React from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import elipse from "../../../assets/Ellipse.svg";
import { useTranslation } from "react-i18next";
import { sectionApproachesSre } from "../../../config/sre/textPage";
export const AppoachesDesk = () => {
  const { t } = useTranslation();
  return (
    <div class="d-none d-sm-none d-lg-block">
      <Row className="justify-content-md-center text-left  mt-4">
        <Col lg={5}>
          <Card bg="light" id="myCard">
            <Card.Header className="titleCard">
              <Image src={elipse} alt="" className="marginElipseColumn1" />
              {t("sectionApproachesSre.titleCard1", {
                titleCard1: `${sectionApproachesSre.titleCard1}`,
              })}
            </Card.Header>
            <div className="lineCard">
              <hr className="m-0 p-0" />
            </div>
            <Card.Body>
              <Card.Text>
                {t("sectionApproachesSre.card1Paragraph", {
                  card1Paragraph: `${sectionApproachesSre.card1Paragraph}`,
                })}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={1} className="m-0 p-0">
          <div style={{ position: "relative", height: "100%" }}>
            <hr className="lineSeparatorBodyLeft" />
            <div className="d-flex lineVerticalBody">
              <div className="vr"></div>
            </div>
          </div>
        </Col>
        <Col lg={5}></Col>
      </Row>
      <Row className="justify-content-md-center text-left  mt-4">
        <Col lg={5}></Col>
        <Col lg={1} className="m-0 p-0">
          <div style={{ position: "relative", height: "100%" }}>
            <hr className="lineSeparatorBodyRight" />
            <div className="d-flex lineVerticalBodyRightConcept">
              <div className="vr"></div>
            </div>
          </div>
        </Col>
        <Col lg={5}>
          <Card bg="light" id="myCard">
            <Card.Header className="titleCard">
              <Image src={elipse} alt="" className="marginElipseColumn2" />
              {t("sectionApproachesSre.titleCard2", {
                titleCard2: `${sectionApproachesSre.titleCard2}`,
              })}
            </Card.Header>
            <div className="lineCard">
              <hr className="m-0 p-0" />
            </div>
            <Card.Body>
              <Card.Text>
                {t("sectionApproachesSre.card2Paragraph", {
                  card2Paragraph: `${sectionApproachesSre.card2Paragraph}`,
                })}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-md-center text-left  mt-4">
        <Col lg={5}>
          <Card bg="light" id="myCard">
            <Card.Header className="titleCard">
              <Image src={elipse} alt="" className="marginElipseColumn1" />
              {t("sectionApproachesSre.titleCard3", {
                titleCard3: `${sectionApproachesSre.titleCard3}`,
              })}
            </Card.Header>
            <div className="lineCard">
              <hr className="m-0 p-0" />
            </div>
            <Card.Body>
              <Card.Text>
                {t("sectionApproachesSre.card3Paragraph", {
                  card3Paragraph: `${sectionApproachesSre.card3Paragraph}`,
                })}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={1} className="m-0 p-0">
          <div style={{ position: "relative", height: "100%" }}>
            <hr className="lineSeparatorBodyLeft" />
            <div className="d-flex lineVerticalBodyLeft">
              <div className="vr"></div>
            </div>
          </div>
        </Col>
        <Col lg={5}></Col>
      </Row>
      <Row className="justify-content-md-center text-left  mt-4">
        <Col lg={5}></Col>
        <Col lg={1} className="m-0">
          <div style={{ position: "relative", height: "100%" }}>
            <hr className="lineSeparatorBodyRight" />
            <div className="d-flex lineVerticalBodyRightEv">
              <div className="vr"></div>
            </div>
          </div>
        </Col>
        <Col lg={5}>
          <Card bg="light" id="myCard" className="containerStyleApproachCard">
            <Card.Header className="titleCard">
              <Image src={elipse} alt="" className="marginElipseColumn2" />
              {t("sectionApproachesSre.titleCard4", {
                titleCard4: `${sectionApproachesSre.titleCard4}`,
              })}
            </Card.Header>
            <div className="lineCard">
              <hr className="m-0 p-0" />
            </div>
            <Card.Body>
              <Card.Text>
                {t("sectionApproachesSre.card4Paragraph", {
                  card4Paragraph: `${sectionApproachesSre.card4Paragraph}`,
                })}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
