import React from "react";
import { useContext } from "react";
import { ContentSimplePayLandingContext } from "../../contexts/ContentSimplePayLandingContext";
import { ActualLangContext } from "../../contexts/ActualLangContext";
import { DownloadFreeUsa } from "../commons/downloadFreeUsa";

export const BannerDownload = () => {
  
  const {bannerDownload, downloadBrochure} = useContext(ContentSimplePayLandingContext)
  const {actualLang} = useContext(ActualLangContext)
  return (
    <>
      {bannerDownload?.imageDownload?.url && (
        <DownloadFreeUsa
          background={bannerDownload?.background?.url}
          backgroundMobile={bannerDownload?.backgroundMobile?.url}
          imageBanner={
            actualLang === "en"
              ? bannerDownload?.imageDownload.url
              : bannerDownload?.imageDownloadEs.url
          }
          textBanner={bannerDownload?.textBanner}
          brochureUrl={downloadBrochure?.url}
        />
      )}
    </>
  );
};
