export const bannerCulture = {
  nameLink: "Culture",
  descriptionPossumus1: `At Possumus we generate positive experiences through technology,
  focused on people, with global scale projects for clients around
  the world. To achieve this, we work in a culture of value,
  innovative,iterative and close, which helps us generate a strong
  community, that scales day by day.`,
  descriptionPossumus21: `Browse the`,
  descriptionPossumus2u1: "Benefits",
  descriptionPossumus22: "program or apply for our",
  descriptionPossumus2u2: "Active Searches.",
  buttonBenefits: "Benefits",
  buttonGrowth: "Crecimiento",
  buttonSearches: "Active Searches",
};

export const bodyCulture = {
  growIntoPossumus: "Grow professionally at Possumus",
  growIntoPossumusDescription: `Get to know Possumus experience firsthand.
     Professionals who have already chosen 
     to work at Possumus tell you what it means to be part of it. `,
};

export const chiefCulture = {
  paragraph: `Work is just one dimension of our life,
  and certainly not the most important one.`,
};
export const exclusiveBenefits = {
  title: "Exclusive benefits for you ",
};
export const careerJoinCulture = {
  description: "Join our team and let's do something great, together!",
  button1: "Careers",
  button2: "Send curriculum",
};
