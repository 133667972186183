import React from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import arrow from "../../../assets/Arrow.svg";
import { ProductEngineeringDesk } from "./productEngineeringDesk";
import { ProductEngineeringMobile } from "./productEngineeringMobile";
import { softwareEngineering } from "../../../config/softwareEngineering/textPage";
import { useTranslation } from "react-i18next";
import { bannerDownload } from "../../../config/Commons/textPage";

export const ProductEngineeringMain = () => {
  const { t } = useTranslation();
  return (
    <>
      <Row
        className="justify-content-md-center spacing"
        id="ProductEngineering"
      >
        <Col lg={10} className="justify-content-center d-flex">
          <Image src={arrow} alt="" />
        </Col>
        <Col lg={10}>
          <h2 className="text-center mt-4">
            {t("sectionEngineering.title", {
              title: `${softwareEngineering.sectionEngineeringTitle}`,
            })}
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-md-center text-center spacingSectionSoftwareEngineering">
        <Col lg={9}>
          <h3>
          {t("sectionEngineering.paragraph", {
              paragraph: `${softwareEngineering.paragraphEngineering}`,
            })}
          </h3>
        </Col>
      </Row>
      <ProductEngineeringDesk />
      <ProductEngineeringMobile />
      <Row className="mt-4">
        <Col className="justify-content-center align-items-center d-flex mt-4">
          <Button
            variant="secondary"
            href="#getInTouch"
            className="paddingButtonMainBanner"
          >
            {t("buttonsAndAccesories.buttonGetFree", {
              buttonGetFree: `${bannerDownload.buttonGetFree}`,
            })}
          </Button>
        </Col>
      </Row>
    </>
  );
};
