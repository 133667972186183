import { createContext, useContext, useEffect, useState } from "react";
import { AuthStrapiContext } from "./AuthStrapiV3Context";
import { ActualLangContext } from "./ActualLangContext";
import axios from "axios";

export const ContentSEContext = createContext({});

export const ContentSEProvider = ({ children }) => {
  const { actualLang } = useContext(ActualLangContext);
  const { token } = useContext(AuthStrapiContext);

  const [loadedData, setLoadedData] = useState(false);
  const [SEOContent, setSEOContent] = useState({});

  useEffect(() => {
    setLoadedData(false);
    const url = `${process.env.REACT_APP_URL_API}/pages-software-engineering?_locale=${actualLang}`;
    if (token) {
      axios
        .get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          setSEOContent(res.data?.SEOMetaData);
          setLoadedData(true);
        })
        .catch((err) =>
          console.log(
            `Ocurrio un error al obtener los datos de la pagina de Software Engineering: ${err.message}`
          )
        );
    }
  }, [actualLang, token]);
  return (
    <ContentSEContext.Provider value={{ loadedData, SEOContent }}>
      {children}
    </ContentSEContext.Provider>
  );
};