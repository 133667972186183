import { Col, Container, Image } from "react-bootstrap";
import CardWithIsoImage from "./cardWithIsoImage";
import floatButton from "../../assets/floatButton.svg";
import floatButtonClose from "../../assets/floatButtonClose.svg";
import { useContext } from "react";
import { ActualLangContext } from "../../contexts/ActualLangContext";
import SideNavbarItem from "./SideNavbarItem";
import { SideNavbarContext } from "../../contexts/SideNavbarContext";

const SideNavbar = ({ items }) => {
  const { actualLang } = useContext(ActualLangContext);

  const {itemSelected, toggleShowMobileOptions, handleItemSelected, showMobileOptions} = useContext(SideNavbarContext)

  return (
    <Container>
      <Col md={3} id="sideNavbar">
        <CardWithIsoImage text={items.title} hover={false} href={items.href && items.href} />
        {items.navItems?.map((item, idx) => (
          <>
            <SideNavbarItem item={item} id={idx} setItem={handleItemSelected} actualItem={itemSelected}/>
          </>
        ))}
      </Col>
      <div id="sideNavbarMobile">
        <div className="mobileNavbar">
          <h6>
            {actualLang === "en" ? "Table of Contents" : "Tabla de contenidos"}
          </h6>
          <Image
            src={showMobileOptions ? floatButtonClose : floatButton}
            alt="float button to open the options"
            onClick={toggleShowMobileOptions}
            id="floatButtonNavbar"
          />
        </div>
        <div
          id={
            showMobileOptions
              ? "navbarMobileContentActive"
              : "navbarMobileContentDeactived"
          }
        >
          <CardWithIsoImage text={items.title} hover={false} />
          {items.navItems?.map((item, idx) => (
            <SideNavbarItem item={item} setShow={toggleShowMobileOptions} actualItem={itemSelected} id={idx} setItem={handleItemSelected}/>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default SideNavbar;
