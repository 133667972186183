import React, { Fragment, useContext } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "../../css/itaas.css";
import withCircle from "../../utils/WithCirlce";
import InfoList from "../../components/microsoftEcosystem/InfoList";
import Header from "../../components/itaas/Header";
import { ActualLangContext } from "../../contexts/ActualLangContext";
import { ContentItaasContext } from "../../contexts/ContentItaasContext";
import Introduction from "../../components/itaas/Introduction";
import Description from "../../components/itaas/Description";
import ServicesIT from "../../components/itaas/ServicesIT";
import Layout from "./Layout";
import useGetHeaderContent from "../../hooks/useGetHeaderContent";

const ITaaSLanding = () => {
  const { actualLang } = useContext(ActualLangContext);
  const { infoButtons } = useContext(ContentItaasContext);

  const { titulo, bajada } = useGetHeaderContent("itaas");

  const isInSpanish = actualLang === "es";

  const IntroductionWithCircle = withCircle(Introduction, {
    top: "-20%",
    right: "-30%",
  });

  const url =
    process.env.REACT_APP_URL + isInSpanish ? "/es-es/itaas" : "/en-en/itaas";

  return (
    <Fragment>
      <HelmetProvider>
        <Helmet>
          <title>{`Possumus - Itaas`}</title>
          <meta property="og:title" content="Possumus - Itaas" />
          <link rel="canonical" href={`${url}`} />
          <meta property="og:url" content={`${url}`} />
          <meta property="og:type" content="article" />
          <meta property="og:site_name" content="Possumus" />
          <meta
            property="og:image"
            content="https://possumustech.blob.core.windows.net/staticfiles/Miniatura_OG_b0b1d5c32e.png"
          />
          {isInSpanish && (
            <script>
              {(function (w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({
                  "gtm.start": new Date().getTime(),
                  event: "gtm.js",
                });
                var f = d.getElementsByTagName(s)[0],
                  j = d.createElement(s),
                  dl = l !== "dataLayer" ? "&l=" + l : "";
                j.async = true;
                j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
                f.parentNode.insertBefore(j, f);
              })(window, document, "script", "dataLayer", "GTM-NQGZXCDH")}
            </script>
          )}
        </Helmet>
        <Layout>
          <Header titulo={titulo} bajada={bajada} />
          <InfoList data={{ infoButtons }} />
          <IntroductionWithCircle />
          <Description />
          <ServicesIT />
        </Layout>
      </HelmetProvider>
      {isInSpanish && (
        <noscript>
          {`<iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-NQGZXCDH"
            height="0"
            width="0"
            style="display:none;visibility:hidden"
          ></iframe>`}
        </noscript>
      )}
    </Fragment>
  );
};

export default ITaaSLanding;
