import React, { Fragment, useState } from "react";
import axios from "axios";
import { Container, Col, Form, Button } from "react-bootstrap";

const FormEvent = ({
  contactOrigin,
  getDataApi,
  setDisabled,
  disabled,
  error,
  setError,
  id
}) => {
  const [values, setValues] = useState({
    origin: contactOrigin,
    yourname: "",
    phone: "",
    email: "",
    enterprise: "",
  });

  const { yourname, email, enterprise, phone } = values;

  const [btnLabel, setBtnLabel] = useState("Descargar");
  const [validated, setValidated] = useState(false);
  const handleInputChange = ({ target }) => {
    setValues({
      ...values,
      [target.name]: target.value,
      origin: contactOrigin,
    });
    setDisabled(false);
    setBtnLabel("Descargar");
  };
  const sendData = () => {
    setError("");
    setBtnLabel("Descargando...");
    setDisabled(true);

    axios
      .post(
        `https://prod-20.brazilsouth.logic.azure.com:443/workflows/4a0de618d958419bb7dc99ba7a3245b7/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=QsAL47KtZeSa5He1YXBvXMI4ZsD802JoaITVlC2wXhs`,
        values
      )
      .then(
        (res) => {
          setError("");
          setValues({
            origin: contactOrigin,
            yourname: "",
            phone: "",
            email: "",
            enterprise: "",
          });
          setBtnLabel("Descarga exitosa");
          getDataApi();
        },
        (error) => {
          setError(
            "Hubo un error al momento de realizar la descarga. Por favor intentelo mas tarde"
          );
          setDisabled(false);
        }
      );
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setError("Por favor, complete todos los campos indicados.");
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      setBtnLabel("Descargar");
      window.grecaptcha.ready(function () {
        window.grecaptcha
          .execute("6LdKISYdAAAAAAZ_z3WlZ1LGYiaM_70zPcZqaYfa", {
            action: "submit",
          })
          .then(function (token) {
            // Send form value as well as token to the server
            sendData();
          });
      });
    }
  };
  return (
    <Fragment>
      <Container id="formLanding" className="p-0">
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group as={Col} className="input-form-event">
            <Form.Control
              id="yourname"
              name="yourname"
              value={yourname}
              onChange={handleInputChange}
              required
              placeholder="Nombre"
            ></Form.Control>
          </Form.Group>
          <Form.Group as={Col} className="input-form-event">
            <Form.Control
              required
              name="email"
              id="email"
              type="email"
              value={email}
              onChange={handleInputChange}
              placeholder="Email"
            ></Form.Control>
          </Form.Group>
          <Form.Group as={Col} className="input-form-event">
            <Form.Control
              required
              name="phone"
              id="phone"
              value={phone}
              onChange={handleInputChange}
              placeholder="Teléfono"
            ></Form.Control>
          </Form.Group>
          <Form.Group as={Col} className="input-form-event">
            <Form.Control
              required
              id="enterprise"
              name="enterprise"
              value={enterprise}
              onChange={handleInputChange}
              rows={5}
              placeholder="Nombre de la empresa"
            ></Form.Control>
          </Form.Group>
          <div className="text-center buttonFormEvent" id="talkTechSolid">
            <div id={id}>
              <Button
                variant="primary"
                type="submit"
                value="Submit"
                className="sendForm btn-blue"
                disabled={disabled}
              >
                {btnLabel}
              </Button>
            </div>
            {error !== "" && (
              <div className="alert-message-danger">
                <p>{error}</p>
              </div>
            )}
          </div>
        </Form>
      </Container>
    </Fragment>
  );
};

export default FormEvent