import React, { useContext } from "react";
import { Container, Button, Col, Image, Row } from "react-bootstrap";
import arrowDown from "../../assets/ArrowDown.svg";
import { useTranslation } from "react-i18next";
import { bannerDownload } from "../../config/Commons/textPage";
import { ContentMicrosoftEcosystemContext } from "../../contexts/ContentMicrosoftEcosystemContext";
import ListOnShore from "../commons/listOnShore";
import ReactMarkdown from 'react-markdown'

const MSEIntroduction = () => {
  const { t } = useTranslation();

  const { introductionContent } = useContext(ContentMicrosoftEcosystemContext);
  return (
    <>
      <Container fluid id="MSEIntroduction">
        <Row className="justify-content-md-center">
          <Col lg={10} className="justify-content-center d-flex">
            <Image src={arrowDown} alt="" />
          </Col>
          <Col lg={10}>
            <h2 className="text-center mt-4">{introductionContent.Title}</h2>
          </Col>
        </Row>
        <Row className="justify-content-md-center text-center p-2">
          <Col lg={8}>
            <p className="subtitle">
              <ReactMarkdown>{introductionContent.Subtitle}</ReactMarkdown>
            </p>
          </Col>
        </Row>
        <ListOnShore items={introductionContent.ItemList} />
        <Row className="justify-content-md-center text-center p-2">
          <Col lg={8}>
            <p className="subtitle"><ReactMarkdown>{introductionContent.Description1}</ReactMarkdown></p>
            <p className="subtitle"><ReactMarkdown>{introductionContent.Description2}</ReactMarkdown></p>
          </Col>
        </Row>
        <Row>
          <Col className="justify-content-center align-items-center d-flex  mt-4">
            <Button
              variant="secondary"
              href="#getInTouch"
              className="paddingButtonMainBanner"
            >
              {t("buttonsAndAccesories.buttonGetFree", {
                buttonGetFree: `${bannerDownload.buttonGetFree}`,
              })}
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MSEIntroduction;
