import axios from "axios";
import { useState } from "react";

export const useRequestApi = () => {
  const [header, setHeader] = useState({});
  const [dataContentLanding, setdataContentLanding] = useState({});
  const [bannerImg, setBannerImg] = useState({});
  const [dataSpeaker, setDataSpeaker] = useState({});
  const [imgSpeakerOne, setImgSpeakerOne] = useState({});
  const [imgSpeakerTwo, setImgSpeakerTwo] = useState({});
  const [bannerBox, setBannerBox] = useState({});
  const [bannerDownload, setBannerDownload] = useState({});
  const [bannerImgMobile, setBannerImgMobile] = useState({});
  const [bannerBoxImg, setBannerBoxImg] = useState({});
  const [contact, setcontact] = useState({});
  const [contactEvent, setContactEvent] = useState({});
  const [postWebinarDesc, setPostWebinarDesc] = useState({});
  const [dataSaler, setDataSaler] = useState({});
  const [dataSalerImg, setDataSalerImg] = useState({});
  const [sticker, setSticker] = useState({});
  const [dataBdd, setdataBdd] = useState({});
  const [dataDetails, setDataDetails] = useState({});
  const [dataModules, setDataModules] = useState({});
  const [dataParticipants, setdataParticipants] = useState({});
  const [dataCta, setDataCta] = useState({});
  const [postWebinarData, setPostWebinarData] = useState({});
  const [postWebinarDescModul1, setPostWebinarDescModul1] = useState("");
  const [postWebinarDescModul2, setPostWebinarDescModul2] = useState("");
  const [imgModal, setImgModal] = useState("");
  const [imageBackgroudMobile, setImageBackgroudMobile] = useState("");
  const [salesConsultant, setSalesConsultant] = useState({});
  const [FormDownloadData, setFormDownloadData] = useState({});
  const [loader, setLoader] = useState(true);
  const requestApiMse = (pathname) => {
    axios.get(`${process.env.REACT_APP_URL_API}/${pathname}`).then((res) => {
      setLoader(false);
      setHeader(res.data.header);
      setDataSpeaker(res.data.details);
      setImgSpeakerOne(res.data.details.speakerImage1);
      setImgSpeakerTwo(res.data.details.speakerImage2);
      setSticker(res.data.details.sticker);
      setPostWebinarDesc(res.data.webinar);
      setcontact(res.data.contact);
      setContactEvent(res.data.contactEvent);
      setBannerDownload(res.data.banner); //
      setBannerImg(res.data.header.banner?.url); //banner header
      setBannerImgMobile(res.data.header.bannerMobile.url);
      setBannerBox(res.data.bannerBox);
      setBannerBoxImg(res.data.bannerBox.BannerImage);
      setDataSaler(res.data.saler);
      setDataSalerImg(res.data.saler.imageSalesConsultant);
      setFormDownloadData(res.data.FormDownload[0])
    });
  };
  const requestApiSre = () => {
    axios.get(`${process.env.REACT_APP_URL_API}/landing-sre`).then((res) => {
      setLoader(false);
      setdataContentLanding(res.data.ContentSre);
      setDataSpeaker(res.data.ContentSre.details);
      setSticker(res.data.ContentSre.details.sticker);
      setImgSpeakerOne(res.data.ContentSre.details.speakerImage1);
      setImgSpeakerTwo(res.data.ContentSre.details.speakerImage2);
      setBannerBox(res.data.ContentSre.bannerBox);
      setcontact(res.data.ContentSre.contact);
      setBannerBoxImg(res.data.ContentSre.bannerBox?.bannerImage);
      setBannerImg(res.data.ContentSre.banner?.url);
      setBannerImgMobile(res.data.ContentSre.bannerMobile?.url);
      setContactEvent(res.data.ContentSre.contactEventSre);
      setPostWebinarDesc(res.data.ContentSre.DiscoveryDescription);
    });
  };
  const requestApiProductDiscovery = () => {
    axios
      .get(`${process.env.REACT_APP_URL_API}/landing-product-discovery`)
      .then((res) => {
        setLoader(false);
        setBannerBox(res.data.ContentLanding.BannerBox);
        setBannerImgMobile(res.data.ContentLanding.Banner_Mobile.url);
        setBannerBoxImg(res.data.ContentLanding.BannerBox.BannerImage);
        setdataContentLanding(res.data.ContentLanding);
        setBannerImg(res.data.ContentLanding.Banner.url);
        setDataSpeaker(res.data.ContentLanding.Detalles_Doble);
        setSticker(res.data.ContentLanding.Detalles_Doble.Sticker);
        setImgSpeakerOne(
          res.data.ContentLanding.Detalles_Doble.Imagen_Speaker_1
        );
        setImgSpeakerTwo(
          res.data.ContentLanding.Detalles_Doble.Imagen_Speaker_2
        );
        setcontact(res.data.ContentLanding.Contacto);
        setContactEvent(res.data.ContentLanding.Contact_eventos);
        setPostWebinarDesc(res.data.ContentLanding.Desc_discovery);
      });
  };

  const requestApiItaas = () => {
    axios
      .get(`${process.env.REACT_APP_URL_API}/landing-it-as-a-service`)
      .then((res) => {
        setLoader(false);
        setdataContentLanding(res.data.ContentLanding);
        setDataSpeaker(res.data.ContentLanding.Details);
        setBannerImg(res.data.ContentLanding.Banner.url);
        setSticker(res.data.ContentLanding.Details.sticker);
        setImgSpeakerOne(res.data.ContentLanding.Details.speakerImage1);
        setImgSpeakerTwo(res.data.ContentLanding.Details.speakerImage2);
        setBannerBox(res.data.ContentLanding.BannerBox);
        setBannerBoxImg(res.data.ContentLanding.BannerBox.BannerImage);
        setcontact(res.data.ContentLanding.Contact);
        setContactEvent(res.data.ContentLanding.Contact_Event);
        setBannerImgMobile(res.data.ContentLanding.Banner_Mobile.url);
        setPostWebinarDesc(res.data.ContentLanding.DiscoveryDescription);
      });
  };

  const requestApiSreProductividad = () => {
    axios
      .get(`${process.env.REACT_APP_URL_API}/landing-productividad-2023`)
      .then((res) => {
        setLoader(false);
        setdataBdd(res.data);
        setBannerImg(res.data.Imagen.url);
        setBannerImgMobile(res.data.Banner_Mobile.url);
        setDataDetails(res.data.Descripcion365);
        setcontact(res.data.Contacto);
        setDataModules(res.data.Modulos);
        setdataParticipants(res.data.Detalles_doble);
        setDataCta(res.data.CTA);
        setPostWebinarData(res.data.Post_webinar);
        setPostWebinarDescModul1(res.data.Post_webinar.descripcion_modulo1);
        setPostWebinarDescModul2(res.data.Post_webinar.descripcion_modulo2);
        setContactEvent(res.data.Contacto_eventos);
        setImageBackgroudMobile(res.data.Descarga_Free_mobile.url);
        setImgModal(res.data.Post_webinar.img_modal.url);
        setSalesConsultant(res.data.Sales_Consultant);
        setPostWebinarDesc(res.data.Post_webinar);
      });
  };
  return {
    loader,
    header,
    bannerImg,
    dataSpeaker,
    imgSpeakerOne,
    imgSpeakerTwo,
    bannerBox,
    bannerDownload,
    bannerImgMobile,
    bannerBoxImg,
    contact,
    contactEvent,
    postWebinarDesc,
    dataSaler,
    dataSalerImg,
    sticker,
    dataContentLanding,
    dataBdd,
    dataDetails,
    dataModules,
    dataParticipants,
    dataCta,
    FormDownloadData,
    postWebinarData,
    postWebinarDescModul1,
    postWebinarDescModul2,
    imgModal,
    imageBackgroudMobile,
    salesConsultant,
    requestApiMse,
    requestApiSre,
    requestApiProductDiscovery,
    requestApiItaas,
    requestApiSreProductividad,
  };
};
