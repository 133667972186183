import React, { Fragment } from "react";
import { Row, Col, Card } from "react-bootstrap";
import "react-dropzone-uploader/dist/styles.css";
import imageBanner from "../../assets/note.png";
import { BannerSuport } from "./bannerSuport";
import { BannerInfoSuport } from "./bannerInfoSuport";
import "./helpdesk.css";
const HelpdeskForm = () => {
  return (
    <Fragment>
      <div className="style-container-heldesk">
      <BannerSuport imageBanner={imageBanner} />
      <div className="justify-content-center align-items-center d-flex">
        <Row className="row-contact">
          <Col lg={3} md={4} className="column1">
            <Card className="justify-content-center align-items-center d-flex card-with">
              <Card.Body>
                <Card.Title className="text-center fw-bold">
                  Microsoft
                </Card.Title>
                <Card.Text className="text-center fw-bold">Teams</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} md={4} className="column2">
            <Card className="justify-content-center align-items-center d-flex card-with ">
              <Card.Body>
                <Card.Title className="text-center fw-bold">
                  Teléfono
                </Card.Title>
                <Card.Text className="text-center">0810 345 0562</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <BannerInfoSuport />

      </div>
    </Fragment>
  );
};

export default HelpdeskForm;
