import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export const WebinarSummary = ({
  columnOne,
  columnTwo,
  columnThree,
  columnFour,
  postWebinarData,
}) => {
  return (
    <Container fluid className="paddingTopContainer">
      <Container>
        <Row className="titulomodulos">
          <Col md={12} xs={12}>
            <h2 className="text-center">{postWebinarData}</h2>
          </Col>
        </Row>
      </Container>
      <Container className="separatorContainer">
        <Row className="d-flex justify-content-center">
          <Col lg={4} xxl={4} xl={4} xs={12} md={5}>
            <ol>
              <li className="paragraphSummary">{columnOne}</li>
            </ol>
          </Col>
          <Col lg={4} xxl={4} xl={4} xs={12} md={5} className="column-position">
            <ol>
              <li className="paragraphSummary">{columnTwo}</li>
            </ol>
          </Col>
        </Row>

        <Row className="d-flex justify-content-center separatorColumn">
          {columnThree ? (
            <Col lg={4} xxl={4} xl={4} xs={12} md={5}>
              <ol>
                <li className="paragraphSummary">{columnThree}</li>
              </ol>
            </Col>
          ) : (
            <Col lg={4} xxl={4} xl={4} xs={12} md={5}></Col>
          )}
          {columnFour ? (
            <Col
              lg={4}
              xxl={4}
              xl={4}
              xs={12}
              md={5}
              className="column-position"
            >
              <ol>
                <li className="paragraphSummary">{columnFour}</li>
              </ol>
            </Col>
          ) : (
            <Col
              lg={4}
              xxl={4}
              xl={4}
              xs={12}
              md={5}
              className="column-position"
            ></Col>
          )}
        </Row>
      </Container>
    </Container>
  );
};
