import React from "react";
import { Button, Col, Container } from "react-bootstrap";
import ReactMarkdown from "react-markdown";

const Banner = ({ banner }) => {
  return (
    <Container
      fluid
      style={{ backgroundImage: `url(${banner.background?.url})` }}
      id="awardsBannerContainer"
    >
      <Container>
        <ReactMarkdown>{banner?.text}</ReactMarkdown>
        {banner.button?.map((btn, idx) => (
          <Col xs={12} md={3} key={idx}>
            <Button className="btn-download" variant={btn.variant} href={btn.href}>
              {btn.textButton}
            </Button>
          </Col>
        ))}
      </Container>
    </Container>
  );
};

export default Banner;
