import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";

const SoftwareEngineering = ({data}) => {
  return (
    <Container id="houstonSoftwareEngineeringSection">
      <Col lg={12}>
        <Row className="d-flex justify-content-center justify-content-xl-start flex-column-reverse flex-md-row gap-3">
          <Col lg={3} className="d-flex justify-content-center">
            <Image src={data.image} alt="ilustration we love dev" />
          </Col>
          <Col lg={6} className="pt-3">
            <p>
              {data.bajada}
            </p>
          </Col>
        </Row>
      </Col>
      <Col lg={9} className="mt-4 d-flex flex-column justify-content-center justify-content-xl-start">
        <p>
          {data.descripcion1}
        </p>
        <p>
          {data.descripcion2}
        </p>
      </Col>
    </Container>
  );
};

export default SoftwareEngineering;
