import { useTranslation } from "react-i18next";
import { Container, Row, Col, Button } from "react-bootstrap";
import imgMain from "../../../assets/bd-ilustracion-nube.svg";
import { bannerText } from "../../../config/home/textPage";
import IsoCertificateAndWeLoveDev from "../../commons/IsoCertificateAndWeLoveDev";

const DevOpsHeader = ({ titulo, bajada }) => {
  const { t } = useTranslation();
  return (
    <Container
      fluid
      className="d-md-block imageBackGroundUsa background-banner-header"
    >
      <Container id="mainbanner" className="marginTitleContainer">
        <Row>
          <Col lg={7}>
            <h1>
              {titulo}
            </h1>
            <h3>
              {bajada}
            </h3>
            <div className="cta">
              <Button variant="primary" href="#getInTouch">
                {t("buttonsAndAccesories.consult", {
                  consult: `${bannerText.buttonConsult}`,
                })}
              </Button>
            </div>
          </Col>
          <Col
            lg={5}
            className="justify-content-center align-items-center d-flex"
          >
            <img
              align="right"
              src={imgMain}
              className="img-fluid"
              alt="Accelerate your digital transformation"
            ></img>
          </Col>
        </Row>
        <IsoCertificateAndWeLoveDev />
      </Container>
    </Container>
  );
};

export default DevOpsHeader;
