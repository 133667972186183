import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { bannerDownload } from "../../config/Commons/textPage";
import { useTranslation } from "react-i18next";
import { cloudnativeSre } from "../../config/sre/textPage";

export const CloudNative = () => {
  const { t } = useTranslation();
  return (
    <Container id="CloudNative" className="container-style-cloud">
      <Row className="justify-content-md-center" id="">
        <Col lg={12}>
          <h2 className="text-center">
            {t("cloudnativeSre.title", {
              title: `${cloudnativeSre.title}`,
            })}
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-md-center text-center spacingSectionSoftwareEngineering mt-2">
        <Col lg={10}>
          <h3>
            {t("cloudnativeSre.paragraph", {
              paragraph: `${cloudnativeSre.paragraph}`,
            })}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col className="justify-content-center align-items-center d-flex">
          <Button
            variant="secondary"
            href="#getInTouch"
            className="paddingButtonMainBanner"
          >
            {t("buttonsAndAccesories.buttonGetFree", {
              buttonGetFree: `${bannerDownload.buttonGetFree}`,
            })}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
