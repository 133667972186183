import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import HoustonNavbar from "./Navbar";
import WeLoveTech from "../../assets/landings/houston/logoWLT.svg";
import IsoImg from "../../assets/9001_azul.svg";
import AnimatedText from "../commons/animatedText";

const PpalHeader = ({ text, backgroundImage, Ilustration }) => {

  return (
    <Container fluid>
      <HoustonNavbar />
      <div
        id="houstonBackgroundImage"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      ></div>
      <Container
        id="ppalHoustonHeader"
        className={Ilustration ? "withIlustration" : ""}
      >
        <Row className={Ilustration ? "flex-column-reverse flex-md-row" : ""}>
          <Col lg={7} className="houstonHeaderDescription">
            <h1>{text.titulo}</h1>
            {text.bajadas?.length > 0 ? <AnimatedText text={text.bajada.replace(text.bajadas[0], '')} strings={text.bajadas} typeSpeed={4000} /> : <p>{text.bajada}</p>}
            <Row className="houstonHeaderIso">
              <Col className="d-flex flex-column-reverse flex-md-column">
                <Col className="d-flex align-items-center flex-column flex-md-row justify-content-center justify-content-md-start gap-md-2 mb-4">
                  <img src={IsoImg} alt="Iso logo" className="mb-3 mb-md-0" />
                  <p className="text-center text-md-start m-0 small-content">
                    {text.iso1}
                    <br></br>
                    {text.iso2}
                  </p>
                </Col>
                <Col
                  lg={3}
                  md={12}
                  className="d-flex justify-content-center d-lg-block mb-3"
                >
                  <Button variant="primary" href="#ourServices">
                    {text.button}
                  </Button>
                </Col>
              </Col>
              <Col
                lg={1}
                sm={12}
                className="d-flex justify-content-center justify-content-md-end align-items-md-end"
              >
                <Image
                  src={WeLoveTech}
                  alt="Possumus We love dev"
                  className="houstonWeLoveTech"
                />
              </Col>
            </Row>
          </Col>
          {Ilustration && (
            <Col className="d-flex justify-content-center">
              <Ilustration />
            </Col>
          )}
        </Row>
      </Container>
    </Container>
  );
};

export default PpalHeader;
