import React, {useContext} from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import elipse from "../../../assets/Ellipse.svg";
import { ContentMicrosoftEcosystemContext } from "../../../contexts/ContentMicrosoftEcosystemContext";

export const AppoachesMobile = () => {
  const { approachesAndMethodologiesContent } = useContext(
    ContentMicrosoftEcosystemContext
  );
  return (
    <div class="d-block d-sm-block d-lg-none">
      <Row className="justify-content-md-center text-left  mt-4 gap-4">
        {approachesAndMethodologiesContent.Card?.map((item, idx) => (
          <Col sm={12} className="m-0 p-0 cardEvolutionary">
          <Card bg="light" id="myCardMobile" className={idx !== 0 && "spacing-card-mobile"}>
            <div>
              <Image
                src={elipse}
                alt=""
                className="marginElipseColumn1Mobile"
              />
            </div>
            <Card.Header className="titleCard mt-4">
              {item.Title}
            </Card.Header>
            <div className="lineCard">
              <hr className="m-0 p-0" />
            </div>
            <Card.Body className="pb-0">
              <Card.Text>
                {item.Description}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        ))}
      </Row>
    </div>
  );
};
