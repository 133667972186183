import React from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import elipse from "../../../assets/Ellipse.svg";
import { useTranslation } from "react-i18next";
import { softwareEngineering } from "../../../config/softwareEngineering/textPage";

export const ProductEngineeringDesk = () => {
  const { t } = useTranslation();
  return (
    <div className="d-none d-sm-none d-lg-block">
      <Row className="justify-content-md-center text-left  mt-4">
        <Col lg={5}>
          <Card bg="light" id="myCard">
            <Card.Header className="titleCard">
              <Image src={elipse} alt="" className="marginElipseColumn1" />
              {t("sectionEngineering.titleCard1", {
                titleCard1: `${softwareEngineering.titleCard1Engineering}`,
              })}
            </Card.Header>
            <div className="lineCard">
              <hr className="m-0 p-0" />
            </div>
            <Card.Body>
              <Card.Text>
                {t("sectionEngineering.card1Paragraph", {
                  card1Paragraph: `${softwareEngineering.card1ParagraphEngineering}`,
                })}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={1} className="m-0 p-0">
          <div style={{ position: "relative", height: "100%" }}>
            <hr className="lineSeparatorBodyLeft" />
            <div className="d-flex lineVerticalBody">
              <div className="vr"></div>
            </div>
          </div>
        </Col>
        <Col lg={5}></Col>
      </Row>
      <Row className="justify-content-md-center text-left  mt-4">
        <Col lg={5}></Col>
        <Col lg={1} className="m-0 p-0">
          <div style={{ position: "relative", height: "100%" }}>
            <hr className="lineSeparatorBodyRight" />
            <div className="d-flex lineVerticalBodyRight">
              <div className="vr"></div>
            </div>
          </div>
        </Col>
        <Col lg={5}>
          <Card bg="light" id="myCard">
            <Card.Header className="titleCard">
              <Image src={elipse} alt="" className="marginElipseColumn2" />
              {t("sectionEngineering.titleCard2", {
                titleCard2: `${softwareEngineering.titleCard2Engineering}`,
              })}
            </Card.Header>
            <div className="lineCard">
              <hr className="m-0 p-0" />
            </div>
            <Card.Body>
              <Card.Text>
                {t("sectionEngineering.card2Paragraph", {
                  card2Paragraph: `${softwareEngineering.card2ParagraphEngineering}`,
                })}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-md-center text-left  mt-4">
        <Col lg={5}>
          <Card bg="light" id="myCard">
            <Card.Header className="titleCard">
              <Image src={elipse} alt="" className="marginElipseColumn1" />
              {t("sectionEngineering.titleCard3", {
                titleCard3: `${softwareEngineering.titleCard3Engineering}`,
              })}
            </Card.Header>
            <div className="lineCard">
              <hr className="m-0 p-0" />
            </div>
            <Card.Body>
              <Card.Text>
              {t("sectionEngineering.card3Paragraph", {
                  card3Paragraph: `${softwareEngineering.card3ParagraphEngineering}`,
                })}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={1} className="m-0 p-0">
          <div style={{ position: "relative", height: "100%" }}>
            <hr className="lineSeparatorBodyLeft" />
            <div className="d-flex lineVerticalBodyLeft">
              <div className="vr"></div>
            </div>
          </div>
        </Col>
        <Col lg={5}></Col>
      </Row>
    </div>
  );
};
