import { Container, Image } from "react-bootstrap";
import "./MSSeparator.css";
import microsofotLogo from "../../../../assets/logos/Microsoft.svg";
import mssolutionInfra from "./assets/MSSolution-infrastructure.svg";
import mssolutionData from "./assets/MSSolution-data.svg";
import mssolutionInnovation from "./assets/MSSolution-innovation.svg";
import mssolutionModern from "./assets/MSSolution-modern.svg";
import mssolutionSecurity from "./assets/MSSolution-security.svg";
import mssolutionBusiness from "./assets/MSSolution-business.svg";
import ReactMarkdown from "react-markdown";

const awardsListItems = [
  {
    title: `Solution partner  
    for Infrastructure  
    (Azure)`,
    image: mssolutionInfra,
  },
  {
    title: `Solution partner  
    for Data & AI  
    (Azure)`,
    image: mssolutionData,
  },
  {
    title: `Solution partner  
    for Digital & App  
    Innovation (Azure)`,
    image: mssolutionInnovation,
  },
  {
    title: `Solution partner  
    for  
    Modern Work`,
    image: mssolutionModern,
  },
  {
    title: `Solution partner  
    for  
    Security`,
    image: mssolutionSecurity,
  },
  {
    title: `Solution partner  
    for Business  
    Applications`,
    image: mssolutionBusiness,
  },
];

const MSSeparatorAwardsHeaderTitle = ({ children }) => <h2>{children}</h2>;
const MSSeparatorAwardsHeaderSubtitle = ({ children }) => (
  <span className="bottom-subtitle">{children}</span>
);

const MSSeparatorAwardsHeader = ({ children }) => (
  <div className="msSeparatorHeader">
    <Image src={microsofotLogo} width={297} height={64} alt="Microsoft" />
    <div className="titleContainer">{children}</div>
  </div>
);

MSSeparatorAwardsHeader.Title = MSSeparatorAwardsHeaderTitle;
MSSeparatorAwardsHeader.Subtitle = MSSeparatorAwardsHeaderSubtitle;
const MSSeparatorAwardsItem = ({ item }) => (
  <article className="msSeparatorItem">
    <ReactMarkdown className="itemTitle">{item.title}</ReactMarkdown>
    <Image src={item.image} alt={item.title} />
  </article>
);
const MSSeparatorAwardsList = () => (
  <div className="awardsList">
    {awardsListItems.map((item) => (
      <MSSeparatorAwardsItem
        key={`MS Solution item - ${item.title}`}
        item={item}
      />
    ))}
  </div>
);
export const MSSeparatorAwards = () => (
  <Container className="msSeparatorAwardsContainer">
    <MSSeparatorAwardsHeader>
      <MSSeparatorAwardsHeader.Subtitle>
        Mayo 2024
      </MSSeparatorAwardsHeader.Subtitle>
      <MSSeparatorAwardsHeader.Title>
        We are a Microsoft Solutions Partner
      </MSSeparatorAwardsHeader.Title>
    </MSSeparatorAwardsHeader>
    <MSSeparatorAwardsList />
  </Container>
);
