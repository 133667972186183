import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { AppoachesDesk } from "./approachesDesk";
import { AppoachesMobile } from "./approachesMobile";
import { bannerDownload } from "../../../config/Commons/textPage";
import { useTranslation } from "react-i18next";

export const AppoachesMain = () => {
  const { t } = useTranslation();
  return (
    <>
      <AppoachesDesk />
      <AppoachesMobile />
      <Row className="mb-4">
        <Col className="justify-content-center align-items-center d-flex mb-4">
          <Button
            variant="secondary"
            href="#getInTouch"
            className="paddingButtonMainBanner"
          >
            {t("buttonsAndAccesories.buttonGetFree", {
              buttonGetFree: `${bannerDownload.buttonGetFree}`,
            })}
          </Button>
        </Col>
      </Row>
    </>
  );
};
