import './SolutionsPartnerMSE.css'
import infra from "../../../../assets/landings/houston/mse/infra.svg";
import data from "../../../../assets/landings/houston/mse/data.svg";
import azure from "../../../../assets/landings/houston/mse/azure.svg";
import modern from "../../../../assets/landings/houston/mse/modern.svg";
import security from "../../../../assets/landings/houston/mse/security.svg";
import bussines from "../../../../assets/landings/houston/mse/bussines.svg";
import { Container } from "react-bootstrap";
import ReactMarkdown from 'react-markdown';

const solutions = [
  {
    title: `Solution partner  
    for Infrastructure  
    (Azure)`,
    image: infra,
  },
  {
    title: `Solution partner  
    for Data & AI  
    (Azure)`,
    image: data,
  },
  {
    title: `Solution partner  
    for Digital & App  
    Innovation (Azure)`,
    image: azure,
  },
  {
    title: `Solution partner  
    for  
    Modern Work`,
    image: modern,
  },
  {
    title: `Solution partner  
    for  
    Security`,
    image: security,
  },
  {
    title: `Solution partner  
    for Business  
    Applications`,
    image: bussines,
  },
];

export default function SolutionsPartnerMSE() {
  return (
    <Container id='solutionsPartnerContainer'>
      <h4>We are a Microsoft Solutions Partner</h4>
      <section className="cardsContainers">
        {solutions.map((solution) => (
          <article>
            <ReactMarkdown>{solution.title}</ReactMarkdown>
            <img src={solution.image} alt={solution.title} />
          </article>
        ))}
      </section>
    </Container>
  );
}
