import React, { useContext } from "react";
import "../css/staff.css";
import "../css/devOpsSre.css";
import GetInTouch from "../components/commons/getInTouch";
import { Footer } from "../components/commons/footer";
import { LocationAddress } from "../components/usa/locationAddress";
import DevopsBanner from "../components/devops/devopsBanner";
import MenuUsa from "../components/commons/menuUsa";
import { CardsSRE } from "../components/commons/cardsSRE";
import { DevopsPlatform } from "../components/devops/devopsPlatform";
import { DevopsCloud } from "../components/devops/devopsCloud";
import { CloudNative } from "../components/devops/cloudNative";
import { ApproachesAndMethodologies } from "../components/devops/approachesAndMethodologies";
import { BannerCloud } from "../components/devops/bannerCloud";
import { DownloadBanner } from "../components/commons/downloadBanner";
import { ContentDevOpsContext } from "../contexts/ContentDevOpsContext";
import SEOContent from "../components/commons/SEOContent";
import { ActualLangContext } from "../contexts/ActualLangContext";
import useScrollToSection from "../hooks/useScrollToSection";

const DevOpsSre = () => {
  const { SEOContent: SEOData } = useContext(ContentDevOpsContext);
  const { actualLang } = useContext(ActualLangContext);

   useScrollToSection()

  const brochureIPD =
    actualLang === "es"
      ? "https://possumustech.blob.core.windows.net/staticfiles/assets/IDP_brochure_es_c0c4fae335.pdf"
      : "https://possumustech.blob.core.windows.net/staticfiles/assets/IDP_brochure_en_dad2a89c71.pdf";
  return (
    <>
      <SEOContent SEOData={SEOData} />
      <div className="bg-circle-sre">
        <MenuUsa />
        <DevopsBanner />
        <CardsSRE />
        <DevopsPlatform />
        <DownloadBanner
          file={brochureIPD}
          mockup="https://possumustech.blob.core.windows.net/staticfiles/assets/IDP_mockup_885846baa3.svg"
        />
        <DevopsCloud />
        <BannerCloud />
        <div className="bg-circle-gray">
          <CloudNative />
          <ApproachesAndMethodologies />
        </div>
        <DownloadBanner />
        <GetInTouch />
        <LocationAddress />
        <Footer />
      </div>
    </>
  );
};

export default DevOpsSre;
