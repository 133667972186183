import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import MemberCard from "./MemberCard";

const OurTeam = ({data}) => {
  
  return (
    <Container
      id="houstonOurTeam"
      className="d-grid"
    >
      <Col className="text-center d-flex flex-column align-items-center">
        <h2 className="sup-title">{data.suptitle && data.suptitle}</h2>
        {data.title && <span>{data.title}</span>}
      </Col>
      <Col xl={{ span: 10, offset: 2 }} lg={10}>
        <Row>
          {data.members.map((member, idx) => (
            <MemberCard member={member} key={idx} />
          ))}
        </Row>
      </Col>
    </Container>
  );
};

export default OurTeam;
