import { useEffect, useState } from "react";
import axios from "axios";
import HeaderLanding from "../components/landings/header";
import "../css/landing.css";
import BannerAlt from "../components/landings/bannerAlt";
import DetailsTwoParticipants from "../components/landings/detailsTwoParticipants";
import FormLanding from "../components/landings/form";
import FooterLanding from "../components/commons/footerLanding";
import { IframeYouTube } from "../components/landings/iframeYouTube";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Partners from "../components/home/ourPartners";

export const Bdd = () => {
  const [dataBdd, setdataBdd] = useState({});
  const [bannerImg, setBannerImg] = useState({});
  const [bannerImgMobile, setBannerImgMobile] = useState({});
  const [dataDetails, setDataDetails] = useState({});
  const [imgSpeakerOne, setImgSpeakerOne] = useState({});
  const [imgSpeakerTwo, setImgSpeakerTwo] = useState({});
  const [dataCta, setdataCta] = useState({});
  const [imgCta, setImgCta] = useState({});
  const [contact, setcontact] = useState({});
  const [contactEvent, setContactEvent] = useState({});
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL_API}/landing-bdd`).then((res) => {
      setdataBdd(res.data);
      setBannerImg(res.data.Imagen);
      setBannerImgMobile(res.data.Banner_Mobile[0].url);
      setDataDetails(res.data.Detalles_Doble);
      setImgSpeakerOne(res.data.Detalles_Doble.Image_1);
      setImgSpeakerTwo(res.data.Detalles_Doble.Imagen_Speaker_2);
      setdataCta(res.data.CTA);
      setImgCta(res.data.CTA.Imagen);
      setcontact(res.data.Contacto);
      setContactEvent(res.data.Contacto_eventos_Bdd);
    });
  }, []);
  const url = process.env.REACT_APP_URL + "/webinars/bdd";
  return (
    <HelmetProvider>
      <Helmet>
        <title>{`Possumus - TechTalk: BDD`}</title>
        <meta property="og:title" content="Possumus - TechTalk: BDD" />
        <link rel="canonical" href={`${url}`} />
        <meta property="og:url" content={`${url}`} />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="Possumus" />
        <meta
          property="og:image"
          content="https://possumustech.blob.core.windows.net/staticfiles/Miniatura_OG_b0b1d5c32e.png"
        />
      </Helmet>
      <div id="talkTechSolid">
        <HeaderLanding
          title="TechTalk: BDD"
          phone="0810 345 0562"
          template="ligth"
        />
        <BannerAlt
          idContainer="bannerLandingSolid"
          idButton="formLandingBdd"
          href="formLanding"
          idHeader="titleMainLandingBDD"
          imgBanner={bannerImg.url}
          imgBannerMobile={bannerImgMobile}
          date={dataBdd.Fecha}
          time={dataBdd.Hora}
          subtitle={dataBdd.Subtitulo}
          title={dataBdd.Titulo}
          active={dataBdd.Activa}
          template="dark"
          buttonWatchWebinar={"Ver webinar"}
          buttonParticipateWebinar={"Quiero participar"}
        />

        <DetailsTwoParticipants
          upTitle={dataDetails.Copete}
          title={dataDetails.Titulo}
          subTitle={dataDetails.Subtitulo}
          description={dataDetails.Descripcion}
          speakerOne={dataDetails.Speaker_1}
          speakerTwo={dataDetails.Speaker_2}
          imgSpeakerOne={imgSpeakerOne.url}
          imgSpeakerTwo={imgSpeakerTwo.url}
          ctaText={dataCta.Texto}
          ctaAlign={dataCta.Alineacion}
          ctaBackground={dataCta.Color_de_fondo}
          ctaImage={imgCta.url}
        />
        <IframeYouTube
          id={contact.Id_Youtube}
          title={"¡Ya disponible en YouTube!"}
          active={dataBdd.Activa}
        />
        <div className="separatorLandingViva"></div>
        <FormLanding
          active={dataBdd.Activa}
          titleActive={contact.Titulo_Formulario}
          titleEvent={contactEvent?.formTitle}
          contactOrigin={
            dataBdd.Activa
              ? contact.Origen_Contacto
              : contactEvent?.Origen_Contacto
          }
          idButton="formLanding"
        />
       <Partners />
        <FooterLanding title={dataBdd.Subtitulo} />
      </div>
    </HelmetProvider>
  );
};

export default Bdd;
