import React, { useState } from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import isopk1 from "../../assets/iso1pk.svg";
import isopk2 from "../../assets/iso2pk.svg";
import "../../css/service.css";
import { menuServices } from "../../config/home/textPage";
import { useTranslation } from "react-i18next";

const ServicesUsa = () => {
  const { t } = useTranslation();
  const [imgIso1, setImgIso1] = useState(isopk1);
  const [imgIso2, setImgIso2] = useState(isopk1);
  const [imgIso3, setImgIso3] = useState(isopk1);
  const [imgIso4, setImgIso4] = useState(isopk1);
  const [imgIso5, setImgIso5] = useState(isopk1);

  return (
    <Container fluid id="whatMovesCirculo">
      <Container
        id="services"
        className="d-none d-sm-none d-md-none d-lg-block"
      >
        <Row className="justify-content-md-center">
          <Col>
            <p className="sup-title text-uppercase text-center">
              {t("menuServices.title", {
                title: `${menuServices.title}`,
              })}
            </p>
            <h4 className="text-center mb-5">
              {t("menuServices.subtitle1", {
                subtitle1: `${menuServices.subtitle1}`,
              })}
            </h4>
          </Col>
        </Row>

        <Row className=" listServices">
          <Col lg={4}>
            <Button
              variant="seeMore"
              className="card d-flex flex-column justify-content-center"
              href="#softwareEngineering"
              onMouseLeave={() => setImgIso1(isopk1)}
              onMouseEnter={() => setImgIso1(isopk2)}
            >
              <div className="clearfix d-lg-flex ">
                <img
                  src={imgIso1}
                  className="img-fluid mr-1"
                  alt="Software Engineering"
                ></img>
                <div className="left">
                  <h4>
                    {t("menuServices.softPart1", {
                      softPart1: `${menuServices.softPart1}`,
                    })}
                    <br />
                    {t("menuServices.softPart2", {
                      softPart2: `${menuServices.softPart2}`,
                    })}
                  </h4>
                </div>
              </div>
              <div className="left paddingLeft">
                <p>
                  {t("menuServices.button", {
                    button: `${menuServices.button}`,
                  })}
                  <i class="fas fa-arrow-right"></i>
                </p>
              </div>
            </Button>
          </Col>
          <Col lg={4}>
            <Button
              variant="seeMore"
              className="card d-flex flex-column justify-content-center"
              href="#sre"
              onMouseLeave={() => setImgIso2(isopk1)}
              onMouseEnter={() => setImgIso2(isopk2)}
            >
              <div className="clearfix d-lg-flex">
                <img
                  src={imgIso2}
                  className="img-fluid mr-1"
                  alt="Software Engineering"
                ></img>
                <div>
                  <h4 className="text-start">
                    {" "}
                    {t("menuServices.sre", {
                      sre: `${menuServices.sre}`,
                    })}
                  </h4>
                </div>
              </div>
              <div className="left paddingLeft">
                <p>
                  {t("menuServices.button", {
                    button: `${menuServices.button}`,
                  })}
                  <i class="fas fa-arrow-right"></i>
                </p>
              </div>
            </Button>
          </Col>
          <Col lg={4}>
            <Button
              variant="seeMore"
              className="card d-flex flex-column justify-content-center"
              href="/en-en/microsoft-ecosystem"
              onMouseLeave={() => setImgIso4(isopk1)}
              onMouseEnter={() => setImgIso4(isopk2)}
            >
              <div className="clearfix d-lg-flex">
                <img
                  src={imgIso4}
                  className="img-fluid float-left mr-1"
                  alt="Software Engineering"
                ></img>
                <div>
                  <h4 className="left">
                    Microsoft
                    <br />
                    Ecosystem
                  </h4>
                </div>
              </div>
              <div className="left paddingLeft">
                <p>
                  {t("menuServices.button", {
                    button: `${menuServices.button}`,
                  })}
                  <i class="fas fa-arrow-right"></i>
                </p>
              </div>
            </Button>
          </Col>
          <Col lg={4}>
            <Button
              variant="seeMore"
              className="card d-flex flex-column justify-content-center"
              href="/en-en/itaas"
              onMouseLeave={() => setImgIso5(isopk1)}
              onMouseEnter={() => setImgIso5(isopk2)}
            >
              <div className="clearfix d-lg-flex">
                <img
                  src={imgIso5}
                  className="img-fluid mr-1"
                  alt="Software Engineering"
                ></img>
                <div>
                  <h4>
                    {" "}
                    ITaaS
                  </h4>
                </div>
              </div>
              <div className="left paddingLeft">
                <p>
                  {t("menuServices.button", {
                    button: `${menuServices.button}`,
                  })}
                  <i class="fas fa-arrow-right"></i>
                </p>
              </div>
            </Button>
          </Col>
          <Col lg={4}>
            <Button
              variant="seeMore"
              className="card d-flex flex-column justify-content-center"
              href="#staffAugmentation"
              onMouseLeave={() => setImgIso3(isopk1)}
              onMouseEnter={() => setImgIso3(isopk2)}
            >
              <div className="clearfix d-lg-flex">
                <img
                  src={imgIso3}
                  className="img-fluid float-left mr-1"
                  alt="Software Engineering"
                ></img>
                <div>
                  <h4 className="left">
                    {t("menuServices.staffPart1", {
                      staffPart1: `${menuServices.staffPart1}`,
                    })}
                    <br />
                    {t("menuServices.staffPart2", {
                      staffPart2: `${menuServices.staffPart2}`,
                    })}
                  </h4>
                </div>
              </div>
              <div className="left paddingLeft">
                <p>
                  {t("menuServices.button", {
                    button: `${menuServices.button}`,
                  })}
                  <i class="fas fa-arrow-right"></i>
                </p>
              </div>
            </Button>
          </Col>
        </Row>
      </Container>

      <Container
        id="service"
        className="d-block d-sm-block d-md-block d-lg-none paddingMobileTrusted"
      >
        <Row className="justify-content-md-center">
          <Col>
            <p className="sup-title text-uppercase text-center">
              {t("menuServices.title", {
                title: `${menuServices.title}`,
              })}
            </p>
            <h4 className="text-center mb-5">
              {t("menuServices.subtitle1", {
                subtitle1: `${menuServices.subtitle1}`,
              })}
            </h4>
          </Col>
        </Row>

        <Row className="justify-content-md-center text-left listSoftEngineeringMobile">
          <Col md={9} sm={12}>
            <a
              href="#software-engineering"
              target="_self"
              className="link-style"
            >
              <Card
                onMouseLeave={() => setImgIso1(isopk1)}
                onMouseEnter={() => setImgIso1(isopk2)}
              >
                <Row className="g-0">
                  <Col className="col-3">
                    <img
                      src={imgIso1}
                      className="img-fluid"
                      alt="Software Engineering"
                    ></img>
                  </Col>
                  <Col className="col-8 align-items-center d-flex">
                    <h4 className="m-0">
                      {" "}
                      {t("menuServices.softPart1", {
                        softPart1: `${menuServices.softPart1}`,
                      })}
                      <br />
                      {t("menuServices.softPart2", {
                        softPart2: `${menuServices.softPart2}`,
                      })}
                    </h4>
                  </Col>
                </Row>

                <div>
                  <Button
                    id="buttonHome"
                    variant="seeMore"
                    href="#software-engineering"
                  >
                    {t("menuServices.button", {
                      button: `${menuServices.button}`,
                    })}
                    <i class="fas fa-arrow-right"></i>
                  </Button>
                </div>
              </Card>
            </a>
          </Col>
          <Col md={9} sm={12}>
            <a href="#Sre" target="_self" className="link-style">
              <Card
                onMouseLeave={() => setImgIso2(isopk1)}
                onMouseEnter={() => setImgIso2(isopk2)}
              >
                <Row className="g-0">
                  <Col className="col-3">
                    <img src={imgIso2} className="img-fluid" alt="Sre"></img>
                  </Col>
                  <Col className="col-8 align-items-center d-flex">
                    <h4 className="m-0">
                      {" "}
                      {t("menuServices.sre", {
                        sre: `${menuServices.sre}`,
                      })}
                    </h4>
                  </Col>
                </Row>
                <div>
                  <Button id="buttonHome" variant="seeMore" href="#Sre">
                    {t("menuServices.button", {
                      button: `${menuServices.button}`,
                    })}
                    <i class="fas fa-arrow-right"></i>
                  </Button>
                </div>
              </Card>
            </a>
          </Col>
          <Col md={9} sm={12}>
            <a href="/en-en/microsoft-ecosystem" target="_self" className="link-style">
              <Card
                onMouseLeave={() => setImgIso4(isopk1)}
                onMouseEnter={() => setImgIso4(isopk2)}
              >
                <Row className="g-0">
                  <Col className="col-3">
                    <img src={imgIso4} className="img-fluid" alt="Sre"></img>
                  </Col>
                  <Col className="col-8 align-items-center d-flex">
                    <h4 className="m-0">
                      Microsoft
                      <br/> 
                      Ecosystem
                    </h4>
                  </Col>
                </Row>
                <div>
                  <Button id="buttonHome" variant="seeMore" href="/en-en/microsoft-ecosystem">
                    {t("menuServices.button", {
                      button: `${menuServices.button}`,
                    })}
                    <i class="fas fa-arrow-right"></i>
                  </Button>
                </div>
              </Card>
            </a>
          </Col>
          <Col md={9} sm={12}>
            <a href="/en-en/itaas" target="_self" className="link-style">
              <Card
                onMouseLeave={() => setImgIso5(isopk1)}
                onMouseEnter={() => setImgIso5(isopk2)}
              >
                <Row className="g-0">
                  <Col className="col-3">
                    <img src={imgIso5} className="img-fluid" alt="Sre"></img>
                  </Col>
                  <Col className="col-8 align-items-center d-flex">
                    <h4 className="m-0">
                      ITaaS
                    </h4>
                  </Col>
                </Row>
                <div>
                  <Button id="buttonHome" variant="seeMore" href="/en-en/itaas">
                    {t("menuServices.button", {
                      button: `${menuServices.button}`,
                    })}
                    <i class="fas fa-arrow-right"></i>
                  </Button>
                </div>
              </Card>
            </a>
          </Col>
          <Col md={9} sm={12}>
            <a href="#staff-augmentation" target="_self" className="link-style">
              <Card
                onMouseLeave={() => setImgIso3(isopk1)}
                onMouseEnter={() => setImgIso3(isopk2)}
              >
                <Row className="g-0">
                  <Col className="col-3">
                    <img
                      src={imgIso3}
                      className="img-fluid"
                      alt="Staff Augmentation"
                    ></img>
                  </Col>
                  <Col className="col-8 align-items-center d-flex">
                    <h4 className="m-0">
                      {" "}
                      {t("menuServices.staffPart1", {
                        staffPart1: `${menuServices.staffPart1}`,
                      })}
                      <br />
                      {t("menuServices.staffPart2", {
                        staffPart2: `${menuServices.staffPart2}`,
                      })}
                    </h4>
                  </Col>
                </Row>
                <div>
                  <Button
                    id="buttonHome"
                    variant="seeMore"
                    href="#staff-augmentation"
                  >
                    {t("menuServices.button", {
                      button: `${menuServices.button}`,
                    })}
                    <i class="fas fa-arrow-right"></i>
                  </Button>
                </div>
              </Card>
            </a>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default ServicesUsa;
