import React, { useEffect, useState } from "react";
import axios from "axios";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { cloudProviderSre } from "../../config/sre/textPage";

export const BannerCloud = () => {
  const { t } = useTranslation();
  const [listCloud, setListCloud] = useState([]);
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL_API}/page-usa`).then((res) => {
      setListCloud(res.data.listCloud.listImages);
    });
  }, []);
  return (
    <>
      {listCloud.length > 0 && (
        <Container fluid className="containerCloud">
          <Row className="justify-content-md-center" id="">
            <Col lg={12}>
              <h2 className="mt-4 textProvider">
                {t("cloudProviderSre.title", {
                  title: `${cloudProviderSre.title}`,
                })}
              </h2>
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center d-flex imgs-banner-cloud">
            {listCloud.map((item, i) => {
              return (
                <Col
                  lg={3}
                  sm={12}
                  key={i}
                  className="justify-content-center align-items-center d-flex"
                >
                  <Image src={item.url} alt="" />
                </Col>
              );
            })}
          </Row>
        </Container>
      )}
    </>
  );
};
