import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";

const Header = ({ content }) => {
  return (
    <Container
      className="d-flex align-items-center"
      as="header"
      id="awardsHeader"
      fluid
      style={{ backgroundImage: `url(${content?.backgroundImage?.url})` }}
    >
      <Container>
        <Row className="align-items-end justify-content-center">
          <Col lg={8}>
            <h1>{content?.titulo}</h1>
            <p>{content?.bajada}</p>
          </Col>
          <Col xs={{offset: 0, span:8}} lg={{ span: 3, offset: 1 }} className="d-flex justify-content-center">
            <Image fluid src={content?.imagen?.url} alt="awards ilustration" />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Header;
