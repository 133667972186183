import "./Chatbot.css";
import { useState } from "react";

export default function Chatbot({ children }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <aside className="chatbotContainer">
      <div className={isOpen ? "visible" : "invisible"}>{children}</div>
      <button onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? (
          <i class="fa-solid fa-xmark"></i>
        ) : (
          <i class="fa-solid fa-message"></i>
        )}
      </button>
    </aside>
  );
}
