import { Card, Col, Container } from "react-bootstrap";

const Cards = ({ data }) => {
  return (
    <Container fluid className="d-flex" id="simplePayCards">
      <Col md={{ offset: 4 }} className="cards-container-grid">
        {data?.map((card) => (
          <Card>
            <Card.Header>
              <h5>{card.Titulo}</h5>
            </Card.Header>
            <Card.Body>{card.Contenido}</Card.Body>
          </Card>
        ))}
      </Col>
    </Container>
  );
};

export default Cards;
