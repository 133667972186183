export const careersEs = {
  careers: {
    title: "Carreras",
    work: "¡Trabaja con nosotros!",
    apply: "Postularte ahora",
  },
  bannerCareers: {
    title: "CARRERAS",
    subtitle: "¡Uníte a nuestro equipo y hagamos algo genial, juntos!",
  },
};
export const careersEn = {
  careers: {
    title: "{{title}}",
    work: "{{work}}",
    apply: "{{apply}}",
  },
  bannerCareers: {
    title: "{{title}}",
    subtitle: "{{subtitle}}",
  },
};
