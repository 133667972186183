import React, { Fragment } from "react";
import MainBannerDedicatedTeam from "../components/dedicatedTeams/banner";
import WhatIs from "../components/dedicatedTeams/whatIs";
import TeamVsTime from "../components/dedicatedTeams/teamVsTime";
import "../css/dedicatedTeams.css";
import GetInTouch from "../components/commons/getInTouch";
import ContactReadyBuild from "../components/contact/contactReadyBuild";
import { Footer } from "../components/commons/footer";
import MenuUsa from "../components/commons/menuUsa";

const DedicatedTeams = () => {
  return (
    <Fragment>
      <MenuUsa />
      <MainBannerDedicatedTeam />
      <WhatIs />
      <TeamVsTime />
      <GetInTouch />
      <ContactReadyBuild />
      <Footer />
    </Fragment>
  );
};

export default DedicatedTeams;
