import { useEffect, useState } from "react";

export default function AnimatedText({ text, strings, typeSpeed }) {
  const [actualStringID, setActualStringID] = useState(0);

  useEffect(()=>{
    const intervalID = setInterval(()=>{
        if (actualStringID < strings.length - 1) {
            setActualStringID(actualStringID + 1);
          } else {
            setActualStringID(0);
          }
    }, typeSpeed)

    return ()=> clearInterval(intervalID)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualStringID])

  return strings.map((string, idx) => (
    <p className={idx === actualStringID ? "animatedText" : "desanimatedText"}>
      {text + ' '}
      <span style={{animationDuration: `${typeSpeed / 1000}s`}}>{string}</span>
    </p>
  ));
}
