import React, { Fragment, useContext } from "react";
import MenuUsa from "../components/commons/menuUsa";
import GetInTouch from "../components/commons/getInTouch";
import "../css/itaas.css";
import { LocationAddress } from "../components/usa/locationAddress";
import { Footer } from "../components/commons/footer";
import InfoList from "../components/microsoftEcosystem/InfoList";
import { DownloadBanner } from "../components/commons/downloadBanner";
import Header from "../components/itaas/Header";
import withCircle from "../utils/WithCirlce";
import { ContentItaasContext } from "../contexts/ContentItaasContext";
import Introduction from "../components/itaas/Introduction";
import Description from "../components/itaas/Description";
import ServicesIT from "../components/itaas/ServicesIT";
import SEOContent from "../components/commons/SEOContent";
import useScrollToSection from "../hooks/useScrollToSection";

const Itaas = () => {
  const { infoButtons, SEOContent: SEOData } = useContext(ContentItaasContext);

  const IntroductionWithCircle = withCircle(Introduction, {
    top: "-20%",
    right: "-30%",
  });

   useScrollToSection()
  return (
    <Fragment>
        <SEOContent SEOData={SEOData} />
        <MenuUsa />
        <Header />
        <InfoList data={{ infoButtons }} />
        <IntroductionWithCircle />
        <Description />
        <ServicesIT />
        <DownloadBanner />
        <GetInTouch />
        <LocationAddress />
        <Footer />
    </Fragment>
  );
};

export default Itaas;
