import { useState } from "react";
import "./Form.css";
import { Button, Col, FormControl } from "react-bootstrap";
import axios from "axios";
import WLT from "../../../../assets/logos/WLT.svg";

export default function Form({ className = "" }) {
  const [values, setValues] = useState({
    yourname: "",
    email: "",
    message: "",
  });
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [formMessage, setFormMessage] = useState({
    title: "",
    message: "",
    active: false,
  });
  const [formErrors, setFormErrors] = useState({
    yourname: "",
    email: "",
  });
  const isEmail =
    /^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/;

  function handleInputChanges({ target }) {
    const { name, value } = target;
    setButtonDisabled(false);
    setFormErrors({});
    setValues({
      ...values,
      [name]: value,
    });
  }

  async function sendData(token) {
    if (token) {
      let errors = {};
      if (!values.email || !isEmail.test(values.email)) {
        errors.email = "Please enter a valid email";
      }
      if (!values.yourname) {
        errors.yourname = "Please write your full name";
      }
      if (!errors.yourname && !errors.email) {
        await axios
          .post(
            `https://prod-20.brazilsouth.logic.azure.com:443/workflows/4a0de618d958419bb7dc99ba7a3245b7/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=QsAL47KtZeSa5He1YXBvXMI4ZsD802JoaITVlC2wXhs`,
            {
              origin: "Possumus - IT Software Solutions",
              ...values,
              response: "",
            }
          )
          .then((res) => {
            if (res.data.success) {
              setFormMessage({
                title: "Thank you, your enquiry has been sent",
                message: "We will get in touch shortly",
                active: true,
              });
            } else {
              throw new Error("Algo salió mal");
            }
          })
          .catch((error) => {
            console.log(error);
            setFormMessage({
              title: "Oops! We couldn't send the query",
              message: "Please try again later",
              active: true,
            });
          })
          .finally(() => {
            setValues({ name: "", email: "", message: "" });
            setButtonDisabled(false);
          });
      } else {
        setFormErrors(errors);
      }
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    setButtonDisabled(true);
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute("6LdKISYdAAAAAAZ_z3WlZ1LGYiaM_70zPcZqaYfa", {
          action: "submit",
        })
        .then(function (token) {
          sendData(token);
        });
    });
  }
  return (
    <Col md={5} lg={4} className={`${className} formContainer`}>
      {formMessage.active ? (
        <div className="message">
          <h3>{formMessage.title}</h3>
          <p>{formMessage.message}</p>
          <img src={WLT} alt="Possumus, We Love Tech" />
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <span>Let's cooperate!</span>
          <h3>Tailored quote</h3>
          <p>
            Accelerate your company's innovation with our tech solutions.
            Complete the form, and we will get in touch.
          </p>
          <div className="inputContainer">
            <FormControl
              onChange={handleInputChanges}
              name="yourname"
              type="text"
              placeholder="Full name*"
              className={`${formErrors.yourname ? "error" : ""} formInput`}
            />
            <p className="input-error">{formErrors.yourname}</p>
          </div>
          <div className="inputContainer">
            <FormControl
              onChange={handleInputChanges}
              name="email"
              type="text"
              placeholder="Email*"
              className={`${formErrors.email ? "error" : ""} formInput`}
            />
            <p className="input-error">{formErrors.email}</p>
          </div>
          <div className="inputContainer">
            <FormControl
              onChange={handleInputChanges}
              name="message"
              as="textarea"
              type="text"
              placeholder="Message"
              className="formInput"
            />
          </div>
          <Button
            disabled={buttonDisabled}
            type="submit"
            variant="primary"
            className="w-100"
          >
            Send
          </Button>
        </form>
      )}
    </Col>
  );
}
