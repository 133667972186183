export const organizationalPolicyEs = {
  organizationalPolicy: {
    title: "Política organizacional",
  },
  bannerOrganizationalPolicy: {
    title: "Misión, visión y política organizacional",
  },  
  misionVisionOrganizationalPolicy: {
    title1: "Nuestra misión",
    description1: "Desarrollar productos y servicios digitales de clase mundial, enfocándonos en brindar la mejor experiencia de usuario.",
    title2: "Nuestra visión",
    description2: "Crear experiencias positivas a través de la tecnología, con valor humano.",
  },
  policiesOrganizationalPolicy: {
    title: "Nuestras politicas",
    toptitle: "Política organizacional",
  }
};

export const organizationalPolicyEn = {
  organizationalPolicy: {
    title: "{{title}}",
  },
  bannerOrganizationalPolicy: {
    title: "{{title}}",
  },
  misionVisionOrganizationalPolicy: {
    title1: "{{title1}}",
    description1: "{{description1}}",
    title2: "{{title2}}",
    description2: "{{description2}}",
  },
  policiesOrganizationalPolicy: {
    title: "{{title}}",
    toptitle: "{{toptitle}}",
  }
};

export const policiesListEs = [
  {
    id: 1,
    Titulo: "Gestión del mercado comercial",
    Contenido:
      "Estamos comprometidos a buscar una expansión persistente, siendo líderes tecnológicos.",
  },
  {
    id: 2,
    Titulo: "Experiencia de usuario positiva",
    Contenido:
      "Nos comprometemos a co-crear soluciones tecnológicas de alta calidad de la mano de nuestros clientes, a través de cuidadosos procesos de digitalización para abordar la transformación digital. Para ello, nos centramos en el diseño y la usabilidad, cumpliendo con los requisitos de los clientes y promoviendo una experiencia positiva.",
  },
  {
    id: 3,
    Titulo: "Experiencia positiva del talento",
    Contenido:
      "Nos dedicamos a buscar el equilibrio adecuado entre los desafíos y el desarrollo continuo de nuestros empleados. Impactando directamente en su calidad de vida a través de la cuidadosa integración de las personas y la tecnología.",
  },
  {
    id: 4,
    Titulo: "Innovación tecnológica",
    Contenido:
      "Creemos que la digitalización conduce a un mundo mejor, integra a las personas y las empresas, facilitando su desarrollo. Por ello, en todo lo que hacemos, apostamos por la innovación y lo hacemos a través de una cuidadosa integración entre las personas y la tecnología.",
  },
  {
    id: 5,
    Titulo: "Salud financiera",
    Contenido:
      "Nos comprometemos a encontrar un equilibrio en la salud financiera que nos ayude a acompañar la evolución y el crecimiento de la organización.",
  },
  {
    id: 6,
    Titulo: "Gestión integrada y mejora continua",
    Contenido:
      "Nos comprometemos a trabajar en la mejora continua de nuestros procesos, logrando experiencias positivas.",
  },
];