import React, { Fragment, useContext } from "react";
import MenuUsa from "../components/commons/menuUsa";
import "../css/softwareServices.css";
import SoftwareServiceDesc from "../components/softwareServices/softwareServiceDesc";
import { CardsSoftwareEngineering } from "../components/commons/cardsSoftwareEngineering";
import { Footer } from "../components/commons/footer";
import MainBannerSoftwareServices from "../components/softwareServices/banner/banner";
import { DownloadBanner } from "../components/commons/downloadBanner";
import GetInTouch from "../components/commons/getInTouch";
import { LocationAddress } from "../components/usa/locationAddress";
import { ContentSEContext } from "../contexts/ContentSEContext";
import SEOContent from "../components/commons/SEOContent";
import useScrollToSection from "../hooks/useScrollToSection";

const SoftwareServices = () => {
  const {SEOContent: SEOData} = useContext(ContentSEContext)
   useScrollToSection()
  return (
    <Fragment>
        <SEOContent SEOData={SEOData} />
        <MenuUsa />
        <MainBannerSoftwareServices />
        <CardsSoftwareEngineering />
        <SoftwareServiceDesc />
        <DownloadBanner />
        <GetInTouch />
        <LocationAddress />
        <Footer />
    </Fragment>
  );
};

export default SoftwareServices;
