import React, { Fragment } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import SecondImg from "../../assets/elementos-end-to-end-solutions.svg";
import { useTranslation } from "react-i18next";
import { staffHomeResume } from "../../config/StaffAugmentation/textPage";

const Staff = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Container id="staff">
        <Row className="row-width d-flex justify-content-center" id="StaffingTeams">
          <Col md={8}>
            <h2 className="text-center">
              {t("infoHomeStaff.title", { title: `${staffHomeResume.title}` })}{" "}
            </h2>
            <h3 className="justify-content-md-center text-center">
              {t("infoHomeStaff.paragraph1", {
                paragraph1: `${staffHomeResume.paragraph1}`,
              })}
            </h3>
          </Col>
        </Row>
        <Row className="justify-content-center align-items-center d-flex whatsContent">
          <Col lg={5} xl={5} sm={12} md={11}>
            <p>
              {t("infoHomeStaff.paragraph2", {
                paragraph2: `${staffHomeResume.paragraph2}`,
              })}
              <b>
                {t("infoHomeStaff.teamStaffing", {
                  teamStaffing: `${staffHomeResume.teamStaffing}`,
                })}
              </b>
              {t("infoHomeStaff.paragraph2Part2", {
                paragraph2Part2: `${staffHomeResume.paragraph2Part2}`,
              })}
            </p>
            <p className="mt-5">
              {t("infoHomeStaff.paragraph3", {
                paragraph3: `${staffHomeResume.paragraph3}`,
              })}
              <b>
                {t("infoHomeStaff.title", {
                  title: `${staffHomeResume.title}`,
                })}
              </b>
              {t("infoHomeStaff.paragraph4", {
                paragraph4: `${staffHomeResume.paragraph4}`,
              })}
            </p>
            <p className="mt-5">
              {t("infoHomeStaff.paragraph5", {
                paragraph5: `${staffHomeResume.paragraph5}`,
              })}
            </p>
          </Col>
          <Col lg={6} xl={5} sm={12}>
            <img
              src={SecondImg}
              className="img-center"
              alt="Staff Augmentation "
            ></img>
          </Col>
        </Row>
        <Row>
          <Col className="mt-5 text-center">
            <Button variant="secondary" href="#getInTouch">
              {t("infoHomeStaff.buttonWhat", {
                buttonWhat: `${staffHomeResume.buttonWhat}`,
              })}
            </Button>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Staff;
