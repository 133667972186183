import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

const FormLanding = ({ active, titleActive, titleEvent, contactOrigin ,idButton}) => {
  const [values, setValues] = useState({
    origin: contactOrigin,
    yourname: "",
    phone: "",
    email: "",
    enterprise: "",
  });

  const { yourname, email, enterprise, phone } = values;
  const [disabled, setDisabled] = useState(false);
  let labelButton = active ? "Quiero Participar" : "Quiero que me avisen";
  const [btnLabel, setBtnLabel] = useState("");
  useEffect(() => {
    setBtnLabel(labelButton);
  }, [labelButton]);

  const [error, setError] = useState("");

  const handleInputChange = ({ target }) => {
    setValues({
      ...values,
      [target.name]: target.value,
      origin: contactOrigin,
    });
    setDisabled(false);
    setBtnLabel(labelButton);
    setError("");
  };

  const [validated, setValidated] = useState(false);
  const sendData = () => {
    setError("");
    setBtnLabel("Enviando...");
    setDisabled(true);

    axios
      .post(
        `https://prod-20.brazilsouth.logic.azure.com:443/workflows/4a0de618d958419bb7dc99ba7a3245b7/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=QsAL47KtZeSa5He1YXBvXMI4ZsD802JoaITVlC2wXhs`,
        values
      )
      .then(
        (res) => {
          setError(
            "Muchas gracias por su interés. Recibira un correo a la direccion provista con la informacion de su inscripción"
          );
          setValues({
            origin: contactOrigin,
            yourname: "",
            phone: "",
            email: "",
            enterprise: "",
          });
          setBtnLabel("Inscripción exitosa");
        },
        (error) => {
          setError(
            "Hubo un error al enviar su consulta. Por favor intentelo mas tarde"
          );
          setDisabled(false);
        }
      );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setError("Por favor, complete todos los campos indicados.");
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      window.grecaptcha.ready(function () {
        window.grecaptcha
          .execute("6LdKISYdAAAAAAZ_z3WlZ1LGYiaM_70zPcZqaYfa", {
            action: "submit",
          })
          .then(function (token) {
            // Send form value as well as token to the server
            sendData();
          });
      });
    }
  };

  return (
    <Fragment>
      <Container id="formLanding">
        <Row className="rowTitle">
          <Col>
            <h5 className="text-center mt-5">
              {active ? titleActive : titleEvent}
            </h5>
          </Col>
        </Row>
        <Row className="rowContact mt-5">
          <Col>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group as={Col} className="input-getIn">
                <Form.Control
                  id="yourname"
                  name="yourname"
                  value={yourname}
                  onChange={handleInputChange}
                  required
                  placeholder="Nombre"
                ></Form.Control>
              </Form.Group>
              <Form.Group as={Col} className="input-getIn">
                <Form.Control
                  required
                  name="email"
                  id="email"
                  type="email"
                  value={email}
                  onChange={handleInputChange}
                  placeholder="Email"
                ></Form.Control>
              </Form.Group>
              <Form.Group as={Col} className="input-getIn">
                <Form.Control
                  required
                  name="phone"
                  id="phone"
                  value={phone}
                  onChange={handleInputChange}
                  placeholder="Teléfono"
                ></Form.Control>
              </Form.Group>

              <Form.Group as={Col} className="input-getIn">
                <Form.Control
                  required
                  id="enterprise"
                  name="enterprise"
                  value={enterprise}
                  onChange={handleInputChange}
                  rows={5}
                  placeholder="Nombre de la empresa"
                ></Form.Control>
              </Form.Group>
              <div className="text-center" id={idButton? idButton:"buttonModify"}>
                <Button
                  variant="primary"
                  type="submit"
                  value="Submit"
                  className="sendLanding"
                  disabled={disabled}
                >
                  {btnLabel}
                </Button>
              </div>

              {error !==
              "Muchas gracias por su interés. Recibira un correo a la direccion provista con la informacion de su inscripción" ? (
                <div className="alert-message-danger">
                  <p>{error}</p>
                </div>
              ) : (
                <div className="alert-message">
                  <p>{error}</p>
                </div>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default FormLanding;
