export const softwareEngineeringEs = {
  softwareEngineering: {
    title: "Qué hacemos",
    subTitle: "Ingeniería de software",
    description1: `Nos enfocamos en el desarrollo de productos de software de alto valor,
     que evolucionan e impactan en las personas generando experiencias positivas.`,
    description2: `
      Convertiremos su idea, transformamos prototipo o producto,
       en activos de gran valor para su empresa, y de interés para los usuarios.`,
    description3: `
      Mantenemos una dinámica de evolución, medición y análisis, para innovar y
       mantener activo y en interés su producto, reinventándolo una y otra vez.`,
    titleBannerPart1: "Desarrollo iterativo de productos",
    titleBannerPart2: "de software para proyectos de tecnología",
  },
  menuSoftwareEngineering: {
    cardDeskOnePart1: "Research",
    cardDeskOnePart2: "engineering",
    cardDeskTwoPart1: "Ingeniería",
    cardDeskTwoPart2: "de producto",
    cardDeskThreePart1: "Desarrollo",
    cardDeskThreePart2: "iterativo",
    titleSectionOne: "Research engineering",
    titleSectionTwo: "Product engineering",
    titleSectionThree: "Desarrollo iterativo",
  },
  sectionResearch: {
    title: "Research engineering",
    paragraph: `Partimos de una idea para dar inicio a un proceso
     de descubrimiento o de refinamiento de producto.
    En esta etapa se validan tempranamente hipótesis, objetivos,
     posibles usuarios y se determina el producto correcto a construir.`,
    titleCard1: "Product Discovery",
    card1Paragraph: `Proceso de investigación para la creación de un nuevo producto,
     en el que se desarrollan diversas dinámicas de validación de visión,
      tester de públicos,
     viajes de usarios, brainstorming de funcionalidades, entre otros.`,
    titleCard2: "Refinamiento de producto",
    card2Paragraph: `Aplicamos las mejores prácticas de validación de enfoques
     y direccionamiento productivo en un producto actual, con herramientas y
      dinámicas que brindan la información correcta,
     reduciendo riesgos y optimizando los pasos de desarrollo posteriores.`,
  },
  sectionEngineering: {
    title: "Ingeniería de producto",
    paragraph: `Entregamos vida a los productos, 
      realizando un proceso de primeros desarrollos, activación de funcionalidades,
       control y análisis de experiencia, cumpliendo las siguientes dinámicas:`,
    titleCard1: "Prototipado",
    card1Paragraph: `La representación utilizable de las interfases de usuario,
     flujos de navegación y funcionalidades del producto, confirma la dirección y
     funciona como guía inicial en el desarrollo del proyecto.`,
    titleCard2: "Prueba de concepto",
    card2Paragraph: `A través de la POC confirmamos la factibilidad técnica del proyecto, 
      aportando datos desde la experiencia hasta la funcionalidad del producto.`,
    titleCard3: "Producto Mínimo Valioso",
    card3Paragraph: `Producto con las suficientes funcionalidades como para ser lanzado,
     para que clarifique el potencial de las funcionalidades,
     a partir de la experiencia de los primeros usuarios.`,
  },
  sectionIterative: {
    title: "Desarrollo iterativo",
    paragraph: `Nuestros equipos de ingeniería trabajan con procesos ágiles,
       que permiten las mejores y más eficientes entregas continuas.`,
    titleCard1: "Equipos dedicados",
    card1Paragraph: `Conformamos un equipo de profesionales especializados,
     acorde a los requerimientos del proyecto,
      que brinda respuestas exclusivamente en cada fase de desarrollo del producto,
     basado en el proceso iterativo.`,
    titleCard2: "Mantenimiento evolutivo",
    card2Paragraph: `Con el enfoque puesto en la evaluación,
     mantenimiento, actualización y evolución de cada producto,
      realizamos procesos de mejoras basados en la innovación constante
       y el pragmatismo de la metodología de trabajo ágil.`,
  },
};

export const softwareEngineeringEn = {
  softwareEngineering: {
    title: "{{title}}",
    subTitle: "{{subTitle}}",
    description1: "{{description1}}",
    description2: "{{description2}}",
    description3: "{{description3}}",
    titleBannerPart1: "{{titleBannerPart1}}",
    titleBannerPart2: "{{titleBannerPart2}}",
  },
  menuSoftwareEngineering: {
    cardDeskOnePart1: "{{cardDeskOnePart1}}",
    cardDeskOnePart2: "{{cardDeskOnePart2}}",
    cardDeskTwoPart1: "{{cardDeskTwoPart1}}",
    cardDeskTwoPart2: "{{cardDeskTwoPart2}}",
    cardDeskThreePart1: "{{cardDeskThreePart1}}",
    cardDeskThreePart2: "{{cardDeskThreePart2}}",
    titleSectionOne: "{{titleSectionOne}}",
    titleSectionTwo: "{{titleSectionTwo}}",
    titleSectionThree: "{{titleSectionThree}}",
  },
  sectionResearch: {
    title: "{{title}}",
    paragraph: "{{paragraph}}",
    titleCard1: "{{titleCard1}}",
    card1Paragraph: "{{card1Paragraph}}",
    titleCard2: "{{titleCard2}}",
    card2Paragraph: "{{card2Paragraph}}",
  },
  sectionEngineering: {
    title: "{{title}}",
    paragraph: "{{paragraph}}",
    titleCard1: "{{titleCard1}}",
    card1Paragraph: "{{card1Paragraph}}",
    titleCard2: "{{titleCard2}}",
    card2Paragraph: "{{card2Paragraph}}",
    titleCard3: "{{titleCard3}}",
    card3Paragraph: "{{card3Paragraph}}",
  },
  sectionIterative: {
    title: "{{title}}",
    paragraph: "{{paragraph}}",
    titleCard1: "{{titleCard1}}",
    card1Paragraph: "{{card1Paragraph}}",
    titleCard2: "{{titleCard2}}",
    card2Paragraph: "{{card2Paragraph}}",
  },
};
