import React from "react";
import "./MSSeparator.css";
import { Container, Image as BoostrapImage } from "react-bootstrap";
import PildoraMS from "./Components/PildoraMS/PildoraMS";

export const MSSeparator = ({ children, className }) => {
  return (
    <Container className={`MSSeparatorContainer ${className}`}>
      {children}
    </Container>
  );
};

const Image = ({ src, alt, width = 302, height = 65 }) => (
  <BoostrapImage src={src} alt={alt} width={width} height={height} />
);
const Title = ({ children }) => (
  <h2 className="MSSeparatorTitle">{children}</h2>
);
const ItemsList = ({ items }) => (
  <div className="MSSeparatorList">
    {items.map((item, idx) => (
      <PildoraMS key={`MSSeparatorItem-${idx}-${item}`}>{item}</PildoraMS>
    ))}
  </div>
);

const MemoizedItemsList = React.memo(ItemsList);
MSSeparator.Image = Image;
MSSeparator.Title = Title;
MSSeparator.ItemsList = MemoizedItemsList;
