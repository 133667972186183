import React from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import Instagram from "../../assets/instagram.svg";
import Facebook from "../../assets/facebook.svg";
import Linkedin from "../../assets/linkedin.svg";
import Twitter from "../../assets/twitter.svg";
import WLTBy from "../../assets/landings/houston/WLTBy.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";

const Footer = ({ text }) => {
  let newDate = new Date();
  let year = newDate.getFullYear();

  const tel = `tel: ${text.contact.tel}`;
  return (
    <Container fluid id="houstonFooter">
      <Row className="d-flex align-items-center justify-content-center flex-column flex-md-row justify-content-lg-around">
        <Col lg="2">
          <Image src={WLTBy} alt="We love Tech" className="houstonWeLoveTech" />
        </Col>
        <Col lg="4" md="12">
          <h3>{text.innovate}</h3>
        </Col>
        <Col
          lg="2"
          className="d-flex flex-column justify-content-center d-lg-grid"
        >
          <h4>{text.contact.title}</h4>
          <Button variant="secondary" href="/en-en/contact">
            {text.contact.button}
          </Button>
          <Button variant="tertiary" href={tel}>
            <FontAwesomeIcon icon={faPhoneAlt} />
            {tel}
          </Button>
        </Col>
      </Row>
      <Row className="disclaimer flex-column-reverse flex-md-row justify-content-center justify-content-md-between gap-0">
        <Col className="text-center text-md-start">
          <p>Copyright © {year} Possumus. All Rigths Reserved.</p>
        </Col>
        <Col className="redes">
          <ul className="d-flex flex-column flex-md-row justify-content-center gap-1">
            <li>
              <p>{text.redes.title}</p>
            </li>
            <div className="d-flex justify-content-center gap-3 gap-md-1">
              <li>
                <a
                  href="https://www.instagram.com/possumus.tech/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={Instagram}
                    alt="Instagram"
                    className="img-fluid"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/possumus.tech/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={Facebook}
                    alt="Facebook"
                    className="img-fluid"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/possumustech"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={Linkedin}
                    alt="Linkedin"
                    className="img-fluid"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/PossumusTech"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Twitter} alt="Twitter" className="img-fluid"></img>
                </a>
              </li>
            </div>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
