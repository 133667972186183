import { useContext } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { ContentItaasContext } from "../../contexts/ContentItaasContext";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

const Description = () => {
  const { description } = useContext(ContentItaasContext);
  return (
    <Container fluid id="description">
      <Row className="d-flex align-items-center justify-content-center">
        <Col lg={7}>
          <ReactMarkdown>{description.text1}</ReactMarkdown>
        </Col>
        <h5>{description.title}</h5>
        <Col lg={7} className="d-grid gap-4">
          <ReactMarkdown>{description.text2}</ReactMarkdown>
        </Col>
      </Row>
        <Col className="justify-content-center align-items-center d-flex  mt-4">
          <Button
            variant="secondary"
            href="#getInTouch"
            className="paddingButtonMainBanner"
          >
            {description.buttonText}
          </Button>
        </Col>
    </Container>
  );
};

export default Description;
