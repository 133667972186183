import React, { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { onshoreStaffSection } from "../../config/StaffAugmentation/textPage";
import { useTranslation } from "react-i18next";

const Onshore = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Container fluid id="onshore">
        <Container>
          <Row className="d-flex justify-content-center">
            <Col md={9}>
              <h2 className="text-center">
                {t("onshoreStaff.title", {
                  title: `${onshoreStaffSection.title}`,
                })}{" "}
              </h2>
              <p className="text-center">
                {t("onshoreStaff.paragraph1", {
                  paragraph1: `${onshoreStaffSection.paragraph1}`,
                })}
              </p>
              <p className="text-center">
                {t("onshoreStaff.paragraph2", {
                  paragraph2: `${onshoreStaffSection.paragraph2}`,
                })}{" "}
              </p>
              <div className="list-onshore">
                <ul>
                  <li>
                    {t("onshoreStaff.listitem1", {
                      listitem1: `${onshoreStaffSection.listitem1}`,
                    })}
                  </li>

                  <li>
                    {t("onshoreStaff.listitem2", {
                      listitem2: `${onshoreStaffSection.listitem2}`,
                    })}
                  </li>

                  <li>
                    {t("onshoreStaff.listitem3", {
                      listitem3: `${onshoreStaffSection.listitem3}`,
                    })}
                  </li>
                </ul>
              </div>
              <p className="text-center row-width text-onshore">
                {t("onshoreStaff.paragraph3", {
                  paragraph3: `${onshoreStaffSection.paragraph3}`,
                })}
              </p>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col lg={3} sm={12} md={9}>
              <div className="card">
                <h4 className="text-center">
                  {t("onshoreStaff.titlecol1", {
                    titlecol1: `${onshoreStaffSection.titlecol1}`,
                  })}
                </h4>
                <h5 className="mb-4">
                  {t("onshoreStaff.subtitlecol1", {
                    subtitlecol1: `${onshoreStaffSection.subtitlecol1}`,
                  })}
                </h5>
                <p className="text-center">
                  {t("onshoreStaff.paragraphcol1", {
                    paragraphcol1: `${onshoreStaffSection.paragraphcol1}`,
                  })}
                </p>
              </div>
            </Col>
            <Col lg={3} sm={12}md={9}>
              <div className="card">
                <h4 className="text-center">
                  {t("onshoreStaff.titlecol2", {
                    titlecol2: `${onshoreStaffSection.titlecol2}`,
                  })}
                </h4>
                <h5 className="mb-4">
                  {t("onshoreStaff.subtitlecol2", {
                    subtitlecol2: `${onshoreStaffSection.subtitlecol2}`,
                  })}
                </h5>
                <p className="text-center">
                  {t("onshoreStaff.paragraphcol2", {
                    paragraphcol2: `${onshoreStaffSection.paragraphcol2}`,
                  })}
                </p>
              </div>
            </Col>
            <Col lg={3} sm={12}md={9}>
              <div className="card">
                <h4 className="text-center">
                  {t("onshoreStaff.titlecol3", {
                    titlecol3: `${onshoreStaffSection.titlecol3}`,
                  })}
                </h4>
                <h5 className="mb-4">
                  {t("onshoreStaff.subtitlecol3", {
                    subtitlecol3: `${onshoreStaffSection.subtitlecol3}`,
                  })}
                </h5>
                <p className="text-center">
                  {t("onshoreStaff.paragraphcol3", {
                    paragraphcol3: `${onshoreStaffSection.paragraphcol3}`,
                  })}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </Fragment>
  );
};

export default Onshore;
