import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import axios from "axios";

const Contact = () => {
  /*Toast*/
  const [showA, setShowA] = useState(false);
  const toggleShowA = () => setShowA(!showA);

  const [positionToast] = useState("middle-end");
  /*ToastEnd*/
  useEffect(() => {
    axios
      .get(`https://admin.possumus.tech/posiciones-abiertas/`)
      .then((res) => {
        setValues({
          origin: "Possumus",
          position: res.data.Titulo,
          yourname: "",
          lastname: "",
          email: "",
          phone: "",
          url: "",
          cv: "",
        });
      });
  }, []);

  const [values, setValues] = useState({
    origin: "Possumus",

    yourname: "",
    lastname: "",
    email: "",
    phone: "",
    url: "",
    cv: "",
    position: "Presentacion espontanea",
  });

  const { yourname, lastname, email, phone, url} =
    values;
  const [error, setError] = useState("");

  const handleInputChange = ({ target }) => {
    setValues({
      ...values,
      [target.name]: target.value,
    });
  };
  const sendData = () => {
    setError("");
    axios
      .post(
        `https://prod-23.brazilsouth.logic.azure.com:443/workflows/b611515ae5a34062abffc60d2f8a3287/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=TdHWkTPbWzvqDBXPKoJwlbz9UFJItyiGxa1eJ_SVtiY`,
        values
      )
      .then(
        (res) => {
          setError(
            "Thank you for your interest in being part of us! We will contact you shortly."
          );
          setValues({
            origin: "Possumus",

            yourname: "",
            lastname: "",
            email: "",
            phone: "",
            url: "",
            cv: "",
            position: "Presentacion espontanea",
          });
        },
        (error) => {
          setError("There was an error sending. Please try again later.");
        }
      );
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute("6LdKISYdAAAAAAZ_z3WlZ1LGYiaM_70zPcZqaYfa", {
          action: "submit",
        })
        .then(function (token) {
          // Send form value as well as token to the server
          sendData();
        });
    });
  };

  const getUploadParams = () => {
    return { url: "https://httpbin.org/post" };
  };

  const changeClass = (id) => {
    const ceve = document.getElementById(id);
    ceve.className = " contentDropzoneOk";
  };

  const removeClass = (id) => {
    const ceve = document.getElementById(id);
    ceve.classList.remove("icontentDropzoneOk");
    ceve.classList.add("contentDropzone");
  };

  const handleChangeStatus = async ({ meta, file }, status) => {
    if (status === "done") {
      const base64 = await convertBase64(file);
      values["cv"] = base64;
      setShowA(!showA);
    }

    if (status === "headers_received") {
      changeClass("curr");
    } else if (status === "removed") {
      removeClass("curr");
    }
  };

  return (
    <Fragment>
      <Container id="getInTouch">
        <Row>
          <Col>
            <h2 className="text-center">Contact us</h2>
            <h3 className="text-center">and let’s start the conversation </h3>
          </Col>
        </Row>
        <Row className="row-width mt-5">
          <Col md={12}>
            <h4 className="text-center">Apply Now</h4>
            <p className="text-center">
              Leave us your information here so that we can get in touch with
              you:
            </p>
            <Form onSubmit={handleSubmit} id="formJobs">
              <ToastContainer className="p-3" position={positionToast}>
                <Toast show={showA} onClose={toggleShowA} delay={3000} autohide>
                  <Toast.Header closeButton={false}>
                    <img
                      src="holder.js/20x20?text=%20"
                      className="rounded me-2"
                      alt=""
                    />
                    <strong className="me-auto">
                      Your resume was uploaded successfully
                    </strong>
                  </Toast.Header>
                </Toast>
              </ToastContainer>
              <Form.Group as={Col} className="input-getIn" controlId="yourname">
                <Form.Label>First Name *</Form.Label>
                <Form.Control
                  required
                  id="yourname"
                  name="yourname"
                  value={yourname}
                  onChange={handleInputChange}
                  placeholder="First Name"
                ></Form.Control>
              </Form.Group>
              <Form.Group as={Col} className="input-getIn" controlId="lastName">
                <Form.Label>Last name *</Form.Label>
                <Form.Control
                  required
                  id="lastname"
                  name="lastname"
                  value={lastname}
                  onChange={handleInputChange}
                  placeholder="Last name"
                ></Form.Control>
              </Form.Group>

              <Form.Group as={Col} className="input-getIn" controlId="email">
                <Form.Label> email *</Form.Label>
                <Form.Control
                  required
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={handleInputChange}
                  placeholder="Email"
                ></Form.Control>
              </Form.Group>
              <Form.Group
                as={Col}
                className="contentDropzone"
                controlId="files"
                id="curr"
              >
                <Form.Label>Add resume *</Form.Label>
                <Dropzone
                  getUploadParams={getUploadParams}
                  onChangeStatus={handleChangeStatus}
                  inputContent="Drag and drop files here"
                  maxFiles={1}
                  id="cv"
                  name="cv"
                  required
                />
                <div id="toast"></div>
              </Form.Group>
              <Form.Group as={Col} className="input-getIn" controlId="phone">
                <Form.Label> Phone *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  id="phone"
                  name="phone"
                  value={phone}
                  onChange={handleInputChange}
                  placeholder="Phone"
                ></Form.Control>
              </Form.Group>
              <Form.Group as={Col} className="input-getIn" controlId="url">
                <Form.Label>Linkedin Profile</Form.Label>
                <Form.Control
                  type="text"
                  id="url"
                  name="url"
                  value={url}
                  onChange={handleInputChange}
                  placeholder="https://"
                ></Form.Control>
              </Form.Group>

              <Button variant="primary" type="submit" value="submit">
                Send
              </Button>
              <div className="alert-message">
                <p>{error}</p>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Contact;
