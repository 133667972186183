import React, { useContext } from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import elipse from "../../../assets/Ellipse.svg";
import { ContentMicrosoftEcosystemContext } from "../../../contexts/ContentMicrosoftEcosystemContext";

export const AppoachesDesk = () => {
  const { approachesAndMethodologiesContent } = useContext(
    ContentMicrosoftEcosystemContext
  );
  return (
    <div class="d-none d-sm-none d-lg-block">
      <Container fluid className="oval no-padding">
        {approachesAndMethodologiesContent?.Card?.map((item, idx) => (
          <Row className="justify-content-md-center text-left">
            {idx % 2 !== 0 && (
              <>
                <Col lg={4}></Col>
                <Col lg={1} className="m-0 p-0">
                  <div style={{ position: "relative", height: "100%" }}>
                    <hr className="lineSeparatorBodyRight" />
                    <div
                      className={
                        idx === 1
                          ? "d-flex lineVerticalBodyRightConcept"
                          : "d-flex lineVerticalBodyRightConcept"
                      }
                    >
                      <div className="vr"></div>
                    </div>
                  </div>
                </Col>
              </>
            )}
            <Col lg={4}>
              <Card
                bg="light"
                id="myCard"
                className={
                  Math.ceil(
                    approachesAndMethodologiesContent?.Card?.length / 2
                  ) === idx && "containerStyleApproachCard"
                }
              >
                <Card.Header className="titleCard">
                  <Image
                    src={elipse}
                    alt=""
                    className={
                      idx % 2 === 0
                        ? "marginElipseColumn1"
                        : "marginElipseColumn2"
                    }
                  />
                  {item.Title}
                </Card.Header>
                <div className="lineCard">
                  <hr className="m-0 p-0" />
                </div>
                <Card.Body>
                  <Card.Text>{item.Description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            {idx % 2 === 0 && (
              <>
                <Col lg={1} className="m-0 p-0">
                  <div style={{ position: "relative", height: "100%" }}>
                    <hr className="lineSeparatorBodyLeft" />
                    <div className="d-flex lineVerticalBodyLeft">
                      <div className="vr"></div>
                    </div>
                  </div>
                </Col>
                <Col lg={4}></Col>
              </>
            )}
          </Row>
        ))}
      </Container>
    </div>
  );
};
