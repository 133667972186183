import { Container, Row, Col } from "react-bootstrap";
import Loader from "../commons/loader";

export const DetailsTwoParticipantsOpc = ({
  dataParticipants,
  dataCta,
  backgroundImage,
  active,
}) => {
  return (
    <Container fluid id="detailsLandingSolid">
      <Container
        fluid
        style={{
          backgroundImage: active ? "none" : `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top",
        }}
        id={"titleParti"}
      >
        <h2 className="titleParticipants">{dataParticipants.Titulo}</h2>
        <Container className="containerParticipants" fluid id="titleParti">
          <Row id="participants">
            <Col md={2} lg={2}>
              {dataParticipants.Image_1 ? (
                <img
                  src={dataParticipants.Image_1.url}
                  className="img-fluid"
                  alt=""
                />
              ) : (
                <Loader />
              )}
            </Col>
            <Col md={4} lg={4}>
              <h2>{dataParticipants.Speaker_1}</h2>
              <p>{dataParticipants.Puesto_Speaker_1}</p>
            </Col>

            <Col md={2} lg={2}>
              {dataParticipants.Imagen_Speaker_2 ? (
                <img
                  src={dataParticipants.Imagen_Speaker_2.url}
                  className="img-fluid"
                  alt=""
                />
              ) : (
                <Loader />
              )}
            </Col>
            <Col md={4} lg={4}>
              <h2>{dataParticipants.Speaker_2}</h2>
              <p>{dataParticipants.Puesto_Speaker_2}</p>
            </Col>
          </Row>
        </Container>
      </Container>

      {dataCta.Imagen ? (
        <Container
          id="ctaLanding"
          style={{
            backgroundColor: dataCta.Color_de_fondo,
            backgroundImage: `url(${dataCta.Imagen.url})`,
          }}
        >
          <Row>
            <Col md={12}>
              <h2 style={{ textAlign: dataCta.Alineacion }}>{dataCta.Texto}</h2>
            </Col>
          </Row>
        </Container>
      ) : (
        <Loader />
      )}
    </Container>
  );
};

export default DetailsTwoParticipantsOpc;
