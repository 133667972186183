import { useState } from "react";
import { createContext } from "react";

export const SideNavbarContext = createContext({});

export const SideNavbarProvider = ({ children }) => {
  const [itemSelected, setItemSelected] = useState(null);
  const [showMobileOptions, setShowMobileOptions] = useState(false);
  const toggleShowMobileOptions = () =>
    setShowMobileOptions(!showMobileOptions);

  const handleItemSelected = (item) =>{
    setItemSelected(item);
  }
  return (
    <SideNavbarContext.Provider
      value={{
        itemSelected,
        showMobileOptions,
        toggleShowMobileOptions,
        handleItemSelected,
      }}
    >
      {children}
    </SideNavbarContext.Provider>
  );
};
