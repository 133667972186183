import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/layout.css";
import "./css/responsive.css";
import "./config/i18next-config";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ActualLangProvider } from "./contexts/ActualLangContext";
import { ContentLayoutProvider } from "./contexts/ContentLayoutContext";
import { AuthStrapiProvider } from "./contexts/AuthStrapiV3Context";

ReactDOM.hydrate(
  <React.StrictMode>
    <AuthStrapiProvider>
      <ActualLangProvider>
        <ContentLayoutProvider>
          <App />
        </ContentLayoutProvider>
      </ActualLangProvider>
    </AuthStrapiProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
