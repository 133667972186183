import { useContext } from "react";
import { Col, Container } from "react-bootstrap";
import { ContentSimplePayLandingContext } from "../../contexts/ContentSimplePayLandingContext";
import ListOnShore from "../commons/listOnShore";
import DescriptionWithVerticalLine from "../commons/descriptionWithVerticalLine";

const Features = () => {
  const { features } = useContext(ContentSimplePayLandingContext);

  return (
    <Container fluid id="simplePayFeatures">
      <Col md={{ offset: 4 }}>
        <ListOnShore items={features.Items} title={features.Titulo} />
        <DescriptionWithVerticalLine description={features.Descripcion} />
      </Col>
    </Container>
  );
};

export default Features;
