import React, { useContext, useEffect, useState } from "react";
import { Button, Image, Navbar } from "react-bootstrap";
import logoPossumusBlack from "../../assets/landings/houston/logoPossumusBlack.svg";
import logoPossumusWhite from "../../assets/landings/houston/logoPossumusWhite.svg";
import { ActualLangContext } from "../../contexts/ActualLangContext";

const defaultButtons = [
  {
    label: "",
    variant: "highlight",
    href: "#getInTouch",
  },
];

const HoustonNavbar = ({
  buttons = defaultButtons,
  logoBlack = true,
  transparent = false,
  fixed = true,
}) => {
  const [isNavbarInTop, setIsNavbarInTop] = useState(true);

  const { actualLang } = useContext(ActualLangContext);

  useEffect(() => {
    window.onscroll = function () {
      const y = window.scrollY;
      if (y === 0 || !fixed) {
        setIsNavbarInTop(true);
      } else {
        if (!transparent && fixed) {
          setIsNavbarInTop(false);
        }
      }
    };
  }, []);
  return (
    <>
      <Navbar
        expand="xl"
        id="houstonNavbar"
        style={{ position: fixed ? "fixed" : "absolute" }}
        className={`${transparent ? "transparent" : "no-transparent"} ${
          isNavbarInTop ? "" : "withBackground"
        }`}
      >
        <Navbar.Brand href="/">
          <Image
            src={logoBlack ? logoPossumusBlack : logoPossumusWhite}
            alt="Enterprise logo"
            fluid
          />
        </Navbar.Brand>
        {buttons &&
          buttons.map((btn, idx) => (
            <Button
              key={`${btn.label}-${btn.variant}-${idx}`}
              variant={isNavbarInTop ? btn.variant : "highlight"}
              href={btn.href}
            >
              {btn.label
                ? btn.label
                : actualLang === "en"
                ? "Contact us"
                : "Contactános"}
            </Button>
          ))}
      </Navbar>
    </>
  );
};

export default HoustonNavbar;
