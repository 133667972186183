import React from "react";
import { Card } from "react-bootstrap";

const SolutionsMSECard = ({ imageUrl, title, description }) => {
  return (
    <Card>
      <Card.Header className="d-flex align-items-center">
        <Card.Img src={imageUrl} style={{ width: "80px" }} />
        <h4>{title}</h4>
      </Card.Header>
      <Card.Body>{description}</Card.Body>
    </Card>
  );
};

export default SolutionsMSECard;
