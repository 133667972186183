import { Fragment, useContext } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loader from "../commons/loader";
import IsoCertificateAndWeLoveDev from "../commons/IsoCertificateAndWeLoveDev";
import { bannerDownload } from "../../config/Commons/textPage";
import { ContentItaasContext } from "../../contexts/ContentItaasContext";

const Header = ({ titulo, bajada }) => {
  const { t } = useTranslation();

  const { headerContent } = useContext(ContentItaasContext);

  return (
    <Fragment>
      <Container fluid id="mainbanner-it" className="background-banner-header">
        <Container>
          <Row className="d-flex">
            <Col lg={7} xs={12} md={10}>
              <h1>{titulo ? titulo : headerContent?.Titulo}</h1>
              <p className="subtitle">
                {bajada ? bajada : headerContent?.Descripcion}
              </p>
              <div className="cta">
                <Button variant="primary" href="#getInTouch">
                  {t("buttonsAndAccesories.buttonGetFree", {
                    buttonGetFree: `${bannerDownload.buttonGetFree}`,
                  })}
                </Button>
              </div>
            </Col>
            <Col lg={5} sm={12}>
              {headerContent.Imagen?.url ? (
                <img
                  src={headerContent.Imagen?.url}
                  className="img-center"
                  alt="Accelerate your digital transformation"
                />
              ) : (
                <Loader />
              )}
            </Col>
          </Row>
          <IsoCertificateAndWeLoveDev />
        </Container>
      </Container>
    </Fragment>
  );
};

export default Header;
