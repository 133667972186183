import Awards from "../../components/commons/Awards";
import WhatMoveUs from "../../components/commons/WhatMoveUs";
import { Footer } from "../../components/commons/footer";
import Partners from "../../components/home/ourPartners";
import HoustonNavbar from "../../components/houston/Navbar";
import joaquina from "../../assets/landings/houston/team/JoaquinaScattone.svg";
import sebastian from "../../assets/landings/houston/team/SebastianDelfino.svg";
import fernando from "../../assets/landings/houston/team/FernandoBulczynski.png";
import marisa from "../../assets/landings/houston/team/MarisaVaira.svg";
import { useContext, useEffect } from "react";
import { ActualLangContext } from "../../contexts/ActualLangContext";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GetInTouchMultiple from "../../components/commons/getInTouchMultiple";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Layout = ({ children }) => {
  const { actualLang, setLang } = useContext(ActualLangContext);

  const { i18n } = useTranslation();

  const { pathname } = useLocation();

  const personalES = [
    {
      nombre_apellido: "Fernando Bulczynski",
      puesto: "Chief Growth Officer",
      Image_personal: { url: fernando },
    },
    {
      nombre_apellido: "Marisa Vaira",
      puesto: "Sales Consultant",
      Image_personal: { url: marisa },
    },
  ];
  const personalEN = [
    {
      nombre_apellido: "Joaquina Scattone",
      puesto: "Growth Marketer",
      Image_personal: {
        url: joaquina,
      },
    },
    {
      nombre_apellido: "Sebastián Delfino",
      puesto: "International Business Developer",
      Image_personal: { url: sebastian },
    },
  ];

  useEffect(() => {
    if (pathname.includes("services")) {
      i18n.changeLanguage("en");
      setLang("en");
    }
    if (pathname.includes("servicios")) {
      i18n.changeLanguage("es");
      setLang("es");
    }
  }, [i18n, pathname, setLang]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{actualLang === 'es' ? 'Possumus | Servicios' : 'Possumus | Services'}</title>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=UA-90867938-1"
          ></script>
          <script>{`window.dataLayer = window.dataLayer || [];   function gtag(){dataLayer.push(arguments);}   gtag('js', new Date());   gtag('config', 'UA-90867938-1'); gtag('event', 'page_view')`}</script>
        </Helmet>
        <HoustonNavbar />
        {children}
        <Partners />
        <Awards />
        <WhatMoveUs />
        <GetInTouchMultiple
          title={actualLang === "es" ? "Estemos en contacto" : "Get in touch"}
          personal={actualLang === "es" ? personalES : personalEN}
        />
        <Footer />
      </HelmetProvider>
    </>
  );
};

export default Layout;
