import React from "react";
import { Col, Row, Container } from "react-bootstrap";

export const IframeYouTubeCulture = ({ id }) => {
  return (
    <Container className="marginYoutubeCulture">
      <Row className="row-width mt-5">
        <Col>
          <iframe
            className="iframeRow styleIframeYouTube"
            src={"https://www.youtube.com/embed/" + id}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Col>
      </Row>
    </Container>
  );
};
