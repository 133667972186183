import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import argItHover from "../../assets/landings/houston/argItHover.svg";
import cessiHover from "../../assets/landings/houston/cessiHover.svg";
import xchangeHover from "../../assets/landings/houston/xchangeHover.svg";
import argItInactive from '../../assets/landings/houston/argIt.svg'
import cessiInactive from '../../assets/landings/houston/cessi.svg'
import xchangeInactive from '../../assets/landings/houston/xchange.svg'
import { useState } from "react";

const InstitutionalPartners = () => {
  const [xchange, setXchangeImage] = useState(xchangeInactive)
  const [cessi, setCessiImage] = useState(cessiInactive)
  const [argIt, setArgItImage] = useState(argItInactive)
  return (
    <Container id="houstonInstitutionalPartners" className="d-grid">
      <h2 className="sup-title">Institutional Partners</h2>
      <Col lg={{span: 10, offset: 1}} xl={{span: 10, offset: 2}}>
        <Row>
          <Col lg="4" md="12">
            <Image src={xchange} alt="xchange enterprise" onMouseEnter={()=> setXchangeImage(xchangeHover)} onMouseLeave={()=> setXchangeImage(xchangeInactive)} />
          </Col>
          <Col lg="4" md="12">
            <Image src={cessi} alt="Cessi enterprise" onMouseEnter={()=> setCessiImage(cessiHover)} onMouseLeave={()=> setCessiImage(cessiInactive)}/>
          </Col>
          <Col lg="4" md="12">
            <Image src={argIt} alt="ArgIt enterprise" onMouseEnter={()=> setArgItImage(argItHover)} onMouseLeave={()=> setArgItImage(argItInactive)}/>
          </Col>
        </Row>
      </Col>
    </Container>
  );
};

export default InstitutionalPartners;
