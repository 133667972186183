import React, { useContext, useEffect, useState } from "react";
import FooterUsaDesk from "./footerDesk";
import FooterUsaMobile from "./footerMobile";
import axios from "axios";
import { ContentLayoutContext } from "../../contexts/ContentLayoutContext";
export const Footer = () => {
  const [logoPossumus, setLogoPossumus] = useState("");
  const [logoIso, setLogoIso] = useState("");

  const {footerContent} = useContext(ContentLayoutContext)

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL_API}/page-usa`).then((res) => {
      setLogoPossumus(res.data.footerUsa.logoPossumus.url);
      setLogoIso(res.data.footerUsa.logoIso.url);
    });
  }, []);
  return (
      <>
        <div className="d-none d-sm-none d-md-block">
          <FooterUsaDesk logoPossumus={logoPossumus} logoIso={logoIso} options={footerContent?.options} />
        </div>
        <div className="d-block d-sm-block d-md-none">
          <FooterUsaMobile logoPossumus={logoPossumus} logoIso={logoIso} options={footerContent?.options} />
        </div>
      </>
  );
};
