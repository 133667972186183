import React, { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../../components/commons/loader";
import { Container, Row, Col } from "react-bootstrap";
import icoFacebook from "../../assets/landings/productividad365/Facebook.svg";
import icolinkedin from "../../assets/landings/productividad365/LinkedIn.svg";
import icoInstagram from "../../assets/landings/productividad365/Instagram.svg";
import icoTwitter from "../../assets/landings/productividad365/Twitter.svg";

export const FooterLanding = ({ title }) => {
    const [footerData, setFooterData] = useState({});
    const [footerLogo, setFooterLogo] = useState({});

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_URL_API}/landing-productividad-365`)
            .then((res) => {
                setFooterData(res.data.Footer);
                setFooterLogo(res.data.Footer.Logo_Footer);
            });
    }, []);

    return (
        <Container fluid id="footerLanding">
            <Container>
                <Row>
                    <Col xs={12} md={3} className="borderRigth">
                        {footerLogo.url ? (
                            <img
                                src={footerLogo.url}
                                alt={footerLogo.alternativeText}
                                className="img-fluid"
                            ></img>
                        ) : (
                            <Loader />
                        )}
                    </Col>
                    <Col xs={12} md={3} className="logoPartner">
                        <h1>Webinar</h1>
                    </Col>
                    <Col xs={12} md={6}>
                        <ul className="socials">
                            <li>
                                <a
                                    href="https://www.facebook.com/possumus.tech/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src={icoFacebook} alt="Facebook"></img>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.linkedin.com/company/possumustech/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src={icolinkedin} alt="Linkedin"></img>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.instagram.com/possumus.tech/"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src={icoInstagram} alt="Youtube"></img>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://twitter.com/PossumusTech"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img src={icoTwitter} alt="Twitter"></img>
                                </a>
                            </li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <p>{footerData.Copyrigth}</p>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default FooterLanding;
