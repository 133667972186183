import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNearScreen } from "../../hooks/useNearScreen";
import { useEffect } from "react";
import { useContext } from "react";
import { SideNavbarContext } from "../../contexts/SideNavbarContext";

const StaffAugmentation = ({id}) => {

  const {handleItemSelected} = useContext(SideNavbarContext)

  const TECHNOLOGIES = [
    { name: "Frontend", tech: ["React.js", "Angular.js", "Next.js"] },
    { name: "Backend", tech: [".NET Core", "Java", "Node.js"] },
    { name: "Mobile", tech: ["React Native", "Android", "IOS"] },
    { name: "Fullstack", tech: ["Web", "Mobile"] },
  ];

  const [isNear, ref] = useNearScreen({rootMargin:'300px'})
  
  useEffect(()=>{
    if(isNear){
      handleItemSelected(id)
    }
  },[isNear, handleItemSelected, id])
  return (
    <Container id="houstonStaff" ref={ref}>
      <h3>Staff Augmentation</h3>
      <Col className="d-flex justify-content-center justify-content-xl-start">
        <Row>
          {TECHNOLOGIES.map((technologie, idx) => (
            <Col xl={3} className="" key={idx}>
              <h4>{technologie.name}</h4>
              {technologie.tech.map((tech) => (
                <p>{tech}</p>
              ))}
            </Col>
          ))}
        </Row>
      </Col>
    </Container>
  );
};

export default StaffAugmentation;
