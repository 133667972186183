import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { JumboButton } from "../commons/JumboButton";

const OurServices = ({data}) => {
  return (
    <Container fluid id="ourServices" className="houstonOurServices">
      <h3 className="sup-title">{data.title}</h3>
      <Col xl={{ span: 6, offset:3 }} lg={{span: 8, offset: 2}} className="d-flex justify-content-center">
        <Row className="align-items-center houstonServicesContainer">
          {data.services.map((service, idx) => (
            <JumboButton
              key={idx}
              title={service.text}
              hoverEffect={service.hover}
              learnMore={data.jumboButtonText}
              href={service.href}
            />
          ))}
        </Row>
      </Col>
    </Container>
  );
};

export default OurServices;
