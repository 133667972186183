export const staffEs = {
  mainbannerStaff: {
    title: "Bajo costo, máxima calidad",
    subtitle:
      "Elimina las barreras al crecimiento escalando rápidamente tu equipo con talento TI latinoamericano de primera categoría.",
  },
  infoHomeStaff: {
    title: "Staff Augmentation",
    paragraph1: `La capacidad de un equipo es fundamental para el crecimiento de cada proyecto, y a medida que alcanza nuevos niveles de madurez, la adquisición de talentos nuevos, especializados y preparados para asumir roles específicos, es clave para lograr tracción en los mercados.`,
    paragraph2: `Nuestros`,
    teamStaffing: " equipos de staffing de TI ",
    paragraph2Part2: `cuentan con el profesionalismo para consolidarse en cada etapa de su proyecto, transformándose con capacidad, flexibilidad y aceleración en cada proceso de escala, atendiendo las necesidades y requerimientos en cada momento`,
    paragraph3: "Con ",
    paragraph4:
      " usted contrata una ventaja diferencial, que le aporta un equipo que responde exclusivamente a su organización, asiste a sus reuniones regulares, sigue sus lineamientos y reporta sin escalas a sus gerentes.",
    paragraph5:
      "Independientemente de la complejidad y del tipo de proyecto de desarrollo que requiera llevar adelante, nuestros equipos están a su alcance para la creación de prototipos, desarrollos de ciclo completo de gran escala, aplicaciones móviles, de escritorio, web, y de gestión de sistemas.",
    buttonWhat: "¿Qué podemos hacer por ti?",
  },
  softwareServiceStaff: {
    title: "Servicios de Software",
    paragraph1:
      "En la actualidad el mercado del desarrollo de software, implica la incorporación de equipos con habilidades especializadas, talento y experiencia. Para empresas de todo el mundo, es la mejor forma de acceder a ingenieros expertos en desarrollo capaces de trabajar con variables de habilidades tecnológicas, desde diseño UX, hasta consultoría Blockchain, optimizando la performance de procesos, obteniendo mejores resultados de desarrollo de software, y mejorando la rentabilidad de su proyecto.",
    paragraph2: `En`,
    company: " Possumus ",
    paragraph2Part2:
      "nos especializamos en customizaciones inteligentes de plataformas previamente desarrolladas y en ofrecer soluciones personalizadas de ciclo completo. Estamos completamente preparados para brindarle soluciones de software confiables y de alto valor que usted necesita.",
    buttonHelp: "Conoce cómo podemos ayudarte",
    title2: "Compañías elegibles para nuestros servicios",
    paragraph1t2:
      "Nuestros equipos están conformados por profesionales Seniors, que impulsan en base a su talento y habilidades, la transformación de proyectos a nuevos niveles.",
  },
  technologiesStaff: {
    title: "Tecnologías",
    paragraph1:
      "Si bien nuestros servicios se pueden brindar en cualquiera de las tecnologías disponibles, el 70% de nuestra base de datos está compuesta por profesionales especializados en:",
    buttonConnectWith: "Conéctate con nosotros",
  },
  timeZoneStaff: {
    title: "Alineación de zona horaria",
    paragraph1:
      "Los desarrolladores de Possumus siempre hacen coincidir sus rangos horarios con los de sus pares norteamericanos, independientemente de su zona horaria. Se hace de esta manera para que puedan asistir a reuniones y llamadas virtuales, responder correos electrónicos y trabajar con los clientes para una integración perfecta durante todo el día.",
    paragraph2:
      "Esto facilita la gestión de proyectos en tiempo real y ciclos de feedback eficientes para tasas de entregas más rápidas.",
    title2: "Zona horaria",
    paragraph1t2:
      "La zona horaria de Argentina se encuentra en UTC-3, lo que significa que podemos adaptarnos fácilmente y compartir la mayor parte de nuestro día de trabajo con su equipo, sin importar si sus bases se encuentran en la costa este u oeste del país. ",
    buttonPutTalent: "Pon a trabajar a los mejores talentos para ti",
  },
  onshoreStaff: {
    title: "Onshore, nearshore o offshore: ",
    paragraph1:
      "¿Qué modelo de Staffing es conveniente para su proyecto?",
    paragraph2:
      "Te ayudamos a definir el modelo que mejor se adapta a tu empresa y proyectos:",
    listitem1: "Onshore: Equipo ubicado en el mismo país o región.",
    listitem2:
      "Offshore: Equipo ubicado en otro país con una zona horaria diferente.",
    listitem3:
      "Nearshore: Equipo ubicado en un país vecino o a poca distancia.",
    paragraph3:
      "En la proyección de desarrollo de software Usted no requiere generar nuevos equipos en su estructura, salvo que así lo desee. Puede incorporar hoy mismo el equipo ideal, con todas las habilidades requeridas, exclusivo para desarrollar y escalar su proyecto, tanto en países cercanos como lejanos.",
    titlecol1: "Onshore",
    subtitlecol1: "Software development",
    paragraphcol1:
      "La compañía está ubicada en California y su socio de subcontratación en New Jersey. Trabajan en diferentes Estados, pero en el mismo país.",
    titlecol2: "Nearshore",
    subtitlecol2: "Software development",
    paragraphcol2:
      "Una compañía de los Estados Unidos subcontrata en Canadá, México y Argentina. ",
    titlecol3: "Offshore",
    subtitlecol3: "Software development",
    paragraphcol3:
      "Una compañía de los Estados Unidos subcontrata servicios en Asia, incluyendo India, Singapur, Vietnam, etc.",
  },
  nearshoreStaff: {
    title: "Nearshore",
    paragraph1:
      "Aunque muchas empresas lo mantienen en secreto, todos en la industria saben que, durante la última década, el negocio de desarrollo de software nearshore creció considerablemente. La principal razón que empuja a las empresas de USA o Europa a subcontratar sus esfuerzos de desarrollo de software en otros países es el dinero. Contratar a un equipo en su país es mucho más costoso que contratar a un equipo en otros países (que son más baratos). Pero… (siempre hay un pero), la mayoría de las veces, más barato significa mala calidad.",
    title2: "Bajo costo, máxima calidad",
    paragraph1t2:
      "Durante la última década, Argentina demostró ser una opción confiable y rentable para la subcontratación. El bajo valor de la moneda argentina en comparación con el dólar estadounidense permite, a nuestras empresas de IT, tener los precios más competitivos del mercado mundial. Si combina los precios bajos, con todas las demás razones enumeradas en este artículo, usted comprenderá por qué Argentina es el principal destino para subcontratar cualquier trabajo de desarrollo de software y ahorrar mucho en costos. ",
    buttonLets: "¿Hablemos ahora?",
  },
  companyStaff: {
    titlecol1: "¿Diferentes idiomas? No es un problema para nosotros",
    paragraphcol1:
      "La comunicación es clave en el desarrollo de proyectos de cualquier tipo, pero especialmente cuando los equipos están ubicados en diferentes países. Según el Índice de Dominio del inglés elaborado por EF, Argentina ocupa el primer lugar en América Latina y es el único país, en la región, que tiene un puntaje de “Alto Dominio”. Tener un alto nivel de competencia significa que los argentinos pueden hacer una presentación en el trabajo, entender programas de televisión o leer un periódico sin ningún problema. Esto significa que cuando contrata a un equipo de desarrollo o simplemente a un desarrollador en Argentina, usted puede esperar que formen parte de las reuniones de su equipo y comuniquen el progreso o los problemas como si fueran parte de su propio equipo.",
    titlecol2: `Pero ¿Y las habilidades tecnológicas?`,
    paragraph1col2: `Hasta ahora, usted podría estar pensando “si estos profesionales hablan bien inglés, tienen una zona horaria similar a la nuestra pero, ¿qué cantidad de habilidades tecnológicas poseen?”. El índice de Habilidades Globales de Coursera une la profunda experiencia de Coursera en datos y educación para resaltar las habilidades profesionales más populares del mundo. En la categoría de Tecnología, Argentina ocupa el primer lugar del mundo. Estamos en la parte superior de la lista de 15 países que tienen habilidades de vanguardia en tecnología, y somos el único país latinoamericano en la lista de vanguardia.`,
    paragraph2col2:
      " Algunas de las competencias que Coursera enumera dentro de la categoría de Tecnología son Redes de Computadoras, Sistemas Operativos, Bases de Datos e Ingeniería de Software.",
    titlecol3: "Cultura",
    paragraph1col3:
      "Aunque hay una distancia de ~9000km (~5,600mi) entre nosotros, los argentinos, y los estadounidenses, somos muy similares en términos de cultura. Y no estamos hablando sólo de comida, bebida, arte o deportes… somos muy similares en términos de cultura laboral, especialmente cuando hablamos de la industria IT. Los entornos de trabajo son prácticamente los mismos que puede encontrar en cualquiera de las principales empresas de IT en USA.",
    paragraph2col3:
      "Compartimos una mentalidad y un estilo de vida similares a los estadounidenses. Esto es clave para comprender los requisitos de su proyecto y nos permite compartir nuestro punto de vista sobre las características de su aplicación. Podemos ver sus proyectos tanto como desarrolladores, y como potenciales usuarios debido a nuestra similitud cultural.",
  },
};
export const staffEn = {
  mainbannerStaff: {
    title: "{{title}}",
    subtitle: "{{subtitle}}",
  },
  infoHomeStaff: {
    title: "{{title}}",
    paragraph1: "{{paragraph1}}",
    paragraph2: "{{paragraph2}}",
    teamStaffing: "{{teamStaffing}}",
    paragraph2Part2: "{{paragraph2Part2}}",
    paragraph3: "{{paragraph3}}",
    paragraph4: "{{paragraph4}}",
    paragraph5: "{{paragraph5}}",
    buttonWhat: "{{buttonWhat}}",
  },
  softwareServiceStaff: {
    title: "{{title}}",
    paragraph1: "{{paragraph1}}",
    paragraph2: "{{paragraph2}}",
    company: "{{company}}",
    paragraph2Part2: "{{paragraph2Part2}}",
    buttonHelp: "{{buttonHelp}}",
    title2: "{{title2}}",
    paragraph1t2: "{{paragraph1t2}}",
  },
  technologiesStaff: {
    title: "{{title}}",
    paragraph1: "{{paragraph1}}",
    buttonConnectWith: "{{buttonConnectWith}}",
  },
  timeZoneStaff: {
    title: "{{title}}",
    paragraph1: "{{paragraph1}}",
    paragraph2: "{{paragraph2}}",
    title2: "{{title2}}",
    paragraph1t2: "{{paragraph1t2}}",
    buttonPutTalent: "{{buttonPutTalent}}",
  },
  onshoreStaff: {
    title: "{{title}}",
    paragraph1: "{{paragraph1}}",
    paragraph2: "{{paragraph2}}",
    listitem1: "{{listitem1}}",
    listitem2: "{{listitem2}}",
    listitem3: "{{listitem3}}",
    paragraph3: "{{paragraph3}}",
    titlecol1: "{{titlecol1}}",
    subtitlecol1: "{{subtitlecol1}}",
    paragraphcol1: "{{paragraphcol1}}",
    titlecol2: "{{titlecol2}}",
    subtitlecol2: "{{subtitlecol2}}",
    paragraphcol2: "{{paragraphcol2}}",
    titlecol3: "{{titlecol3}}",
    subtitlecol3: "{{subtitlecol3}}",
    paragraphcol3: "{{paragraphcol3}}",
  },
  nearshoreStaff: {
    title: "{{title}}",
    paragraph1: "{{paragraph1}}",
    title2: "{{title2}}",
    paragraph1t2: "{{paragraph1t2}}",
    buttonLets: "{{buttonLets}}",
  },
  companyStaff: {
    titlecol1: "{{titlecol1}}",
    paragraphcol1: "{{paragraphcol1}}",
    titlecol2: "{{titlecol2}}",
    paragraph1col2: "{{paragraph1col2}}",
    paragraph2col2: "{{paragraph2col2}}",
    titlecol3: "{{titlecol3}}",
    paragraph1col3: "{{paragraph1col3}}",
    paragraph2col3: "{{paragraph2col3}}",
  },
};
