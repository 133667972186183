import React from "react";

import { Container, Row, Col } from "react-bootstrap";
import gKozub from "../../assets/landings/VivaGoals/GKozub.png";
import vGoalsLogo from "../../assets/landings/VivaGoals/vGoalsLogo.png";

export const Details = () => {
  return (
    <>
      <Container fluid id="detailsLandingSolidGeneric">
        <Container>
          <Row className="titulomodulos d-flex justify-content-center subtitleLandingVivaGoals">
            <Col md={11} xs={12} lg={8}>
              <h2 className="text-center">
                Sistema para la gestión y establecimiento de objetivos comunes
                en los equipos de trabajo.​
              </h2>
            </Col>
          </Row>
          <Row className="textLandingGeneric justify-content-center">
            <Col
              md={6}
              sm={12}
              xxl={5}
              id="desc"
              className="styleColParticipants"
            >
              <p>
                En el webinar “Viva Goals”, conozca cómo Microsoft Viva facilita
                la gestión de objetivos enfocados en los OKR, alinea equipos
                tras los resultados claves, define éxitos, guía cada desarrollo
                hacia la meta; y brinda a cada usuario herramientas para
                clarificar prioridades y medir su impacto.
              </p>
            </Col>

            <Col md={6} sm={12} className="hideimageStickerParticipant">
              <div>
                <img src={vGoalsLogo} className="img-fluid" alt="sticker" />
              </div>
            </Col>

            <Col md={6} sm={12} className={`d-sm-block d-none`}>
              <div className="styleImageStickerParticipants">
                <img src={vGoalsLogo} className="img-fluid" alt="sticker" />
              </div>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container
        id="titleParticipantsVivaGoals"
        className="d-none d-sm-none d-md-block"
      >
        <Row className="titleExposes">
          <Col lg={6}>
            <h2>Presentado por:</h2>
          </Col>
        </Row>
        <Row className="textLandingGeneric styleExposesVivaGoals">
          <Col md={10} sm={12} xxl={5} id="desc">
            <Row className="rowSpeaker">
              <Col md={3} className="parti-center">
                <img src={gKozub} className="img-fluid" alt="speakerOne" />
              </Col>
              <Col md={8} className="d-flex align-items-center parti-center">
                <div>
                  <h2>Guillermo Kozub</h2>
                  <p className="mb-0">Product Owner & Delivery, Possumus </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Container
        id="titleParticipantsDiscovery"
        className="d-block d-sm-block d-md-none"
      >
        <h2>Presentado por:</h2>
        <Row className="textLandingGeneric">
          <Col md={6} sm={12} xxl={5} id="desc">
            <Row className="rowSpeaker">
              <Col md={3} className="parti-horizontal-center">
                <img src={gKozub} className="img-fluid" alt="speakerOne" />
              </Col>
              <Col
                md={8}
                className="d-flex align-items-center parti-horizontal-center"
              >
                <div>
                  <h2>Guillermo Kozub</h2>
                  <p>Product Owner & Delivery, Possumus </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container id="ctaLandingViva">
        <Row>
          <Col md={{ span: 10, offset: 1 }}  className="p-0">
            <h2 className="text-white">
              En el webinar “Viva Goals”, entienda cómo crear una cultura basada
              en objetivos, para alcanzar resultados.
            </h2>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Details;
