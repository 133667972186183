import fernando from "../../assets/landings/houston/team/FernandoBulczynski.svg";
import marisa from "../../assets/landings/houston/team/MarisaVaira.svg";
import ilustrationITaaS from "../../assets/landings/houston/ilustrationITaaS.svg";
import ilustrationMSE from "../../assets/landings/houston/ilustrationMSE.svg";
import ilustrationEngineering from "../../assets/landings/houston/ilustrationEngineering.svg";
import softwareWLD from "../../assets/landings/houston/softwareWLD.svg";

export const SOFTWARE_ENGINEERING = {
  image: softwareWLD,
  bajada: 'Desarrollamos productos digitales que generan valor al negocio. Trabajamos de manera iterativa, logrando productos que evolucionan e impactan con experiencia positiva en el mercado de nuestros clientes.',
  descripcion1: 'Podemos hacer tu producto en nuestra empresa, o podemos proveerte un equipo completo para acelerar tus procesos y mejorar tiempos de entrega.',
  descripcion2: 'Nuestra cultura de desarrollo equilibra mejor la calidad con la eficiencia económica.',
}

export const OUR_SERVICES = {
  title: "Nuestros servicios",
  services: [
    {
      text: "Ingeniería de Software",
      hover: true,
      href: "#houstonSoftwareEngineering",
    },
    {
      text: "Ingeniería de Nube & Práctica DevOps",
      hover: true,
      href: "#devOpsPractice",
    },
    {
      text: "IT as a Service",
      hover: true,
      href: "#houstonItaas",
    },
    {
      text: "Microsoft Ecosystem",
      hover: true,
      href: "#houstonMse",
    },
  ],
  jumboButtonText: 'Saber más'
};

export const SERVICES = [
  {
    id: "devOpsPractice",
    title: "Ingeniería de Nube & Práctica DevOps",
    description:
      "Garantizamos optimización y escalabilidad de tus sistemas, con ingeniería de plataforma centrada en la nube, con un enfoque muy sólido sobre seguridad y la optimización de rendimiento, que logra maximizar resultados y acelerar procesos.\n\n Nuestra performance es fuerte, y equilibra mejor la calidad con la eficiencia económica.",
    image: ilustrationEngineering,
  },
  {
    id: "houstonItaas",
    title: "ITaaS (IT as a Service)",
    description:
      "Simplificamos la transformación digital con tecnología escalable a medidas de cada proyecto, que promueve la estandarización y mejora la disponibilidad de equipos de trabajo. Incrementamos productividad, mejoramos costos y el direccionamiento de inversión, agilizamos procesos y aseguramos la continuidad de los negocios dentro de tu compañía.\n\n Nuestra performance es fuerte, y equilibra mejor la calidad con la eficiencia económica.",
    image: ilustrationITaaS,
  },
  {
    id: "houstonMse",
    title: "Microsoft Ecosystem",
    description:
      "Aceleramos la transformación e incrementamos la productividad de tu negocio, vinculando personas, datos y procesos. Con herramientas colaborativas y automatizadas, que miden en tiempo real y mejoran la toma de decisión de negocios.\n\n Tenemos un enfoque muy sólido sobre seguridad de datos, administración eficiente y conformidad por parte del usuario. Nuestra performance es fuerte, y equilibra mejor la calidad con la eficiencia económica.",
    image: ilustrationMSE,
  },
];

export const TITLEANDBULLETSSECTION = [
  {
    id: "corporateSoftwareDevelopment",
    title: "Corporate Software Development",
    bullets: [
      {
        title: "Integraciones",
        description:
          "Nuestras soluciones son la clave para establecer una única fuente de la verdad, simplificando la gestión y exposición de datos.",
      },
      {
        title: "Arquitectura",
        description:
          "Ayudamos a migrar arquitecturas monolíticas a microservicios totalmente administrados.",
      },
      {
        title: "SSO",
        description:
          "Aplique Single Sign On a su ecosistema de aplicaciones para dar respuesta a sus necesidades de Identity Management System y simplificar la experiencia de sus usuarios, garantizando un correcto nivel de seguridad.",
      },
      {
        title: "Middlewares",
        description:
          "Nuestros servicios de desarrollo de middlewares impulsan la interoperabilidad y la escalabilidad de sus aplicaciones.",
      },
    ],
  },
  {
    id: "digitalProducts",
    title: "Productos digitales",
    bullets: [
      {
        title: "Product Discovery",
        description:
          "Nuestra metodología permite identificar oportunidades estratégicas, comprender a fondo las necesidades del mercado y liderar la innovación con confianza.",
      },
      {
        title: "Pruebas de concepto​",
        description:
          "Transformamos ideas en soluciones tangibles y validamos su factibilidad antes de invertir recursos significativos.",
      },
      {
        title: "MVP​",
        description:
          "Con nuestro servicio de desarrollo de MVPs acelere el time to market creando prototipos funcionales que validan sus ideas y gestionan eficazmente su presupuesto.",
      },
      {
        title: "Desarrollo iterativo",
        description:
          "Garantizamos soluciones que evolucionan con las demandas cambiantes del mercado, donde cada ciclo de mejora nos acerca más a su visión.",
      },
    ],
  },
  {
    id: "uxServices",
    title: "Servicios UX",
    bullets: [
      {
        title: "Investigación de usuarios y usabilidad",
        description:
          "Nos enfocamos en entender a tus usuarios para diseñar interfaces que se adapten a sus necesidades.",
      },
      {
        title: "Arquitectura de información",
        description:
          "Diseñamos estructuras de información que facilitan la navegación y la comprensión de tus aplicaciones.",
      },
      {
        title: "Estrategia de contenido",
        description:
          "Optimizamos la comunicación y el contenido para transmitir la personalidad de tu marca de manera efectiva.",
      },
      {
        title: "Diseño de interacción",
        description:
          "Creamos interacciones dinámicas que cautivan a los usuarios y enriquecen la experiencia.",
      },
      {
        title: "Diseño visual",
        description:
          "Creamos una estética visual que refleja la identidad de tu marca y mejora la apariencia de tus productos.",
      },
    ],
  },
];

export const SIDENAVBAR_ITEMS = {
  id: 1,
  title: "Ingeniería de Software",
  href: "houstonSoftwareEngineering",
  navItems: [
    { id: 1, Titulo: "Staff Augmentation", Href: "houstonStaff" },
    {
      id: 2,
      Titulo: "Corporate Software Development",
      Href: "corporateSoftwareDevelopment",
    },
    {
      id: 3,
      Titulo: "Productos digitales",
      Href: "digitalProducts",
    },
    {
      id: 4,
      Titulo: "Servicios UX",
      Href: "uxServices",
    },
  ],
};

export const MEMBERS = [
  { name: "Fernando Bulczynski", charge: "Chief Growth Officer", image: fernando },
  {
    name: "Marisa Vaira",
    charge: "Sales Consultant",
    image: marisa,
  },
];

export const ourTeamPpalContent = {
  suptitle: "¿Que nos mueve?",
  title:
    "Impulsamos la innovación digital en las empresas para garantizar su competitividad, vigencia y relevancia en el mercado.",
  members: MEMBERS,
};

export const ourTeamContent = {
  suptitle: "OUR TEAM MEMBERS AT NEXGEN 2023",
  members: MEMBERS,
};

export const FOOTER_TEXT = {
  innovate: 'Innovemos juntos',
  contact: {
    title: '¡Estemos en contacto!',
    button: 'Contáctanos',
    tel: '0810 345 0562'
  },
  redes: {
    title: 'Join Us'
  }
}