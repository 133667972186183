import React from "react";
import { Col, Row, Container } from "react-bootstrap";

export const IframeYouTube = ({ title,id, active }) => {
  return (
    !active && (
      <Container  className="marginYoutube" id="youtubeSection">
        <Row className="titleYouTube">
          <Col>
            <h2 className="text-center titleFontYoutube">{title}</h2>
          </Col>
        </Row>
        <Row className="row-width mt-5">
          <Col>
            <iframe
              className="iframeRow styleIframeYouTube"
              src={"https://www.youtube.com/embed/" + id}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Col>
        </Row>
      </Container>
    )
  );
};
