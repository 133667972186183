import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { createContext } from "react";
import { ActualLangContext } from "./ActualLangContext";
import { AuthStrapiContext } from "./AuthStrapiV3Context";

export const ContentOrganizationalPoliciesContext = createContext({});

export const ContentOrganizationalPoliciesProvider = ({ children }) => {
  const [itemsPolicies, setItemsPolicies] = useState([]);
  const [dataBanner, setDataBanner] = useState({});
  const [titleAndSubtitle, setTitleAndSubtitle] = useState({});
  const [misionAndVision, setMisionAndVision] = useState({});
  const [loadedData, setLoadedData] = useState(false);

  const { actualLang } = useContext(ActualLangContext);
  const { token } = useContext(AuthStrapiContext);

  useEffect(() => {
    setLoadedData(false);
    const url = `${process.env.REACT_APP_URL_API}/pages-politica-organizacional?_locale=${actualLang}`;
    if (token) {
      axios
        .get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          setDataBanner(res.data.Banner[0]);
          setItemsPolicies(res.data.Politicas.Items);
          setTitleAndSubtitle({
            title: res.data.Politicas.Titulo,
            subtitle: res.data.Politicas.Subtitulo,
          });
          setMisionAndVision({
            mision: res.data.Mision_Vision.Mision,
            vision: res.data.Mision_Vision.Vision,
            misionTitle: res.data.Mision_Vision.MisionTitle,
            visionTitle: res.data.Mision_Vision.VisionTitle,
          });
          setLoadedData(true);
        }).catch(err => console.log(`Ocurrio un error al obtener los datos de la pagina de politicas: ${err.message}`))
    }
  }, [actualLang, token]);
  return (
    <ContentOrganizationalPoliciesContext.Provider
      value={{
        itemsPolicies,
        loadedData,
        titleAndSubtitle,
        misionAndVision,
        dataBanner,
      }}
    >
      {children}
    </ContentOrganizationalPoliciesContext.Provider>
  );
};
