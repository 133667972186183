import "./PreFooter.css";
import { Col, Container, Image, Row } from "react-bootstrap";
import axios from "axios";
import { useEffect, useState } from "react";

export default function PreFooter() {
  const [logoIso, setLogoIso] = useState("");

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL_API}/page-usa`).then((res) => {
      setLogoIso(res.data.footerUsa.logoIso.url);
    });
  }, []);
  return (
    <Container fluid className="preFooterContainer">
      <Container>
        <Row className="d-flex justify-content-center flex-column flex-md-row">
          <Col className="justify-content-center d-flex align-items-center">
            <div
              className="clutch-widget"
              data-url="https://widget.clutch.co"
              data-widget-type="2"
              data-height="45"
              data-primary-color="#fcd702"
              data-secondary-color="#fcd702"
              data-clutchcompany-id="1572791"
            ></div>
          </Col>
          <Col className="justify-content-center d-flex align-items-center">
            <Image src={logoIso} alt="Possumus" fluid />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
