import React, { useContext } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import isopk1 from "../../assets/iso1pk.svg";
import { ContentOrganizationalPoliciesContext } from "../../contexts/ContentOrganizationalPoliciesContext";

const MisionVision = () => {

  const {misionAndVision} = useContext(ContentOrganizationalPoliciesContext)

  return (
    <Container fluid id="misionVision">
      <Container>
        <Row>
          <Col lg={6}>
            <Card className="text-center">
              <img
                src={isopk1}
                className="mr-1 isoMisionVision"
                alt="Software Engineering"
              ></img>
              <h4>
                {misionAndVision.misionTitle}
              </h4>
              <p>
                {misionAndVision.mision}
              </p>
            </Card>
          </Col>
          <Col lg={6}>
            <Card className="text-center">
              <img
                src={isopk1}
                className="mr-1 isoMisionVision"
                alt="Software Engineering"
              ></img>
              <h4>
                {misionAndVision.visionTitle}
              </h4>
              <p>
                {misionAndVision.vision}
              </p>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default MisionVision;
