import React, { Fragment } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import meme from "../assets/404/notFoundMeme.svg";
import { Footer } from "../components/commons/footer";
import MenuUsa from "../components/commons/menuUsa";
import { LocationAddress } from "../components/usa/locationAddress";
import { useTranslation } from "react-i18next";
import { page404 } from "../config/home/textPage";

export const Page404 = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <MenuUsa />
      <Container fluid id="Page404">
        <Container>
          <Row className="d-flex justify-content-center">
            <Col md={5} lg={5} xl={5} xxl={5} className="text404">
              <h1>
                {t("page404.title", {
                  title: `${page404.title}`,
                })}
              </h1>
              <p>
                {t("page404.paragrahp", {
                  paragrahp: `${page404.paragrahp}`,
                })}
              </p>
              <Button variant="primary" href="/">
                {t("page404.button", {
                  button: `${page404.button}`,
                })}
              </Button>
            </Col>
            <Col
              md={5}
              lg={5}
              xl={5}
              xxl={5}
              className="img404 d-flex align-items-center text-center"
            >
              <img src={meme} className="img-fluid" alt=""></img>
            </Col>
          </Row>
        </Container>
      </Container>
      <LocationAddress />
      <Footer />
    </Fragment>
  );
};

export default Page404;
