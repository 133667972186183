import calendarIcon from "../../assets/calendarIcon.svg";
import beneIcon from "../../assets/bene.svg";
import homeIcon from "../../assets/home.svg";
import certiIcon from "../../assets/certi.svg";
import aumenIcon from "../../assets/aumen.svg";
import coberturaIcon from "../../assets/cobertura.svg";
import webIcon from "../../assets/web.svg";
import programIcon from "../../assets/program.svg";
import sparkleIcon from "../../assets/sparkle.svg";
import backPackIcon from "../../assets/backPack.svg";
export const infoBenefits = [
  {
    logo: homeIcon,
    title: "Home Office",
    paragraph: `Trabaja 100% remoto. Te proporcionamos el equipo de trabajo y
     te ofrecemos una compensación para cubrir los gastos en Home Office.`,
  },

  {
    logo: aumenIcon,
    title: "Aumento escalable",
    paragraph: `Como parte de nuestro compromiso con vos, 
    ajustamos el crecimiento variable de tu salario de forma bimestral,
     manteniendo un equilibrio.`,
  },
  {
    logo: calendarIcon,
    title: `Días off extendidos`,
    item: [
      {
        item1: "+ Días adicionales",
        item2: "+ Días Extendidos",
        item3: "+ Expandid days",
      },
    ],
    paragraph: `Sabemos lo importante que es desconectar y
    renovar energías, por eso sumamos 5 días libres adicionales a 
    tus vacaciones para que los puedas disfrutar como más te guste.`,
  },
  {
    logo: certiIcon,
    title: "Entrenamiento certificado",
    paragraph: `Te asistimos en la transformación hacia tu mejor versión,
     capacitándote en nuevas habilidades,
      con certificaciones que te ayuden a 
      potenciar tu carrera profesional.`,
  },
  {
    logo: beneIcon,
    title: "Celebramos juntos",
    paragraph: `Nos encontramos para conocernos más
    y compartir momentos inolvidables en nuestra sede centran 
    en San Rafael Mendoza. Además celebramos una increíble fiesta cada año para disfrutar
    y consolidar más nuestros vínculos.`,
  },
  {
    logo: coberturaIcon,
    title: "Cobertura Plus Salud",
    paragraph: `Para vos y tu grupo familiar, la mejor cobertura de salud a través de 
    Swiss Medical SMG20.
    `,
  },
  {
    logo: webIcon,
    title: "English in company",
    paragraph: `El idioma no es una barrera para nosotros. Con nuestro programa English in Company,
     vas a poder mejorar o aprender el idioma en distintos niveles! Let´s do it!`,
  },
  {
    logo: programIcon,
    title: "Programa de referidos",
    paragraph: `Somos una gran comunidad en dónde el talento se comparte.
     Referí a tus contactos para que puedan sumarse a Possumus, y obtené gratificaciones.`,
  },
  {
    logo: sparkleIcon,
    title: "Licencia por paternidad extendida",
    paragraph: `Sabemos lo importante que es estar presente y compartir momentos de valor, en familia.
     Por eso entregamos la licencia por paternidad extendida.`,
  },
  {
    logo: backPackIcon,
    title: "Asistencia escolar",
    paragraph: `Colaboramos con la compra de los útiles al inicio del ciclo lectivo,
     en Possumus te brindamos ayuda para lograr un excelente comienzo escolar.`,
  },
];

export const infoBenefitsEn = [
  {
    logo: homeIcon,
    title: "Home Office",
    paragraph: `Work 100% remote. We provide you with
     the equipment and offer you a compensation to cover your home office expenses. `,
  },

  {
    logo: aumenIcon,
    title: "Progressive salary increase",
    paragraph: `As part of our commitment to you,
     we adjust the variable growth of your salary on a bimonthly basis,
     maintaining a balance between fluctuations and pre-established increases. `,
  },
  {
    logo: calendarIcon,
    title: `Extended days off`,
    item: [
      {
        item1: "+ Additional days",
        item2: "+ Extended days",
        item3: "+ Expandid days",
      },
    ],
    paragraph: `We know how important it is to disconnect and renew energies, that's why 
    we add 5 additional days to your vacation/holiday so you can enjoy them as you like.`,
  },
  {
    logo: certiIcon,
    title: "Certified training ",
    paragraph: `We assist you in transforming into your best version by 
    providing training in new skills and certifications 
    that help enhance your professional career. `,
  },
  {
    logo: beneIcon,
    title: "Celebrating together ",
    paragraph: `We gather to get to know each other better
     and share unforgettable moments at our central headquarters in San Rafael, Mendoza.
      Additionally,
     we celebrate an incredible party each year to enjoy and further strengthen our bonds. `,
  },
  {
    logo: coberturaIcon,
    title: "Plus Health Coverage",
    paragraph: `For you and your family,
     the best health coverage through Swiss Medical SMG20.  .
    `,
  },
  {
    logo: webIcon,
    title: "English in company",
    paragraph: `Language is not a barrier for us.
     With our English in Company program,
      you will be able to improve or learn the language at different levels.
       Let's do it! `,
  },
  {
    logo: programIcon,
    title: "Referral program ",
    paragraph: `We are a vibrant community where talent is shared.
     Refer your contacts to join Possumus and earn rewards. `,
  },
  {
    logo: sparkleIcon,
    title: "Extended paternity leave ",
    paragraph: `We know how important it is to be present and 
    share precious moments with your family.
     That's why we provide extended paternity leave. `,
  },
  {
    logo: backPackIcon,
    title: "School Aid ",
    paragraph: `We collaborate with the purchase of school supplies
     at the beginning of the school year.
     At Possumus, we provide assistance to ensure an excellent start. `,
  },
];

export const infoVideos = [
  {
    description: `Lucia Marcolini: Iniciar la carrera siempre es un desafío y un gran primer paso hacia el futuro.`,
    people: "Lucia",
    url: "jmG7lQc8tMo",
  },
  {
    description: `Jorge Andreoni: Jugarse siempre, darle lugar a las cosas que importan, darnos la oportunidad de hacer lo que amamos.`,
    people: "Jorge",
    url: "cGhXXib9MWU",
  },
  {
    description: `Fernanda Venteo: Darse la oportunidad, desafiarse, animarse a crecer y sentirse parte.`,
    people: "Fernanda",
    url: "7yrx_VrOUpg",
  },
  {
    description:
      "Iván Milstain: Crecer creando, de menos a más, poniendo en valor las cosas que realmente son importantes.",
    people: "Iván",
    url: "rEardrJ_bcQ",
  },
];
