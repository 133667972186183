import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import imgSre from "../../assets/imgSre.svg";
import imgStaff from "../../assets/imgStaff.svg";
import imgSoftware from "../../assets/softwareIngWLD.svg";
import { menuServices } from "../../config/home/textPage";
import { useTranslation } from "react-i18next";

import { staffHomeResume } from "../../config/StaffAugmentation/textPage";
import { srePage } from "../../config/sre/textPage";
import { softwareEngineering } from "../../config/softwareEngineering/textPage";

const WhatWeDoDesktop = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Container fluid id="whatWeDo" className="d-none d-sm-none d-md-block">
        <Row id="softwareEngineering" className="justify-content-md-center">
          <Col className="d-flex flex-column align-items-center justify-content-center">
            <p className="sup-title text-uppercase text-center">
              {t("softwareEngineering.title", {
                title: `${softwareEngineering.title}`,
              })}
            </p>
            <h2 className="text-center mb-5">
              {t("softwareEngineering.subTitle", {
                subTitle: `${softwareEngineering.subTitle}`,
              })}
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg={7} className="align-self-center">
            <p className="text-center">
              {t("softwareEngineering.description1", {
                description1: `${softwareEngineering.description1}`,
              })}
            </p>
          </Col>
        </Row>
        <Row className=" marginTop50  justify-content-center align-items-center d-flex">
          <Col lg={4}>
            <img
              align="center"
              src={imgSoftware}
              alt="Software Engineering"
              className="mx-auto d-block img-fluid "
            ></img>
          </Col>
          <Col lg={4}>
            <p className="text-right">
              {t("softwareEngineering.description2", {
                description2: `${softwareEngineering.description2}`,
              })}
            </p>
            <p className="text-right">
              {t("softwareEngineering.description3", {
                description3: `${softwareEngineering.description3}`,
              })}
            </p>
            <p className="text-right">
              {t("infoHome.paragraph3", {
                paragraph3: `${srePage.paragraph3}`,
              })}
              <b>
                {t("infoHome.company", {
                  company: `${srePage.company}`,
                })}
              </b>
              {t("infoHome.paragraph4", {
                paragraph4: `${srePage.paragraph4}`,
              })}
            </p>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col className="text-center mt-4">
            <Button
              variant="secondary"
              href={`/${
                i18n.language === "en" ? "en-en" : "es-es"
              }/software-engineering`}
            >
              {t("menuServices.button", {
                button: `${menuServices.button}`,
              })}
            </Button>
          </Col>
        </Row>
        <Row id="sre" className="justify-content-md-center marginTop150">
          <Col className="d-flex justify-content-center">
            <h2 className="text-center mb-5">
              {t("infoHome.title", {
                title: `${srePage.title}`,
              })}
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg={8} className="align-self-center">
            <p className="text-center">
              {t("infoHome.paragraph1", {
                paragraph1: `${srePage.paragraph1}`,
              })}
            </p>
          </Col>
        </Row>
        <Row className=" marginTop50 justify-content-center align-items-center d-flex">
          <Col lg={4}>
            <img
              align="center"
              src={imgSre}
              alt="Software Engineering"
              className="mx-auto d-block img-fluid "
            ></img>
          </Col>
          <Col lg={4} className="margin50">
            <p className="text-right ">
              {t("infoHome.paragraph2", {
                paragraph2: `${srePage.paragraph2}`,
              })}
            </p>
            <p className="text-right ">
              {t("infoHome.paragraph3", {
                paragraph3: `${srePage.paragraph3}`,
              })}
              <b>
                {t("infoHome.company", {
                  company: `${srePage.company}`,
                })}
              </b>
              {t("infoHome.paragraph4", {
                paragraph4: `${srePage.paragraph4}`,
              })}
            </p>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col className="text-center mt-4">
            <Button
              variant="secondary"
              href={`/${
                i18n.language === "en" ? "en-en" : "es-es"
              }/devops-sre`}
            >
              {t("menuServices.button", {
                button: `${menuServices.button}`,
              })}
            </Button>
          </Col>
        </Row>
        <Row
          id="staffAugmentation"
          className="justify-content-md-center marginTop150"
        >
          <Col className="d-flex justify-content-center">
            <h2 className="text-center mb-5">
              {t("infoHomeStaff.title", {
                title: `${staffHomeResume.title}`,
              })}
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg={6} className="align-self-center">
            <p className="text-center">
              {t("infoHomeStaff.paragraph1", {
                paragraph1: `${staffHomeResume.paragraph1}`,
              })}
            </p>
          </Col>
        </Row>
        <Row className=" marginTop50 justify-content-center align-items-center d-flex">
          <Col lg={4}>
            <p className="text-right">
              {t("infoHomeStaff.paragraph2", {
                paragraph2: `${staffHomeResume.paragraph2}`,
              })}
              <b>
                {t("infoHomeStaff.teamStaffing", {
                  teamStaffing: `${staffHomeResume.teamStaffing}`,
                })}
              </b>
              {t("infoHomeStaff.paragraph2Part2", {
                paragraph2Part2: `${staffHomeResume.paragraph2Part2}`,
              })}
            </p>

            <p className="text-right">
              {t("infoHomeStaff.paragraph3", {
                paragraph3: `${staffHomeResume.paragraph3}`,
              })}
              <b>
                {t("infoHomeStaff.title", {
                  title: `${staffHomeResume.title}`,
                })}
              </b>
              {t("infoHomeStaff.paragraph4", {
                paragraph4: `${staffHomeResume.paragraph4}`,
              })}
            </p>
          </Col>
          <Col lg={4}>
            <img
              align="center"
              src={imgStaff}
              alt="Software Engineering"
              className="mx-auto d-block img-fluid "
            ></img>
          </Col>
        </Row>
        <Row className="mt-4 ">
          <Col className="text-center mt-4">
            <Button
              variant="secondary"
              href={`/${
                i18n.language === "en" ? "en-en" : "es-es"
              }/staff-augmentation`}
            >
              {t("menuServices.button", {
                button: `${menuServices.button}`,
              })}
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default WhatWeDoDesktop;
