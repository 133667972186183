import React, { Fragment, useContext } from "react";
import GetInTouch from "../components/commons/getInTouch";
import { LocationAddress } from "../components/usa/locationAddress";
import { Footer } from "../components/commons/footer";
import { ContactMain } from "../components/contact/contactMain";
import MenuUsa from "../components/commons/menuUsa";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { ActualLangContext } from "../contexts/ActualLangContext";

export const ContactPage = () => {
  const url = process.env.REACT_APP_URL + "/en-en/contact";
  const { actualLang } = useContext(ActualLangContext);
  return (
    <Fragment>
      <HelmetProvider>
        <Helmet>
          <title>{`Possumus - Contact`}</title>
          <meta property="og:title" content="Possumus - Contact" />
          <link rel="canonical" href={`${url}`} />
          <meta property="og:url" content={`${url}`} />
          <meta property="og:type" content="article" />
          <meta property="og:site_name" content="Possumus" />
          <meta
            property="og:image"
            content="https://possumustech.blob.core.windows.net/staticfiles/Miniatura_OG_b0b1d5c32e.png"
          />
        </Helmet>
        <MenuUsa />
        <ContactMain />
        <GetInTouch title={actualLang === 'es' ? "Estemos en contacto" :"Get in touch"} />
        <LocationAddress />
        <Footer />
      </HelmetProvider>
    </Fragment>
  );
};

export default ContactPage;
