import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import imgMain from "../../../assets/toyIS.svg";
import { useTranslation } from "react-i18next";
import { softwareEngineering } from "../../../config/softwareEngineering/textPage";
import { bannerDownload } from "../../../config/Commons/textPage";
import IsoCertificateAndWeLoveDev from "../../commons/IsoCertificateAndWeLoveDev";
export const BannerMobile = ({titulo, bajada}) => {
  const { t } = useTranslation();
  return (
    <Container
      fluid
      className="d-block d-sm-block d-md-block d-lg-none imageBackGroundMobileSE background-banner-header"
    >
      <Container>
        <Row>
          <Col sm={12} id="mainbannerMobile">
          <h1>
              {titulo ? (
                titulo
              ) : (
                <>
                  {t("softwareEngineering.titleBannerPart1", {
                    titleBannerPart1: `${softwareEngineering.titleBannerPart1}`,
                  })}
                  <br></br>
                  {t("softwareEngineering.titleBannerPart2", {
                    titleBannerPart2: `${softwareEngineering.titleBannerPart2}`,
                  })}
                </>
              )}
            </h1>
            <h3>
              {bajada
                ? bajada
                : t("softwareEngineering.description1", {
                    description1: `${softwareEngineering.description1}`,
                  })}
            </h3>
          </Col>
        </Row>
        <Row className="justify-content-center align-items-center d-flex">
          <Col
            sm={12}
            className="justify-content-center align-items-center d-flex"
          >
            <Button
              variant="primary"
              href="#getInTouch"
              className="paddingButtonMainBanner"
            >
              {t("buttonsAndAccesories.buttonGetFree", {
                buttonGetFree: `${bannerDownload.buttonGetFree}`,
              })}
            </Button>
          </Col>
          <Col sm={6} className="col-image-people">
            <img
              align="right"
              src={imgMain}
              className="img-fluid"
              alt="Accelerate your digital transformation"
            ></img>
          </Col>
        </Row>
        <IsoCertificateAndWeLoveDev />
      </Container>
    </Container>
  );
};
