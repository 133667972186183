import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import ReactMarkdown from "react-markdown";

const Services = ({data}) => {
  
  return (
    <>
      <Container fluid id="houstonServicesBackground">
        <Container id="houstonServices">
          {data.map((service, idx) => (
            <Row key={idx} className="houstonService" id={service.id}>
              <h3>{service.title}</h3>
              <Col lg={{ span: 11, offset: 3 }}>
                <Row className="justify-content-center">
                  {idx % 2 === 0 ? (
                    <>
                      <Row>
                        <Col lg={6}>
                          <ReactMarkdown>{service.description}</ReactMarkdown>
                        </Col>
                        <Col lg={3} className="d-flex justify-content-center">
                          <Image src={service.image} />
                        </Col>{" "}
                      </Row>
                    </>
                  ) : (
                    <Row className="flex-column-reverse flex-lg-row">
                      <Col lg={3} className="d-flex justify-content-center">
                        <Image src={service.image} />
                      </Col>
                      <Col lg={{span:6, offset:1}}>
                        <ReactMarkdown>{service.description}</ReactMarkdown>
                      </Col>
                    </Row>
                  )}
                </Row>
              </Col>
            </Row>
          ))}
        </Container>
      </Container>
    </>
  );
};

export default Services;
