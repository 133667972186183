import React, { useContext, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { ActualLangContext } from "../../contexts/ActualLangContext";
import axios from "axios";
import WLT from "../../assets/logos/WLT.svg";

const GetInTouchForm = ({ title, withAlert = true }) => {
  const { actualLang } = useContext(ActualLangContext);

  const [values, setValues] = useState({
    origin: "Possumuss",
    phone: "",
    yourname: "",
    email: "",
    message: "",
    response: "",
  });
  const { yourname, email, message, phone } = values;
  const [activeMessage, setActiveMessage] = useState(false);
  const [error, setError] = useState({
    title: "",
    message: "",
    status: false,
  });
  const [success, setSuccess] = useState({
    title: "Muchas gracias por su mensaje.",
    message: "Nos pondremos en contacto en breve.",
    status: false,
  });
  const [isButtonDisabled, setButtonDisabled] = useState(false)

  const handleInputChange = ({ target }) => {
    setValues({
      ...values,
      [target.name]: target.value,
    });
  };

  const sendData = (token) => {
    setError({});
    setSuccess({});
    axios
      .post(
        `https://prod-20.brazilsouth.logic.azure.com:443/workflows/4a0de618d958419bb7dc99ba7a3245b7/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=QsAL47KtZeSa5He1YXBvXMI4ZsD802JoaITVlC2wXhs`,
        { ...values, response: token }
      )
      .then((res) => {
        if (res.data.success) {
          setSuccess(
            actualLang === "en"
              ? {
                  title: "Thank you very much for your inquiry.",
                  message: "We will be in touch with you shortly.",
                  status: true,
                }
              : {
                  title: "Muchas gracias por su mensaje.",
                  message: "Nos pondremos en contacto en breve.",
                  status: true,
                }
          );
        } else {
          throw new Error("Algo salió mal");
        }
      })
      .catch((error) => {
        setError(
          actualLang === "en"
            ? {
                title: "There was an error sending.",
                message: "Please try again later.",
                status: true,
              }
            : {
                title: "Ha ocurrido un error al enviar tu consulta.",
                message: "Por favor intente nuevamente mas tarde.",
                status: true,
              }
        );
      })
      .finally(() => {
        setValues({
          origin: "Possumuss",
          phone: "",
          yourname: "",
          email: "",
          message: "",
          response: "",
        });
        setButtonDisabled(false)
        setActiveMessage(true);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonDisabled(true)
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute("6LdKISYdAAAAAAZ_z3WlZ1LGYiaM_70zPcZqaYfa", {
          action: "submit",
        })
        .then(function (token) {
          sendData(token);
        });
    });
  };
  return (
    <Col className="getInTouchForm">
      {withAlert && (
        <Col className={activeMessage ? "back activeCard" : "back"}>
          <Col className="getInTouchAlert">
            {success.status ? (
              <>
                <h4>{success.title}</h4>
                <p>{success.message}</p>
                <img src={WLT} alt="Possumus, We Love Tech" />
              </>
            ) : (
              error.status && (
                <>
                  <h4>{error.title}</h4>
                  <p>{error.message}</p>
                  <Button
                    variant="primary"
                    onClick={() => setActiveMessage(false)}
                  >
                    {actualLang === "es" ? "Intentar nuevamente" : "Try again"}
                  </Button>
                </>
              )
            )}
          </Col>
        </Col>
      )}
      <Col className={activeMessage ? "front" : "front activeCard"}>
        <h2 className="text-left title">
          {title
            ? title
            : actualLang === "es"
            ? "Detalles de contacto"
            : "Get in touch"}
        </h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group as={Col} className="input-getInTouch">
            <Form.Control
              id="yourname"
              name="yourname"
              value={yourname}
              onChange={handleInputChange}
              required
              placeholder={
                actualLang === "es" ? "Nombre completo*" : "Full name*"
              }
            ></Form.Control>
          </Form.Group>
          <Form.Group as={Col} className="input-getInTouch">
            <Form.Control
              required
              name="email"
              id="email"
              type="email"
              value={email}
              onChange={handleInputChange}
              placeholder="Email *"
            ></Form.Control>
          </Form.Group>
          <Form.Group as={Col} className="input-getInTouch">
            <Form.Control
              name="phone"
              id="phone"
              value={phone}
              onChange={handleInputChange}
              placeholder={
                actualLang === "es" ? "Número de teléfono:" : "Phone number:"
              }
            ></Form.Control>
          </Form.Group>
          <Form.Group as={Col} className="input-getInTouch"></Form.Group>
          <Row className="m-0 mb-3 input-getInTouch pe-0 ps-0 message">
            <Col md={8}>
              <Form.Control
                className="ps-0 pe-0"
                id="message"
                name="message"
                as="textarea"
                value={message}
                onChange={handleInputChange}
                required
                rows={5}
                placeholder={actualLang === "es" ? "Mensaje:" : "Message:"}
              ></Form.Control>
            </Col>
            <Col lg={4}>
              <Button
                variant="primary"
                type="submit"
                value="Submit"
                className="w-100"
                disabled={isButtonDisabled}
              >
                {actualLang === "es" ? "Enviar" : "Send now"}
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Col>
  );
};

export default GetInTouchForm;
