import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";

export const JumboButton = ({ title, learnMore, href, hoverEffect }) => {
    const [isHover, setIsHover] = useState(false);
  
    return (
      <Col lg={6} id="jumboButtonContainer">
        <Button
          variant="jumbo-button"
          href={href ? `${href}` : ""}
          onMouseEnter={() => hoverEffect && setIsHover(true)}
          onMouseLeave={() => hoverEffect && setIsHover(false)}
        >
          <Row className="d-flex align-items-center justify-content-start gap-1">
            <div className={isHover ? "elipse yellow" : "elipse"}></div>
            <h4 className="jumbo-button">{title}</h4>
          </Row>
          {learnMore && (
            <Row className="d-grid justify-content-start">
              <Button variant="tertiary">
                {learnMore}
                <i class="fas fa-arrow-right"></i>
              </Button>
            </Row>
          )}
        </Button>
      </Col>
    );
  };