import React from "react";
import "./PildoraMS.css";

const PildoraMS = ({ children }) => (
  <p className="pildoraMS">
    <span>{children}</span>
  </p>
);

export default PildoraMS;
