import React, { useContext } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import Instagram from "../../assets/instagram.svg";
import Facebook from "../../assets/facebook.svg";
import Linkedin from "../../assets/linkedin.svg";
import Twitter from "../../assets/twitter.svg";
import "../../css/footerUsa.css";
import { ActualLangContext } from "../../contexts/ActualLangContext";

const FooterUsaDesk = ({ logoPossumus, logoIso, options }) => {
  let newDate = new Date();
  let year = newDate.getFullYear();
  const { actualLang } = useContext(ActualLangContext);
  return (
    <Container fluid id="footer">
      <Container style={{ padding: "0 50px" }}>
        <Row className="justify-content-center justify-content-md-around">
          {options?.map((option, idx) => {
            return (
              <Col className="p-0" key={idx}>
                <p className="titleFooter">{option.title}</p>
                {option.multipleOptions.data?.length > 0 &&
                  option.multipleOptions.data.map((multipleOption) => (
                    <p>
                      <a
                        href={
                          multipleOption.attributes.href.includes("https")
                            ? multipleOption.attributes.href
                            : `/${actualLang === "en" ? "en-en" : "es-es"}${
                                multipleOption.attributes.href
                              }`
                        }
                      >
                        {multipleOption.attributes.title}
                      </a>
                    </p>
                  ))}
              </Col>
            );
          })}
        </Row>
        <Row md={12} className="d-flex justify-content-center pt-5">
          <Col>
            <a href="/">
              <Image src={logoPossumus} alt="Possumus" fluid />
            </a>
          </Col>
          <Col className="justify-content-center d-flex align-items-center w-100">
            <div
              className="clutch-widget"
              data-url="https://widget.clutch.co"
              data-widget-type="2"
              data-height="45"
              data-nofollow="true"
              data-expandifr="true"
              data-primary-color="#fcd702"
              data-secondary-color="#fcd702"
              data-clutchcompany-id="1572791"
            ></div>
          </Col>
          <Col className="justify-content-center d-flex align-items-center">
            <Image src={logoIso} alt="Possumus" fluid />
          </Col>
          <Col className="justify-content-center d-flex flex-column align-items-end">
            <p className="titleFooter">
              {actualLang === "es" ? "Hablemos" : "Let's talk"}
            </p>
            <p>
              <a href="tel: 08103450562">0810 345 0562</a>
            </p>
            <p>
              <a href="mailto:info@possumus.tech">info@possumus.tech</a>
            </p>
          </Col>
        </Row>
      </Container>
      <Row className="disclaimer">
        <Col>
          <p>Copyright © {year} Possumus. All Rigths Reserved.</p>
        </Col>
        <Col className="redes">
          <ul>
            <li>
              <p>Join Us</p>
            </li>
            <li>
              <a
                href="https://www.instagram.com/possumus.tech/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={Instagram}
                  alt="Instagram"
                  className="img-fluid"
                ></img>
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/possumus.tech/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Facebook} alt="Facebook" className="img-fluid"></img>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/possumustech"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Linkedin} alt="Linkedin" className="img-fluid"></img>
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/PossumusTech"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Twitter} alt="Twitter" className="img-fluid"></img>
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default FooterUsaDesk;
