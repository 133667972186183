import React, { Fragment, useContext, useEffect } from "react";
import MenuUsa from "../components/commons/menuUsa";
import GetInTouch from "../components/commons/getInTouch";
import "../css/products.css";
import { LocationAddress } from "../components/usa/locationAddress";
import { Footer } from "../components/commons/footer";
import InfoList from "../components/microsoftEcosystem/InfoList";
import { DownloadBanner } from "../components/commons/downloadBanner";
import { ContentMicrosoftEcosystemProvider } from "../contexts/ContentMicrosoftEcosystemContext";
import Header from "../components/products/header";
import SimplePay from "../components/products/SimplePay";
import Molix from "../components/products/Molix";
// import Paax from "../components/products/Paax";
import { ContentProductsContext } from "../contexts/ContentProductsContext";
import withCircle from "../utils/WithCirlce";
import { ActualLangContext } from "../contexts/ActualLangContext";
import { useHistory } from "react-router-dom";
import SEOContent from "../components/commons/SEOContent";
import useScrollToSection from "../hooks/useScrollToSection";

const Products = () => {

  const { actualLang } = useContext(ActualLangContext);
  const { buttonsNavContent, SEOContent: SEOData } = useContext(ContentProductsContext);

  const history = useHistory();

   useScrollToSection()

  //Se pasa el compoente como parametro y se devuelve el mismo componente
  //pero con el circulo de fondo
  const InfoListWithCircle = withCircle(
    InfoList,
    { top: "-40%", right: "-25%" },
    {
      infoButtons: buttonsNavContent.buttons,
      title: buttonsNavContent.title,
      circle: true,
    }
  );

  useEffect(() => {
    if (actualLang === "es") {
      const newUrl = window.location.pathname.replace("products", "productos");
      history.push(newUrl);
    } else {
      const newUrl = window.location.pathname.replace("productos", "products");
      history.push(newUrl);
    }
  }, [actualLang, history]);
  return (
    <ContentMicrosoftEcosystemProvider>
      <Fragment>
          <SEOContent SEOData={SEOData}/>
          <MenuUsa />
          <Header />
          <InfoListWithCircle />
          <SimplePay />
          <Molix />
          {/* <Paax /> */}
          <DownloadBanner />
          <GetInTouch />
          <LocationAddress />
          <Footer />
      </Fragment>
    </ContentMicrosoftEcosystemProvider>
  );
};

export default Products;
