import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import isoKoiron from "../../assets/iso.svg";

const WhyPossumus = () => {
  return (
    <Container fluid id="whyKoiron">
      <Container>
        <Row className="justify-content-md-center text-center">
          <Col lg="8">
            <img src={isoKoiron} alt="Koiron" className="img-fluid iso"></img>
            <h2 class="border-bottom text-center">Why Possumus</h2>
            <h3>
              Because we are willing to grow, learn and explore possibilities by
              offering a wide range of job opportunities. We train our personnel
              so that, knowing their purpose, aiming at their improvement and
              through autonomy, they can take ownership of their talents and
              make the most of the development dimensions that we offer.​
            </h3>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default WhyPossumus;
