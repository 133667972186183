import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { DownloadFreeUsa } from "./downloadFreeUsa";
import { useTranslation } from "react-i18next";

export const DownloadBanner = ({ text, file, mockup }) => {
  const { i18n } = useTranslation();
  const [downloadDeck, setDownloadDeck] = useState();
  const [downloadMobile, setDownloadMobile] = useState();
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL_API}/page-usa`).then((res) => {
      setDownloadDeck(res.data.bannerDownload);
      setDownloadMobile(res.data.bannerDownload);
    });
  }, []);
  return (
    <>
      {downloadDeck?.imageDownload?.url && (
        <DownloadFreeUsa
          background={downloadDeck?.background?.url}
          backgroundMobile={downloadMobile?.backgroundMobile?.url}
          imageBanner={
            mockup
              ? mockup
              : i18n.language === "en"
              ? downloadDeck?.imageDownload.url
              : downloadDeck?.imageDownloadEs.url
          }
          brochureUrl={file && file}
          textBanner={text ? text : downloadDeck?.textBanner}
        />
      )}
    </>
  );
};
