import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import imgAboutBanner from "../../assets/ImgAboutBanner.svg";
import WeloveImg from "../../assets/logos/WLT.svg";
import { useTranslation } from "react-i18next";
import { bannerAboutUsSection } from "../../config/about/textPage";

const AboutBanner = () => {
  const { t } = useTranslation();

  return (
    <Container
      fluid
      id="mainbanner"
      className="about-banner background-banner-header"
    >
      <Container>
        <Row>
          <Col lg={8}>
            <h1>
              {t("bannerAboutUs.title", {
                title: `${bannerAboutUsSection.title}`,
              })}
            </h1>
            <h3>
              {t("bannerAboutUs.description1", {
                description1: `${bannerAboutUsSection.description1}`,
              })}
            </h3>
            <h3>
              {t("bannerAboutUs.description2", {
                description2: `${bannerAboutUsSection.description2}`,
              })}
            </h3>
          </Col>
          <Col lg={4}>
            <img
              src={imgAboutBanner}
              alt="Accelerate your digital transformation"
            ></img>
          </Col>
        </Row>
        <Row>
          <Col lg={6} sm={12}></Col>
          <Col lg={6} sm={12}>
            <img
              src={WeloveImg}
              className="img-right"
              alt="Possumus We love dev"
            ></img>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default AboutBanner;
