import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { createContext } from "react";
import { ActualLangContext } from "./ActualLangContext";
import axios from "axios";
import { AuthStrapiContext } from "./AuthStrapiV3Context";

export const ContentHomeContext = createContext({});

export const ContentHomeProvider = ({ children }) => {
  const { actualLang } = useContext(ActualLangContext);
  const { token } = useContext(AuthStrapiContext);

  const [loadedData, setLoadedData] = useState(false);
  const [SEOContent, setSEOContent] = useState({});
  const [headerContent, setHeaderContent] = useState({});
  const [trustedBy, setTrustedBy] = useState([]);
  const [ourServices, setOurServices] = useState({});

  useEffect(() => {
    setLoadedData(false);
    const url = `${process.env.REACT_APP_URL_API}/pages-home?_locale=${actualLang}`;
    if(token){
      axios
      .get(url, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setSEOContent(res.data?.SEOMetaData);
        setHeaderContent(res.data?.header);
        setTrustedBy(res.data?.TrustedBy);
        setOurServices(res.data?.OurServices);
        setLoadedData(true);
      }).catch(err => console.log(`Ocurrio un error al obtener los datos de la Home: ${err.message}`))
    }
  }, [actualLang, token]);
  return (
    <ContentHomeContext.Provider
      value={{
        loadedData,
        SEOContent,
        headerContent,
        trustedBy,
        ourServices
      }}
    >
      {children}
    </ContentHomeContext.Provider>
  );
};
