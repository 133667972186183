import React from "react";
import { Container, Image, Row } from "react-bootstrap";

const HighligthedAwards = ({ awards }) => {
  return (
    <Container id="highligthedContainer">
      <Row className="highligthedAwardsContainer">
        {awards?.map((award, idx) => {
          return (
            <div key={idx} className="award d-grid justify-items-center">
              <Image src={award.image.url} alt={award.award} width={130} />
              <p className="text-center">{award.award + ", " + award.scope}</p>
            </div>
          );
        })}
      </Row>
    </Container>
  );
};

export default HighligthedAwards;
