import { createContext, useContext, useEffect, useState } from "react";
import { ActualLangContext } from "./ActualLangContext";
import axios from "axios";
import { AuthStrapiContext } from "./AuthStrapiV3Context";

export const ContentProductsContext = createContext({});

export const ContentProductsProvider = ({ children }) => {
  const [SEOContent, setSEOContent] = useState({});
  const [headerContent, setHeaderContent] = useState({});
  const [buttonsNavContent, setButtonsNavContent] = useState({});
  const [simplePayContent, setSimplePayContent] = useState({});
  const [molixContent, setMolixContent] = useState({});
  const [paaxContent, setPaaxContent] = useState({});
  const [loadedData, setLoadedData] = useState(false);

  const { actualLang } = useContext(ActualLangContext);
  const { token } = useContext(AuthStrapiContext);

  useEffect(() => {
    setLoadedData(false);
    const url = `${process.env.REACT_APP_URL_API}/landing-productos?_locale=${actualLang}`;
    if (token) {
      axios
        .get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          setSEOContent(res.data?.SEOMetaData);
          setHeaderContent(res.data.Header[0]);
          setButtonsNavContent(res.data.ButtonsAndTitle[0]);
          setSimplePayContent(res.data.Section[0]);
          setMolixContent(res.data.Section[1]);
          setPaaxContent(res.data.Section[2]);
          setLoadedData(true);
        }).catch(err => console.log(`Ocurrio un error al traer la data para la landing de productos: ${err.message}`))
    }
  }, [actualLang, token]);
  return (
    <ContentProductsContext.Provider
      value={{
        loadedData,
        SEOContent,
        headerContent,
        buttonsNavContent,
        simplePayContent,
        molixContent,
        paaxContent,
      }}
    >
      {children}
    </ContentProductsContext.Provider>
  );
};
