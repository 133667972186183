import React, { useContext } from "react";
import { Container, Button, Col, Image, Row } from "react-bootstrap";
import { ContentProductsContext } from "../../contexts/ContentProductsContext";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import Loader from "../commons/loader";

const Molix = () => {
  const { molixContent } = useContext(ContentProductsContext);
  return (
    <>
      <Container fluid className="product-section" id="molix">
        <Row className="justify-content-md-center">
          <Col lg={10}>
            <h2 className="text-center mt-4">{molixContent.title}</h2>
          </Col>
        </Row>
        <Row className="justify-content-md-center text-center p-2">
          <Col lg={9}>
            <p className="subtitle">{molixContent.subtitle}</p>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col lg={4} xs={12} md={10} className="product-section-description">
            <p>
              <ReactMarkdown>{molixContent.description}</ReactMarkdown>
            </p>
          </Col>
          <Col lg={4} sm={12} className="d-flex justify-content-center">
            {molixContent?.images ? (
              <Image
                src={molixContent.images ? molixContent.images[0].url : ""}
                alt="Simple pay ilustration"
              />
            ) : (
              <Loader />
            )}
          </Col>
        </Row>
        <Row>
          <Col className="justify-content-center align-items-center d-flex mb-4">
            <Button
              variant="secondary"
              href={molixContent.downloadBrochure?.url}
              className="paddingButtonMainBanner"
              target="_blank"
            >
              {" "}
              {!molixContent?.buttonText ? <Loader /> : molixContent?.buttonText}
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Molix;
