import React from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import elipse from "../../../assets/Ellipse.svg";
import { useTranslation } from "react-i18next";
import { softwareEngineering } from "../../../config/softwareEngineering/textPage";
export const ProductEngineeringMobile = () => {
  const { t } = useTranslation();
  return (
    <div class="d-block d-sm-block d-lg-none">
      <Row className="justify-content-md-center text-left  mt-4">
        <Col sm={12} className="m-0 p-0">
          <Card bg="light" id="myCardMobile">
            <div>
              <Image
                src={elipse}
                alt=""
                className="marginElipseColumn1Mobile"
              />
            </div>
            <Card.Header className="titleCard mt-4">
              {t("sectionEngineering.titleCard1", {
                titleCard1: `${softwareEngineering.titleCard1Engineering}`,
              })}
            </Card.Header>
            <div className="lineCard">
              <hr className="m-0 p-0" />
            </div>
            <Card.Body className="pb-0">
              <Card.Text>
                {t("sectionEngineering.card1Paragraph", {
                  card1Paragraph: `${softwareEngineering.card1ParagraphEngineering}`,
                })}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} className="m-0 p-0">
          <Card bg="light" id="myCardMobile" className="spacing-card-mobile ">
            <div className="justify-content-center d-flex mt-3 mb-3">
              <Image
                src={elipse}
                alt=""
                className="marginElipseColumn1Mobile"
              />
            </div>
            <Card.Header className="titleCard mt-2">
              {t("sectionEngineering.titleCard2", {
                titleCard2: `${softwareEngineering.titleCard2Engineering}`,
              })}
            </Card.Header>
            <div className="lineCard">
              <hr className="m-0 p-0" />
            </div>
            <Card.Body className="pb-0">
              <Card.Text>
                {t("sectionEngineering.card2Paragraph", {
                  card2Paragraph: `${softwareEngineering.card2ParagraphEngineering}`,
                })}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} className="m-0 p-0">
          <Card bg="light" id="myCardMobile" className="spacing-card-mobile-2">
            <div>
              <Image
                src={elipse}
                alt=""
                className="marginElipseColumn1Mobile"
              />
            </div>
            <Card.Header className="titleCard mt-4">
              {t("sectionEngineering.titleCard3", {
                titleCard3: `${softwareEngineering.titleCard3Engineering}`,
              })}
            </Card.Header>
            <div className="lineCard">
              <hr className="m-0 p-0" />
            </div>
            <Card.Body className="pb-0">
              <Card.Text>
                {t("sectionEngineering.card3Paragraph", {
                  card3Paragraph: `${softwareEngineering.card3ParagraphEngineering}`,
                })}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
