import React from "react";
import { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import FormEvent from "./formEvent";

export const ModalDownloadFree = ({ imgModal, show, onHide, data,id }) => {
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const downloadFile = () => {
    let file = data;
    try {
      setDisabled(true);
      const link = document.createElement("a");
      link.href = file;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      setError("Ocurrio un error al momento de realizar la descarga.");
    }
  };
  return (
    <>
      {imgModal && (
        <Modal show={show} onHide={onHide} centered>
          <Modal.Header
            closeButton
            className="styleModalHeader"
            style={{
              backgroundImage: `url(${imgModal})`,
            }}
          >
            <Row className="marginForm">
              <Col>
                <h5 className="text-center titleForm text-white">
                  Llená el formulario y obtén la versión en PDF gratuita del
                  webinar.
                </h5>
              </Col>
            </Row>
          </Modal.Header>
          <FormEvent
            contactOrigin={"Quiero que me avisen"}
            getDataApi={downloadFile}
            setDisabled={setDisabled}
            disabled={disabled}
            error={error}
            setError={setError}
            id={id}
          />
        </Modal>
      )}
    </>
  );
};
