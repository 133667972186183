import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import imgMainBanner from "../../assets/mainBanner.svg";
import axios from "axios";
import { useEffect, useState } from "react";
import "../../css/homeUsa.css";
import { bannerText } from "../../config/home/textPage";
import { useTranslation } from "react-i18next";

const MainBannerUsaDesktop = () => {
  const { t, i18n } = useTranslation();
  const [backgroundHome, setbackgroundHome] = useState({});
  const [isoLogo, setIsoLogo] = useState({});
  const [wLDLogo, setWLDLogo] = useState({});
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL_API}/page-usa`).then((res) => {
      setbackgroundHome(res.data.element.backgroundHome.url);
      setIsoLogo(res.data.element.isoLogoBlack.url);
      setWLDLogo(res.data.element.logoWLD.url);
    });
  }, []);

  return (
    <>
      <Container
        fluid
        style={{ backgroundImage: `url(${backgroundHome})` }}
        className="d-none d-sm-none d-md-block imageBackGroundUsa"
      >
        <Container id="mainbannerUsa">
          <Row>
            <Col lg={6}>
              <h1>
                {t("mainbannerHome.title", {
                  title: `${bannerText.title}`,
                })}
              </h1>
              <h3 className="pb-2">
                {t("mainbannerHome.subtitle", {
                  subtitle: `${bannerText.subTitle}`,
                })}
              </h3>
              <div className="mt-4">
                <Button
                  variant="primary"
                  target="_self"
                  href={`/${
                    i18n.language === "en" ? "en-en" : "es-es"
                  }/contact`}
                  id="buttonHeaderConsul"
                >
                  {t("buttonsAndAccesories.consult", {
                    consult: `${bannerText.buttonConsult}`,
                  })}
                </Button>
                <Button
                  variant="secondary"
                  href="#services"
                  id="buttonHeaderService"
                >
                  {t("buttonsAndAccesories.ourServices", {
                    ourServices: `${bannerText.ourServices}`,
                  })}
                </Button>
              </div>
            </Col>
            <Col lg={6}>
              <img
                align="right"
                src={imgMainBanner}
                alt="Accelerate your digital transformation"
              ></img>
            </Col>
          </Row>
          <Row className="logoAndIso">
            <Col lg={6}>
              <img align="left" src={isoLogo} alt=""></img>
              <p className="marginLeft iso2015">
                {t("buttonsAndAccesories.certificatePart1", {
                  certificatePart1: `${bannerText.certificatePart1}`,
                })}
                <br></br>
                {t("buttonsAndAccesories.certificatePart2", {
                  certificatePart2: `${bannerText.certificatePart2}`,
                })}
              </p>
            </Col>
            <Col lg={6}>
              <img
                align="right"
                className="marginLeft"
                src={wLDLogo}
                alt=""
              ></img>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default MainBannerUsaDesktop;
