import React from "react";
import { Container, Button, Col, Image, Row } from "react-bootstrap";
import arrowDown from "../../assets/ArrowDown.svg";
import { sectionPlatform } from "../../config/sre/textPage";
import { useTranslation } from "react-i18next";
import { bannerDownload } from "../../config/Commons/textPage";
export const DevopsPlatform = () => {
  const { t } = useTranslation();
  return (
    <>
      <Container id="PlatformEngineering">
        <Row className="justify-content-md-center">
          <Col lg={10} className="justify-content-center d-flex">
            <Image src={arrowDown} alt="" />
          </Col>
          <Col lg={10}>
            <h2 className="text-center mt-4">
              {t("sectionPlatformSre.title", {
                title: `${sectionPlatform.sectionPlatformTitle}`,
              })}
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-md-center text-center p-2">
          <Col lg={9}>
            <h3>
              {t("sectionPlatformSre.paragraph", {
                paragraph: `${sectionPlatform.paragraphPlatform}`,
              })}
            </h3>
          </Col>
        </Row>
        <Row className="justify-content-md-center text-center row-list-devops">
          <Col lg={6}>
            <div className="list-onshore">
              <ul>
                <li>
                  {t("sectionPlatformSre.item1", {
                    item1: `${sectionPlatform.item1}`,
                  })}
                </li>
                <li>
                  {t("sectionPlatformSre.item2", {
                    item2: `${sectionPlatform.item2}`,
                    interpolation: { escapeValue: false },
                  })}
                </li>
                <li>
                  {t("sectionPlatformSre.item3", {
                    item3: `${sectionPlatform.item3}`,
                    interpolation: { escapeValue: false },
                  })}
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={6}>
            <div className="list-onshore margin-items">
              <ul>
                <li>
                  {" "}
                  {t("sectionPlatformSre.item4", {
                    item4: `${sectionPlatform.item4}`,
                    interpolation: { escapeValue: false },
                  })}
                </li>
                <li>
                  {t("sectionPlatformSre.item5", {
                    item5: `${sectionPlatform.item5}`,
                  })}
                </li>
                <li>
                  {t("sectionPlatformSre.item6", {
                    item6: `${sectionPlatform.item6}`,
                  })}
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="justify-content-center align-items-center d-flex  mt-4">
            <Button
              variant="secondary"
              href="#getInTouch"
              className="paddingButtonMainBanner"
            >
              {t("buttonsAndAccesories.buttonGetFree", {
                buttonGetFree: `${bannerDownload.buttonGetFree}`,
              })}
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};
