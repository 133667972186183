import React from "react";
import { Card, Col, Image, Row } from "react-bootstrap";
import elipse from "../../../assets/Ellipse.svg";
import { useTranslation } from "react-i18next";
import { sectionApproachesSre } from "../../../config/sre/textPage";
export const AppoachesMobile = () => {
  const { t } = useTranslation();
  return (
    <div class="d-block d-sm-block d-lg-none">
      <Row className="justify-content-md-center text-left  mt-4">
        <Col sm={12} className="m-0 p-0">
          <Card bg="light" id="myCardMobile">
            <div>
              <Image
                src={elipse}
                alt=""
                className="marginElipseColumn1Mobile"
              />
            </div>
            <Card.Header className="titleCard mt-4">
              {t("sectionApproachesSre.titleCard1", {
                titleCard1: `${sectionApproachesSre.titleCard1}`,
              })}
            </Card.Header>
            <div className="lineCard">
              <hr className="m-0 p-0" />
            </div>
            <Card.Body className="pb-0">
              <Card.Text>
                {t("sectionApproachesSre.card1Paragraph", {
                  card1Paragraph: `${sectionApproachesSre.card1Paragraph}`,
                })}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} className="m-0 p-0">
          <Card bg="light" id="myCardMobile" className="spacing-card-mobile">
            <div>
              <Image
                src={elipse}
                alt=""
                className="marginElipseColumn1Mobile"
              />
            </div>
            <Card.Header className="titleCard mt-4">
              {t("sectionApproachesSre.titleCard2", {
                titleCard2: `${sectionApproachesSre.titleCard2}`,
              })}
            </Card.Header>
            <div className="lineCard">
              <hr className="m-0 p-0" />
            </div>
            <Card.Body className="pb-0">
              <Card.Text>
                {t("sectionApproachesSre.card2Paragraph", {
                  card2Paragraph: `${sectionApproachesSre.card2Paragraph}`,
                })}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} className="m-0 p-0">
          <Card bg="light" id="myCardMobile" className="spacing-card-mobile-2">
            <div>
              <Image
                src={elipse}
                alt=""
                className="marginElipseColumn1Mobile"
              />
            </div>
            <Card.Header className="titleCard mt-4">
              {t("sectionApproachesSre.titleCard3", {
                titleCard3: `${sectionApproachesSre.titleCard3}`,
              })}
            </Card.Header>
            <div className="lineCard">
              <hr className="m-0 p-0" />
            </div>
            <Card.Body className="pb-0">
              <Card.Text>
                {t("sectionApproachesSre.card3Paragraph", {
                  card3Paragraph: `${sectionApproachesSre.card3Paragraph}`,
                })}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} className="m-0 p-0 cardEvolutionary">
          <Card bg="light" id="myCardMobile" className="spacing-card-mobile-3">
            <div>
              <Image
                src={elipse}
                alt=""
                className="marginElipseColumn1Mobile"
              />
            </div>
            <Card.Header className="titleCard mt-4">
              {t("sectionApproachesSre.titleCard4", {
                titleCard4: `${sectionApproachesSre.titleCard4}`,
              })}
            </Card.Header>
            <div className="lineCard">
              <hr className="m-0 p-0" />
            </div>
            <Card.Body className="pb-0">
              <Card.Text>
                {t("sectionApproachesSre.card4Paragraph", {
                  card4Paragraph: `${sectionApproachesSre.card4Paragraph}`,
                })}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
