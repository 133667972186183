import React, { Fragment, useContext } from "react";
import MenuUsa from "../components/commons/menuUsa";
import GetInTouch from "../components/commons/getInTouch";
import "../css/MicrosoftEcosystem.css";
import { LocationAddress } from "../components/usa/locationAddress";
import { Footer } from "../components/commons/footer";
import MSEBanner from "../components/microsoftEcosystem/MSEBanner";
import InfoList from "../components/microsoftEcosystem/InfoList";
import MSEIntroduction from "../components/microsoftEcosystem/MSEIntroduction";
import MSESolutions from "../components/microsoftEcosystem/MSESolutions";
import ApproachesAndMethodologies from "../components/microsoftEcosystem/ApproachesAndMethodologies";
import { DownloadBanner } from "../components/commons/downloadBanner";
import { ContentMicrosoftEcosystemContext } from "../contexts/ContentMicrosoftEcosystemContext";
import withCircle from "../utils/WithCirlce";
import SEOContent from "../components/commons/SEOContent";
import useScrollToSection from "../hooks/useScrollToSection";

const MicrosoftEcosystem = () => {
  const { infoButtons, SEOContent: SEOData } = useContext(ContentMicrosoftEcosystemContext);
  const MSEIntroductionWithCircle = withCircle(MSEIntroduction, {top: "10%", right: "-32%"})
   useScrollToSection()
  return (
      <Fragment>
          <SEOContent SEOData={SEOData}/>
          <MenuUsa />
          <MSEBanner />
          <InfoList data={{infoButtons}}/>
          <MSEIntroductionWithCircle />
          <MSESolutions />
          <ApproachesAndMethodologies />
          <DownloadBanner />
          <GetInTouch />
          <LocationAddress />
          <Footer />
      </Fragment>
  );
};

export default MicrosoftEcosystem;
