import OurTeam from "../../components/houston/OurTeam";
import withCircle from "../../utils/WithCirlce";
import OurServices from "../../components/houston/OurServices";
import { Col } from "react-bootstrap";
import SideNavbar from "../../components/commons/SideNavbar";
import {
  SOFTWARE_ENGINEERING,
  SIDENAVBAR_ITEMS,
  TITLEANDBULLETSSECTION,
  ourTeamPpalContent,
  OUR_SERVICES,
  SERVICES,
  FOOTER_TEXT,
  MEMBERS,
} from "./contentES";
import SoftwareEngineering from "../../components/houston/SoftwareEngineering";
import StaffAugmentation from "../../components/houston/StaffAugmentation";
import TitleAndBullets from "../../components/commons/TitleAndBullets";
import Services from "../../components/houston/Services";
import Partners from "../../components/home/ourPartners";
import Awards from "../../components/commons/Awards";
import { DownloadBanner } from "../../components/commons/downloadBanner";
import Footer from "../../components/houston/Footer";
import PpalHeader from "../../components/houston/PpalHeader";
import { SideNavbarProvider } from "../../contexts/SideNavbarContext";
import brochureFile from "../../assets/landings/houston/brochureES.pdf";
import brochureMockup from "../../assets/landings/houston/nearshoreBrochureMockup.png";
import backgroundHeader from "../../assets/landings/houston/HoustonEsBack.webp";
import { useContext, useEffect } from "react";
import { ActualLangContext } from "../../contexts/ActualLangContext";
import "../../css/houston.css";
import HeaderIlustration from "./HeaderIlustration";
import GetInTouch from "../../components/commons/getInTouch";
import { Helmet, HelmetProvider } from "react-helmet-async";

const NearshoreES = () => {
  const infoMember = MEMBERS.filter(
    (member) => member.name === "Marisa Vaira"
  )[0];

  const OurServicesWithCircle = withCircle(
    OurServices,
    {
      top: "-40%",
      right: "-25%",
    },
    OUR_SERVICES
  );

  const { setLang } = useContext(ActualLangContext);
  useEffect(() => setLang("es"), [setLang]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Possumus | Nearshore latam ES</title>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-4MTPZV4RXQ"
          ></script>
          <script>{`window.dataLayer = window.dataLayer || [];   function gtag(){dataLayer.push(arguments);}   gtag('js', new Date());   gtag('config', 'G-4MTPZV4RXQ');`}</script>
        </Helmet>
        <div
          id="houstonPpal"
          style={{
            position: "relative",
            zIndex: "999999",
            backgroundColor: "#fff",
          }}
        >
          <PpalHeader
            text={{
              titulo: "Escale hacia nuevas metas",
              bajada: "Desarrollo IT de escalabilidad para productos",
              iso1: "Nuestros procesos están acreditados por la norma ISO 9001:2015",
              iso2: "prácticas de certificación de calidad para el desarrollo de productos de software.",
              button: "Nuestros servicios",
              bajadas: ["productos", "negocios", "mercados"],
            }}
            backgroundImage={backgroundHeader}
            Ilustration={HeaderIlustration}
          />
          <OurTeam data={ourTeamPpalContent} />
          <OurServicesWithCircle />
        </div>
        <h2 id="houstonSoftwareEngineering" className="text-center">
          Ingeniería de Software
        </h2>
        <Col xl={{ span: 7, offset: 4 }} className="sideNavbarContainerHouston">
          <SideNavbarProvider>
            <SideNavbar items={SIDENAVBAR_ITEMS} />
            <SoftwareEngineering data={SOFTWARE_ENGINEERING} />
            <StaffAugmentation id={0} />
            {TITLEANDBULLETSSECTION.map((section, idx) => (
              <TitleAndBullets data={section} id={idx + 1} key={idx} />
            ))}
          </SideNavbarProvider>
        </Col>
        <div
          style={{
            position: "relative",
            zIndex: "99999",
            backgroundColor: "#fff",
          }}
        >
          <Services data={SERVICES} />
          <Partners />
          <Awards />
          <GetInTouch
            title="Detalles de contacto"
            personal={{
              nombre_apellido: infoMember.name,
              puesto: infoMember.charge,
              Image_personal: { url: infoMember.image },
            }}
          />
          <DownloadBanner
            text="Descubre nuestros servicios"
            file={brochureFile}
            mockup={brochureMockup}
          />
          <Footer text={FOOTER_TEXT} />
        </div>
      </HelmetProvider>
    </>
  );
};

export default NearshoreES;
