import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import imgMainBanner from "../../assets/mainBanner.svg";
import axios from "axios";
import { useEffect, useState } from "react";
import "../../css/homeUsa.css";
import { bannerText } from "../../config/home/textPage";
import { useTranslation } from "react-i18next";

const MainBannerUsaMobile = () => {
  const { t, i18n } = useTranslation();
  const [backgroundHomeMobile, setbackgroundHomeMobile] = useState({});
  const [isoLogo, setIsoLogo] = useState({});
  const [wLDLogo, setWLDLogo] = useState({});
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL_API}/page-usa`).then((res) => {
      setIsoLogo(res.data.element.isoLogoBlack.url);
      setWLDLogo(res.data.element.logoWLD.url);
      setbackgroundHomeMobile(res.data.element.backgroundHomeMobile.url);
    });
  }, []);

  return (
    <>
      <Container
        fluid
        className="d-block d-sm-block d-md-none backGroundHomeMobile"
        style={{
          backgroundImage: `url(${backgroundHomeMobile})`,
        }}
      >
        <Container id="homeMobile">
          <Row className="textHomeMobile">
            <Col lg={8} className="justify-content-center">
              <h1>
                {t("mainbannerHome.title", {
                  title: `${bannerText.title}`,
                })}
              </h1>
              <h3 className="marginTop50">
                {t("mainbannerHome.subtitle", {
                  subtitle: `${bannerText.subTitle}`,
                })}
              </h3>
              <div>
                <Button
                  className="marginTop50"
                  variant="primary"
                  target="_self"
                  href={`/${
                    i18n.language === "en" ? "en-en" : "es-es"
                  }/contact`}
                  id="buttonHeaderConsul"
                >
                  {t("buttonsAndAccesories.consult", {
                    consult: `${bannerText.buttonConsult}`,
                  })}
                </Button>
                <Button
                
                  variant="secondary"
                  href="#service"
                  id="buttonHeaderService"
                >
                  {t("buttonsAndAccesories.ourServices", {
                    ourServices: `${bannerText.ourServices}`,
                  })}
                </Button>
              </div>
            </Col>
            <Col lg={4}>
              <img
                className="width70"
                src={imgMainBanner}
                alt="Accelerate your digital transformation"
              ></img>
            </Col>
          </Row>
          <Row className="textHomeMobile">
            <Col lg={12}>
              <img className="logoAndIsoMobile" src={isoLogo} alt=""></img>
            </Col>
            <Col lg={12}>
              <p className="iso2015 marginTop24">
                {t("buttonsAndAccesories.certificatePart1", {
                  certificatePart1: `${bannerText.certificatePart1}`,
                })}{" "}
                <br></br>
                {t("buttonsAndAccesories.certificatePart2", {
                  certificatePart2: `${bannerText.certificatePart2}`,
                })}
              </p>
            </Col>
            <Col lg={12}>
              <img className="marginLeftMobile" src={wLDLogo} alt=""></img>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default MainBannerUsaMobile;
