import React from "react";
import { Col, Container, Row, Card } from "react-bootstrap";
import { infoBenefits, infoBenefitsEn } from "./infoDataBenefits";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useTranslation } from "react-i18next";
import { exclusiveBenefits } from "../../config/culture/textPage";
export const ExclusiveBenefits = () => {
  const { t, i18n } = useTranslation();
  return (
    <div id="exclusiveBenefits">
      <Container fluid className="background-banner-header containerBene">
        <Row className="justify-content-center align-items-center d-flex titleBenefits">
          <h2 className="text-center mt-4 titlePossumusCulture">
            {t("exclusiveBenefits.title", {
              title: `${exclusiveBenefits.title}`,
            })}
          </h2>
        </Row>
      </Container>
      <ResponsiveMasonry
        className="responsiveMasonry col-xl-9 m-auto"
        columnsCountBreakPoints={{ 350: 1, 750: 2, 1280: 3 }}
      >
        <Masonry columnsCount={3} gutter="15px" className="widthMasonry">
          {i18n.language === "en"
            ? infoBenefitsEn.map((info, index) => (
                <Card className="containerCard" key={index}>
                  <Card.Body>
                    <Card.Title>
                      <img
                        src={info.logo}
                        className="img-fluid float-left m-0"
                        alt="logoIcon"
                      />
                    </Card.Title>
                    <Card.Subtitle className="mb-2  cardTitleBenefits">
                      {info.title}
                    </Card.Subtitle>
                    {info?.item?.map((i, index) => (
                      <Row key={index}>
                        <Col>
                          <Card.Text className="cardTitleBenefitsItemsChild">
                            {i.item1}
                          </Card.Text>
                          <Card.Text className="cardTitleBenefitsItems">
                            {i.item2}
                          </Card.Text>
                          <Card.Text className="cardTitleBenefitsItems">
                            {i.item3}
                          </Card.Text>
                        </Col>
                      </Row>
                    ))}
                    <Card.Text className="cardTextBenefits">
                      {info.paragraph}
                    </Card.Text>
                  </Card.Body>
                </Card>
              ))
            : infoBenefits.map((info, index) => (
                <Card className="containerCard" key={index}>
                  <Card.Body>
                    <Card.Title>
                      <img
                        src={info.logo}
                        className="img-fluid float-left m-0"
                        alt="logoIcon"
                      />
                    </Card.Title>
                    <Card.Subtitle className="mb-2  cardTitleBenefits">
                      {info.title}
                    </Card.Subtitle>
                    {info?.item?.map((i, index) => (
                      <Row key={index}>
                        <Col>
                          <Card.Text className="cardTitleBenefitsItemsChild">
                            {i.item1}
                          </Card.Text>
                          <Card.Text className="cardTitleBenefitsItems">
                            {i.item2}
                          </Card.Text>
                          <Card.Text className="cardTitleBenefitsItems">
                            {i.item3}
                          </Card.Text>
                        </Col>
                      </Row>
                    ))}
                    <Card.Text className="cardTextBenefits">
                      {info.paragraph}
                    </Card.Text>
                  </Card.Body>
                </Card>
              ))}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
};
