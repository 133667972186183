export const softwareEngineering = {
  title: "What we do",
  subTitle: "Software Engineering",
  description1: `We focus on software development through dynamic and secure processes. We deliver high-value products that have a positive impact on people’s experiences.`,
  description2: `We can turn your idea, prototype, or product into highly valuable assets for your company and of interest to users.`,
  description3: `We maintain a dynamic of evolution, measurement, and analysis to innovate and keep your product active and interesting by continuously reinventing it.`,
  titleBannerPart1: "Iterative software product",
  titleBannerPart2: "development for tech projects",
  cardDeskOnePart1: "Research",
  cardDeskOnePart2: "engineering",
  cardDeskTwoPart1: "Product",
  cardDeskTwoPart2: "engineering",
  cardDeskThreePart1: "Iterative",
  cardDeskThreePart2: "development",
  titleSectionOne: "Research engineering",
  titleSectionTwo: "Product engineering",
  titleSectionThree: "Iterative development",
  sectionResearchTitle: "Research engineering",
  paragraphResearch: `To begin the process of product discovery or refinement, we start from an idea. In this stage, we conduct early validations on hypotheses, objectives, potential users, and determine the precise product to develop.`,
  titleCard1Research: "Product discovery",
  card1ParagraphResearch: `Research process to create a new product, in which we develop several dynamics of vision validation, audience testing, user journeys, features brainstorming, among others.`,
  titleCard2Research: "Product refinement",
  card2ParagraphResearch:
    "We apply the best practices of approach validation and product steering on a current product, using tools and dynamics that provide the correct information, reducing risks and optimizing subsequent development steps.",

  sectionEngineeringTitle: "Product engineering",
  paragraphEngineering: `We bring products to life through initial development, activation of functionalities, control and analysis of the experience, engaging in the following dynamics:`,
  titleCard1Engineering: "Prototyping",
  card1ParagraphEngineering: `The usable representation of user interfaces, navigation flows, and product functionalities confirms the direction and serves as an initial guide in the project development.`,
  titleCard2Engineering: "Proof of Concept (PoC)",
  card2ParagraphEngineering: `Through the PoC, we confirm the technical feasibility of the project, providing data from the experience to the functionalities of the product.`,
  titleCard3Engineering: "Minimum Viable Product (MVP)",
  card3ParagraphEngineering: `It is a product with enough functionalities to be launched, so it can be used to clarify the potential of the functionalities, based on the experience of the first users.`,

  sectionIterativeTitle: "Iterative development",
  paragraphIterative: `Our engineering teams work with Agile processes that help provide the best and most efficient continuous deliveries.`,
  titleCard1Iterative: "Dedicated Team",
  card1ParagraphIterative: `We assemble an exclusive team of specialized professionals tailored to the project’s requirements. They provide specific responses in each phase of the product development, based on the iterative process.`,
  titleCard2Iterative: "Evolutionary Maintenance",
  card2ParagraphIterative: `With a focus on evaluating, maintaining, updating, and evolving each product, we carry out improvement processes based on constant innovation and the pragmatism of working with agile methodologies.`,
};
