import { Fragment } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loader from "../commons/loader";
import { bannerDownload } from "../../config/Commons/textPage";
import { useContext } from "react";
import { ContentSimplePayLandingContext } from "../../contexts/ContentSimplePayLandingContext";
import WeloveImg from "../../assets/logos/WLT.svg";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

const Header = () => {
  const { t } = useTranslation();

  const { headerContent } = useContext(ContentSimplePayLandingContext);
  return (
    <Fragment>
      <Container fluid id="mainbanner-it" className="simple-pay background-banner-header">
        <Col md={{offset: 4}}>

          <Row className="d-flex">
            <Col lg={7} xs={12} md={10}>
              <h2 className="sup-title text-uppercase">
                {headerContent.Subtitulo}
              </h2>
              <h1>{headerContent.Titulo}</h1>
              <p className="subtitle"><ReactMarkdown>{headerContent.Descripcion}</ReactMarkdown></p>
              <div className="cta">
                <Button variant="primary" href="#getInTouch">
                  {t("buttonsAndAccesories.buttonGetFree", {
                    buttonGetFree: `${bannerDownload.buttonGetFree}`,
                  })}
                </Button>
              </div>
            </Col>
            <Col lg={5} sm={12}>
              {headerContent.Imagen?.url ? (
                <img
                  src={headerContent.Imagen?.url}
                  className="img-center"
                  alt="Accelerate your digital transformation"
                />
              ) : (
                <Loader />
              )}
            </Col>
          </Row>
          <Image className="img-right" src={WeloveImg} alt="We love dev" />
        </Col>
      </Container>
    </Fragment>
  );
};

export default Header;
