import { useParams } from "react-router-dom";

export default function useScrollToSection() {
  //En caso de recibir una seccion por parametros, redirecciona a dicha seccion
  const { section } = useParams();

  if (section) {
    setTimeout(()=>{
        window.location.hash = "#" + section;
        window.location.pathname.replace(section, " ");
    },2000)
  }

  return section
}
