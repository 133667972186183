import { Button, Col, Container, Row } from "react-bootstrap";
import "../../css/joinUs.css";
import { useTranslation } from "react-i18next";
import { careerJoinCulture } from "../../config/culture/textPage";
import { bannerCareers } from "../../config/careers/textPage";
const CareerJoinCulture = () => {
  const { t } = useTranslation();
  return (
    <>
      <Container fluid id="joinUs">
        <Container className="joinMargin">
          <Row
            id="softwareEngineering"
            className="justify-content-md-left mobile"
          >
            <Col className="col-lg-8">
              <p className="text-uppercase text-left text-white spacing">
                {t("bannerCareers.title", {
                  title: `${bannerCareers.title} `,
                })}
              </p>
              <h4 className="text-left mb-5 text-white careersDescription">
                {t("careerJoinCulture.description", {
                  description: `${careerJoinCulture.description} `,
                })}
              </h4>
            </Col>
          </Row>
          <Row>
            <Col lg={8} className="mobile" id="homeMobile">
              <Button
                target="blank"
                variant="primary"
                href="https://careers.possumus.tech/jobs"
              >
                {t("buttonsAndAccesories.buttonJob", {
                  buttonJob: `${bannerCareers.buttonJob} `,
                })}
              </Button>
              <Button
                variant="secondary"
                href="mailto:jobs@possumus.tech"
                id="buttonJoin"
              >
                {t("buttonsAndAccesories.buttonSendResume", {
                  buttonSendResume: `${bannerCareers.buttonSendResume} `,
                })}
              </Button>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default CareerJoinCulture;
