import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import imgMain from "../../../assets/toyIS.svg";
import { useTranslation } from "react-i18next";
import { softwareEngineering } from "../../../config/softwareEngineering/textPage";
import { bannerDownload } from "../../../config/Commons/textPage";
import IsoCertificateAndWeLoveDev from "../../commons/IsoCertificateAndWeLoveDev";
export const BannerDesk = ({ titulo, bajada }) => {
  const { t } = useTranslation();
  return (
    <Container
      fluid
      className="d-none d-sm-none d-md-none d-lg-block imageBackGroundUsa background-banner-header"
    >
      <Container id="mainbanner">
        <Row>
          <Col lg={7}>
            <h1>
              {titulo ? (
                titulo
              ) : (
                <>
                  {t("softwareEngineering.titleBannerPart1", {
                    titleBannerPart1: `${softwareEngineering.titleBannerPart1}`,
                  })}
                  <br></br>
                  {t("softwareEngineering.titleBannerPart2", {
                    titleBannerPart2: `${softwareEngineering.titleBannerPart2}`,
                  })}
                </>
              )}
            </h1>
            <h3>
              {bajada
                ? bajada
                : t("softwareEngineering.description1", {
                    description1: `${softwareEngineering.description1}`,
                  })}
            </h3>
            <div className="cta">
              <Button variant="primary" href="#getInTouch">
                {t("buttonsAndAccesories.buttonGetFree", {
                  buttonGetFree: `${bannerDownload.buttonGetFree}`,
                })}
              </Button>
            </div>
          </Col>
          <Col lg={5}>
            <img
              align="right"
              src={imgMain}
              className="img-fluid"
              alt="Accelerate your digital transformation"
            ></img>
          </Col>
        </Row>
        <IsoCertificateAndWeLoveDev />
      </Container>
    </Container>
  );
};
