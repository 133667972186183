import React from "react";
import WhatWeDoDesktop from "./whatWeDoDesktop";
import WhatWeDoMobile from "./whatWeDoMobile";

const WhatWeDo = () => {
  return (
    <>
      <WhatWeDoDesktop />
      <WhatWeDoMobile />
    </>
  );
};

export default WhatWeDo;
