import { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ContentSimplePayLandingContext } from "../../contexts/ContentSimplePayLandingContext";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

const Modalities = () => {
  const { modalities } = useContext(ContentSimplePayLandingContext);

  return (
    <Container fluid id="simplePayModalities">
      <Col md={{offset: 4}} className="d-flex">
        <Row className="d-grid justify-items-center">
          <h5>{modalities.Titulo}</h5>
          {modalities.Seccion?.map((section, idx) => (
            <>
              <Row
                className={
                  idx % 2 !== 0
                    ? "d-flex align-items-center service"
                    : "d-flex align-items-center service flex-row-reverse"
                }
              >
                <Col>
                  <h6
                    className="sup-title text-uppercase"
                  >
                    {section.Titulo}
                  </h6>
                  <h5>{section.Subtitulo}</h5>
                  <p className="subtitle">
                    <ReactMarkdown>{section.Descripcion}</ReactMarkdown>
                  </p>
                </Col>
              </Row>
              {idx % 2 === 0 && <span id="line" className="service"></span>}
            </>
          ))}
        </Row>
      </Col>
    </Container>
  );
};

export default Modalities;
