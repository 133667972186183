import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Benefits = () => {
  return (
    <Container fluid id="benefits">
      <Container>
        <Row>
          <h4>Benefits</h4>
        </Row>
        <Row className="contentBenefits">
          <Col lg={3}>
            <h5>
              <span>English Training</span>
            </h5>
            <p>
              We offer online classes to improve our employee's communication
              skills in English. That's why we have different programs align
              with each English level.
            </p>
          </Col>
          <Col lg={3}>
            <h5>
              <span>Professional Training</span>
            </h5>
            <p>
              We grant access to trainings and certifications on Microsoft, Red
              Hat, Udemy, among others platforms.
            </p>
          </Col>
          <Col lg={3}>
            <h5>
              <span>Flexibility</span>
            </h5>
            <p>
              It matters to us that you have a balance between your work and
              personal life, so we offer flexibility regarding your work
              schedule.
            </p>
          </Col>
          <Col lg={3}>
            <h5>
              <span>Referral Programs</span>
            </h5>
            <p>
              To continue creating excellent work teams, we invite you to
              participate in the program by referring your friends interested in
              our open positions. We offer a bonus for referrals hired.
            </p>
          </Col>
        </Row>
        <Row className="contentBenefits">
          <Col lg={3}>
            <h5>
              <span>Days Off</span>
            </h5>
            <p>We offer 5 extra business days off </p>
          </Col>
          <Col lg={3}>
            <h5>
              <span>Health Insurance</span>
            </h5>
            <p>
              We provide SWISS MEDICAL insurance coverage (Plan SMG 20) for you
              and your primary family group
            </p>
          </Col>
          <Col lg={3}>
            <h5>
              <span>Remote Work</span>
            </h5>
            <p>We offer 100% remote working hours</p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Benefits;
