import { Helmet, HelmetProvider } from "react-helmet-async";
import Footer from "../../components/houston/Footer";
import HoustonNavbar from "../../components/houston/Navbar";
import Banner from "../../components/landings/usa/Banner";
import { Col, Container, Row } from "react-bootstrap";
import Form from "../../components/landings/usa/Form";
import WeSpecialize from "../../components/landings/usa/WeSpecialize";
import withCircle from "../../utils/WithCirlce";
import Separator from "../../components/landings/usa/Separator";
import separatorBackground from "../../assets/landings/houston/BannerBackground.png";
import PreFooter from "../../components/landings/usa/PreFooter";
import Chatbot from "../../components/commons/chatbot/Chatbot";
import SolutionsPartnerMSE from "../../components/landings/usa/SolutionsPartnerMSE/SolutionsPartnerMSE";

export default function AISoftwareSolutions() {
  const FOOTER_TEXT = {
    innovate: "Let’s innovate together",
    contact: {
      title: "Get in touch!",
      button: "Contact us",
      tel: "+1 608 423 6916",
    },
    redes: {
      title: "Join Us",
    },
  };

  const WeSpecializeWithCircle = withCircle(WeSpecialize, {
    top: "-60%",
    right: "-120%",
  });
  const SeparatorWithCircle = withCircle(
    Separator,
    { top: "-120%", right: "80%" },
    { background: separatorBackground }
  );

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Possumus | AI Software Solutions</title>
        </Helmet>
        <>
          <HoustonNavbar
            transparent
            fixed={false}
            logoBlack={false}
            buttons={[
              {
                label: "0810 345 0562",
                variant: "quaternary",
                href: "tel: 08103450562",
              },
            ]}
          />
          <Chatbot>
            <iframe
              title="chatbot"
              src="
https://web.powerva.microsoft.com/environments/2b019365-2459-477a-b073-02ff853c1f19/bots/crc73_copilotSebastian/webchat?__version__=2"
              frameborder="0"
            ></iframe>
          </Chatbot>
          <Container fluid className="p-0" style={{ position: "relative" }}>
            <Container className="p-0" id="USAbannerContainer">
              <Row className="gap-5">
                <Col md={6}>
                  <Banner />
                  {/* <Form className="d-lg-none" /> */}
                  <WeSpecializeWithCircle />
                </Col>
                <Form className="d-none d-md-block" />
              </Row>
            </Container>
          </Container>
          <SeparatorWithCircle />
          <SolutionsPartnerMSE />
          <PreFooter />
          <Footer text={FOOTER_TEXT} />
        </>
      </HelmetProvider>
    </>
  );
}
