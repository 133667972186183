import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AppoachesMain } from "./appoaches/appoachesMain";
import { ContentMicrosoftEcosystemContext } from "../../contexts/ContentMicrosoftEcosystemContext";

const ApproachesAndMethodologies = () => {

  const {approachesAndMethodologiesContent} = useContext(ContentMicrosoftEcosystemContext)

  return (
    <Container fluid id="approaches" className="no-padding">
        <Row className="justify-content-md-center">
          <Col lg={12}>
            <h2 className="text-center">{approachesAndMethodologiesContent.Title}</h2>
          </Col>
        </Row>
        <Row className="justify-content-md-center text-center spacingSectionSoftwareEngineering mt-2">
          <Col lg={10}>
            <p className="subtitle">
              {approachesAndMethodologiesContent.Subtitle}
            </p>
          </Col>
        </Row>
        <AppoachesMain />
    </Container>
  );
};

export default ApproachesAndMethodologies;
