import { Container, Row, Col, Image, Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { bannerCulture } from "../../config/culture/textPage";
import React from "react";
export const BannerCultureDesk = ({
  description,
  title,
  imgBanner,
  template,
  idContainer,
  idHeader,
  logoPossumus,
  imagePeople,
  titleLandingPart1,
  titleLandingPart2,
}) => {
  const { t } = useTranslation();
  return (
    <Container
      fluid
      id={idContainer}
      style={{
        backgroundImage: `url(${imgBanner})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className={`${template} d-none d-sm-none d-md-block`}
    >
      <Container>
        <Row className="containerBannerCultureDesk">
          <Col id={idHeader} lg={6} md={7} xl={6} className="containerTop">
            <h4 className="titlePage">
              {t("headerBannerCulture.title", {
                title: `${title ? title : ""}`,
              })}
            </h4>
            <div className="divTitles">
              <h2>
                {t("headerBannerCulture.titleLandingPart1", {
                  titleLandingPart1: `${titleLandingPart1 ? titleLandingPart1 : ""}`,
                })}
              </h2>
              <h2>
                {t("headerBannerCulture.titleLandingPart2", {
                  titleLandingPart2: `${titleLandingPart2 ? titleLandingPart2 : ""}`,
                })}
              </h2>
            </div>
            {description && (
              <p className="descriptionText">
                {t("headerBannerCulture.description", {
                  description: `${description}`,
                })}
              </p>
            )}
          </Col>

          <Col id={idHeader} lg={5} md={5} xl={4}>
            {
              imagePeople ? (
                <Image
                  align="right"
                  src={imagePeople?.url}
                  alt="people"
                  className="img-fluid"
                />
              )
                :
                <Spinner />
            }

          </Col>
        </Row>
        <Row>
          <Col lg={6}></Col>
          
            {
              logoPossumus ? (
                <Col lg={6}>
                <img
                  align="right"
                  className="logoPossumusBanner"
                  src={logoPossumus?.url}
                  alt="logoPossumus"
                />
                </Col>
              )
              :
              <Col lg={6} className="img-culture-loader"></Col>
            }
        </Row>
      </Container>

      <Container>
        <Row className="justify-content-md-center text-center spacingSectionSoftwareEngineering">
          <Col lg={9} xl={9}>
            <h3 className="descriptionRowPossumus">
              {t("headerBannerCulture.descriptionPossumus1", {
                descriptionPossumus1: `${bannerCulture.descriptionPossumus1}`,
              })}
            </h3>
          </Col>
          <Col lg={9} xl={9}>
            <h3 className="descriptionRowPossumus">
              <Col>
                <h3 className="descriptionRowPossumus mt-3">
                  {t("headerBannerCulture.descriptionPossumus21", {
                    descriptionPossumus21: `${bannerCulture.descriptionPossumus21}`,
                  })}
                  <u>
                    <a
                      href="#exclusiveBenefits"
                      target="_self"
                      className="colorTextLinkCulture"
                    >
                      {t("headerBannerCulture.descriptionPossumus2u1", {
                        descriptionPossumus2u1: `${bannerCulture.descriptionPossumus2u1}`,
                      })}
                    </a>
                  </u>
                  {t("headerBannerCulture.descriptionPossumus22", {
                    descriptionPossumus22: `${bannerCulture.descriptionPossumus22}`,
                  })}
                  <a
                    href="https://careers.possumus.tech/jobs"
                    className="colorTextLink2Culture"
                    target="_parent"
                  >
                    <u>
                      {t("headerBannerCulture.buttonSearches", {
                        buttonSearches: `${bannerCulture.buttonSearches}`,
                      })}
                    </u>
                  </a>
                  .
                </h3>
              </Col>
            </h3>
          </Col>
        </Row>
        <Row>
          <Col className="justify-content-center align-items-center d-flex ">
            <Button
              variant="primary"
              href="#exclusiveBenefits"
              className="buttonCulture"
              target="_self"
            >
              {t("headerBannerCulture.descriptionPossumus2u1", {
                descriptionPossumus2u1: `${bannerCulture.descriptionPossumus2u1}`,
              })}
            </Button>

            <Button
              variant="secondary"
              href="https://careers.possumus.tech/jobs"
              className="buttonCulture"
            >
              {t("headerBannerCulture.buttonSearches", {
                buttonSearches: `${bannerCulture.buttonSearches}`,
              })}
            </Button>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
