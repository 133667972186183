import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

export const DetailsDescription = ({
  descriptionParagraph2,
  description,
  exposes,
  imgSpeakerOne,
  imgSpeakerTwo,
  sticker,
  bannerImg,
  bannerText,
  speakerOnePosition,
  speakerTwoPosition,
  speakerOne,
  colorText,
  company,
  speakerTwo,
}) => {
  return (
    <>
      <Container fluid id="detailsLandingSolidGeneric">
        <Container>
          <Row className="textLandingGeneric justify-content-center">
            <Col
              md={6}
              sm={12}
              xxl={5}
              id="desc"
              className="styleColParticipants"
            >
              <ReactMarkdown>{description}</ReactMarkdown>
              <ReactMarkdown>{descriptionParagraph2}</ReactMarkdown>
            </Col>

            <Col md={6} sm={12} className="hideimageStickerParticipant">
              <div>
                <img src={sticker} className="img-fluid" alt="sticker" />
              </div>
            </Col>

            <Col md={6} sm={12} className={`d-sm-block d-none`}>
              <div className="styleImageStickerParticipants">
                <img src={sticker} className="img-fluid" alt="sticker" />
              </div>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container
        id="titleParticipantsDiscovery"
        className="d-none d-sm-none d-md-block"
      >
        <Row className="titleExposes">
          <Col lg={6}>
            <h2>{exposes}</h2>
          </Col>
        </Row>
        <Row className="textLandingGeneric styleExposes">
          <Col md={6} sm={12} xxl={5} id="desc">
            <Row className="rowSpeaker">
              <Col md={3} className="parti-center">
                <img
                  src={imgSpeakerOne}
                  className="img-fluid"
                  alt="speakerOne"
                />
              </Col>
              <Col md={8} className="d-flex align-items-center parti-center">
                <div>
                  <h2>{speakerOne}</h2>
                  <p className="mb-0">{speakerOnePosition} </p>
                  <p>{company} </p>
                </div>
              </Col>
            </Row>
          </Col>
          {imgSpeakerTwo ? (
            <Col md={6} sm={12} xxl={5} id="desc">
              <Row className="rowSpeaker">
                <Col md={3} className="parti-center">
                  <img
                    src={imgSpeakerTwo}
                    className="img-fluid"
                    alt="speakerOne"
                  />
                </Col>
                <Col md={8} className="d-flex align-items-center parti-center">
                  <div>
                    <h2>{speakerTwo}</h2>
                    <p className="mb-0">{speakerTwoPosition} </p>
                    <p>{company} </p>
                  </div>
                </Col>
              </Row>
            </Col>
          ) : (
            <Col md={6} sm={12} xxl={5} id="desc"></Col>
          )}
        </Row>
      </Container>

      <Container
        id="titleParticipantsDiscovery"
        className="d-block d-sm-block d-md-none"
      >
        <h2>{exposes}</h2>
        <Row className="textLandingGeneric">
          <Col md={6} sm={12} xxl={5} id="desc">
            <Row className="rowSpeaker">
              <Col md={3} className="parti-horizontal-center">
                <img
                  src={imgSpeakerOne}
                  className="img-fluid"
                  alt="speakerOne"
                />
              </Col>
              <Col
                md={8}
                className="d-flex align-items-center parti-horizontal-center"
              >
                <div>
                  <h2>{speakerOne}</h2>
                  <p>{speakerOnePosition} </p>
                  <p>{company} </p>
                </div>
              </Col>
            </Row>
          </Col>
          {imgSpeakerTwo && (
            <Col md={6} sm={12} xxl={5} id="desc">
              <Row className="rowSpeaker">
                <Col md={3} className="parti-horizontal-center">
                  <img
                    src={imgSpeakerTwo}
                    className="img-fluid"
                    alt="speakerOne"
                  />
                </Col>
                <Col md={8} className="d-flex align-items-center parti-center">
                  <div>
                    <h2>{speakerTwo}</h2>
                    <p>{speakerTwoPosition} </p>
                    <p>{company} </p>
                  </div>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Container>

      <Container
        id="ctaLanding"
        style={{
          backgroundImage: `url(${bannerImg})`,
        }}
      >
        <Row>
          <Col md={{ span: 10, offset: 1 }} className="p-0">
            <h2 className={colorText}>{bannerText}</h2>
          </Col>
        </Row>
      </Container>
    </>
  );
};
