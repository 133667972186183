import React, { Fragment } from "react";
import MenuUsa from "../components/commons/menuUsa";
import GetInTouch from "../components/commons/getInTouch";
import PolicyBanner from "../components/policy/banner";
import MisionVision from "../components/policy/misionVision";
import Policies from "../components/policy/policies";
import { LocationAddress } from "../components/usa/locationAddress";
import { Footer } from "../components/commons/footer";
import { DownloadBanner } from "../components/commons/downloadBanner";
import { Helmet, HelmetProvider } from "react-helmet-async";

export const OrganizationalPolicy = () => {
  const url = process.env.REACT_APP_URL + "/en-en/organizational-policy";
  return (
      <Fragment>
        <HelmetProvider>
          <Helmet>
            <title>{`Possumus - Organizational policy`}</title>
            <meta
              property="og:title"
              content="Possumus - Organizational policy"
            />
            <link rel="canonical" href={`${url}`} />
            <meta property="og:url" content={`${url}`} />
            <meta property="og:type" content="article" />
            <meta property="og:site_name" content="Possumus" />
            <meta
              property="og:image"
              content="https://possumustech.blob.core.windows.net/staticfiles/Miniatura_OG_b0b1d5c32e.png"
            />
          </Helmet>
          <MenuUsa />
          <PolicyBanner />
          <MisionVision />
          <Policies />
          <DownloadBanner />
          <GetInTouch />
          <LocationAddress />
          <Footer />
        </HelmetProvider>
      </Fragment>
  );
};

export default OrganizationalPolicy;
