import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import MarkdownView from "react-showdown";

export const Descripcion = ({ dataDetails }) => {
  return (
    <Container id="descripcion">
      <Row className="tituloDescripcion">
        <Col md={{ span: 10, offset: 1 }} xs={12}>
          <h2 className="text-center">{dataDetails.Titulo}</h2>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="normalDescripcion">
          <MarkdownView markdown={dataDetails.Descripcion} />
        </Col>
        <Col md={6} className="destacadoDescripcion">
          <p>{dataDetails.Destacado}</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Descripcion;
