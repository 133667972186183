import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import MarkdownView from "react-showdown";
export const BannerMoreInfo = ({ active, completeName,
  email,
  phone,
  phoneOp,
  imageSalesConsultant,needInformation,
  position }) => {
  
  return (
    <>
      {!active && (
        <Container fluid className="cardContact">
          <Container>
            <Row className="sales">
              <Col lg={5} id="info">
                <MarkdownView markdown={needInformation}/>                
              </Col>
              <Col lg={7} className="p-0 m-0">
                <Row className="p-0 m-0">
                  <Col lg={3} className="text-center">
                    <img
                      className="img-fluid"
                      src={imageSalesConsultant}
                      alt="Sebastian Delfino"
                    ></img>
                  </Col>
                  <Col lg={9} className="p-0 m-0 centeredSales">
                    <h3>{completeName}</h3>
                    <h4>{position}</h4>
                    {phoneOp && (<p>{`W: ${phoneOp}`}</p>)}
                    <p>{`T: ${phone}`}</p>
                    <p>
                      E: <a href={`mailto:${email}`}>{email}</a>
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Container>
      )}
    </>
  );
};
