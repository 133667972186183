import React, { Fragment } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import RankingTable from "../../assets/ef-epi.svg";
import Map from "../../assets/mapa-time.svg";
import { useTranslation } from "react-i18next";
import { timeZoneStaffSection } from "../../config/StaffAugmentation/textPage";

const TimeZone = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Container fluid id="timeZone">
        <Container>
          <Row>
            <h2 className="text-center">
              {t("timeZoneStaff.title", {
                title: `${timeZoneStaffSection.title}`,
              })}
            </h2>
          </Row>
          <Row className="d-flex justify-content-center">
            <Col lg={5} sm={11} className="mt-lg-5">
              <p>
                {t("timeZoneStaff.paragraph1", {
                  paragraph1: `${timeZoneStaffSection.paragraph1}`,
                })}
              </p>
              <p>
                {t("timeZoneStaff.paragraph2", {
                  paragraph2: `${timeZoneStaffSection.paragraph2}`,
                })}
              </p>
              <div className="table-ranking d-none d-sm-none d-md-block">
                <img src={RankingTable} alt="EF EPI Rankings"></img>
              </div>
            </Col>
            <Col lg={5} sm={11}>
              <img src={Map} alt="Time Zone"></img>
              <div className="table-ranking d-content d-sm-content d-md-none">
                <img src={RankingTable} alt="EF EPI Rankings"></img>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid id="timeZone-gray">
        <Container>
          <Row className="d-flex justify-content-center">
            <Col lg={3} xs={11}>
              <h4 className="text-center">
                {t("timeZoneStaff.title2", {
                  title2: `${timeZoneStaffSection.title2}`,
                })}
              </h4>
            </Col>
            <Col lg={7} xs={11}>
              <p>
                {t("timeZoneStaff.paragraph1t2", {
                  paragraph1t2: `${timeZoneStaffSection.paragraph1t2}`,
                })}
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <Button variant="secondary" href="#getInTouch">
                {t("timeZoneStaff.buttonPutTalent", {
                  buttonPutTalent: `${timeZoneStaffSection.buttonPutTalent}`,
                })}
              </Button>
            </Col>
          </Row>
        </Container>
      </Container>
    </Fragment>
  );
};

export default TimeZone;
