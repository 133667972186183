import React, { Fragment } from "react";
import CareerBanner from "../components/careers/banner";
import Trusted from "../components/commons/trusted";
import WhyPossumus from "../components/careers/whyPossumus";
import ContactReadyBuild from "../components/contact/contactReadyBuild";
import Benefits from "../components/careers/Benefits";
import OpenJobs from "../components/careers/openingJobs";
import OurProcess from "../components/careers/OurProcess";
import Contact from "../components/careers/contact";
import { Footer } from "../components/commons/footer";
import MenuUsa from "../components/commons/menuUsa";

export const Careers = () => {
  return (
    <Fragment>
      <MenuUsa />
      <CareerBanner />
      <Trusted />
      <WhyPossumus />
      <Benefits />
      <OpenJobs />
      <OurProcess />
      <Contact />
      <ContactReadyBuild />
      <Footer />
    </Fragment>
  );
};

export default Careers;
