import { Container, Row, Col } from "react-bootstrap";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
export const DetailsTwoParticipantsGeneric = ({
  paragraph,
  title,
  sticker,
  subTitle,
  description,
  descriptionParagraph2,
  speakerOne,
  imgSpeakerOne,
  speakerOnePuesto,
  bannerText,
  bannerImg,
  speakerTwoPuesto,
  imgSpeakerTwo,
  speakerTwo,
  exposes,
  colorText,
  company,
}) => {
  return (
    <Container fluid id="detailsLandingSolidGeneric">
      <Container>
        <Row className="justify-content-center ">
          <Col xxl={{ span: 9 }}>
            <div className="containerTitle">
              <h2 className="text-center">{paragraph}</h2>
              <h1 className="text-center">{title}</h1>
            </div>
          </Col>
        </Row>
        <Row className="textLandingGeneric justify-content-center">
          <Col md={6} sm={12} xxl={5} id="desc">
            <ReactMarkdown>{description}</ReactMarkdown>

            {descriptionParagraph2 && (
              <ReactMarkdown>{descriptionParagraph2}</ReactMarkdown>
            )}
            <h5 id="description">{subTitle}</h5>
          </Col>
          <Col md={6} sm={12} className="d-sm-block d-none">
            <div className="styleImageSticker">
              <img src={sticker} className="img-fluid" alt="sticker" />
            </div>
          </Col>
        </Row>
        <Container
          id="titleParticipantsDiscovery"
          className="d-none d-sm-none d-md-block"
        >
          <Row className="titleExposes">
            <Col lg={6}>
              <h2>{exposes}</h2>
            </Col>
          </Row>
          <Row className="textLandingGeneric styleExposes">
            <Col md={6} sm={12} xxl={5} id="desc">
              <Row className="rowSpeaker">
                <Col md={3} className="parti-center">
                  <img
                    src={imgSpeakerOne}
                    className="img-fluid"
                    alt="speakerOne"
                  />
                </Col>
                <Col md={8} className="d-flex align-items-center parti-center">
                  <div>
                    <h2>{speakerOne}</h2>
                    <p className="mb-0">{speakerOnePuesto}</p>
                    <p>{company}</p>
                  </div>
                </Col>
              </Row>
            </Col>
            {imgSpeakerTwo ? (
              <Col md={6} sm={12} xxl={5} id="desc">
                <Row className="rowSpeaker">
                  <Col md={3} className="parti-center">
                    <img
                      src={imgSpeakerTwo}
                      className="img-fluid"
                      alt="speakerOne"
                    />
                  </Col>
                  <Col
                    md={8}
                    className="d-flex align-items-center parti-center"
                  >
                    <div>
                      <h2>{speakerTwo}</h2>
                      <p className="mb-0">{speakerTwoPuesto} </p>
                      <p>{company} </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            ) : (
              <Col md={6} sm={12} xxl={5} id="desc"></Col>
            )}
          </Row>
        </Container>

        <Container
          id="titleParticipantsDiscovery"
          className="d-block d-sm-block d-md-none"
        >
          <h2>{exposes}</h2>
          <Row className="textLandingGeneric">
            <Col md={6} sm={12} xxl={5} id="desc">
              <Row className="rowSpeaker">
                <Col md={3} className="parti-horizontal-center">
                  <img
                    src={imgSpeakerOne}
                    className="img-fluid"
                    alt="speakerOne"
                  />
                </Col>
                <Col
                  md={8}
                  className="d-flex align-items-center parti-horizontal-center"
                >
                  <div>
                    <h2>{speakerOne}</h2>
                    <p className="mb-0">{speakerOnePuesto}</p>
                    <p>{company} </p>
                  </div>
                </Col>
              </Row>
            </Col>
            {imgSpeakerTwo && (
              <Col md={6} sm={12} xxl={5} id="desc">
                <Row className="rowSpeaker">
                  <Col md={3} className="parti-horizontal-center">
                    <img
                      src={imgSpeakerTwo}
                      className="img-fluid"
                      alt="speakerOne"
                    />
                  </Col>
                  <Col
                    md={8}
                    className="d-flex align-items-center parti-center"
                  >
                    <div>
                      <h2>{speakerTwo}</h2>
                      <p className="mb-0">{speakerTwoPuesto} </p>
                      <p>{company} </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Container>
      </Container>
      <Container
        id="ctaLanding"
        style={{
          backgroundImage: `url(${bannerImg})`,
        }}
      >
        <Row>
          <Col md={{ span: 10, offset: 1 }} className="p-0 ">
            <h2 className={colorText}>{bannerText}</h2>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default DetailsTwoParticipantsGeneric;
