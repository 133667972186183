import { Card, Col, Image, Row } from "react-bootstrap";
import elipse from "../../../assets/Ellipse.svg";
import { ContentItaasContext } from "../../../contexts/ContentItaasContext";
import { useContext } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

const ServicesMobile = () => {
  const { servicesIT } = useContext(ContentItaasContext);

  return (
    <div class="d-block d-sm-block d-lg-none">
      <Row className="justify-content-md-center text-left  mt-4 gap-4">
        {servicesIT.Card?.map((item, idx) => (
          <Col sm={12} className="m-0 p-0 cardEvolutionary">
            <Card
              bg="light"
              id="myCardMobile"
              className={idx !== 0 && "spacing-card-mobile"}
            >
              <div>
                <Image
                  src={elipse}
                  alt=""
                  className="marginElipseColumn1Mobile"
                />
              </div>
              <Card.Header className="titleCard mt-4">{item.Title}</Card.Header>
              <div className="lineCard">
                <hr className="m-0 p-0" />
              </div>
              <Card.Body className="pb-0">
                <Card.Text>
                  {item.DescriptionEnriched ? (
                    <ReactMarkdown>{item.DescriptionEnriched}</ReactMarkdown>
                  ) : (
                    item.Description
                  )}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ServicesMobile;
