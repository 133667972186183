import { useEffect, useState } from "react";
import HeaderLanding from "../components/landings/header";
import BannerAlt from "../components/landings/bannerAlt";
import DetailsTwoParticipantsGeneric from "../components/landings/detailsTwoParticipantsGeneric";
import FormLanding from "../components/landings/form";
import FooterLanding from "../components/landings/footerLanding";
import { IframeYouTube } from "../components/landings/iframeYouTube";
import { DownloadFree } from "../components/landings/downloadFree";
import { ModalDownloadFree } from "../components/landings/modalDownloadFree";
import { BannerMoreInfo } from "../components/landings/bannerMoreInfo";
import { WebinarSummary } from "../components/landings/webinarSummary";
import { DetailsDescription } from "../components/landings/detailsDescription";
import { useRequestApi } from "../hooks/useRequestApi";
import Partners from "../components/home/ourPartners";
import { Spinner } from "react-bootstrap";
import { Helmet, HelmetProvider } from "react-helmet-async";

export const ITAsAService = () => {
  const {
    bannerImg,
    dataSpeaker,
    imgSpeakerOne,
    imgSpeakerTwo,
    bannerBox,
    bannerImgMobile,
    bannerBoxImg,
    contact,
    contactEvent,
    postWebinarDesc,
    sticker,
    requestApiItaas,
    dataContentLanding,
    loader,
  } = useRequestApi();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    requestApiItaas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const url = process.env.REACT_APP_URL + "/webinars/it-as-a-service";
  return loader ? (
    <Spinner />
  ) : (
    <HelmetProvider>
      <Helmet>
        <title>{`Possumus - Itaas`}</title>
        <meta property="og:title" content="Possumus - Itaas" />
        <link rel="canonical" href={`${url}`} />
        <meta property="og:url" content={`${url}`} />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="Possumus" />
        <meta
          property="og:image"
          content="https://possumustech.blob.core.windows.net/staticfiles/Miniatura_OG_b0b1d5c32e.png"
        />
      </Helmet>
      <div id="talkTechSolid">
        <HeaderLanding
          title={dataContentLanding.Title_Header}
          phone="0810 345 0562"
          template="dark"
        />
        <BannerAlt
          classButton="btn-blue"
          idContainer="bannerLandingSolidWebinar"
          imgBanner={bannerImg}
          imgBannerMobile={bannerImgMobile}
          idButton="formLandingButtonItas"
          href="#formLanding"
          idHeader="titleMain"
          date={dataContentLanding.Date}
          time={dataContentLanding.Hour}
          title={dataContentLanding.Title_1}
          tittle2={dataContentLanding.Title_2}
          active={dataContentLanding.Active}
          mainTitle={dataContentLanding.Main_title}
          template="none"
          buttonWatchWebinar={"Ver webinar"}
          buttonParticipateWebinar={"Quiero participar"}
        />
        {dataContentLanding.Active ? (
          <DetailsTwoParticipantsGeneric
            paragraph={dataSpeaker.paragraph}
            title={dataSpeaker.Title}
            sticker={sticker.url}
            description={dataSpeaker.subtitle}
            speakerOne={dataSpeaker.speaker1}
            speakerTwo={dataSpeaker.speaker2}
            speakerOnePuesto={dataSpeaker.speakerJob1}
            speakerTwoPuesto={dataSpeaker.speakerJob2}
            imgSpeakerOne={imgSpeakerOne?.url}
            imgSpeakerTwo={imgSpeakerTwo?.url}
            bannerText={bannerBox.BannerText}
            bannerImg={bannerBoxImg.url}
            exposes={dataSpeaker.exposes}
            colImage={2}
            colPost={4}
            colorText={"textWhite"}
            company={dataSpeaker.company}
          />
        ) : (
          <>
            <WebinarSummary
              columnOne={postWebinarDesc.columnOne}
              columnTwo={postWebinarDesc.columnTwo}
              columnThree={postWebinarDesc.columnThree}
              columnFour={postWebinarDesc.columnFour}
              postWebinarData={postWebinarDesc?.Title}
            />
            <DetailsDescription
              speakerOnePosition={dataSpeaker.speakerJob1}
              speakerTwoPosition={dataSpeaker.speakerJob2}
              speakerOne={dataSpeaker.speaker1}
              speakerTwo={dataSpeaker.speaker2}
              descriptionParagraph2={dataSpeaker.subtitle}
              description={dataSpeaker.description}
              imgSpeakerOne={imgSpeakerOne.url}
              imgSpeakerTwo={imgSpeakerTwo?.url}
              sticker={sticker.url}
              bannerImg={bannerBoxImg.url}
              bannerText={bannerBox.BannerText}
              colorText={"textWhite"}
              company={dataSpeaker.company}
              exposes={dataSpeaker.exposes}
            />
          </>
        )}
        <IframeYouTube
          id={contact.idYoutube}
          title={contact.videoTitle}
          active={dataContentLanding.Active}
        />
        <DownloadFree
          imageBackgroudMobile={dataContentLanding.Download_Free_mobile?.url}
          imageBackgroud={dataContentLanding.Download_Free_mobile?.url}
          handleShow={handleShow}
          download={contact.download}
          active={dataContentLanding.Active}
          textBanner={dataContentLanding.bannerFreeText}
          id="formLandingButtonItas"
        />
        {dataContentLanding.SaleConsultant && (
          <BannerMoreInfo
            completeName={dataContentLanding.SaleConsultant.nameLastName}
            email={dataContentLanding.SaleConsultant.email}
            phone={dataContentLanding.SaleConsultant.phone}
            phoneOp={dataContentLanding.SaleConsultant.phoneOp}
            imageSalesConsultant={
              dataContentLanding.SaleConsultant.imageSalesConsultant?.url
            }
            position={dataContentLanding.SaleConsultant.position}
            active={dataContentLanding.Active}
            needInformation={dataContentLanding.SaleConsultant.needInformation}
          />
        )}
        <FormLanding
          idButton={"formLandingButtonItas"}
          active={dataContentLanding.Active}
          titleActive={contact.formTitle}
          titleEvent={contactEvent?.formTitle}
          contactOrigin={
            dataContentLanding.Active
              ? contact.originContact
              : contactEvent?.Origen_contacto
          }
        />
        <Partners />
        <FooterLanding title={dataContentLanding.Subtitle_Footer} />
      </div>
      <ModalDownloadFree
        imgModal={dataContentLanding.Modal_image?.url}
        show={show}
        onHide={handleClose}
        url={dataContentLanding.shortUrl}
        data={dataContentLanding?.File_pdf?.url}
        id="formLandingButtonItas"
      />
    </HelmetProvider>
  );
};

export default ITAsAService;
