import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import Loader from "../../components/commons/loader";
import "../../css/organizationalPolicy.css";
import { ContentOrganizationalPoliciesContext } from "../../contexts/ContentOrganizationalPoliciesContext";

const PolicyBanner = () => {
  const [wLDLogo, setWLDLogo] = useState({});

  const {dataBanner} = useContext(ContentOrganizationalPoliciesContext)

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL_API}/page-usa`).then((res) => {
      setWLDLogo(res.data.element.logoWLD.url);
    });
  }, []);
  return (
    <Container fluid className="imageBackGroundUsa background-banner-header">
      <Container>
        <Row className="padingTop">
          <Col lg={7}>
            <h1>
              {dataBanner.Titulo}
            </h1>
          </Col>
          <Col lg={5}>
            {dataBanner.Imagen?.url ? (
              <img
                align="right"
                src={process.env.REACT_APP_URL_API + dataBanner.Imagen.url}
                alt={dataBanner.Imagen.alternativeText}
                className="d-md-block"
              ></img>
            ) : (
              <Loader />
            )}
          </Col>
        </Row>
        <Row className="logoAndIso">
          <Col lg={12}>
            <img
              align="right"
              className="marginLeft"
              src={wLDLogo}
              alt=""
            ></img>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default PolicyBanner;
