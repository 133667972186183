import React from "react";
import { Button, Col, Container, Row, Image } from "react-bootstrap";
export const DownloadFree = ({
  imageBackgroudMobile,
  download,
  handleShow,
  active,
  textBanner,
  id,
}) => {
  return (
    <>
      {!active && (
        <Container
          fluid
          className="d-none d-sm-none d-md-block containerBannerDownloadFreeDesktop"
        >
          <Row>
            <Col
              xl={5}
              md={6}
              className="d-flex justify-content-center align-items-center"
            >
              <Image
                src={imageBackgroudMobile}
                alt="bannerMobile"
                className="imagedesktop"
              />
            </Col>
            <Col xl={4} md={5}>
              <div className="detalleDescarga">{textBanner}</div>
              <div className="buttonFreeDownload" id={id}>
                <Button
                  variant="primary"
                  className="btn-blue"
                  onClick={() => handleShow()}
                >
                  {download}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      )}

      {!active && (
        <Container className="d-block d-sm-block d-md-none containerBannerDownloadFree">
          <div className="imagemobile">
            <Image src={imageBackgroudMobile} alt="bannerMobile" />
          </div>
          <Row className="margin-lg">
            <Col
              md={{ span: 6, offset: 6 }}
              lg={{ span: 5, offset: 7 }}
              xl={{ span: 4, offset: 7 }}
              xxl={{ span: 3, offset: 7 }}
              className="mt-5"
            >
              <div className="marginContent">
                <div className="detalleDescarga">{textBanner}</div>
                <div className="buttonFreeDownload" id={id}>
                  <Button
                    href="#formLanding"
                    variant="primary"
                    className="btn-blue"
                    onClick={() => handleShow()}
                  >
                    {download}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};
