import React, { Fragment } from "react";
import AppRouter from "./routers/AppRouter";
import Enviroment from "./components/commons/enviroment";
function App() {
  return (
    <Fragment>
        <Enviroment />
        <AppRouter />
    </Fragment>
  );
}

export default App;
