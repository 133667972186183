import { useContext, useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import "../../css/MegaMenu.css";
import { ActualLangContext } from "../../contexts/ActualLangContext";

export const MegaMenu = ({ items }) => {
  const [selected, setSelected] = useState(items[0].id);
  const { actualLang } = useContext(ActualLangContext);
  return (
    <Row className="megaMenu">
      <Col lg={{ offset: 1, span: 3 }} id="megaMenuItemContainer">
        {items.map((item) => (
          <div
            key={item.id}
            onMouseEnter={() => setSelected(item.id)}
            className={
              item.id === selected ? "megaMenuItem selected" : "megaMenuItem"
            }
          >
            <a
              href={
                item.attributes.href.includes("https")
                  ? item.attributes.href
                  : item.attributes.href
                  ? (`/${
                      actualLang === "en" ? "en-en" : "es-es"
                    }${item.attributes.href}`)
                  : ""
              }
            >
              <p>{item.attributes.title}</p>
              {item.attributes.slogan && (
                <p className="small-content">{item.attributes.slogan}</p>
              )}
            </a>
          </div>
        ))}
      </Col>
      {items.map((item) => {
        return (
          <>
            {item.id === selected && (
              <Col
                key={item.id}
                lg={6}
                className="d-flex align-items-start justify-content-between"
                id="megaMenuItemDescription"
              >
                <Col lg={7}>
                  <h4>
                    <a
                      href={
                        item.attributes.href
                          ? `/${actualLang === "en" ? "en-en" : "es-es"}${
                              item.attributes.href
                            }`
                          : null
                      }
                    >
                      {item.attributes.title}
                    </a>
                  </h4>
                  <ReactMarkdown>{item.attributes.description}</ReactMarkdown>
                  <div className="linksContainer">
                    {item.attributes.links.map((link) => (
                      <h5>
                        <a
                          href={
                            link.href.includes("https")
                              ? link.href
                              : item.attributes.href
                              ? `/${actualLang === "en" ? "en-en" : "es-es"}${
                                  item.attributes.href
                                }/${link.href}`
                              : null
                          }
                        >
                          {link.title}
                        </a>
                      </h5>
                    ))}
                  </div>
                </Col>
                <Col lg={4}>
                  <Image
                    src={item.attributes.image?.data.attributes.url}
                    alt={
                      item.attributes.image?.data.attributes?.alternativeText ||
                      "product image"
                    }
                    className="w-100"
                  />
                </Col>
              </Col>
            )}
          </>
        );
      })}
    </Row>
  );
};
