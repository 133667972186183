import MainBannerUsaDesktop from "./bannerUsaDesktop";
import MainBannerUsaMobile from "./bannerUsaMobile";

const MainBanner = () => {
  return (
    <>
      <MainBannerUsaDesktop/>
      <MainBannerUsaMobile />
    </>
  );
};

export default MainBanner;
