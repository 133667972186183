import { Image } from "react-bootstrap";
import circleImg from "../assets/elementos-circulo-rayas.svg";

export default function withCircle(Component, position, data) {
  const { top, right } = position;
  return function () {
    return (
      <div id="circleContainer">
        <Component data={data} />
        <Image
          src={circleImg}
          alt="background"
          id="circle"
          style={{ top: top, right: right }}
        />
      </div>
    );
  };
}
