import React, { Fragment, useContext, useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { ActualLangContext } from "../../contexts/ActualLangContext";
import GetInTouchForm from "./getInTouchForm";

const GetInTouch = ({ title, personal }) => {
  const { actualLang } = useContext(ActualLangContext);

  const [actualPersonal, setActualPersonal] = useState(personal || {});

  useEffect(() => {
    if (!personal) {
      //Si no hay personal por props, por default se pone Sebastian Delfino
      axios
        .get(`${process.env.REACT_APP_URL_API}/institucional`)
        .then((res) => {
          setActualPersonal(res.data.Personal);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Container id="getInTouch">
        <Row>
          <GetInTouchForm title={title} />
          <Col
            lg={4}
            sm={12}
            className="d-flex flex-column justify-content-end"
          >
            <Row className="salesman">
              <Col lg={12} className="p-0 m-0">
                <img
                  className="img-fluid img-center"
                  src={actualPersonal.Image_personal?.url}
                  alt={actualPersonal.nombre_apellido}
                />
                <h2>{actualPersonal.nombre_apellido}</h2>
                <h3>{actualPersonal.puesto}</h3>
              </Col>
              <Col lg={12} className="p-0 m-0 text-center">
                <Button variant="secondary" href={"tel: 0810 345 0562"}>
                  <FontAwesomeIcon icon={faPhoneAlt} />
                  {actualLang === "es" ? "Hablemos" : "Let's talk"}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default GetInTouch;
