import { useEffect, useState } from "react";
import HeaderLanding from "../components/landings/header";
import FormLanding from "../components/landings/form";
import FooterLanding from "../components/landings/footerLanding";
import "../css/landingDiscovery.css";
import BannerAlt from "../components/landings/bannerAlt";
import DetailsTwoParticipantsGeneric from "../components/landings/detailsTwoParticipantsGeneric";
import { IframeYouTube } from "../components/landings/iframeYouTube";
import { DownloadFree } from "../components/landings/downloadFree";
import { ModalDownloadFree } from "../components/landings/modalDownloadFree";
import { BannerMoreInfo } from "../components/landings/bannerMoreInfo";
import { WebinarSummary } from "../components/landings/webinarSummary";
import { DetailsDescription } from "../components/landings/detailsDescription";
import { useRequestApi } from "../hooks/useRequestApi";
import Partners from "../components/home/ourPartners";
import { Spinner } from "react-bootstrap";
import { Helmet, HelmetProvider } from "react-helmet-async";

export const ProductDiscovery = () => {
  const {
    bannerImg,
    dataSpeaker,
    imgSpeakerOne,
    imgSpeakerTwo,
    bannerBox,
    bannerImgMobile,
    bannerBoxImg,
    contact,
    contactEvent,
    postWebinarDesc,
    sticker,
    requestApiProductDiscovery,
    loader,
    dataContentLanding,
  } = useRequestApi();
  const {
    Descarga_Free_mobile,
    Img_modal,
    Sales_Consultant,
    texto_banner_free,
  } = dataContentLanding;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    requestApiProductDiscovery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const url = process.env.REACT_APP_URL + "/webinars/product-discovery";
  return loader ? (
    <Spinner />
  ) : (
    <HelmetProvider>
      <Helmet>
        <title>{`Possumus - Product Discovery`}</title>
        <meta property="og:title" content="Possumus - Product Discovery" />
        <link rel="canonical" href={`${url}`} />
        <meta property="og:url" content={`${url}`} />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="Possumus" />
        <meta
          property="og:image"
          content="https://possumustech.blob.core.windows.net/staticfiles/Miniatura_OG_b0b1d5c32e.png"
        />
      </Helmet>
      <div id="talkTechSolid">
        <HeaderLanding
          title={dataContentLanding.Title_1}
          phone="0810 345 0562"
          template="dark"
        />
        <BannerAlt
          idContainer="bannerLandingSolidWebinar"
          idButton="formLanding"
          idHeader="titleMainLanding"
          href="#formLanding"
          imgBanner={bannerImg}
          imgBannerMobile={bannerImgMobile}
          date={dataContentLanding.Date}
          time={dataContentLanding.Hour}
          title={dataContentLanding.Title_1}
          tittle2={dataContentLanding.Title_2}
          active={dataContentLanding.Active}
          template="none"
          buttonWatchWebinar={"Ver webinar"}
          buttonParticipateWebinar={"Quiero participar"}
        />
        {dataContentLanding.Active ? (
          <DetailsTwoParticipantsGeneric
            paragraph={dataSpeaker.Copete}
            title={dataSpeaker.Title}
            sticker={dataSpeaker.url}
            description={dataSpeaker.Subtitle}
            speakerOne={dataSpeaker.Speaker_1}
            speakerTwo={dataSpeaker.Speaker_2}
            speakerOnePuesto={dataSpeaker.Position_Speaker_1}
            speakerTwoPuesto={dataSpeaker.Position_Speaker_2}
            imgSpeakerOne={imgSpeakerOne.url}
            imgSpeakerTwo={imgSpeakerTwo?.url}
            bannerText={bannerBox.BannerText}
            bannerImg={bannerBoxImg.url}
            colImage={3}
            colPost={8}
            colorText={"textWhite"}
          />
        ) : (
          <>
            <WebinarSummary
              columnOne={postWebinarDesc.columnOne}
              columnTwo={postWebinarDesc.columnTwo}
              columnThree={postWebinarDesc.columnThree}
              columnFour={postWebinarDesc.columnFour}
              postWebinarData={postWebinarDesc?.Title}
            />
            <DetailsDescription
              exposes="Presentado por:"
              descriptionParagraph2={dataSpeaker.Subtitle}
              description={dataSpeaker.Description}
              speakerOnePosition={dataSpeaker.Position_Speaker_1}
              speakerTwoPosition={dataSpeaker.Position_Speaker_2}
              speakerOne={dataSpeaker.Speaker_1}
              speakerTwo={dataSpeaker.Speaker_2}
              imgSpeakerOne={imgSpeakerOne.url}
              imgSpeakerTwo={imgSpeakerTwo?.url}
              sticker={sticker.url}
              bannerImg={bannerBoxImg.url}
              bannerText={bannerBox.BannerText}
              colorText={"textWhite"}
            />
          </>
        )}
        <IframeYouTube
          id={contact.Id_Youtube}
          title={contact.Video_title}
          active={dataContentLanding.Active}
        />
        <DownloadFree
          imageBackgroudMobile={Descarga_Free_mobile?.url}
          imageBackgroud={Descarga_Free_mobile?.url}
          handleShow={handleShow}
          download={contact.download}
          active={dataContentLanding.Active}
          textBanner={texto_banner_free}
          id="formLanding"
        />
        {Sales_Consultant && (
          <BannerMoreInfo
            salesConsultant={Sales_Consultant}
            completeName={Sales_Consultant.name_last_name}
            email={Sales_Consultant.email}
            phone={Sales_Consultant.phone}
            phoneOp={Sales_Consultant.phone_Op}
            imageSalesConsultant={Sales_Consultant.Image_sales_consultant?.url}
            position={Sales_Consultant.position}
            active={dataContentLanding.Active}
            needInformation={Sales_Consultant.needInformation}
          />
        )}
        <FormLanding
          idButton="formLanding"
          active={dataContentLanding.Active}
          titleActive={contact.Form_title}
          titleEvent={contactEvent?.formTitle}
          contactOrigin={
            dataContentLanding.Active
              ? contact.Origen_Contacto
              : contactEvent?.Origen_Contacto
          }
        />
        <Partners />
        <FooterLanding title={dataContentLanding.Subtitle} />
      </div>
      <ModalDownloadFree
        imgModal={Img_modal?.url}
        show={show}
        onHide={handleClose}
        url={"landing-product-discovery"}
        data={
          dataContentLanding?.file_pdf && dataContentLanding?.file_pdf[0]?.url
        }
        id="formLanding"
      />
    </HelmetProvider>
  );
};

export default ProductDiscovery;
