import "./Separator.css";
import { Button, Col, Container, Row } from "react-bootstrap";

export default function Separator({ data }) {
  const { background } = data;
  return (
    <Container className="separatorContainer" fluid style={{ backgroundImage: `url(${background})` }}>
      <Container>
        <Row>
          <Col md={8}>
            <h2>Discover the potential of implementing AI in your business.</h2>
            <Button variant="quinary" href="/">Explore more in Possumus</Button>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
