import React, { Fragment } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import ThirdImg from "../../assets/elementos-ilustracion-3.svg";
import { softwareServiceStaffSection } from "../../config/StaffAugmentation/textPage";
import { useTranslation } from "react-i18next";

const SoftwareServices = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Container fluid id="software-services">
        <Container id="SoftwareServices">
          <Row className="d-flex justify-content-center">
            <Col lg={5} sm={12} md={11} className="pr-5">
              <h4 className="titleStaffCentered">
                {t("softwareServiceStaff.title", {
                  title: `${softwareServiceStaffSection.title}`,
                })}
              </h4>
              <p>
                {t("softwareServiceStaff.paragraph1", {
                  paragraph1: `${softwareServiceStaffSection.paragraph1}`,
                })}
              </p>
              <p>
                {t("softwareServiceStaff.paragraph2", {
                  paragraph2: `${softwareServiceStaffSection.paragraph2}`,
                })}
                <b>
                  {t("softwareServiceStaff.company", {
                    company: `${softwareServiceStaffSection.company}`,
                  })}
                </b>
                {t("softwareServiceStaff.paragraph2Part2", {
                  paragraph2Part2: `${softwareServiceStaffSection.paragraph2Part2}`,
                })}
              </p>
              <p className="text-lg-left">
                <Button
                  variant="secondary"
                  className="mt-5 mb-5"
                  href="#getInTouch"
                >
                  {t("softwareServiceStaff.buttonHelp", {
                    buttonHelp: `${softwareServiceStaffSection.buttonHelp}`,
                  })}
                </Button>
              </p>
            </Col>
            <Col lg={5} sm={12}>
              <div className="card-white">
                <img src={ThirdImg} alt="Software Services<"></img>
                <h4 className="titleStaffCentered">
                  {t("softwareServiceStaff.title2", {
                    title2: `${softwareServiceStaffSection.title2}`,
                  })}
                </h4>
                <p>
                  {t("softwareServiceStaff.paragraph1t2", {
                    paragraph1t2: `${softwareServiceStaffSection.paragraph1t2}`,
                  })}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </Fragment>
  );
};

export default SoftwareServices;
