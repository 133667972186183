import { useContext, useState, useEffect } from "react";
import axios from "axios";
import { ActualLangContext } from "../contexts/ActualLangContext";

const useDownloadBrochure = () => {
  const { actualLang } = useContext(ActualLangContext);
  const [isLoading, setIsLoading] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(" ");

  useEffect(() => {
    setIsLoading(true);
    axios.get(`https://admin.possumus.tech/brochures`).then((res) => {
      if (actualLang === "es") {
        setDownloadUrl(res.data.PossumusEs.url);
      } else {
        setDownloadUrl(res.data.PossumusEn.url);
      }
      setIsLoading(false);
    });
  }, [actualLang]);

  return { downloadUrl, isLoading };
};

export default useDownloadBrochure;
