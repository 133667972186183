import React, { useContext } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import MSPowerPlatformIcon from "../../assets/MSPowerPlatform.svg";
import MSMicrosoft365Icon from "../../assets/MSMicrosoft365.svg";
import SolutionsMSECard from "../commons/solutionsMSECard";
import { bannerDownload } from "../../config/Commons/textPage";
import { useTranslation } from "react-i18next";
import { ContentMicrosoftEcosystemContext } from "../../contexts/ContentMicrosoftEcosystemContext";
import ListOnShore from "../commons/listOnShore";

const MSESolutions = () => {
  const { t } = useTranslation();

  const { solutionsContent, microsoftDynamicsToolsContent } = useContext(
    ContentMicrosoftEcosystemContext
  );

  return (
    <Container fluid id="solutions">
      <Row className="justify-content-md-center p-2">
        <Col lg={10}>
          <h2 className="text-center mt-4">{solutionsContent?.Title}</h2>
        </Col>
        <Col lg={8} className="justify-content-md-center text-center p-3">
          <p className="subtitle">{solutionsContent?.Subtitle}</p>
        </Col>
      </Row>
      <Row className="justify-content-md-center" id="PowerPlatform">
        <Col lg={10} className="justify-content-center d-flex">
          <Image
            src={MSPowerPlatformIcon}
            alt="Microsoft System Power Plataform"
          />
        </Col>
        <Col lg={10}>
          <h4 className="text-center mt-4">
            {solutionsContent?.PowerPlatformTools &&
              solutionsContent?.PowerPlatformTools[0].Titulo}
          </h4>
        </Col>
      </Row>
      <Row className="justify-content-md-center mt-94">
        <div className="cardsSolutionsContainer">
          {solutionsContent?.Card?.map((item) => (
            <SolutionsMSECard
              imageUrl={item.Image.url}
              title={item.Title}
              description={item.Description}
            />
          ))}
        </div>
      </Row>
      <Row className="justify-content-md-center p-2" id="Dynamics365">
        <Col lg={10} className="justify-content-center d-flex">
          <Image
            src={MSMicrosoft365Icon}
            alt="Microsoft System Power Plataform"
          />
        </Col>
        <Col lg={10} className="text-center p-2">
          <h4 className="text-center mt-4">
            {microsoftDynamicsToolsContent.Title}
          </h4>
        </Col>
        <Col lg={9} className="justify-content-md-center text-center p-2">
          <p className="subtitle">{microsoftDynamicsToolsContent.Subtitle}</p>
        </Col>
      </Row>
      <ListOnShore items={microsoftDynamicsToolsContent.ItemList} />
      <Col className="justify-content-center align-items-center d-flex  mt-4">
        <Button
          variant="secondary"
          href="#getInTouch"
          className="paddingButtonMainBanner"
        >
          {t("buttonsAndAccesories.buttonGetFree", {
            buttonGetFree: `${bannerDownload.buttonGetFree}`,
          })}
        </Button>
      </Col>
    </Container>
  );
};

export default MSESolutions;
