import { Image } from "react-bootstrap";
import shadow from "../assets/bg-shadow.svg";

export default function withShadow(Component, position, data, quantity = 1) {
  const { top, right } = position;
  const array = []
  for (let i = 0; i < quantity; i++) {
    array.push({id: i})
  }
  return function () {
    return (
      <div id="circleContainer">
        <Component data={data} />
        {array.map(el => {
        return <Image
            src={shadow}
            alt="background"
            id="circle"
            style={{ top: el.id === 0 ? top : `${Math.abs(parseInt(top) * (el.id + 4))}%`, right: right }}
        />
  })}
      </div>
    );
  };
}
