import React from 'react'
import { Col, Image } from 'react-bootstrap'
import bulletIcon from '../../assets/icons/bulletIcon.svg'

const Bullet = ({title, description}) => {
  return (
    <Col lg={12} className='d-flex bullet'>
        <Col>
            <Image src={bulletIcon} alt='Bullet Icon'/>
        </Col>
        <Col lg="12">
            <h4>{title}</h4>
            <p>{description}</p>
        </Col>
    </Col>
  )
}

export default Bullet