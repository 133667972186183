import React, { Fragment, useContext } from "react";
import "../../css/MicrosoftEcosystem.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import InfoList from "../../components/microsoftEcosystem/InfoList";
import MSEIntroduction from "../../components/microsoftEcosystem/MSEIntroduction";
import MSESolutions from "../../components/microsoftEcosystem/MSESolutions";
import ApproachesAndMethodologies from "../../components/microsoftEcosystem/ApproachesAndMethodologies";
import { ContentMicrosoftEcosystemContext } from "../../contexts/ContentMicrosoftEcosystemContext";
import withCircle from "../../utils/WithCirlce";
import Layout from "./Layout";
import { ActualLangContext } from "../../contexts/ActualLangContext";
import useGetHeaderContent from "../../hooks/useGetHeaderContent";
import MSEHeader from "../../components/landings/comercial/mseHeader";

const MicrosoftEcosystemLanding = () => {
  const { actualLang } = useContext(ActualLangContext);
  const { infoButtons } = useContext(ContentMicrosoftEcosystemContext);

  const isInEnglish = actualLang === "en";

  const url =
    process.env.REACT_APP_URL + isInEnglish
      ? "/services/microsoft-ecosystem"
      : "/servicios/microsoft-ecosystem";

  const { titulo, bajada } = useGetHeaderContent("microsoft-ecosystem");

  const MSEIntroductionWithCircle = withCircle(MSEIntroduction, {
    top: "10%",
    right: "-32%",
  });
  return (
    <Fragment>
      <HelmetProvider>
        <Helmet>
          <title>{`Possumus - Microsoft Ecosystem`}</title>
          <meta property="og:title" content="Possumus - Microsoft Ecosystem" />
          <link rel="canonical" href={`${url}`} />
          <meta property="og:url" content={`${url}`} />
          <meta property="og:type" content="article" />
          <meta property="og:site_name" content="Possumus" />
          <meta
            property="og:image"
            content="https://possumustech.blob.core.windows.net/staticfiles/Miniatura_OG_b0b1d5c32e.png"
          />
          {!isInEnglish && (
            <script>
              {(function (w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({
                  "gtm.start": new Date().getTime(),
                  event: "gtm.js",
                });
                var f = d.getElementsByTagName(s)[0],
                  j = d.createElement(s),
                  dl = l !== "dataLayer" ? "&l=" + l : "";
                j.async = true;
                j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
                f.parentNode.insertBefore(j, f);
              })(window, document, "script", "dataLayer", "GTM-T7NNKSD9")}
            </script>
          )}
        </Helmet>
        <Layout>
          <MSEHeader titulo={titulo} bajada={bajada} />
          <InfoList data={{ infoButtons }} />
          <MSEIntroductionWithCircle />
          <MSESolutions />
          <ApproachesAndMethodologies />
        </Layout>
      </HelmetProvider>
      {!isInEnglish && (
        <noscript>
          {`<iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-T7NNKSD9"
            height="0"
            width="0"
            style="display:none;visibility:hidden"
          ></iframe>`}
        </noscript>
      )}
    </Fragment>
  );
};

export default MicrosoftEcosystemLanding;
