import axios from "axios";
import { createContext, useEffect, useState, useContext } from "react";
import { ActualLangContext } from "./ActualLangContext";
import { AuthStrapiContext } from "./AuthStrapiV3Context";

export const ContentAwardsLandingContext = createContext({});

export const ContentAwardsLandingProvider = ({ children }) => {
  const { actualLang } = useContext(ActualLangContext);
  const { token } = useContext(AuthStrapiContext);

  const [isDataLoaded, setLoadedData] = useState(false);
  const [headerContent, setHeaderContent] = useState({});
  const [bannerContent, setBannerContent] = useState({});
  const [awards, setAwards] = useState([]);
  const [highlightedAwards, setHighlightedAwards] = useState([]);

  useEffect(() => {
    const url = `${process.env.REACT_APP_URL_API}/landing-awards?_locale=${actualLang}`;
    if(token){
      axios
      .get(url, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        setHeaderContent(res.data?.header);
        setBannerContent(res.data?.banner);
        setAwards(res.data?.awards);
        setHighlightedAwards(
          res.data?.awards
            .filter((award) => award.isHighlighted)
            .sort((a, b) => (a.id < b.id ? 1 : -1))
        )
        setLoadedData(true);
      }).catch(err => console.log(`Ocurrio un error al obtener los datos de la landing de awards: ${err.message}`))
    }
  }, [actualLang, token]);
  return (
    <ContentAwardsLandingContext.Provider
      value={{
        headerContent,
        isDataLoaded,
        bannerContent,
        awards,
        highlightedAwards,
      }}
    >
      {children}
    </ContentAwardsLandingContext.Provider>
  );
};
