import React from "react";
import { Container } from "react-bootstrap";
import { ResearchEngineering } from "./researchEngineering";
import { ProductEngineeringMain } from "./productEngineering/productEngineeringMain";
import { IterativeDevelopmentMain } from "./iterativeDevelopment/iterativeDevelopmentMain";
const SoftwareServiceDesc = () => {
  return (
    <>
      <Container fluid id="softwareServiceDesc">
        <Container fluid  className="oval">
          <Container>  
            <ResearchEngineering/>
            <ProductEngineeringMain />
            <IterativeDevelopmentMain />
          </Container>
        </Container>
      </Container>
    </>
  );
};

export default SoftwareServiceDesc;
