import { useEffect, useState } from "react";
import HeaderLanding from "../components/landings/header";
import Partners from "../components/home/ourPartners";
import FooterLanding from "../components/landings/footerLanding";
import "../css/landingDiscovery.css";
import BannerAlt from "../components/landings/bannerAlt";
import DetailsTwoParticipantsGeneric from "../components/landings/detailsTwoParticipantsGeneric";
import { IframeYouTube } from "../components/landings/iframeYouTube";
import { DownloadFree } from "../components/landings/downloadFree";
import { BannerMoreInfo } from "../components/landings/bannerMoreInfo";
import { WebinarSummary } from "../components/landings/webinarSummary";
import { DetailsDescription } from "../components/landings/detailsDescription";
import FormLandingEnglish from "../components/landings/formEnglish";
import { useRequestApi } from "../hooks/useRequestApi";
import { ModalDownloadFreeEnglish } from "../components/landings/modalDownloadFreeEnglish";
import { Spinner } from "react-bootstrap";
import { Helmet, HelmetProvider } from "react-helmet-async";
import FormLanding from "../components/landings/form";

export const Mse = () => {
  let pathnameWebinarMse =
    window.location.pathname === "/webinars/microsoft-dynamics-sales"
      ? "landing-ms-dynamics-sales?_locale=es"
      : "landing-ms-dynamics-sales?_locale=en";
  const {
    header,
    bannerImg,
    dataSpeaker,
    imgSpeakerOne,
    imgSpeakerTwo,
    bannerBox,
    bannerDownload,
    bannerImgMobile,
    bannerBoxImg,
    contact,
    contactEvent,
    postWebinarDesc,
    dataSaler,
    dataSalerImg,
    FormDownloadData,
    sticker,
    requestApiMse,
    loader,
  } = useRequestApi();
  useEffect(() => {
    requestApiMse(pathnameWebinarMse);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const {
    Active, //esta activa la webinar
    titleForm, //se usa en banner del header
    titleWebinarMain, //lo que va antes a los dos puntos
    titleWebinar, //el titulo principal lo que va luego de los dos puntos
    titleWebinar2, // webinar:
    date,
    subtitleHeader,
    hour,
    imageModal,
    phoneCompany,
    shortUrl, //usado en el mobile
    dowloadFreeMobile,
    buttonWatchWebinar,
    buttonParticipateWebinar,
  } = header;

  const {
    paragraph,
    speakerJob1,
    speakerJob2,
    company,
    speaker1,
    speaker2,
    descriptionBold,
    descriptionBold2,
    title,
    exposes,
  } = dataSpeaker;

  const { nameLastName, phone, email, phoneOp, position, needInformation } =
    dataSaler;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const url = process.env.REACT_APP_URL + "/webinars/ms-dynamics-sales";
  return loader ? (
    <Spinner />
  ) : (
    <HelmetProvider>
      <Helmet>
        <title>{`Possumus - Microsoft Dynamics 365 Sales`}</title>
        <meta
          property="og:title"
          content="Possumus - Microsoft Dynamics 365 Sales"
        />
        <link rel="canonical" href={`${url}`} />
        <meta property="og:url" content={`${url}`} />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="Possumus" />
        <meta
          property="og:image"
          content="https://possumustech.blob.core.windows.net/staticfiles/Miniatura_OG_b0b1d5c32e.png"
        />
      </Helmet>
      <div id="talkTechSolid">
        <HeaderLanding title={titleForm} phone={phoneCompany} template="dark" />
        <BannerAlt
          idContainer="bannerLandingSolidWebinar"
          idButton="formLandingEnglish"
          href="#youtubeSection"
          idHeader="titleMainLanding"
          imgBanner={bannerImg}
          imgBannerMobile={bannerImgMobile}
          date={date}
          time={hour}
          title={titleWebinar}
          tittle2={titleWebinar2}
          active={Active}
          mainTitle={titleWebinarMain}
          template="none"
          buttonWatchWebinar={buttonWatchWebinar}
          buttonParticipateWebinar={buttonParticipateWebinar}
        />
        {Active ? (
          <DetailsTwoParticipantsGeneric
            paragraph={paragraph}
            title={title}
            sticker={sticker.url}
            description={descriptionBold2}
            descriptionParagraph2={descriptionBold}
            speakerOne={speaker1}
            speakerTwo={speaker2}
            speakerOnePuesto={speakerJob1}
            speakerTwoPuesto={speakerJob2}
            imgSpeakerOne={imgSpeakerOne.url}
            imgSpeakerTwo={imgSpeakerTwo?.url}
            bannerText={bannerBox.BannerText}
            bannerImg={bannerBoxImg.url}
            exposes={exposes}
            colImage={2}
            colPost={3}
            colorText={"textBlack"}
            company={company}
          />
        ) : (
          <>
            <WebinarSummary
              columnOne={postWebinarDesc.columnOne}
              columnTwo={postWebinarDesc.columnTwo}
              columnThree={postWebinarDesc.columnThree}
              columnFour={postWebinarDesc.columnFour}
              postWebinarData={postWebinarDesc?.title}
            />
            <DetailsDescription
              description={descriptionBold}
              exposes={exposes}
              descriptionParagraph2={descriptionBold2}
              speakerOne={speaker1}
              speakerTwo={speaker2}
              speakerOnePuesto={speakerJob1}
              speakerTwoPuesto={speakerJob2}
              imgSpeakerOne={imgSpeakerOne.url}
              imgSpeakerTwo={imgSpeakerTwo?.url}
              bannerText={bannerBox.BannerText}
              bannerImg={bannerBoxImg.url}
              sticker={sticker.url}
              colorText={"textBlack"}
              company={company}
              speakerOnePosition={speakerJob1}
              speakerTwoPosition={speakerJob2}
            />
          </>
        )}
        {!Active ? (
          <IframeYouTube
            id={contact.idYoutube}
            title={contact.videoTitle}
            active={Active}
          />
        ) : (
          <div style={{ marginBottom: 137 }}></div>
        )}
        <DownloadFree
          imageBackgroudMobile={dowloadFreeMobile?.url}
          handleShow={handleShow}
          active={Active}
          download={contact.download}
          textBanner={bannerDownload.bannerText}
          id="formLandingEnglish"
        />
        {dataSaler && (
          <BannerMoreInfo
            completeName={nameLastName}
            email={email}
            phone={phone}
            phoneOp={phoneOp}
            imageSalesConsultant={dataSalerImg?.url}
            position={position}
            active={Active}
            needInformation={needInformation}
          />
        )}
        {window.location.pathname === "/webinars/microsoft-dynamics-sales" ? (
          <FormLanding
            idButton="formLandingEnglish"
            active={Active}
            titleActive={contact.formTitle}
            titleEvent={contactEvent?.formTitle}
            contactOrigin={
              Active ? contact.originContact : contactEvent?.Origen_contacto
            }
          />
        ) : (
          <FormLandingEnglish
            active={Active}
            idButton="formLandingEnglish"
            titleActive={contact.formTitle}
            titleEvent={contactEvent?.formTitle}
            contactOrigin={
              Active ? contact.originContact : contactEvent?.Origen_contacto
            }
          />
        )}
      </div>
      <Partners />
      <FooterLanding title={subtitleHeader} />
      <ModalDownloadFreeEnglish
        formData ={FormDownloadData}
        imgModal={imageModal?.url}
        show={show}
        onHide={handleClose}
        url={shortUrl}
        data={header?.pdfFile?.url}
        id="formLandingEnglish"
      />
    </HelmetProvider>
  );
};

export default Mse;
