export const bannerStaff = {
  title: "Low cost, top quality",
  subtitle:
    "Remove the barriers to growth by scaling your team fast with top-notch Latin American IT talent.",
  buttonConsult: "Get Free Consultation",
  getStarted: "Get started",
  certificatePart1: "Our processes are accredited by ISO 9001:2015",
  certificatePart2:
    "certification quality practices for software product development.",
};
export const staffHomeResume = {
  title: "Staff Augmentation",
  paragraph1:
    "A team’s capability is fundamental to the growth of each project. As it reaches new levels of maturation, acquiring new talent, that is specialized and prepared to assume specific roles becomes key to gaining traction in the markets.",
  paragraph2: "Our",
  teamStaffing: " IT staffing teams ",
  paragraph2Part2:
    "have the professionalism to consolidate themselves in each stage of your project, transforming with capacity, flexibility, and acceleration in each process of scale, attending to the needs and requirements at all times.",
  paragraph3: "Through  ",
  paragraph4:
    " you gain a differential advantage. It provides you with a team that responds exclusively to your organization, attending regular meetings, following your guidelines, and reporting continuously to your managers.",
  paragraph5:
    "Regardless of the complexity and type of development project you need to undertake, our teams are at your disposal for prototyping, full-scale development cycles, mobile, desktop, web, and system management applications.",
  buttonWhat: "What can we do for you?",
};

export const softwareServiceStaffSection = {
  title: "Software Services",
  paragraph1:
    "Currently the software development market involves the incorporation of teams with specialized skills, talent and experience. For companies around the world, it is the best way to access expert development engineers capable of working with technological skill variables, from UX design to Blockchain consulting, optimizing process performance, obtaining better software development results, and improving the profitability of your project. ",
  paragraph2: "At",
  company: " Possumus, ",
  paragraph2Part2:
    "we specialize in intelligent customizations of previously developed platforms and in the delivery of full-cycle custom solutions. We are fully prepared to provide you with reliable, high-value software solutions you need.",
  buttonHelp: "See how we can help",
  title2: "Companies eligible for our services",
  paragraph1t2:
    "Our teams consist of senior professionals who, based on their talent and skills, drive the transformation of projects to new levels.",
};

export const technologiesStaffSection = {
  title: "Technologies",
  paragraph1:
    "Although our services can cover a broad range of technologies, 70% of our team consists of professionals specialized in:",
  buttonConnectWith: "Connect with us",
};

export const timeZoneStaffSection = {
  title: "Time zone alignment",
  paragraph1:
    "Possumus developers always match a range of hours with their North American peers, regardless of their time zone. So they can attend virtual meetings and calls, respond to emails and work alongside clients for seamless integration throughout the day.",
  paragraph2:
    "This facilitates real-time project management and efficient feedback cycles for faster delivery rates.",
  title2: "Time zone",
  paragraph1t2:
    "Argentina is located in UTC-3, meaning we can easily adapt and share most of our working day with your team, whether you are based on the east or the west coast.",
  buttonPutTalent: "Put Top Talent to work for you",
};

export const onshoreStaffSection = {
  title: "Onshore, nearshore or offshore: ",
  paragraph1: "What hiring model is suitable for your project?",
  paragraph2:
    "Let us help you define the model that best suits your company and projects:",
  listitem1: "Onshore: Teams located in your country or region.",
  listitem2:
    "Offshore: Teams located in another country with a different time zone.",
  listitem3:
    "Nearshore: Teams located in a neighboring country or in proximity.",
  paragraph3:
    "In software development projection, there's no need to assemble new in-house teams unless it's your desire. You can incorporate the ideal nearshore or offshore exclusive team today, equipped with all the required skills to develop and scale your project.",
  titlecol1: "Onshore",
  subtitlecol1: "Software development",
  paragraphcol1:
    "Company located in California and their outsourcing partner in New Jersey. Operating in different states but within the same country.",
  titlecol2: "Nearshore",
  subtitlecol2: "Software development",
  paragraphcol2: "US company that outsources from Canada, Mexico or Argentina.",
  titlecol3: "Offshore",
  subtitlecol3: "Software development",
  paragraphcol3:
    "American company hires outsourcing services from Asia, including India, Singapore, Vietnam, etc.",
};

export const nearshoreStaffSection = {
  title: "Nearshore",
  paragraph1:
    "Over the past decade, the nearshore software development sector has witnessed a substantial growth with numerous US companies adopting it continuously, even though some of them prefer to keep this confidential. The most important aspect that pushes companies from the US or Europe to outsource their software development lies in financial considerations. Having an in-house team is considerably more expensive than outsourcing the services of a whole team. It is important to highlight that cheaper does not necessarily mean lower quality.",
  title2: "Low cost, top quality",
  paragraph1t2:
    "Over the past decade, Argentina has proven to be a reliable and cost-effective option for outsourcing. The low value of the Argentine currency compared to the US Dollar allows our IT companies to have the most competitive prices in the global market. When you pair low prices with all the other reasons listed in this article, you'll understand why Argentina is the top destination to outsource any software development work and save lots on costs.",
  buttonLets: "Let's talk",
};

export const companyStaffSection = {
  titlecol1: "Different language? Not a problem for us",
  paragraphcol1: `Communication is key in the development of projects of any kind, but specially when teams are located in different countries. According to the English Proficiency Index elaborated by EF, Argentina ranks first in Latin America and is the only country having a score of "High proficiency" in the region. Having high proficiency means Argentines can make a presentation at work, understand TV shows or read a newspaper without any problem. This means that when you hire a dev team or just a developer in Argentina, you can expect them to be part of your team meetings and communicate progress or issues as if they were part of your in-house team.`,
  titlecol2: "But what about tech skills?",
  paragraph1col2: `So far you might be thinking "yeah, those guys speak good English, have a timezone similar to ours, but how do they rank on technology skills?". The Coursera Global Skills Index unites Coursera’s deep expertise in data and education to highlight the world’s top trending career skills. In the Technology category,  Argentina ranks 1st in the world. We are at the top of the list of 15 countries that have cutting edge skills on Technology, and we are the only Latin American country in the cutting edge list.`,
  paragraph2col2:
    "Some of the competencies that Coursera lists within the Technology category are Computer Networking, Operating Systems, Databases and Software Engineering.",
  titlecol3: "Culture",
  paragraph1col3:
    "Even though there's a ~9000km (~5,600mi) distance between us, Argentines and Americans are very similar in terms of culture in general. Both countries are very similar in terms of working culture, especially when talking about the IT industry. Argentine working environments are pretty much the same as any of the major IT companies in the US.",
  paragraph2col3:
    "We share a similar mindset and lifestyle with American people. This is key in understanding your project requirements and it allows us to share our point of view about your app features. We can see your projects both as developers as well as potential users because of our cultural similarity.",
};
