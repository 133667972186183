import "../../../css/staff.css";
import React, { Fragment } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import FirstImg from "../../../assets/elementos-bd-ilustracion-stf.svg";
import { useTranslation } from "react-i18next";
import { bannerStaff } from "../../../config/StaffAugmentation/textPage";
import IsoCertificateAndWeLoveDev from "../../commons/IsoCertificateAndWeLoveDev";

const StaffHeader = ({ titulo, bajada }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Container fluid id="mainbanner-it" className="background-banner-header">
        <Container>
          <Row className="d-flex justify-content-center">
            <Col lg={7} xs={12} md={10}>
              <h1>
                {titulo}
              </h1>
              <h3>
                {bajada}
              </h3>
              <div className="cta">
                <Button variant="primary" href="#staff">
                  {t("buttonsAndAccesories.getStarted", {
                    getStarted: `${bannerStaff.getStarted}`,
                  })}
                </Button>
                <Button variant="secondary" href="#getInTouch">
                  {t("buttonsAndAccesories.consult", {
                    consult: `${bannerStaff.buttonConsult}`,
                  })}
                </Button>
              </div>
            </Col>
            <Col lg={5} sm={12}>
              <img
                src={FirstImg}
                className="img-center"
                alt="Accelerate your digital transformation"
              ></img>
            </Col>
          </Row>
          <IsoCertificateAndWeLoveDev />
        </Container>
      </Container>
    </Fragment>
  );
};

export default StaffHeader;