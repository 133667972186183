export const getInTouchEs = {
  getInTouchBanner: {
    title: "Contacto",
    title2: "¡Estemos en contacto!",
    paragraph:
      "Si buscas la transformación digital para tu empresa u organización, podemos ayudarte. No dude en ponerse en contacto con nosotros para comentar sus ideas y proyectos, y facilitarle un presupuesto acorde a sus necesidades.",
    subtitle: "¿Listo para construir tu software?",
  },
  getInTouchForm: {
    title: "Detalles de contacto",
    firstName: "Nombre completo*",
    phone: "Número de teléfono:",
    message: "Mensaje:",
  },
};
export const getInTouchEn = {
  getInTouchBanner: {
    title: "{{title}}",
    title2: "{{title2}}",
    subtitle: "{{subtitle}}",
    paragraph: "{{paragraph}}",
  },
  getInTouchForm: {
    title: "{{title}}",
    firstName: "{{firstName}}",
    phone: "{{phone}}",
    message: "{{message}}",
  },
};
