import { Container, Row } from "react-bootstrap";
import Loader from "./loader";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import "../../css/trusted.css";
import { useTranslation } from "react-i18next";
import { trusted } from "../../config/home/textPage";

const TrustedUsa = () => {
  const { t, i18n } = useTranslation();
  const [listLogos, setlistLogos] = useState({});
  const [listLogosEs, setlistLogosEs] = useState({});
  const [loadedData, setLoadedData] = useState(false);
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL_API}/page-usa`).then((res) => {
      setlistLogos(res.data.element.logos);
      setlistLogosEs(res.data.element.logosEs);
      setLoadedData(true);
    });
  }, []);

  return (
    <>
      <Container
        fluid
        id="logos"
        className="d-none d-sm-none d-md-none d-lg-block"
      >
        <Container className="paddingRight" fluid>
          <Row>
            <ul class="list-group list-group-horizontal">
              {loadedData ? (
                <>
                  <li class="list-group-item borderRectangle align">
                    {t("trusted.title", {
                      title: `${trusted.title}`,
                    })}
                  </li>
                  {loadedData && i18n.language === "en"
                    ? listLogos.map((logo) =>
                        logo.name === "div.client-count.svg" ? (
                          <li
                            class="list-group-item-pos-5 borderRectangle align"
                            key={logo.id}
                          >
                            <img
                              src={logo.url}
                              alt=""
                              className="img-fluid"
                            ></img>
                          </li>
                        ) : (
                          <li
                            class="list-group-item borderRectangle align"
                            key={logo.id}
                          >
                            <img
                              src={logo.url}
                              alt=""
                              className="img-fluid"
                            ></img>
                          </li>
                        )
                      )
                    : listLogosEs.map((logo) =>
                        logo.name === "div.client-count.svg" ? (
                          <li
                            class="list-group-item-pos-5 borderRectangle align"
                            key={logo.id}
                          >
                            <img
                              src={logo.url}
                              alt=""
                              className="img-fluid"
                            ></img>
                          </li>
                        ) : (
                          <li
                            class="list-group-item borderRectangle align"
                            key={logo.id}
                          >
                            <img
                              src={logo.url}
                              alt=""
                              className="img-fluid"
                            ></img>
                          </li>
                        )
                      )}
                </>
              ) : (
                <Loader />
              )}
            </ul>
          </Row>
        </Container>
      </Container>
      <Container fluid className="d-block d-sm-block d-md-block d-lg-none ">
        <Container>
          <Row>
            <marquee>
              {loadedData ? (
                <>
                  {listLogos.map((logo, i) => (
                    <img
                      key={i}
                      className="marginRight"
                      src={logo.url}
                      alt=""
                    ></img>
                  ))}
                </>
              ) : (
                <Loader />
              )}
            </marquee>
          </Row>
        </Container>
      </Container>
    </>
  );
};
export default TrustedUsa;
