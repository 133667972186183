import React, { Fragment, useContext } from "react";
import "../../css/softwareServices.css";
import SoftwareServiceDesc from "../../components/softwareServices/softwareServiceDesc";
import { CardsSoftwareEngineering } from "../../components/commons/cardsSoftwareEngineering";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Layout from "./Layout";
import { ActualLangContext } from "../../contexts/ActualLangContext";
import useGetHeaderContent from "../../hooks/useGetHeaderContent";
import { SEHeader } from "../../components/landings/comercial/seHeader";

const SoftwareEngineeringLanding = () => {
  const {actualLang} = useContext(ActualLangContext)
  const url = process.env.REACT_APP_URL + actualLang === 'en' ? "/services/software-engineering" : "/servicios/software-engineering";

  const {titulo, bajada} = useGetHeaderContent('software-engineering')
  return (
    <Fragment>
      <HelmetProvider>
        <Helmet>
          <title>{`Possumus - Software Engineering`}</title>
          <link rel="canonical" href={`${url}`} />
          <meta property="og:url" content={`${url}`} />
          <meta property="og:type" content="article" />
          <meta property="og:site_name" content="Possumus" />
          <meta property="og:title" content="Possumus - Software Engineering" />
          <meta
            property="og:image"
            content="https://possumustech.blob.core.windows.net/staticfiles/Miniatura_OG_b0b1d5c32e.png"
          />
        </Helmet>
        <Layout>
          <SEHeader titulo={titulo} bajada={bajada}/>
          <CardsSoftwareEngineering />
          <SoftwareServiceDesc />
        </Layout>
      </HelmetProvider>
    </Fragment>
  );
};

export default SoftwareEngineeringLanding;
