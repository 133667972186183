import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import imgMain from "../../../assets/toyIS.svg";
import { useTranslation } from "react-i18next";
import { bannerDownload } from "../../../config/Commons/textPage";
import IsoCertificateAndWeLoveDev from "../../commons/IsoCertificateAndWeLoveDev";
export const SEHeader = ({ titulo, bajada }) => {
  const { t } = useTranslation();
  return (
    <>
      <Container
        fluid
        className="d-none d-sm-none d-md-none d-lg-block imageBackGroundUsa background-banner-header"
      >
        <Container id="mainbanner">
          <Row>
            <Col lg={7}>
              <h1>{titulo}</h1>
              <h3>{bajada}</h3>
              <div className="cta">
                <Button variant="primary" href="#getInTouch">
                  {t("buttonsAndAccesories.buttonGetFree", {
                    buttonGetFree: `${bannerDownload.buttonGetFree}`,
                  })}
                </Button>
              </div>
            </Col>
            <Col lg={5}>
              <img
                align="right"
                src={imgMain}
                className="img-fluid"
                alt="Accelerate your digital transformation"
              ></img>
            </Col>
          </Row>
          <IsoCertificateAndWeLoveDev />
        </Container>
      </Container>
      {/* MOBILE */}
      <Container
        fluid
        className="d-block d-sm-block d-md-block d-lg-none imageBackGroundMobileSE background-banner-header"
      >
        <Container>
          <Row>
            <Col sm={12} id="mainbannerMobile">
              <h1>{titulo}</h1>
              <h3>{bajada}</h3>
            </Col>
          </Row>
          <Row className="justify-content-center align-items-center d-flex">
            <Col
              sm={12}
              className="justify-content-center align-items-center d-flex"
            >
              <Button
                variant="primary"
                href="#getInTouch"
                className="paddingButtonMainBanner"
              >
                {t("buttonsAndAccesories.buttonGetFree", {
                  buttonGetFree: `${bannerDownload.buttonGetFree}`,
                })}
              </Button>
            </Col>
            <Col sm={6} className="col-image-people">
              <img
                align="right"
                src={imgMain}
                className="img-fluid"
                alt="Accelerate your digital transformation"
              ></img>
            </Col>
          </Row>
          <IsoCertificateAndWeLoveDev />
        </Container>
      </Container>
    </>
  );
};
