import "./WeSpecialize.css";
import { Container, Image } from "react-bootstrap";

const CARDS = [
  {
    title: "Artificial Intelligence",
    description:
      "Discover how our AI technology is revolutionizing software development by delivering cutting-edge solutions across key sectors.",
    icon: "",
  },
  {
    title: "Microsoft Tools Integration",
    description:
      "Simplify and streamline your business processes with our Microsoft tools integration, no deep programming knowledge required (Low-Code/No-Code).",
    icon: "",
  },
  {
    title: "Software Engineering",
    description:
      "Create high-quality software and reduce development times with our Software Engineering model (EDA).",
    icon: "",
  },
  {
    title: "Staff Augmentation",
    description:
      "Expand your team with top-tier IT specialists to efficiently achieve your business objectives.",
    icon: "",
  },
];

export default function WeSpecialize() {
  return (
    <Container className="weSpecialize" id="ourExpertise">
      <h2>Our expertise:</h2>
      <div className="cardContainer">
        {CARDS.map((card, idx) => (
          <article key={`card-${card.title}-${idx}`}>
            {card.icon && <Image src={card.icon} alt={card.title} />}
            <h4>{card.title}</h4>
            <p>{card.description}</p>
          </article>
        ))}
      </div>
    </Container>
  );
}
