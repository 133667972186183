import { useEffect, useState } from "react";
import "../css/landing.css";
import "../css/productividad365.css";
import HeaderLanding from "../components/landings/header";
import BannerAlt from "../components/landings/bannerAlt";
import FormLanding from "../components/landings/form";
import FooterLanding from "../components/commons/footerLanding";
import Descripcion from "../components/landings/descripcion";
import Modulos365 from "../components/landings/modulos";
import DetailsTwoParticipantsOpc from "../components/landings/detailsTwoParticipantsOpc";
import { DownloadFree } from "../components/landings/downloadFree";
import { BannerMoreInfo } from "../components/landings/bannerMoreInfo";
import { WebinarSummary } from "../components/landings/webinarSummary";
import { IframeYouTube } from "../components/landings/iframeYouTube";
import { ModalDownloadFree } from "../components/landings/modalDownloadFree";
import { useRequestApi } from "../hooks/useRequestApi";
import Partners from "../components/home/ourPartners";
import { Spinner } from "react-bootstrap";
import { Helmet, HelmetProvider } from "react-helmet-async";

export const Productividad2023 = () => {
  const {
    dataBdd,
    dataDetails,
    dataModules,
    dataParticipants,
    dataCta,
    postWebinarData,
    postWebinarDesc,
    imgModal,
    imageBackgroudMobile,
    salesConsultant,
    bannerImg,
    contact,
    contactEvent,
    bannerImgMobile,
    requestApiSreProductividad,
    loader,
  } = useRequestApi();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    requestApiSreProductividad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const url =
    process.env.REACT_APP_URL + "/webinars/productividad-microsoft-365";
  return loader ? (
    <Spinner />
  ) : (
    <HelmetProvider>
      <Helmet>
        <title>{`Possumus - Productividad Microsoft 365`}</title>
        <meta
          property="og:title"
          content="Possumus - Productividad Microsoft 365"
        />
        <link rel="canonical" href={`${url}`} />
        <meta property="og:url" content={`${url}`} />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="Possumus" />
        <meta
          property="og:image"
          content="https://possumustech.blob.core.windows.net/staticfiles/Miniatura_OG_b0b1d5c32e.png"
        />
      </Helmet>
      <div id="talkTechSolid">
        <HeaderLanding
          title={dataBdd.Titulo}
          phone="0810 345 0562"
          template="ligth"
        />
        <BannerAlt
          idContainer="bannerLandingSolid"
          idButton="formLanding"
          idHeader="titleMainLanding"
          href="#formLanding"
          imgBanner={bannerImg}
          imgBannerMobile={bannerImgMobile}
          date={dataBdd.Fecha}
          time={dataBdd.Hora}
          tittle2={dataBdd.Subtitulo}
          title={dataBdd.Titulo}
          active={dataBdd.Activa}
          template="none"
          buttonWatchWebinar={"Ver webinar"}
          buttonParticipateWebinar={"Quiero participar"}
        />
        {dataBdd.Activa ? (
          <>
            <Descripcion dataDetails={dataDetails} />
            <Modulos365 dataModules={dataModules} titulo="Hola Mundo" />
          </>
        ) : (
          <WebinarSummary
            columnOne={postWebinarDesc.columnOne}
            columnTwo={postWebinarDesc.columnTwo}
            columnThree={postWebinarDesc.columnThree}
            columnFour={postWebinarDesc.columnFour}
            postWebinarData={postWebinarData?.Title}
          />
        )}

        <DetailsTwoParticipantsOpc
          active={dataBdd.Activa}
          backgroundImage={postWebinarData?.fondo_presentadores?.url}
          dataParticipants={dataParticipants}
          dataCta={dataCta}
        />
        <IframeYouTube
          id={contact.Id_Youtube}
          title={contact.Video_title}
          active={dataBdd.Activa}
        />
        <DownloadFree
          imageBackgroudMobile={imageBackgroudMobile}
          imageBackgroud={imageBackgroudMobile}
          handleShow={handleShow}
          download={contact.download}
          active={dataBdd.Activa}
          textBanner={dataBdd.texto_banner_free}
          id="formLanding"
        />
        <BannerMoreInfo
          completeName={salesConsultant.name_last_name}
          email={salesConsultant.email}
          phone={salesConsultant.phone}
          phoneOp={salesConsultant.phone_Op}
          imageSalesConsultant={salesConsultant.Image_sales_consultant?.url}
          position={salesConsultant.position}
          active={dataBdd.Activa}
          needInformation={salesConsultant.needInformation}
        />
        <FormLanding
          idButton={"formLanding"}
          active={dataBdd.Activa}
          titleActive={contact.Titulo_Formulario}
          titleEvent={contactEvent?.formTitle}
          contactOrigin={
            dataBdd.Activa
              ? contact.Origen_Contacto
              : contactEvent?.Origen_Contacto
          }
        />
        <Partners />
        <FooterLanding title={dataBdd.Subtitulo} />
      </div>
      <ModalDownloadFree
        imgModal={imgModal}
        show={show}
        onHide={handleClose}
        url={"landing-productividad-2023"}
        data={dataBdd.Post_webinar?.file_pdf?.url}
        id="formLanding"
      />
    </HelmetProvider>
  );
};

export default Productividad2023;
