import { MSSeparator } from "../../commons/MSSeparator/MSSeparator";
import MSPartnerSolutions from "../../../assets/logos/MSSolutionPartner.svg";
import "./MSSeparator.css";

const MSSeparatorItems = [
  "Business Applications",
  "Data & AI",
  "Digital & App Innovation",
  "Infrastructure",
  "Security",
  "Modern Work",
];

export const HomeMSSeparator = ({ items = MSSeparatorItems }) => (
  <MSSeparator className="homeMSSeparatorContainer">
    <div className="titleAndImageContainer">
      <MSSeparator.Title>
        We are a Microsoft Solutions Partner
      </MSSeparator.Title>
      <MSSeparator.Image src={MSPartnerSolutions} width={248} alt="MSSolutionPartner" />
    </div>
    <MSSeparator.ItemsList items={items} />
  </MSSeparator>
);
