import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Row } from "react-bootstrap";
import Loader from "./loader";
import { useTranslation } from "react-i18next";
import { trusted } from "../../config/home/textPage";

const Trusted = () => {
  const { t } = useTranslation();
  const [trustedIMG, settrustedIMG] = useState({});
  const [loadedData, setLoadedData] = useState(false);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL_API}/pages-home`).then((res) => {
      settrustedIMG(res.data.TrustedBy.Logos);
      setLoadedData(true);
    });
  }, []);

  return (
    <Container fluid id="trusted">
      <Container>
        <Row>
          <ul class="list-group list-group-horizontal">
            <li class="list-group-item ">
              {t("trusted.title", {
                title: `${trusted.title}`,
              })}
            </li>

            {loadedData ? (
              trustedIMG.map((trusted) => (
                <li class="list-group-item " key={trusted.id}>
                  <img src={trusted.url} alt="Banco Santander"></img>
                </li>
              ))
            ) : (
              <Loader />
            )}
          </ul>
        </Row>
      </Container>
    </Container>
  );
};

export default Trusted;
