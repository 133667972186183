import { BannerCultureDesk } from "./bannerCultureDesk";
import { BannerCultureMobile } from "./bannerCultureMobile";


export const BannerCulture = ({
  description,
  title,
  imgBanner,
  template,
  titleLandingPart1,
  titleLandingPart2,
  idContainer,
  idHeader,
  logoPossumus,
  imagePeople,
}) => {
  return (
    <>
   <BannerCultureDesk
        imgBanner={imgBanner}
        description={description}
        title={title}
        template={template}
        idContainer={idContainer}
        idHeader={idHeader}
        logoPossumus={logoPossumus}
        imagePeople={imagePeople}
        titleLandingPart1={titleLandingPart1}
        titleLandingPart2={titleLandingPart2}
      />
      <BannerCultureMobile
        description={description}
        title={title}
        template={template}
        idContainer={idContainer}
        idHeader={idHeader}
        logoPossumus={logoPossumus}
        imagePeople={imagePeople}
        titleLandingPart1={titleLandingPart1}
        titleLandingPart2={titleLandingPart2}
      />

     
    </>
  );
};

export default BannerCulture;
