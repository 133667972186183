import React, { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ContentSimplePayLandingContext } from "../../contexts/ContentSimplePayLandingContext";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import Loader from "../commons/loader";

const Services = () => {
  const { services } = useContext(ContentSimplePayLandingContext);

  return (
    <Container fluid id="simplePayServices">
      <Col md={{ offset: 4 }} className="d-grid">
        <h5 style={{ textAlign: "start", marginBottom: '40px'}}>{services.Titulo}</h5>
        <Col lg={8} id="simplePayServicesContainer">
          {services.service?.map((service, idx) => (
            <Row className="mb-4">
              {idx % 2 === 0 ? (
                <>
                  <Col lg={2}>
                    {service.Imagen?.url ? (
                      <img
                        src={service.Imagen?.url}
                        className="img-center"
                        alt="Accelerate your digital transformation"
                      />
                    ) : (
                      <Loader />
                    )}
                  </Col>
                  <Col lg={7} md={{offset:3}}>
                    <h5 style={{ textAlign: "start" }}>{service.Titulo}</h5>
                    <p className="subtitle">
                      <ReactMarkdown>{service.Descripcion}</ReactMarkdown>
                    </p>
                  </Col>
                </>
              ) : (
                <>
                  <Col lg={7} >
                    <h5 style={{ textAlign: "start" }}>{service.Titulo}</h5>
                    <p className="subtitle">
                      <ReactMarkdown>{service.Descripcion}</ReactMarkdown>
                    </p>
                  </Col>
                  <Col lg={2} md={{offset: 2}}>
                    {service.Imagen?.url ? (
                      <img
                        src={service.Imagen?.url}
                        className="img-center"
                        alt="Accelerate your digital transformation"
                      />
                    ) : (
                      <Loader />
                    )}
                  </Col>
                </>
              )}
            </Row>
          ))}
        </Col>
      </Col>
    </Container>
  );
};

export default Services;
