import { useEffect, useState } from "react";
import axios from "axios";
import HeaderLanding from "../components/landings/header";
import BannerAlt from "../components/landings/bannerAlt";
import FormLanding from "../components/landings/form";
import FooterLanding from "../components/landings/footerLanding";
import Details from "../components/landings/details";
import { IframeYouTube } from "../components/landings/iframeYouTube";
import "../css/landing.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Partners from "../components/home/ourPartners";
export const VivaGoals = () => {
  const [dataGoals, setdataGoals] = useState({});
  const [bannerImg, setBannerImg] = useState();
  const [bannerImgMobile, setBannerImgMobile] = useState({});
  const [contactEvent, setContactEvent] = useState({});
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL_API}/landing-viva-goals`)
      .then((res) => {
        setdataGoals(res.data);
        setBannerImg(res.data.Banner.url);
        setBannerImgMobile(res.data.Banner_Mobile.url);
        setContactEvent(res.data.Contact_eventos_viva);
      });
  }, []);
  const url = process.env.REACT_APP_URL + "/webinars/viva-goals";
  return (
    <HelmetProvider>
      <Helmet>
        <title>{`Possumus - Viva goals`}</title>
        <meta property="og:title" content="Possumus - Viva goals" />
        <link rel="canonical" href={`${url}`} />
        <meta property="og:url" content={`${url}`} />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="Possumus" />
        <meta
          property="og:image"
          content="https://possumustech.blob.core.windows.net/staticfiles/Miniatura_OG_b0b1d5c32e.png"
        />
      </Helmet>
      <div id="talkTechSolid">
        <HeaderLanding
          title={dataGoals.Titulo_1 + dataGoals.Titulo_2}
          phone="0810 345 0562"
        />
        <BannerAlt
          idContainer="bannerLandingSolid"
          idButton="formLanding"
          href="#formLanding"
          idHeader="titleMainLanding"
          imgBanner={bannerImg}
          imgBannerMobile={bannerImgMobile}
          date={dataGoals.Fecha}
          time={dataGoals.Hora}
          tittle2={`${dataGoals.Subtitulo}`}
          title={`${dataGoals.Titulo_1} ${dataGoals.Titulo_2}`}
          active={dataGoals.Activa}
          buttonWatchWebinar={"Ver webinar"}
          buttonParticipateWebinar={"Quiero participar"}
        />
        <Details />
        <IframeYouTube
          id={dataGoals.Id_Youtube}
          title={"¡Ya disponible en YouTube!"}
          active={dataGoals.Activa}
        />
        <div className="separatorLandingViva"></div>
        <FormLanding
          active={dataGoals.Activa}
          titleActive={dataGoals.Titulo_Form}
          titleEvent={contactEvent?.formTitle}
          titleInactive={dataGoals.Titulo_Video}
        />
        <Partners />
        <FooterLanding title={dataGoals.Subtitulo} />
      </div>
    </HelmetProvider>
  );
};

export default VivaGoals;
