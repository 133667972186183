export const buttonsAndAccesoriesEs = {
  buttonsAndAccesories: {
    consult: "Obtén una consulta gratuita",
    ourServices: "Nuestros servicios",
    getStarted: "Empezar ahora",
    certificatePart1:
      "Nuestros procesos están acreditados por la norma ISO 9001:2015",
    certificatePart2:
      "prácticas de certificación de calidad para el desarrollo de productos de software.",
    downloadText: "Descargue nuestro brochure",
    buttonDownload: "Descargar",
    buttonJob: "Ver nuestras ofertas de trabajo",
    buttonSendResume: "Enviar curriculum",
    buttonCall: "Llámanos ya",
    buttonGetFree: "Obtén una consulta gratuita",
    buttonGetInTouch: "Enviar",
    salesConsultant: "Consultor de ventas",
  },
};
export const buttonsAndAccesoriesEn = {
  buttonsAndAccesories: {
    consult: "{{consult}}",
    getStarted: "{{getStarted}}",
    ourServices: "{{ourServices}}",
    certificatePart1: "{{certificatePart1}}",
    certificatePart2: "{{certificatePart2}}",
    downloadText: "{{downloadText}}",
    buttonDownload: "{{buttonDownload}}",
    buttonJob: "{{buttonJob}}",
    buttonSendResume: "{{buttonSendResume}}",
    buttonCall: "{{buttonCall}}",
    buttonGetFree: "{{buttonGetFree}}",
    buttonGetInTouch: "{{buttonGetInTouch}}",
    salesConsultant: "{{salesConsultant}}",
  },
};
