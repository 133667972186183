import React, { Fragment } from "react";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import { IframeYouTubeCulture } from "../../components/landings/iframeYouTubeCulture.js";
import arrowDown from "../../assets/ArrowDown.svg";
import circle from "../../assets/circuloRayas.png";
import { Chief } from "../../components/landings/chief";
import { useTranslation } from "react-i18next";
import { bodyCulture } from "../../config/culture/textPage.js";
import { infoVideos } from "./infoDataBenefits.js";
export const BodyCulture = ({
  videos,
  name,
  positionWork,
  image,
  imageIcon,
}) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Container fluid className="positionCircle">
        <div className="circle d-none d-sm-none d-md-block">
          <img alt="alt-text" src={circle} />
        </div>
        <Row
          className="justify-content-center align-items-center d-flex"
          id="videosPossumus"
        >
          <Col
            lg={10}
            className="justify-content-center align-items-center d-flex containerArrow"
          >
            <Image src={arrowDown} alt="" className="mb-4" />
          </Col>
        </Row>
        <Row className="justify-content-center align-items-center d-flex">
          <Col
            lg={10}
            className="justify-content-center align-items-center d-flex"
          >
            <h2 className="text-center mt-4 titlePossumusCulture">
              {t("bodyCulture.growIntoPossumus", {
                growIntoPossumus: `${bodyCulture.growIntoPossumus}`,
              })}
            </h2>
          </Col>
        </Row>

        <Row className="justify-content-md-center text-center  mt-4">
          <Col lg={12} md={8} xl={6}>
            <h3 className="textgrowthPossumus">
              {t("bodyCulture.growIntoPossumusDescription", {
                growIntoPossumusDescription: `${bodyCulture.growIntoPossumusDescription}`,
              })}
            </h3>
          </Col>
        </Row>

        <Row className="mt-4 d-none d-sm-none d-md-block">
          <Col className="justify-content-center align-items-center d-flex ">
            {videos.map((video, index) => (
              <Button
                variant="secondary"
                href={`#${video.people}`}
                className=" buttonCulture"
                key={index}
              >
                {video.people}
              </Button>
            ))}
          </Col>
        </Row>
        <Row className="mt-4  d-block d-sm-block d-md-none d-lg-none">
          {videos.map((video, index) => (
            <Col
              className="justify-content-center align-items-center d-flex "
              sm={12}
              key={index}
            >
              <Button
                variant="secondary"
                href={`#${video.people}`}
                className=" buttonCultureMobile"
                size="lg"
              >
                {video.people}
              </Button>
            </Col>
          ))}
        </Row>
      </Container>
      <Container fluid className="containerBackgrond">
        {i18n.language === "en"
          ? videos.map((video, index) => {
              return (
                <Fragment key={index}>
                  <div id={`${video.people}`}>
                    <IframeYouTubeCulture id={video.url} />
                    <Container>
                      <Row className="d-flex justify-content-center align-items-center">
                        <Col className="col-lg-8 videoYoutubeDescription">
                          {video.description}
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </Fragment>
              );
            })
          : infoVideos.map((video, index) => {
              return (
                <Fragment key={index}>
                  <div id={`${video.people}`}>
                    <IframeYouTubeCulture id={video.url} />
                    <Container>
                      <Row className="d-flex justify-content-center align-items-center">
                        <Col className="col-lg-8 videoYoutubeDescription">
                          {video.description}
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </Fragment>
              );
            })}
      </Container>
      <Chief
        name={name}
        position={positionWork}
        img={image}
        imageIcon={imageIcon}
      />
    </>
  );
};
