export const ourPartnersEs = {
  ourPartners: {
    title: "Nuestro partner",
  },
};

export const ourPartnersEn = {
  ourPartners: {
    title: "{{title}}",
  },
};
