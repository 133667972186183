import { Button } from "react-bootstrap";
import { SideNavbarContext } from "../../contexts/SideNavbarContext";
import { useContext } from "react";

const SideNavbarItem = ({ item, setShow, id }) => {
  const {itemSelected, handleItemSelected} = useContext(SideNavbarContext)

  return (
    <Button
      className="card"
      href={"#" + item.Href}
      onClick={()=>{
        setShow && setShow(false)
        handleItemSelected(id)
      }}
    >
      {item.Titulo}
      <div className={itemSelected === id ? "sideNavbar-elipse yellow": "sideNavbar-elipse"}></div>
    </Button>
  );
};

export default SideNavbarItem;
