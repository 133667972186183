import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";
import "../../css/Navbar.css";
import { MenuUsaDesk } from "./menuUsaDesk";
import { MenuUsaMobile } from "./menuUsaMobile";
import { useContext } from "react";
import { ActualLangContext } from "../../contexts/ActualLangContext";
import { ContentLayoutContext } from "../../contexts/ContentLayoutContext";
const MenuUsa = (props) => {
  const history = useHistory();
  const [checked, setchecked] = useState(false);
  const { i18n } = useTranslation();

  const { setLang } = useContext(ActualLangContext);

  const { navbarContent } = useContext(ContentLayoutContext);

  useEffect(() => {
    let lang = localStorage.getItem("lang");
    if (lang === "es") {
      setLang("es");
      i18n.changeLanguage("es");
      localStorage.setItem("lang", "es");
      const newUrl = window.location.pathname.replace(`/en-en`, `/es-es`);
      history.push(newUrl);
      setchecked(true);
    } else if (lang === "en") {
      setLang("en");
      i18n.changeLanguage("en");
      localStorage.setItem("lang", "en");
      const newUrl = window.location.pathname.replace(`/es-es`, `/en-en`);
      history.push(newUrl);
      setchecked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function handleChange(event) {
    if (event.target.type === "checkbox") {
      setchecked(!checked);
    }
  }
  return (
    <>
      <MenuUsaDesk
        logo={navbarContent?.logo}
        options={navbarContent?.options}
        buttons={navbarContent?.buttons}
        handleChange={handleChange}
        checked={checked}
      />
      <MenuUsaMobile
        logo={navbarContent?.logo}
        options={navbarContent?.options}
        buttons={navbarContent?.buttons}
        handleChange={handleChange}
        checked={checked}
      />
    </>
  );
};
export const LanguageSwitcher = withRouter(withTranslation("routes")(MenuUsa));
export default MenuUsa;
