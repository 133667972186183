import React, { useContext, useEffect } from "react";
import MenuUsa from "../components/commons/menuUsa";
import { Footer } from "../components/commons/footer";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { ActualLangContext } from "../contexts/ActualLangContext";
import { useHistory } from "react-router";
import Header from "../components/awards/header";
import { ContentAwardsLandingContext } from "../contexts/ContentAwardsLandingContext";
import "../css/awards.css";
import HighligthedAwards from "../components/awards/highligthedAwards";
import AwardsList from "../components/awards/awardsList";
import Banner from "../components/awards/banner";
import ClutchReviews from "../components/awards/clutchReviews";
import Loader from "../components/commons/loader";
import { Container } from "react-bootstrap";
import withShadow from "../utils/WithShadow";
import { MSSeparatorAwards } from "../components/landings/awards/MSSeparator";

const LandingAwards = () => {
  const history = useHistory();
  const { actualLang } = useContext(ActualLangContext);

  const {
    headerContent,
    isDataLoaded,
    bannerContent,
    awards,
    highlightedAwards,
  } = useContext(ContentAwardsLandingContext);

  const url =
    process.env.REACT_APP_URL + actualLang === "es"
      ? "/es-es/simple-pay"
      : "/en-en/simple-pay";

  const AwardListWithBackground = withShadow(
    AwardsList,
    { top: "-10%", right: "-5%" },
    awards,
    2
  );
  useEffect(() => {
    if (actualLang === "es") {
      const newUrl = window.location.pathname.replace(
        "awards",
        "reconocimientos"
      );
      history.push(newUrl);
    } else {
      const newUrl = window.location.pathname.replace(
        "reconocimientos",
        "awards"
      );
      history.push(newUrl);
    }
  }, [actualLang, history]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{`Possumus - ${
            actualLang === "es" ? "Reconocimientos" : "Awards"
          }`}</title>
          <meta property="og:title" content="Possumus - Awards" />
          <link rel="canonical" href={`${url}`} />
          <meta property="og:url" content={`${url}`} />
          <meta property="og:type" content="article" />
          <meta property="og:site_name" content="Possumus" />
          <meta
            property="og:image"
            content="https://possumustech.blob.core.windows.net/staticfiles/Miniatura_OG_b0b1d5c32e.png"
          />
          <script type="text/javascript" src="https://widget.clutch.co/static/js/widget.js" async></script> 
        </Helmet>
        <MenuUsa />
        {isDataLoaded ? (
          <>
            <Header content={headerContent} />
            <HighligthedAwards awards={highlightedAwards} />
            <MSSeparatorAwards />
            <AwardListWithBackground />
            <ClutchReviews />
            <Banner banner={bannerContent} />
          </>
        ) : (
          <Container id="awardLoading">
            <Loader />
          </Container>
        )}
        <Footer />
      </HelmetProvider>
    </>
  );
};

export default LandingAwards;
