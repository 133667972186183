export const aboutUsEs = {
  about: {
    aboutMenuHeader:"Nosotros",
    titleLinkMenu: "Sobre nosotros",
    title: "Sobre nosotros",
    subTitle: "¿Qué nos mueve?",
    description1:
      "Creemos que la digitalización ayuda a la evolución del mundo, e integra a personas y empresas, haciendo fácil su desarrollo.",
    description2:
      "​En cada cosa que hacemos apostamos por la innovación y lo hacemos a través de una integración cuidada entre recursos humanos y tecnología.",
    whatWeAre: "Quienes somos",
  },
  bannerAboutUs: {
    title: "Qué nos mueve",
    description1: "Creemos que la digitalización ayuda a la evolución del mundo, e integra a personas y empresas, facilitando su desarrollo.",
    description2: "En todo lo que hacemos, apostamos por la innovación y lo hacemos mediante una cuidadosa integración de recursos humanos y tecnología.",
  },
  valueProposalAboutUs: {
    toptitle: "Sobre nosotros",
    title: "Propuesta de valor",
    description1: "Nuestro enfoque está basado en cuidados procesos de digitalización que se convierten en soluciones tecnológicas para todo tipo de empresas.",
    description2: "Co creamos con nuestros clientes productos de software de alta calidad, para abordar la transformación digital, siempre haciendo foco en la usabilidad y el diseño.",
  },
  ourWorkAboutUs: {
    title: "Nuestro Trabajo",
    description1: "Desarrollamos productos y servicios digitales, enfocados en la mejor experiencia de los usuarios.",
  },
};

export const aboutUsEn = {
  about: {
    aboutMenuHeader:"{{aboutMenuHeader}}",
    titleLinkMenu:"{{titleLinkMenu}}",
    title: "{{title}}",
    subTitle: "{{subTitle}}",
    description1: "{{description1}}",
    description2: "{{description2}}",
    whatWeAre: "{{whatWeAre}}",
  },
  bannerAboutUs: {
    title: "{{title}}",
    description1: "{{description1}}",
    description2: "{{description2}}",
  },
  valueProposalAboutUs: {
    toptitle: "{{toptitle}}",
    title: "{{title}}",
    description1: "{{description1}}",
    description2: "{{description2}}",
  },
  ourWorkAboutUs: {
    title: "{{title}}",
    description1: "{{description1}}",
  },
};
