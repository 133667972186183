import React, { useContext, useEffect } from "react";
import InstitutionalPartners from "../../components/houston/InstitutionalPartners";
import OurTeam from "../../components/houston/OurTeam";
import withCircle from "../../utils/WithCirlce";
import OurServices from "../../components/houston/OurServices";
import { Col } from "react-bootstrap";
import SideNavbar from "../../components/commons/SideNavbar";
import {
  SOFTWARE_ENGINEERING,
  SIDENAVBAR_ITEMS,
  TITLEANDBULLETSSECTION,
  ourTeamPpalContent,
  OUR_SERVICES,
  SERVICES,
  FOOTER_TEXT,
} from "./content";
import SoftwareEngineering from "../../components/houston/SoftwareEngineering";
import StaffAugmentation from "../../components/houston/StaffAugmentation";
import TitleAndBullets from "../../components/commons/TitleAndBullets";
import Services from "../../components/houston/Services";
import Partners from "../../components/home/ourPartners";
import Awards from "../../components/commons/Awards";
import { DownloadBanner } from "../../components/commons/downloadBanner";
import Footer from "../../components/houston/Footer";
import PpalHeader from "../../components/houston/PpalHeader";
import { SideNavbarProvider } from "../../contexts/SideNavbarContext";
import { ActualLangContext } from "../../contexts/ActualLangContext";
import brochureFile from "../../assets/landings/houston/brochure.pdf";
import brochureMockup from "../../assets/landings/houston/brochureMockup.svg";
import backgroundHeader from "../../assets/landings/houston/header.png";
import { Helmet, HelmetProvider } from "react-helmet-async";
import "../../css/houston.css";
import GetInTouch from "../../components/commons/getInTouch";

const NearshoreUS = () => {
  const OurServicesWithCircle = withCircle(
    OurServices,
    {
      top: "-40%",
      right: "-25%",
    },
    OUR_SERVICES
  );
  const { setLang } = useContext(ActualLangContext);
  useEffect(() => {
    setLang("en");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Possumus | Nearshore latam US</title>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-QYDKZ1E8HJ"
          ></script>
          <script>{`window.dataLayer = window.dataLayer || [];   function gtag(){dataLayer.push(arguments);}   gtag('js', new Date());   gtag('config', 'G-QYDKZ1E8HJ'); gtag('event', 'page_view', {'page_location': 'nearshore-latam-us',})`}</script>
        </Helmet>
        <div
          id="houstonPpal"
          style={{
            position: "relative",
            zIndex: "999999",
            backgroundColor: "#fff",
          }}
        >
          <PpalHeader
            text={{
              titulo: "Scale to new heights ",
              bajada: "and trust your technology to the champions.",
              iso1: "Our processes are accredited with ISO 9001:2015",
              iso2: "certification quality practices for software product development.",
              button: "Our services",
            }}
            backgroundImage={backgroundHeader}
          />
          <InstitutionalPartners />
          <OurTeam data={ourTeamPpalContent} />
          <OurServicesWithCircle />
        </div>
        <h2 id="houstonSoftwareEngineering" className="text-center">
          Software Engineering
        </h2>
        <Col xl={{ span: 7, offset: 4 }} className="sideNavbarContainerHouston">
          <SideNavbarProvider>
            <SideNavbar items={SIDENAVBAR_ITEMS} />
            <SoftwareEngineering data={SOFTWARE_ENGINEERING} />
            <StaffAugmentation id={0} />
            {TITLEANDBULLETSSECTION.map((section, idx) => (
              <TitleAndBullets data={section} id={idx + 1} />
            ))}
          </SideNavbarProvider>
        </Col>
        <div
          style={{
            position: "relative",
            zIndex: "99999",
            backgroundColor: "#fff",
          }}
        >
          <Services data={SERVICES} />
          <Partners />
          <Awards />
          <GetInTouch />
          <DownloadBanner
            text="Discover our services"
            file={brochureFile}
            mockup={brochureMockup}
          />
          <Footer text={FOOTER_TEXT} />
        </div>
      </HelmetProvider>
    </>
  );
};

export default NearshoreUS;
