import { Col } from "react-bootstrap";
import IsoImg from "../../assets/9001_azul.svg";
import { bannerStaff } from "../../config/StaffAugmentation/textPage";
import { useTranslation } from "react-i18next";

export default function IsoCertificate({ button, textColor = "#000" }) {
  const { t } = useTranslation();
  return (
    <Col lg={button ? 6 : 6} sm={12} className="banner-iso">
      <img src={IsoImg} align="left" alt="ISO 9001" />
      <p className="marginLeft iso2015" style={{color: textColor}}>
        {t("buttonsAndAccesories.certificatePart1", {
          certificatePart1: `${bannerStaff.certificatePart1}`,
        })}
        {" "}
        <br></br>
        {t("buttonsAndAccesories.certificatePart2", {
          certificatePart2: `${bannerStaff.certificatePart2}`,
        })}
      </p>
    </Col>
  );
}
