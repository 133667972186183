import React from "react";
import { Button, Card, Col, Image, Row } from "react-bootstrap";
import elipse from "../../assets/Ellipse.svg";
import arrowDown from "../../assets/ArrowDown.svg";
import { useTranslation } from "react-i18next";
import { softwareEngineering } from "../../config/softwareEngineering/textPage";
import { bannerDownload } from "../../config/Commons/textPage";
export const ResearchEngineering = () => {
  const { t } = useTranslation();
  return (
    <>
      <Row className="justify-content-md-center" id="ResearchEngineering">
        <Col lg={10} className="justify-content-center d-flex">
          <Image src={arrowDown} alt="" />
        </Col>
        <Col lg={10}>
          <h2 className="text-center mt-4">
            {t("sectionResearch.title", {
              title: `${softwareEngineering.sectionResearchTitle}`,
            })}
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-md-center text-center spacingSectionSoftwareEngineering">
        <Col lg={9}>
          <h3>
            {t("sectionResearch.paragraph", {
              paragraph: `${softwareEngineering.paragraphResearch}`,
            })}
          </h3>
        </Col>
      </Row>
      <div class="d-none d-sm-none d-lg-block">
        <Row className="justify-content-md-center text-left  mt-4">
          <Col lg={5}>
            <Card bg="light" id="myCard">
              <Card.Header className="titleCard">
                <Image src={elipse} alt="" className="marginElipseColumn1" />
                {t("sectionResearch.titleCard1", {
                  titleCard1: `${softwareEngineering.titleCard1Research}`,
                })}
              </Card.Header>
              <div className="lineCard">
                <hr className="m-0 p-0" />
              </div>
              <Card.Body>
                <Card.Text>
                  {t("sectionResearch.card1Paragraph", {
                    card1Paragraph: `${softwareEngineering.card1ParagraphResearch}`,
                  })}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={1} className="m-0 p-0">
            <div style={{ position: "relative", height: "100%" }}>
              <hr className="lineSeparator" />
              <div className="d-flex lineVertical">
                <div className="vr"></div>
              </div>
            </div>
          </Col>
          <Col lg={5}>
            <Card bg="light" id="myCard">
              <Card.Header className="titleCard">
                <Image src={elipse} alt="" className="marginElipseColumn2" />
                {t("sectionResearch.titleCard2", {
                  titleCard2: `${softwareEngineering.titleCard2Research}`,
                })}
              </Card.Header>
              <div className="lineCard">
                <hr className="m-0 p-0" />
              </div>
              <Card.Body>
                <Card.Text>
                  {t("sectionResearch.card2Paragraph", {
                    card2Paragraph: `${softwareEngineering.card2ParagraphResearch}`,
                  })}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <div class="d-block d-sm-block d-lg-none">
        <Row className="justify-content-md-center text-left  mt-4">
          <Col sm={12} className="m-0 p-0">
            <Card bg="light" id="myCardMobile">
              <div>
                <Image
                  src={elipse}
                  alt=""
                  className="marginElipseColumn1Mobile"
                />
              </div>
              <Card.Header className="titleCard mt-4">
                {t("sectionResearch.titleCard1", {
                  titleCard1: `${softwareEngineering.titleCard1Research}`,
                })}
              </Card.Header>
              <div className="lineCard">
                <hr className="m-0 p-0" />
              </div>
              <Card.Body className="pb-0">
                <Card.Text>
                  {t("sectionResearch.card1Paragraph", {
                    card1Paragraph: `${softwareEngineering.card1ParagraphResearch}`,
                  })}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12} className="m-0 p-0">
            <Card bg="light" id="myCardMobile" className="spacing-card-mobile">
              <div className="justify-content-center d-flex mt-3 mb-3">
                <Image
                  src={elipse}
                  alt=""
                  className="marginElipseColumn1Mobile"
                />
              </div>
              <Card.Header className="titleCard mt-2">
                {t("sectionResearch.titleCard2", {
                  titleCard2: `${softwareEngineering.titleCard2Research}`,
                })}
              </Card.Header>
              <div className="lineCard">
                <hr className="m-0 p-0" />
              </div>
              <Card.Body>
                <Card.Text>
                  {t("sectionResearch.card2Paragraph", {
                    card2Paragraph: `${softwareEngineering.card2ParagraphResearch}`,
                  })}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <Row>
        <Col className="justify-content-center align-items-center d-flex  mt-4">
          <Button
            variant="secondary"
            href="#getInTouch"
            className="paddingButtonMainBanner"
          >
            {t("buttonsAndAccesories.buttonGetFree", {
              buttonGetFree: `${bannerDownload.buttonGetFree}`,
            })}
          </Button>
        </Col>
      </Row>
    </>
  );
};
