import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import imgMainBanner from "../../assets/mainBannerDedicatedTeam.svg";

const MainBannerDedicatedTeam = () => {
  return (
    <Container fluid id="mainbannerDedicatedTeams">
      <Container id="mainbanner">
        <Row>
          <Col lg={8}>
            <h1>Dedicated Teams Service</h1>
            <h3>
              Dedicated Teams Service: Possumus provides all the roles needed to
              complete the team you require.
            </h3>
            <div className="cta">
              <Button variant="primary" href="#whatIs">
                Get started
              </Button>
              <Button variant="secondary" href="#getInTouch">
                Contact us
              </Button>
            </div>
          </Col>
          <Col lg={4}>
            <img
              src={imgMainBanner}
              className="img-fluid"
              alt="Accelerate your digital transformation"
            ></img>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default MainBannerDedicatedTeam;
