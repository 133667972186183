import React from "react";
import { Container, Row, Col, Image, Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { bannerCulture } from "../../config/culture/textPage";
export const BannerCultureMobile = ({
  description,
  title,
  template,
  idContainer,
  idHeader,
  logoPossumus,
  imagePeople,
  titleLandingPart1,
  titleLandingPart2,
}) => {
  const { t } = useTranslation();
  return (
    <Container
      fluid
      id={idContainer}
      className={`${template} d-block d-sm-block d-md-none background-banner-header`}
    >
      <Container>
        <h4 className="titlePageMobile text-center">
          {t("headerBannerCulture.title", {
            title: `${title ? title : ""}`,
          })}
        </h4>
        <Row className="containerBannerCulture">
          <Col id={idHeader} md={9} xl={9} xxl={8} sm={6} lg={5} xs={12}>
            <h2 className="text-center">
              {t("headerBannerCulture.titleLandingPart1", {
               titleLandingPart1: `${titleLandingPart1 ? titleLandingPart1 : ""}`,
              })}
            </h2>
            <h2 className="text-center">
              {t("headerBannerCulture.titleLandingPart2", {
                titleLandingPart2: `${titleLandingPart2 ? titleLandingPart2 : ""}`,
              })}
            </h2>
            {description && (
              <p className="descriptionText text-center">
                {t("headerBannerCulture.description", {
                  description: `${description}`,
                })}
              </p>
            )}
          </Col>
        </Row>
        <Row>
          <Col id={idHeader} md={9} xl={9} xxl={8} sm={6} lg={5} xs={12}>
            {
              imagePeople ? (
                <Image src={imagePeople?.url} alt="people" className="img-fluid" />
              )
              :
              <Spinner />
            }
          </Col>
        </Row>
        <Row>
          <Col
            className="d-flex justify-content-center align-items-center mb-4"
            id={idHeader}
            md={9}
            xl={9}
            xxl={8}
            sm={6}
            lg={5}
            xs={12}
          >
          {
            logoPossumus ? (
              <Image
                src={logoPossumus?.url}
                alt="people"
                className="img-fluid logoPossumusBannerMobile"
              />
              )
            :
            <Spinner/>
          }
 
          </Col>
        </Row>
      </Container>
      <Container id="benefic">
        <Row className="justify-content-center text-center spacingSectionSoftwareEngineering">
          <Col xs={11}>
            <h3 className="descriptionRowPossumus">
              {t("headerBannerCulture.descriptionPossumus1", {
                descriptionPossumus1: `${bannerCulture.descriptionPossumus1}`,
              })}
            </h3>
          </Col>
          <Col xs={11}>
            <h3 className="descriptionRowPossumus">
              <Col xs={12}>
                <h3 className="descriptionRowPossumus mt-3">
                  {t("headerBannerCulture.descriptionPossumus21", {
                    descriptionPossumus21: `${bannerCulture.descriptionPossumus21}`,
                  })}
                  <u>
                    <a
                      href="#exclusiveBenefits"
                      target="_self"
                      className="colorTextLinkCulture"
                    >
                      {t("headerBannerCulture.descriptionPossumus2u1", {
                        descriptionPossumus2u1: `${bannerCulture.descriptionPossumus2u1}`,
                      })}
                    </a>
                  </u>
                  {t("headerBannerCulture.descriptionPossumus22", {
                    descriptionPossumus22: `${bannerCulture.descriptionPossumus22}`,
                  })}
                  <a
                    href="https://careers.possumus.tech/jobs"
                    className="colorTextLink2Culture"
                  >
                    <u>
                      {t("headerBannerCulture.buttonSearches", {
                        buttonSearches: `${bannerCulture.buttonSearches}`,
                      })}
                    </u>
                  </a>
                </h3>
              </Col>
            </h3>
          </Col>
        </Row>

        <Row>
          <Col
            className="justify-content-center align-items-center d-flex  containerButton"
            xs={12}
          >
            <Button
              variant="primary"
              href="#exclusiveBenefits"
              className="buttonCultureMobile"
              size="lg"
            >
              {t("headerBannerCulture.descriptionPossumus2u1", {
                descriptionPossumus2u1: `${bannerCulture.descriptionPossumus2u1}`,
              })}
            </Button>
          </Col>
          <Col
            className="justify-content-center align-items-center d-flex"
            xs={12}
          >
            <Button
              variant="secondary"
              href="https://careers.possumus.tech/jobs"
              className="buttonCultureMobile"
              size="lg"
            >
              {t("headerBannerCulture.buttonSearches", {
                buttonSearches: `${bannerCulture.buttonSearches}`,
              })}
            </Button>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};
