import { Container, Row, Col, Image } from "react-bootstrap";
import Loader from "../../components/commons/loader";
import arrowDown from "../../assets/ArrowDown.svg";
import { useContext } from "react";
import { ContentOrganizationalPoliciesContext } from "../../contexts/ContentOrganizationalPoliciesContext";

const Policies = () => {
  
  const {titleAndSubtitle, itemsPolicies, loadedData} = useContext(ContentOrganizationalPoliciesContext)

  return (
    <Container fluid id="policies">
      <Container>
        <Row className="justify-content-md-center" id="ResearchEngineering">
          <Col lg={10} className="justify-content-center d-flex">
            <Image src={arrowDown} alt="" />
          </Col>
          <Col lg={10} className="top60">
            <p class="sup-title text-uppercase text-center mt-5 ">
              {titleAndSubtitle?.subtitle}
            </p>
            <h4 class="text-center mb-5">{titleAndSubtitle?.title}</h4>
          </Col>
        </Row>

        {loadedData ? (
          itemsPolicies.map((item, idx) => (
            <Row key={idx}>
              <Col xs={12} md={6} >
                <h3> {item.Titulo}</h3>
              </Col>
              <Col xs={12} md={6}>
                <p>{item.Contenido}</p>
              </Col>
            </Row>
          ))
        ) : (
          <Loader />
        )}
      </Container>
    </Container>
  );
};

export default Policies;
