import React from "react";
import { Container, Row } from "react-bootstrap";
import CardWithIsoImage from "../commons/cardWithIsoImage";

const InfoList = ({data}) => {
  const {infoButtons, title} = data
  return (
    <Container id="infoList">
      {title && <p className="sup-title text-uppercase text-center">{title}</p>}
      <Row className="listServices mse justify-content-center">
        {infoButtons?.map((btnInfo) => (
          <CardWithIsoImage text={btnInfo.Button} href={btnInfo.href} />
        ))}
      </Row>
    </Container>
  );
};

export default InfoList;
