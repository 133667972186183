import { useContext } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ContentItaasContext } from "../../contexts/ContentItaasContext";
import ServicesMain from "./servicesIT/ServicesMain";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

const ServicesIT = () => {
  const { servicesIT } = useContext(ContentItaasContext);
  return (
    <Container fluid id="approaches" className="no-padding">
      <Row className="justify-content-md-center">
        <Col lg={12}>
          <h2 className="text-center">{servicesIT.Title}</h2>
        </Col>
      </Row>
      <Row className="justify-content-md-center text-center spacingSectionSoftwareEngineering mt-2">
        <Col lg={6}>
          <ReactMarkdown className="subtitle">
            {servicesIT.Subtitle}
          </ReactMarkdown>
        </Col>
      </Row>
      <ServicesMain />
    </Container>
  );
};

export default ServicesIT;
