import React from "react";
import { Col, Row } from "react-bootstrap";

const ListOnShore = ({ items, title }) => {
  const middleArrayIndex = Math.ceil(items?.length / 2);
  const firstColumn = items?.slice(0, middleArrayIndex);
  const lastColumn = items?.slice(middleArrayIndex);

  return (
    <Row className="justify-content-md-center text-center row-list-devops">
      {title && <h5 style={{textAlign: "start"}}>{title}</h5>}
      <Col lg={6}>
        <div className="list-onshore">
          <ul>
            {firstColumn?.map((item) => (
              <li>{item.Titulo}</li>
            ))}
          </ul>
        </div>
      </Col>
      <Col lg={6}>
        <div className="list-onshore margin-items">
          <ul>
            {lastColumn?.map((item) => (
              <li>{item.Titulo}</li>
            ))}
          </ul>
        </div>
      </Col>
    </Row>
  );
};

export default ListOnShore;
