import { Container, Image } from "react-bootstrap"
import Line from '../../assets/verticalLineBlue.svg'

const DescriptionWithVerticalLine = ({description}) => {
  return (
    <Container fluid className="d-flex align-items-center gap-5 descriptionWithVerticalLine">
        <Image src={Line} alt="Line to separate"/>
        <p style={{marginBottom: '0'}}>{description}</p>
    </Container>
  )
}

export default DescriptionWithVerticalLine