import { useContext } from "react";
import { useEffect } from "react";
import { createContext } from "react";
import { ActualLangContext } from "./ActualLangContext";
import axios from "axios";
import { useState } from "react";
import { AuthStrapiContext } from "./AuthStrapiV3Context";

export const ContentSimplePayLandingContext = createContext({});

export const ContentSimplePayLandingProvider = ({ children }) => {
  const { actualLang } = useContext(ActualLangContext);
  const { token } = useContext(AuthStrapiContext);

  const [loadedData, setLoadedData] = useState(false);
  const [SEOContent, setSEOContent] = useState({});
  const [navegation, setNavegation] = useState({});
  const [headerContent, setHeaderContent] = useState({});
  const [cards, setCards] = useState([]);
  const [features, setFeatures] = useState({});
  const [services, setServices] = useState({});
  const [modalities, setModalities] = useState({});
  const [bannerDownload, setBannerDownload] = useState({});
  const [downloadBrochure, setDownloadBrochure] = useState({});

  useEffect(() => {
    const url = `${process.env.REACT_APP_URL_API}/landing-simple-pay?_locale=${actualLang}`;
    if (token) {
      axios
        .get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          setSEOContent(res.data?.SEOMetaData);
          setNavegation(res.data?.navegationItemsAndTitle[0]);
          setHeaderContent(res.data?.header[0]);
          setCards(res.data?.cards);
          setFeatures(res.data?.itemsAndTitle[0]);
          setServices(res.data?.services[0]);
          setModalities(res.data?.modalities[0]);
          setBannerDownload(res.data?.bannerDownload[0]);
          setDownloadBrochure(res.data?.downloadBrochure);
          setLoadedData(true);
        }).catch(err => console.log(`Ocurrió un error al obtener la data de la landing de Simple Pay: ${err.message}`))
    }
  }, [actualLang, token]);
  return (
    <ContentSimplePayLandingContext.Provider
      value={{
        loadedData,
        SEOContent,
        navegation,
        headerContent,
        cards,
        features,
        services,
        modalities,
        bannerDownload,
        downloadBrochure,
      }}
    >
      {children}
    </ContentSimplePayLandingContext.Provider>
  );
};
