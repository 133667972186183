import React, { Fragment } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { companyStaffSection } from "../../config/StaffAugmentation/textPage";
import { useTranslation } from "react-i18next";

const Company = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Container id="company">
        <Row className="d-flex justify-content-center">
          <Col lg={3} xs={11} className="separatorColStaff">
            <h2 className="mb-5 titleStaffCentered">
              {t("companyStaff.titlecol1", {
                titlecol1: `${companyStaffSection.titlecol1}`,
              })}
            </h2>
            <p>
              {t("companyStaff.paragraphcol1", {
                paragraphcol1: `${companyStaffSection.paragraphcol1}`,
              })}
            </p>
          </Col>
          <Col lg={3} xs={11} className="separatorColStaff">
            <h2 className="mb-5 titleStaffCentered">
              {t("companyStaff.titlecol2", {
                titlecol2: `${companyStaffSection.titlecol2}`,
              })}{" "}
            </h2>
            <p>
              {t("companyStaff.paragraph1col2", {
                paragraph1col2: `${companyStaffSection.paragraph1col2}`,
              })}
            </p>
            <p>
              {t("companyStaff.paragraph2col2", {
                paragraph2col2: `${companyStaffSection.paragraph2col2}`,
              })}
            </p>
          </Col>
          <Col lg={3} xs={11} className="separatorColStaff">
            <h2 className="mb-5 titleStaffCentered">
              {t("companyStaff.titlecol3", {
                titlecol3: `${companyStaffSection.titlecol3}`,
              })}
            </h2>
            <p>
              {t("companyStaff.paragraph1col3", {
                paragraph1col3: `${companyStaffSection.paragraph1col3}`,
                interpolation: { escapeValue: false },
              })}
            </p>
            <p>
              {t("companyStaff.paragraph2col3", {
                paragraph2col3: `${companyStaffSection.paragraph2col3}`,
              })}
            </p>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Company;
