import React, { Fragment } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { bannerDownload } from "../../config/Commons/textPage";
import "../../css/staff.css";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { ContentMicrosoftEcosystemContext } from "../../contexts/ContentMicrosoftEcosystemContext";
import IsoCertificateAndWeLoveDev from "../commons/IsoCertificateAndWeLoveDev";
import Loader from "../commons/loader";

const MSEBanner = ({ titulo, bajada }) => {
  const { t } = useTranslation();

  const { headerContent } = useContext(ContentMicrosoftEcosystemContext);

  return (
    <Fragment>
      <Container fluid id="mainbanner-it" className="background-banner-header">
        <Container>
          <Row className="d-flex justify-content-between">
            <Col lg={7} xs={12} md={10}>
              <h1>{titulo ? titulo : headerContent?.Titulo}</h1>
              <p className="subtitle">
                {bajada ? bajada : headerContent?.Descripcion}
              </p>
              <div className="cta">
                <Button variant="primary" href="#getInTouch">
                  {t("buttonsAndAccesories.buttonGetFree", {
                    buttonGetFree: `${bannerDownload.buttonGetFree}`,
                  })}
                </Button>
              </div>
            </Col>
            <Col lg={5} sm={12}>
              {headerContent.Imagen?.url ? (
                <img
                  src={`${headerContent?.Imagen?.url}`}
                  className="img-center"
                  alt="Accelerate your digital transformation"
                />
              ) : (
                <Loader />
              )}
            </Col>
          </Row>
          <IsoCertificateAndWeLoveDev />
        </Container>
      </Container>
    </Fragment>
  );
};

export default MSEBanner;
