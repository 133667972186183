import React, { Fragment, useContext } from "react";
import StaffBanner from "../components/staff/staffBanner";
import MenuUsa from "../components/commons/menuUsa";
import Staff from "../components/staff/staff";
import SoftwareServices from "../components/staff/softwareServices";
import Technologies from "../components/staff/technologies";
import TimeZone from "../components/staff/timeZone";
import Onshore from "../components/staff/onshore";
import Nearshore from "../components/staff/nearshore";
import Company from "../components/staff/company";
import GetInTouch from "../components/commons/getInTouch";
import "../css/StaffAugmentation.css";
import { LocationAddress } from "../components/usa/locationAddress";
import { Footer } from "../components/commons/footer";
import { ContentStaffAugmentationContext } from "../contexts/ContentStaffAugmentationContext";
import SEOContent from "../components/commons/SEOContent";
import useScrollToSection from "../hooks/useScrollToSection";

const StaffAugmentation = () => {
  const {SEOContent: SEOData} = useContext(ContentStaffAugmentationContext)
   useScrollToSection()
  return (
    <Fragment>
        <SEOContent SEOData={SEOData} />
        <MenuUsa />
        <StaffBanner />
        <Staff />
        <SoftwareServices />
        <Technologies />
        <TimeZone />
        <Onshore />
        <Nearshore />
        <Company />
        <GetInTouch />
        <LocationAddress />
        <Footer />
    </Fragment>
  );
};

export default StaffAugmentation;
