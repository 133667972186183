import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export const BannerSuport = ({ imageBanner }) => {
  return (
    <Container className="justify-content-md-center align-items-center d-flex mb-4 bannerSuport">
      <div class="d-none d-sm-none d-xl-block">
        <Row className="mt-4 justify-content-md-center align-items-center style-row-desk">
          <Col lg={4}>
            <img
              src={imageBanner}
              className="img-fluid float-left m-2"
              alt="Software Engineering"
            />
          </Col>
          <Col lg={6}>
            <h2 className="how-to-ask-desk">Cómo pedir</h2>
            <h2 className="text-suport-desk">Soporte</h2>
            <h5 className="p-suport">
              Ante cualquier duda o inconveniente pueden consultarnos a través
              de los siguientes canales:
            </h5>
          </Col>
        </Row>
      </div>
      <div class="d-block d-sm-block d-xl-none m-4 ">
        <Row className="mb-4">
          <Col md={12} className="d-flex justify-content-center">
            <img
              src={imageBanner}
              className="m-2"
              alt="Software Engineering"
              width={200}
              height={200}
            />
          </Col>
          <Col md={12}>
            <h2 className="how-to-ask-mobile">Cómo pedir</h2>
            <h2 className="text-suport-mobile">Soporte</h2>
            <h2 className="p-suport">
              Ante cualquier tema estamos siempre en teams, teléfono o por mail.
            </h2>
          </Col>
        </Row>
      </div>
    </Container>
  );
};
