import React, { useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import isopk1 from "../../assets/iso1pk.svg";
import isopk2 from "../../assets/iso2pk.svg";
import { useTranslation } from "react-i18next";
import { softwareEngineering } from "../../config/softwareEngineering/textPage";
export const CardsSoftwareEngineering = () => {
  const { t } = useTranslation();
  const [imgIso1, setImgIso1] = useState(isopk1);
  const [imgIso2, setImgIso2] = useState(isopk1);
  const [imgIso3, setImgIso3] = useState(isopk1);
  return (
    <>
      <Container
        id="servicesSoftware"
        className="d-none d-sm-none d-md-none d-lg-block"
      >
        <Row className="justify-content-md-center text-left listSoftEngineering">
          <Col lg={4} sm={12}>
            <a
              href="#ResearchEngineering"
              target="_self"
              className="link-style"
            >
              <Card
                onMouseLeave={() => setImgIso1(isopk1)}
                onMouseEnter={() => setImgIso1(isopk2)}
                className="action-card"
              >
                <div className="clearfix d-lg-flex">
                  <img
                    src={imgIso1}
                    className="img-fluid float-left mr-1"
                    alt="Research Engineering"
                  ></img>
                  <div>
                    <h4>
                      {t("menuSoftwareEngineering.cardDeskOnePart1", {
                        cardDeskOnePart1: `${softwareEngineering.cardDeskOnePart1}`,
                      })}
                      <br />
                      {t("menuSoftwareEngineering.cardDeskOnePart2", {
                        cardDeskOnePart2: `${softwareEngineering.cardDeskOnePart2}`,
                      })}
                    </h4>
                  </div>
                </div>
              </Card>
            </a>
          </Col>
          <Col lg={4}>
            <a href="#ProductEngineering" target="_self" className="link-style">
              <Card
                onMouseLeave={() => setImgIso2(isopk1)}
                onMouseEnter={() => setImgIso2(isopk2)}
                className="action-card"
              >
                <div className="clearfix d-lg-flex">
                  <img
                    src={imgIso2}
                    className="img-fluid float-left mr-1"
                    alt="Software Engineering"
                  ></img>
                  <div>
                    <h4>
                      {t("menuSoftwareEngineering.cardDeskTwoPart1", {
                        cardDeskTwoPart1: `${softwareEngineering.cardDeskTwoPart1}`,
                      })}
                      <br />
                      {t("menuSoftwareEngineering.cardDeskTwoPart2", {
                        cardDeskTwoPart2: `${softwareEngineering.cardDeskTwoPart2}`,
                      })}
                    </h4>
                  </div>
                </div>
              </Card>
            </a>
          </Col>
          <Col lg={4}>
            <a
              href="#IterativeDevelopment"
              target="_self"
              className="link-style"
            >
              <Card
                onMouseLeave={() => setImgIso3(isopk1)}
                onMouseEnter={() => setImgIso3(isopk2)}
                className="action-card"
              >
                <div className="clearfix d-lg-flex">
                  <img
                    src={imgIso3}
                    className="img-fluid float-left mr-1"
                    alt="Software Engineering"
                  ></img>
                  <div>
                    <h4>
                      {t("menuSoftwareEngineering.cardDeskThreePart1", {
                        cardDeskThreePart1: `${softwareEngineering.cardDeskThreePart1}`,
                      })}
                      <br />
                      {t("menuSoftwareEngineering.cardDeskThreePart2", {
                        cardDeskThreePart2: `${softwareEngineering.cardDeskThreePart2}`,
                      })}
                    </h4>
                  </div>
                </div>
              </Card>
            </a>
          </Col>
        </Row>
      </Container>
      <Container
        id="servicesSoftware"
        className="d-block d-sm-block d-md-block d-lg-none mt-4"
      >
        <Row className="justify-content-md-center text-left listSoftEngineeringMobile">
          <Col md={9} sm={12}>
            <a
              href="#ResearchEngineering"
              target="_self"
              className="link-style"
            >
              <Card
                onMouseLeave={() => setImgIso1(isopk1)}
                onMouseEnter={() => setImgIso1(isopk2)}
                className="action-card"
              >
                <Row className="g-0">
                  <Col className="col-3">
                    <img
                      src={imgIso1}
                      className="img-fluid"
                      alt="Research Engineering"
                    ></img>
                  </Col>
                  <Col className="col-8">
                    <h4 className="m-0">
                      {t("menuSoftwareEngineering.titleSectionOne", {
                        titleSectionOne: `${softwareEngineering.titleSectionOne}`,
                      })}
                    </h4>
                  </Col>
                </Row>
              </Card>
            </a>
          </Col>
          <Col md={9} sm={12}>
            <a href="#ProductEngineering" target="_self" className="link-style">
              <Card
                onMouseLeave={() => setImgIso2(isopk1)}
                onMouseEnter={() => setImgIso2(isopk2)}
                className="action-card"
              >
                <Row className="g-0">
                  <Col className="col-3">
                    <img
                      src={imgIso2}
                      className="img-fluid"
                      alt="Software Engineering"
                    ></img>
                  </Col>
                  <Col className="col-8">
                    <h4 className="m-0">
                      {t("menuSoftwareEngineering.titleSectionTwo", {
                        titleSectionTwo: `${softwareEngineering.titleSectionTwo}`,
                      })}
                    </h4>
                  </Col>
                </Row>
              </Card>
            </a>
          </Col>
          <Col md={9} sm={12}>
            <a
              href="#IterativeDevelopment"
              target="_self"
              className="link-style"
            >
              <Card
                onMouseLeave={() => setImgIso3(isopk1)}
                onMouseEnter={() => setImgIso3(isopk2)}
                className="action-card"
              >
                <Row className="g-0">
                  <Col className="col-3">
                    <img
                      src={imgIso3}
                      className="img-fluid"
                      alt="Iterative Development"
                    ></img>
                  </Col>
                  <Col className="col-8">
                    <h4 className="m-0">
                      {t("menuSoftwareEngineering.titleSectionThree", {
                        titleSectionThree: `${softwareEngineering.titleSectionThree}`,
                      })}
                    </h4>
                  </Col>
                </Row>
              </Card>
            </a>
          </Col>
        </Row>
      </Container>
    </>
  );
};





