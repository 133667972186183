import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Loader from "../../components/commons/loader";
import MarkdownView from "react-showdown";

export const Modulos365 = ({ dataModules }) => {
  return (
    <Container fluid id="modulos">
      <Container>
        <Row className="titulomodulos">
          <Col md={8} xs={12}>
            <h2>{dataModules.Titulo}</h2>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Col md={6} className="contentModulos">
            <div className="individualModulo">
              <div className="text-center"></div>

              <div className="descripcionModulo">
                <MarkdownView markdown={dataModules.Descripcion_Modulo_01} />
              </div>
            </div>
          </Col>

          <Col md={6} className="imagenModulos">
            {dataModules.Imagen_Destacada ? (
              <img
                src={dataModules.Imagen_Destacada.url}
                className="img-fluid"
                alt=""
              />
            ) : (
              <Loader />
            )}
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Modulos365;
