import { Container, Row, Col, Image } from "react-bootstrap";
import Rocket from "../../assets/landings/techTalkSolid/rocket.png";

export const DetailsTwoParticipants = ({
  upTitle,
  title,
  subTitle,
  description,
  speakerOne,
  speakerTwo,
  imgSpeakerOne,
  imgSpeakerTwo,
  ctaAlign,
  ctaBackground,
  ctaImage,
}) => {
  return (
    <Container fluid id="detailsLandingSolid">
      <img src={Rocket} className="rocketSolid" alt="" />
      <Container>
        <h2 className="text-center">{upTitle}</h2>
        <h1 className="text-center">{title}</h1>

        <Row className="textLanding text-center">
          <Col md={{ span: 8, offset: 2 }}>
            <p>{subTitle}</p>
            <h5>{description}</h5>
          </Col>
        </Row>
        <Row id="participants">
          <Col md={2}>
            <img src={imgSpeakerOne} className="img-fluid" alt="" />
          </Col>
          <Col md={4}>
            <h2>{speakerOne}</h2>
            <p>Software Tech Lead, Possumus </p>
          </Col>

          <Col md={2}>
            <img src={imgSpeakerTwo} className="img-fluid" alt="" />
          </Col>
          <Col md={4}>
            <h2>{speakerTwo}</h2>
            <p>Software Tech Lead, Possumus </p>
          </Col>
        </Row>
      </Container>
      <Container id="ctaLandingBdd" style={{ backgroundColor: ctaBackground }}>
        <Row className="d-flex justify-content-center align-items-center">
          <Col md={8}>
            <h2 className="text-white">
              Cuando crecemos en lo individual potenciamos a nuestros equipos.
            </h2>
          </Col>
          <Col md={4} className="p-0">
            <Image src={ctaImage} className="img-fluid " />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default DetailsTwoParticipants;
