import React, { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../../components/commons/loader";
import { Container, Row, Col, Image, Spinner } from "react-bootstrap";
import Line from "../../assets/Line.png";
export const HeaderLanding = ({ title, phone, template }) => {
  const [possumusLogo, setPossumusLogo] = useState([]);
  const [hState, sethState] = useState("top");

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_URL_API}/institucional`).then((res) => {
      setPossumusLogo(res.data.Assets.Logo);
    });

    var lastVal = 0;
    window.onscroll = function () {
      let y = window.scrollY;
      if (y > lastVal) {
        sethState("down");
      }
      if (y < lastVal) {
        sethState("up");
      }
      if (y === 0) {
        sethState("top");
      }
      lastVal = y;
    };
  }, []);
  return (
    <Container
      id="headerLanding"
      className={template + " header  fixed  " + hState}
    >
      <Container fluid>
        <Row className="headerDesktop text-center">
          <Col className="p-0" xl={2}>
            {possumusLogo.url ? (
              <>
                <img
                  src={possumusLogo.url}
                  alt={possumusLogo.alternativeText}
                  className="LogoHeader"
                ></img>
              </>
            ) : (
              <Loader />
            )}
          </Col>
          <Col
            xl={2}
            className=" text-left p-lg-0 d-none d-xl-block m-xl-3 mt-xl-0 styleLine"
          >
            <Image src={Line} />
          </Col>
          {title ? (
            <Col
              xl={5}
              className="titleHeader p-0 d-none d-xl-block d-xl-flex positionTitleHead"
            >
              {`${title}`}
            </Col>
          ) : (
            <Spinner animation="border" variant="light" />
          )}

          <Col xl={4} className="telLanding titleHeader p-md-0">
            {phone}
          </Col>
        </Row>

        <Row className="d-block d-sm-block d-md-none">
          <Col xs={4} sm={4}>
            {possumusLogo.url ? (
              <img
                src={possumusLogo.url}
                alt={possumusLogo.alternativeText}
                className="LogoHeader"
              ></img>
            ) : (
              <Loader />
            )}
          </Col>
          <Col xs={6} className="hidePhoneXs">
            {phone}
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default HeaderLanding;
