import React, { Fragment } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import FourImg from "../../assets/nearshore.svg";
import { useTranslation } from "react-i18next";
import { nearshoreStaffSection } from "../../config/StaffAugmentation/textPage";

const Nearshore = () => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Container fluid id="Nearshore">
        <Container>
          <Row>
            <Col>
              <h2 className="text-center">
                {t("nearshoreStaff.title", {
                  title: `${nearshoreStaffSection.title}`,
                })}
              </h2>
            </Col>
          </Row>
          <Row className="mt-5 d-dlex justify-content-center">
            <Col lg={5} sm={12} className="vertical-align-center">
              <h3>
                {t("nearshoreStaff.paragraph1", {
                  paragraph1: `${nearshoreStaffSection.paragraph1}`,
                  interpolation: { escapeValue: false },
                })}
              </h3>
            </Col>
            <Col lg={5} sm={12}>
              <img src={FourImg} className="img-center" alt="Nearshore"></img>
            </Col>
          </Row>
          <Row className="row-width mt-5 d-flex justify-content-center">
            <Col lg={9} xl={9}>
              <h4 className="text-center mb-5 mt-5">
                {t("nearshoreStaff.title2", {
                  title2: `${nearshoreStaffSection.title2}`,
                })}
              </h4>
              <p className="text-center">
                {t("nearshoreStaff.paragraph1t2", {
                  paragraph1t2: `${nearshoreStaffSection.paragraph1t2}`,
                })}
              </p>
            </Col>
          </Row>
          <Row>
            <Col className="text-center">
              <Button variant="secondary" href="#getInTouch">
                {t("nearshoreStaff.buttonLets", {
                  buttonLets: `${nearshoreStaffSection.buttonLets}`,
                  interpolation: { escapeValue: false },
                })}
              </Button>
            </Col>
          </Row>
        </Container>
      </Container>
    </Fragment>
  );
};

export default Nearshore;
