import React from "react";
import { Col, Image } from "react-bootstrap";

const MemberCard = ({ member }) => {
  const { image, name, charge } = member;
  return (
    <Col
      lg="6"
      md="12"
      className="d-flex flex-column flex-lg-row gap-3 align-items-center houstonMember"
    >
      <Image src={image} alt="Houston member" />
      <Col lg={6} md="12">
        <h3>{name}</h3>
        <p>
          {charge + ", "}
          {charge.length < 20 && <br></br>}
          Possumus
        </p>
      </Col>
    </Col>
  );
};

export default MemberCard;
