import { BannerMobile } from "./bannerMobile";
import { BannerDesk } from "./bannerDesk";

const MainBannerSoftwareServices = ({titulo, bajada}) => {
  return (
    <>
      <BannerDesk titulo={titulo} bajada={bajada}/>
      <BannerMobile titulo={titulo} bajada={bajada}/>
    </>
  );
};

export default MainBannerSoftwareServices;
