export const bannerAboutUsSection = {
      title: "What moves us",
      description1: "We believe that digitalization helps the evolution of the world, and integrates people and companies, making their development easier.",
      description2: "In everything that we do, we bet for innovation and we do it through careful integration of human resources and technology.​",
};

export const valueProposalAboutUsSection = {
    toptitle: "About us",
    title: "Value proposal",
    description1: "Our approach is based on careful digitalization processes that turn into technological solutions for all kind of companies.",
    description2: "We co-create, alongside with our clients, high-quality software products, to address the digital transformation, focusing on design and usability.",
};

export const ourWorkAboutUsSection = {
    title: "Our Work",
    description1: "We develop digital products and services, focused on the users’ best experience.",
};