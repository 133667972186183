import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import isoKoiron from "../../assets/iso.svg";
import { valueProposalAboutUsSection } from "../../config/about/textPage";
import { useTranslation } from "react-i18next";

const ValueProposal = () => {
  const { t } = useTranslation();

  return (
    <Container id="valueProposal">
      <Row className="justify-content-md-center text-center">
        <Col lg="8">
          <h5 class="top-title text-uppercase mb-5">
            {t("valueProposalAboutUs.toptitle", {
              toptitle: `${valueProposalAboutUsSection.toptitle}`,
            })}
          </h5>
          <img src={isoKoiron} alt="Koiron" className="img-fluid iso"></img>
          <h2 class="border-bottom text-center">
            {t("valueProposalAboutUs.title", {
              title: `${valueProposalAboutUsSection.title}`,
            })}
          </h2>
          <h3>
            {t("valueProposalAboutUs.description1", {
              description1: `${valueProposalAboutUsSection.description1}`,
            })}
            ​
          </h3>
          <h3>
            {t("valueProposalAboutUs.description2", {
              description2: `${valueProposalAboutUsSection.description2}`,
            })}
          </h3>
        </Col>
      </Row>
    </Container>
  );
};

export default ValueProposal;
