import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { createContext } from "react";
import { ActualLangContext } from "./ActualLangContext";
import { AuthStrapiContext } from "./AuthStrapiV3Context";

export const ContentMicrosoftEcosystemContext = createContext({});

export const ContentMicrosoftEcosystemProvider = ({ children }) => {
  const [SEOContent, setSEOContent] = useState({});
  const [headerContent, setHeaderContent] = useState({});
  const [infoButtons, setInfoButtons] = useState([]);
  const [introductionContent, setIntroductionContent] = useState({});
  const [solutionsContent, setSolutionsContent] = useState({});
  const [microsoftDynamicsToolsContent, setMicrosoftDynamicsToolsContent] =
    useState({});
  const [
    approachesAndMethodologiesContent,
    setApproachesAndMethodologiesContent,
  ] = useState({});
  const [loadedData, setLoadedData] = useState(false);

  const { actualLang } = useContext(ActualLangContext);
  const { token } = useContext(AuthStrapiContext);

  useEffect(() => {
    setLoadedData(false);
    const url = `${process.env.REACT_APP_URL_API}/pages-microsoft-ecosystem?_locale=${actualLang}`;
    if (token) {
      axios
        .get(url, { headers: { Authorization: `Bearer ${token}` } })
        .then((res) => {
          setSEOContent(res.data?.SEOMetaData);
          setHeaderContent(res.data.Header[0]);
          setInfoButtons(res.data.InfoButtons);
          setIntroductionContent(res.data.Introduction[0]);
          setSolutionsContent(res.data.Solutions[0]);
          setMicrosoftDynamicsToolsContent(res.data.MicrosoftDynamicsTools[0]);
          setApproachesAndMethodologiesContent(
            res.data.ApproachesMethodologies[0]
          );
          setLoadedData(true);
        }).catch(err => console.log(`Ocurrio un error al obtener los datos de la pagina de MSE: ${err.message}`))
    }
  }, [actualLang, token]);
  return (
    <ContentMicrosoftEcosystemContext.Provider
      value={{
        loadedData,
        SEOContent,
        headerContent,
        infoButtons,
        introductionContent,
        solutionsContent,
        microsoftDynamicsToolsContent,
        approachesAndMethodologiesContent,
      }}
    >
      {children}
    </ContentMicrosoftEcosystemContext.Provider>
  );
};
