export const sreEs = {
  mainbannerSre: {
    title:
      "Garantiza el rendimiento, escalabilidad y seguridad de tus sistemas con nuestra experiencia en SRE",
    subtitle:
      "Nuestro equipo SRE ofrece servicios personalizados, escalables y seguros, que optimizan la gestión de sistemas en la nube y maximizan la eficiencia empresarial.",
  },
  infoHome: {
    title: "Cloud Engineering & DevOps Practice",
    paragraph1: `Trabajamos para lograr procesos de transformación digital ágiles en las empresas,
     impactando en la experiencia de usuario 
     e impulsando la evolución de los activos de software,
      a través de procesos dinámicos y seguros, que entregan
       productos de alto valor e impactan en las personas con
        experiencias positivas. `,
    paragraph2: `Nuestro trabajo se enfoca en garantizar la disponibilidad, el rendimiento,
     la escalabilidad y la resiliencia de las aplicaciones, así como también la automatización
      de tareas y la mejora continua de la plataforma para optimizar el tiempo de desarrollo
     y reducir el riesgo de errores en producción. `,
    paragraph3: `En`,
    company: " Possumus,",
    paragraph4: ` trabajamos con metodología ágil,
     para crear experiencias positivas a través de la tecnología, con valor`,
  },
  menuSre: {
    cardDeskOnePart1: "Platform",
    cardDeskOnePart2: "Engineering",
    cardDeskTwoPart1: "Cloud",
    cardDeskTwoPart2: "infrastructure management & monitoring",
    cardDeskThreePart1: "Cloud native application",
    cardDeskThreePart2: "development",
    titleSectionOne: "Platform Engineering",
    titleSectionTwo: "Cloud infrastruture management & monitoring",
    titleSectionThree: "Cloud native application development",
  },
  sectionPlatformSre: {
    title: "Ingeniería de Plataforma",
    paragraph: `Diseñamos, implementamos y 
    mantenemos plataformas internas de desarrollo (IDP) que reducen 
    la carga de generación de conocimiento y permiten el “self service” del equipo de desarrollo.
     Optimizan costos, reducen la complejidad de gestión y aumentan la confiabilidad.`,
    item1: "Arquitectura de Software.",
    item2: "Microservicios y Serverless.",
    item3: "Contenedores y Orquestación.",
    item4: "Automatización de procesos CI/CD.",
    item5: "DevSecOps (Desarrollo, Seguridad y Operaciones).",
    item6: "API Thinking.",
  },
  sectionCloudSre: {
    title: "Gestión y monitoreo de infraestructura Cloud",
    paragraph: `Brindamos optimización de infraestructura en la nube
     asegurando un rendimiento óptimo de las aplicaciones desplegadas en ella.
      Ofrecemos una amplia gama de soluciones para satisfacer las necesidades
       únicas de cada cliente, desde la implementación y configuración
        de infraestructura en la nube hasta la monitorización en tiempo real y
     la resolución de problemas de manera proactiva.`,
    item1: "Consultoría Cloud.",
    item2: "Aprovisionamiento de infraestructura (IaC).",
    item3: "Monitoreo y alertas.",
    item4: "Tecnologías Cloud Native.",
  },
  approachesSre: {
    title: "Enfoques y metodologías",
    paragraph: `Estas prácticas nos ayudan a obtener una comprensión
     profunda de las necesidades de nuestros clientes y
     a desarrollar soluciones que satisfacen esas necesidades de manera efectiva, tales como:`,
  },
  sectionApproachesSre: {
    titleCard1: "Product Discovery",
    card1Paragraph: `Utilizamos diferentes herramientas,
     actividades y prácticas para determinar tempranamente cuál es la necesidad real a cubrir,
     evitando basar soluciones en supuestos, reduciendo riesgos y maximizando resultados.`,
    titleCard2: "Prueba de concepto",
    card2Paragraph:
      "A través de la POC confirmamos la factibilidad técnica de la iniciativa del cliente.",
    titleCard3: "Producto Mínimo Viable",
    card3Paragraph: `Desarrollamos un proyecto con las
     suficientes funcionalidades para ser probado,
      y que clarifique el potencial de las funcionalidades,
     a partir de la experiencia de los primeros usuarios.`,
    titleCard4: "Mantenimiento Evolutivo",
    card4Paragraph: `Con el enfoque puesto en la evaluación, 
      mantenimiento, actualización y evolución de cada producto,
       realizamos procesos de mejoras basados en la innovación constante y
       el pragmatismo de la metodología de trabajo ágil.`,
  },
  cloudProviderSre: {
    title: "Cloud Provider",
  },
  cloudnativeSre: {
    title: "Desarrollo de aplicaciones Cloud Native",
    paragraph: `Desarrollamos aplicaciones nativas en la nube utilizando tecnologías y prácticas avanzadas, para aprovechar al máximo sus beneficios y características, para implementar y escalar de forma rápida, segura y eficiente, brindando agilidad y eficiencia empresarial.`,
  },
};
export const sreEn = {
  mainbannerSre: {
    title: "{{title}}",
    subtitle: "{{subtitle}}",
  },
  infoHome: {
    title: "{{title}}",
    paragraph1: "{{paragraph1}}",
    paragraph2: "{{paragraph2}}",
    paragraph3: "{{paragraph3}}",
    company: "{{company}}",
    paragraph4: "{{paragraph4}}",
  },
  menuSre: {
    cardDeskOnePart1: "{{cardDeskOnePart1}}",
    cardDeskOnePart2: "{{cardDeskOnePart2}}",
    cardDeskTwoPart1: "{{cardDeskTwoPart1}}",
    cardDeskTwoPart2: "{{cardDeskTwoPart2}}",
    cardDeskThreePart1: "{{cardDeskThreePart1}}",
    cardDeskThreePart2: "{{cardDeskThreePart2}}",
    titleSectionOne: "{{titleSectionOne}}",
    titleSectionTwo: "{{titleSectionTwo}}",
    titleSectionThree: "{{titleSectionThree}}",
  },
  sectionPlatformSre: {
    title: "{{title}}",
    paragraph: "{{paragraph}}",
    item1: "{{item1}}",
    item2: "{{item2}}",
    item3: "{{item3}}",
    item4: "{{item4}}",
    item5: "{{item5}}",
    item6: "{{item6}}",
  },
  sectionCloudSre: {
    title: "{{title}}",
    paragraph: "{{paragraph}}",
    item1: "{{item1}}",
    item2: "{{item2}}",
    item3: "{{item3}}",
    item4: "{{item4}}",
  },
  approachesSre: {
    title: "{{title}}",
    paragraph: "{{paragraph}}",
  },
  sectionApproachesSre: {
    titleCard1: "{{titleCard1}}",
    card1Paragraph: "{{card1Paragraph}}",
    titleCard2: "{{titleCard2}}",
    card2Paragraph: "{{card2Paragraph}}",
    titleCard3: "{{titleCard3}}",
    card3Paragraph: "{{card3Paragraph}}",
    titleCard4: "{{titleCard4}}",
    card4Paragraph: "{{card4Paragraph}}",
  },
  cloudProviderSre: {
    title: "{{title}}",
  },
  cloudnativeSre: {
    title: "{{title}}",
    paragraph: "{{paragraph}}",
  },
};
