import React, { useState } from "react";
import { Button, Col } from "react-bootstrap";
import isopk1 from "../../assets/iso1pk.svg";
import isopk2 from "../../assets/iso2pk.svg";

const CardWithIsoImage = ({ text, href, hover }) => {
  const [imgIso1, setImgIso1] = useState(isopk1);

  hover = hover === false ? hover : true

  return (
    <Col lg={4} style={{zIndex: 10}} id="cardWithIso">
      <Button
        className="card justify-content-center"
        href={href ? `#${href}` : ''}
        onMouseLeave={() => hover && setImgIso1(isopk1)}
        onMouseEnter={() => hover && setImgIso1(isopk2)}
      >
        <div className="clearfix d-flex align-items-center">
          <img
            src={imgIso1}
            className="img-fluid mr-1"
            alt="Soluciones Microsoft Ecosystem"
          ></img>
          <div className="left">
            <h4>{text}</h4>
          </div>
        </div>
      </Button>
    </Col>
  );
};

export default CardWithIsoImage;
