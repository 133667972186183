import React, { useContext } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { ContentLayoutContext } from "../../contexts/ContentLayoutContext";

const Awards = () => {
  const { awardsContent } = useContext(ContentLayoutContext);
  return (
    <Container fluid id="houstonAwardsContainer">
      <div id="awardsBackgroundGrey"></div>
      <Container id="houstonAwards">
        <Row className="awards">
          <Col lg={4} className="awardsContent">
            <h3>{awardsContent?.title}</h3>
            <p>{awardsContent?.description}</p>
            {awardsContent?.buttons.map((btn) => (
              <Button variant={btn.variant} href={btn.href}>
                {btn.textButton}
              </Button>
            ))}
          </Col>
          <Col lg="6">
            <Row>
              {awardsContent?.award.map((award, idx) => (
                <Col lg={4} key={idx} className="award">
                  <Image src={award.image.data.attributes.url} />
                  <p className="awardText">{award.description}</p>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Awards;
