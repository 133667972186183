import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import WeloveImg from "../../assets/logos/WLT.svg";
import { useTranslation } from "react-i18next";
import { getInTouch } from "../../config/getInTouch/textPage";

export const ContactMain = () => {
  const { t } = useTranslation();
  return (
    <>
      <Container fluid id="ContactMain" className="background-banner-header">
        <Row>
          <Col lg={6} sm={12}></Col>
          <Col lg={6} sm={12}>
            <img
              src={WeloveImg}
              className="img-right"
              alt="Possumus We love dev"
            ></img>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col lg={12}>
            <h2 className="text-center mt-4">
              {t("getInTouchBanner.title", {
                title: `${getInTouch.title}`,
              })}
            </h2>
          </Col>
        </Row>
        <Row className="justify-content-md-center text-center">
          <Col lg={8}>
            <h3>
              {t("getInTouchBanner.paragraph", {
                paragraph: `${getInTouch.paragraph}`,
              })}
            </h3>
          </Col>
        </Row>
      </Container>
    </>
  );
};
