import axios from "axios";
import { createContext, useEffect, useState } from "react";

export const AuthStrapiContext = createContext({})

export const AuthStrapiProvider = ({children})=>{
    const [token, setToken] = useState('')
    const url = `${process.env.REACT_APP_URL_API}/auth/local`;
        
    async function getToken(){
        try {
            const {data} = await axios.post(url, {
                "identifier": "front",
                "password": "sz56afZpSVkLqR7"
              })
            if(data){
                setToken(data.jwt)
            }
        } catch (error) {
            console.log(error.message)
        }
    }

    useEffect(()=>{
        getToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    return <AuthStrapiContext.Provider value={{token}}>
        {children}
    </AuthStrapiContext.Provider>
}