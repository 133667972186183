import React from 'react'
import { Container, Row } from 'react-bootstrap'
import Bullet from './bulllet'
import { useNearScreen } from '../../hooks/useNearScreen'
import { useEffect } from 'react'
import { useContext } from 'react'
import { SideNavbarContext } from '../../contexts/SideNavbarContext'

const TitleAndBullets = ({data, id}) => {
  const {title, bullets} = data
  
  const [isNear, ref] = useNearScreen({rootMargin:'-300px'})
  
  const {handleItemSelected, itemSelected} = useContext(SideNavbarContext)
  
  useEffect(()=>{
    if(isNear){
      handleItemSelected(id)
    }else{
      if(id === itemSelected){
        handleItemSelected(0)
      }
    }
  },[isNear, handleItemSelected, id, itemSelected])
  return (
    <Container className='houstonTitleAndBulletSections' id={data.id} ref={ref}>
      <h3>{title}</h3>
      <Row>
        {bullets.map((bullet, idx) => (
          <Bullet title={bullet.title} description={bullet.description} key={idx} />
        ))}
      </Row>
    </Container>
  )
}

export default TitleAndBullets